import { Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../../components/Accordion';
import HandoverForm from '../../CreatePurchaseTransaction/components/HandoverForm';

const HandoverTab = () => {
    const { t } = useTranslation(['purchaseTransactionsPage']);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion
                    title={t('purchaseTransactionsPage:accordionTitle.handover')}
                    useCardDesign={!isSmall}
                    defaultExpanded
                >
                    <HandoverForm />
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default HandoverTab;
