import { Box, IconButton, Typography, useMediaQuery, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '../../../../Session';
import { UserType } from '../../../../api';
import MobileAppBar from '../../../../components/MobileAppBar';
import useCommonStyles from '../../../../useCommonStyles';

type ViewBiddingSessionDetailHeaderProps = {
    title: string;
    goToEdition: () => void;
    downloadPdf?: () => void;
    downloadExcel?: () => void;
};

const ViewBiddingSessionDetailHeader = ({
    title,
    goToEdition,
    downloadPdf,
    downloadExcel,
}: ViewBiddingSessionDetailHeaderProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const history = useHistory();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const currentUser = useCurrentUser();

    if (isSmall) {
        return (
            <MobileAppBar>
                <IconButton color="secondary" onClick={history.goBack}>
                    <ArrowBackIosIcon className={commonStyles.bigFontSize} />
                </IconButton>
                <Box display="flex" justifyContent="center" width="100%">
                    <Typography className={commonStyles.fontWeightBold} color="secondary">
                        {title}
                    </Typography>
                </Box>
                <Box display="flex">
                    {currentUser.type !== UserType.SaleConsultant && currentUser.type !== UserType.Dealer && (
                        <IconButton color="secondary" onClick={downloadPdf}>
                            <GetAppIcon />
                        </IconButton>
                    )}
                    <IconButton color="secondary" onClick={goToEdition}>
                        <EditIcon />
                    </IconButton>
                </Box>
            </MobileAppBar>
        );
    }

    return (
        <Box alignItems="center" className={commonStyles.header} display="flex">
            <Typography className={commonStyles.title}>{title}</Typography>
            <Box ml="auto">
                <Box className={commonStyles.buttonGroup}>
                    {currentUser.type !== UserType.SaleConsultant && currentUser.type !== UserType.Dealer && (
                        <>
                            <Button color="secondary" onClick={downloadExcel} size="large" variant="contained">
                                <GetAppIcon fontSize="small" /> {t('common:exportInExcel')}
                            </Button>
                            <Button color="secondary" onClick={downloadPdf} size="large" variant="contained">
                                <GetAppIcon fontSize="small" /> {t('common:exportInPdf')}
                            </Button>
                        </>
                    )}
                    <Button color="secondary" onClick={goToEdition} size="large" variant="contained">
                        {t('common:edit')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default ViewBiddingSessionDetailHeader;
