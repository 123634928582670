import { i18n as I18n } from 'i18next';
import { I18nextProvider } from 'react-i18next';
import Bootstrap, { BootstrapProps } from './Bootstrap';
import MainRouter from './MainRouter';
import SessionProvider from './Session';
import AlertProvider from './components/AlertProvider';
import FlutterProvider from './components/FlutterProvider';
import LoadingProvider from './components/LoadingProvider';
import { RuntimeProvider, RuntimeConfig } from './runtimeConfig';

export type AppProps = {
    i18n: I18n;
    runtime: RuntimeConfig;
    createApolloClient: BootstrapProps['createApolloClient'];
};

const App = ({ i18n, runtime, createApolloClient }: AppProps) => (
    <RuntimeProvider runtime={runtime}>
        <FlutterProvider>
            <I18nextProvider i18n={i18n}>
                <LoadingProvider>
                    <AlertProvider>
                        <Bootstrap createApolloClient={createApolloClient}>
                            <SessionProvider>
                                <MainRouter />
                            </SessionProvider>
                        </Bootstrap>
                    </AlertProvider>
                </LoadingProvider>
            </I18nextProvider>
        </FlutterProvider>
    </RuntimeProvider>
);

export default App;
