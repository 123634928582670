import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CreatePurchaseTransactionMutationVariables, useCreatePurchaseTransactionMutation } from '../../../api';
import { useFlutter } from '../../../components/FlutterProvider';
import { useHandleError } from '../../../utilities/handleErrors';
import useValidator from '../../../utilities/useValidator';
import validators from '../../../utilities/validators';
import {
    CreatePurchaseTransactionHeader,
    CreatePurchaseTransactionBody,
    CreatePurchaseTransactionFooter,
    CheckDuplicateCarPlate,
} from './components';

export type CreatePurchaseTransactionFormValues = {
    handover: CreatePurchaseTransactionMutationVariables['fields']['handover'] & {
        targetHandoverDate?: Date;
        targetHandoverTime?: Date;
        handoverLocationField: { main: string; other?: string };
    };
} & CreatePurchaseTransactionMutationVariables['fields'];

const preparePayload = (
    values: CreatePurchaseTransactionFormValues
): CreatePurchaseTransactionMutationVariables['fields'] => {
    // we need to override the handover object so extract it
    const { handover, ...fields } = values;

    const { handoverLocationField, handoverLocation, ...handoverFields } = handover;

    return {
        handover: {
            ...handoverFields,
            handoverLocation: handoverLocationField.other || handoverLocationField.main,
            targetHandoverDateTime: null, // always null on creation
        },
        ...fields,
    };
};

const formValidator = validators.compose(
    validators.requiredString('vehicle.number'),
    validators.requiredNumber('vehicle.mileage'),
    validators.requiredString('customer.ownerIdType'),
    validators.requiredString('customer.ownerId'),
    validators.requiredDate('vehicle.intendedDeregistrationDate'),
    validators.requiredString('vehicle.make'),
    validators.requiredString('vehicle.model'),
    validators.requiredString('vehicle.primaryColour'),
    validators.requiredNumber('vehicle.manufacturingYear'),
    validators.requiredString('vehicle.engineNumber'),
    validators.requiredString('vehicle.chassisNumber'),
    validators.requiredString('vehicle.maximumPowerOutput'),
    validators.requiredNumber('vehicle.openMarketValue'),
    validators.requiredDate('vehicle.originalRegistrationDate'),
    validators.requiredDate('vehicle.firstRegistrationDate'),
    validators.requiredNumber('vehicle.transferCount'),
    validators.requiredNumber('vehicle.actualARFPaid'),
    validators.requiredDate('vehicle.coeExpiryDate'),
    validators.requiredString('vehicle.coeCategory'),
    validators.requiredNumber('vehicle.coePeriodYear'),
    validators.requiredString('handover.personResponsible'),
    validators.requiredString('vehicle.importMethod'),

    validators.only(
        ({ vehicle: { parfEligibility } }) => parfEligibility === true,
        validators.requiredDate('vehicle.parfEligibilityDate')
    )
);

export type CreatePurchaseTransactionProps = {
    initialValues: CreatePurchaseTransactionFormValues;
    checkDuplicateCarPlate: CheckDuplicateCarPlate;
};

const CreatePurchaseTransaction = ({ initialValues, checkDuplicateCarPlate }: CreatePurchaseTransactionProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [createPurchaseTransaction] = useCreatePurchaseTransactionMutation();
    const history = useHistory();
    const { capabilities } = useFlutter();
    const { hasOneMonitoring } = capabilities;

    const [showForm, setShowForm] = useState(!hasOneMonitoring);

    const onSubmit = useHandleError(
        async (values: CreatePurchaseTransactionFormValues) => {
            if (!values.vehicle.isOneMotoringData) {
                const isDuplicated = await checkDuplicateCarPlate(values.vehicle);
                if (isDuplicated) {
                    // do nothing;
                    return;
                }
            }

            // prepare payload for the mutation
            const fields = preparePayload(values);
            // execute the mutation
            await createPurchaseTransaction({ variables: { fields } });
            // then go back from where we came
            history.goBack();
        },
        [createPurchaseTransaction, history]
    );

    const validate = useValidator(formValidator);

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
            {() => (
                <Form>
                    <CreatePurchaseTransactionHeader showForm={showForm} />
                    <CreatePurchaseTransactionBody
                        checkDuplicateCarPlate={checkDuplicateCarPlate}
                        hasOneMonitoring={hasOneMonitoring}
                        setShowForm={setShowForm}
                        showForm={showForm}
                    />
                    {isSmall && <CreatePurchaseTransactionFooter showForm={showForm} />}
                </Form>
            )}
        </Formik>
    );
};

export default CreatePurchaseTransaction;
