import { Grid } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FullSaleTransactionDataFragment, useGetSaleTransactionsQuery } from '../../../../../api';
import ListPagination from '../../../../../components/ListPagination';
import NoRecordsFound from '../../../../../components/NoRecordsFound';
import SaleTransactionHistoryCard from './SaleTransactionHistoryCard';

export type SaleTransactionHistoricalReferenceProps = {
    saleTransaction: FullSaleTransactionDataFragment;
    historicalReferenceFilter: string[];
};

const pageSize = 9;

type ListContext = {
    make: string;
    model: string;
    originalRegistrationDate?: Date;
    page: number;
};

const initialContext: ListContext = {
    make: '',
    model: '',
    originalRegistrationDate: null,
    page: 0,
};

const SaleTransactionHistoricalReferences = ({
    historicalReferenceFilter,
    saleTransaction,
}: SaleTransactionHistoricalReferenceProps) => {
    const { t } = useTranslation('historicalReferenceFilterOptions');

    const [{ page, make, model, originalRegistrationDate }, setListContext] = useState<ListContext>(initialContext);

    const { data } = useGetSaleTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            page: { limit: pageSize, offset: page * pageSize },
            filter: {
                make,
                model,
                originalRegistrationDate,
                pastOfferId: saleTransaction.id,
                exceptIds: [saleTransaction.id],
            },
        },
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    const setActivePage = useCallback(
        (newPage: number) => {
            setListContext(state => ({ ...state, page: newPage }));
        },
        [setListContext]
    );

    useEffect(() => {
        const makeFilter = historicalReferenceFilter.find(
            filter => filter === t('historicalReferenceFilterOptions:make.value')
        );

        const modelFilter = historicalReferenceFilter.find(
            filter => filter === t('historicalReferenceFilterOptions:model.value')
        );

        const registrationFilter = historicalReferenceFilter.find(
            filter => filter === t('historicalReferenceFilterOptions:registration.value')
        );

        setListContext(state => ({
            ...state,
            make: makeFilter ? saleTransaction?.vehicle?.make : '',
            model: modelFilter ? saleTransaction?.vehicle?.model : '',
            originalRegistrationDate: registrationFilter
                ? (saleTransaction?.vehicle?.originalRegistrationDate as Date)
                : null,
        }));
    }, [historicalReferenceFilter, setListContext, saleTransaction, t]);

    const count = data?.saleTransactions?.count || 0;
    const historicalReferences =
        data?.saleTransactions?.items.filter(historicalReference => historicalReference.id !== saleTransaction.id) ||
        [];

    if (count === 0) {
        return <NoRecordsFound />;
    }

    return (
        <Grid spacing={3} container>
            {historicalReferences.map(historicalReference => (
                <Grid key={historicalReference.id} xs={12} item>
                    <SaleTransactionHistoryCard
                        historicalReferenceTransaction={historicalReference}
                        saleTransaction={saleTransaction}
                    />
                </Grid>
            ))}
            <Grid xs={12} item>
                <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
            </Grid>
        </Grid>
    );
};

export default SaleTransactionHistoricalReferences;
