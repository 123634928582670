import { Box, Grid, CardMedia, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import cs from 'classnames';
import React, { useState } from 'react';
import { cardDefaultImage } from '../../../../server/schema/constants';
import { useCurrentUser } from '../../../Session';
import { FullSaleTransactionDataFragment, UserType, BiddingStatus } from '../../../api';
import TabPanel from '../../../components/TabPanel';
import usePublic from '../../../utilities/usePublic';
import DesktopMenuTabs from '../DesktopMenuTabs';
import MobileMenuTabs from '../MobileMenuTabs';
import BiddingLiveDuration from './components/BiddingLiveDuration';
import BiddingTab from './components/BiddingTab';
import HandoverTab from './components/HandoverTab';
import TransactionTab from './components/TransactionTab';
import VehicleInfo from './components/VehicleInfo';
import VehicleTab from './components/VehicleTab';

export type ViewSaleTransactionBodyProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

const a11yProps = (index: string) => ({
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(8),
            },
        },
        body: {
            [theme.breakpoints.down('sm')]: {
                paddingTop: theme.spacing(3),
                paddingBottom: theme.spacing(3),
            },
        },
        carImage: {
            height: theme.spacing(30),
            borderRadius: theme.spacing(1),
            [theme.breakpoints.down('sm')]: {
                borderRadius: theme.spacing(0),
            },
        },
        carImageContain: {
            backgroundSize: 'contain',
        },
    })
);

const ViewSaleTransactionBody = ({ saleTransaction }: ViewSaleTransactionBodyProps) => {
    const styles = useStyles();
    const currentUser = useCurrentUser();
    const [value, setValue] = useState(currentUser.type !== UserType.Dealer ? 'transaction' : 'vehicle');

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const defaultImage = usePublic(cardDefaultImage);

    const showBiddingLiveDuration =
        currentUser.type === UserType.Dealer &&
        [BiddingStatus.Upcoming, BiddingStatus.Current].includes(saleTransaction.latestBiddingSession?.status);

    return (
        <>
            <Box className={styles.root} mb={isSmall ? 10 : 0}>
                {isSmall && (
                    <Box position="relative">
                        <CardMedia
                            className={cs([
                                styles.carImage,
                                !saleTransaction.frontPagePhoto?.signedUrl && styles.carImageContain,
                            ])}
                            image={saleTransaction.frontPagePhoto?.signedUrl || defaultImage}
                        />
                        {showBiddingLiveDuration && <BiddingLiveDuration transaction={saleTransaction} />}
                    </Box>
                )}
                <Box className={styles.body} pl={isSmall ? 1 : 0} pr={isSmall ? 1 : 0}>
                    <Box mt={showBiddingLiveDuration ? 6 : 0}>
                        <Grid spacing={2} container>
                            <Grid lg={5} md={5} sm={12} xs={12} item>
                                <Box pl={isSmall ? 2 : 0} pr={isSmall ? 2 : 0}>
                                    <VehicleInfo saleTransaction={saleTransaction} />
                                </Box>
                            </Grid>
                            <Grid lg={7} md={7} sm={12} xs={12} item>
                                {!isSmall && (
                                    <DesktopMenuTabs
                                        a11yProps={a11yProps}
                                        setValue={setValue}
                                        stage={saleTransaction.stage}
                                        value={value}
                                    />
                                )}
                                <Box pl={isSmall ? 0.5 : 0} pr={isSmall ? 0.5 : 0}>
                                    {currentUser.type !== UserType.Dealer && (
                                        <TabPanel index="transaction" value={value}>
                                            <TransactionTab saleTransaction={saleTransaction} />
                                        </TabPanel>
                                    )}
                                    <TabPanel index="vehicle" value={value}>
                                        <VehicleTab saleTransaction={saleTransaction} />
                                    </TabPanel>
                                    <TabPanel index="bidding" value={value}>
                                        <BiddingTab saleTransaction={saleTransaction} />
                                    </TabPanel>
                                    <TabPanel index="handover" value={value}>
                                        <HandoverTab saleTransaction={saleTransaction} />
                                    </TabPanel>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
            {isSmall && (
                <MobileMenuTabs a11yProps={a11yProps} setValue={setValue} stage={saleTransaction.stage} value={value} />
            )}
        </>
    );
};

export default ViewSaleTransactionBody;
