import { Button, FormControl, FormHelperText, Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FullSaleTransactionDataFragment,
    SaleCancelReason,
    SaleTransactionStage,
    useCancelSalesTransactionMutation,
} from '../../../../api';
import Dialog from '../../../../components/Dialog';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import OutlinedSelectField from '../../../../components/fields/OutlinedSelectField';
import { useHandleError } from '../../../../utilities/handleErrors';
import useValidator from '../../../../utilities/useValidator';
import validators from '../../../../utilities/validators';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            padding: '0px',
        },
        formControl: {
            marginTop: '40px',
        },
        dialogContent: {
            minHeight: '100px',
        },
    })
);

export type ActionCancelTransactionProps = {
    transaction?: FullSaleTransactionDataFragment;
    component?: React.ComponentType<{ onClick: () => void }>;
};

const MAXCHAR = 160;

const formValidator = validators.compose(validators.requiredString('reason'), validators.maxChar('message', MAXCHAR));

export const useCancelFormatter = () => {
    const { t } = useTranslation(['saleTransactionsPage']);

    const formatReason = useCallback(
        (reason: SaleCancelReason) => {
            switch (reason) {
                case SaleCancelReason.NotPurchase:
                    return t('saleTransactionsPage:sessionDetails.cancelReason.notPurchase');

                case SaleCancelReason.SellPrivately:
                    return t('saleTransactionsPage:sessionDetails.cancelReason.sellPrivately');

                case SaleCancelReason.KeepCar:
                    return t('saleTransactionsPage:sessionDetails.cancelReason.keepCar');

                case SaleCancelReason.Others:
                    return t('saleTransactionsPage:sessionDetails.cancelReason.others');

                default:
                    throw new Error('Invalid cancel reason');
            }
        },
        [t]
    );

    const formatReasonWithMessage = useCallback(
        (reason: SaleCancelReason, message?: string) => {
            switch (reason) {
                case SaleCancelReason.NotPurchase:
                case SaleCancelReason.SellPrivately:
                case SaleCancelReason.KeepCar:
                    return formatReason(reason);

                case SaleCancelReason.Others:
                    return t('saleTransactionsPage:sessionDetails.cancelReason.othersWithMessage', { message });

                default:
                    // throw new Error('Invalid cancel reason');
                    return '';
            }
        },
        [formatReason, t]
    );

    return { formatReason, formatReasonWithMessage };
};

const ActionCancelTransaction = ({ transaction, component: Component }: ActionCancelTransactionProps) => {
    const [isShown, setShown] = useState(false);
    const { t } = useTranslation(['saleTransactionsPage']);

    const [cancelSalesTransaction] = useCancelSalesTransactionMutation();

    const styles = useStyles();

    const onSubmit = useHandleError(
        async ({ message, reason }) => {
            await cancelSalesTransaction({
                variables: {
                    message,
                    reason,
                    salesTransactionId: transaction.id,
                },
            });

            setShown(false);
        },
        [cancelSalesTransaction, transaction]
    );

    const onCancel = useCallback(() => setShown(false), []);

    const validate = useValidator(formValidator);

    const initialValues = useMemo(() => ({}), []);

    const reasonOptions = useMemo(
        () => [
            {
                label: t('saleTransactionsPage:sessionDetails.cancelReason.notPurchase'),
                value: SaleCancelReason.NotPurchase,
            },
            {
                label: t('saleTransactionsPage:sessionDetails.cancelReason.sellPrivately'),
                value: SaleCancelReason.SellPrivately,
            },
            {
                label: t('saleTransactionsPage:sessionDetails.cancelReason.keepCar'),
                value: SaleCancelReason.KeepCar,
            },
            {
                label: t('saleTransactionsPage:sessionDetails.cancelReason.others'),
                value: SaleCancelReason.Others,
            },
        ],
        [t]
    );

    const UsedButtonComponent = useMemo(() => Component ?? Button, [Component]);

    if ([SaleTransactionStage.Cancelled].includes(transaction.stage)) {
        return null;
    }

    return (
        <>
            <UsedButtonComponent color="secondary" onClick={() => setShown(old => !old)} variant="contained">
                {t('saleTransactionsPage:sessionDetails.cancel.action')}
            </UsedButtonComponent>
            <Dialog
                dialogContentClassName={styles.dialogContent}
                maxWidth="sm"
                onCloseClick={onCancel}
                open={isShown}
                title={t('saleTransactionsPage:sessionDetails.cancel.title')}
                fullWidth
            >
                <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form className={styles.container}>
                            <Grid spacing={1} container>
                                <Grid xs={12} item>
                                    <OutlinedSelectField
                                        label={t('saleTransactionsPage:sessionDetails.cancel.reason')}
                                        name="reason"
                                        options={reasonOptions}
                                    />
                                </Grid>
                                {values.reason === SaleCancelReason.Others && (
                                    <Grid xs={12} item>
                                        <OutlinedInputField
                                            color="secondary"
                                            helperText={value => (
                                                <FormHelperText style={{ marginLeft: 'auto' }}>{`${
                                                    value?.length ?? 0
                                                }/${MAXCHAR}`}</FormHelperText>
                                            )}
                                            label={t('saleTransactionsPage:sessionDetails.cancel.message')}
                                            minRows={2}
                                            name="message"
                                            placeholder={t(
                                                'saleTransactionsPage:sessionDetails.cancel.messagePlaceholder'
                                            )}
                                            multiline
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid className={styles.formControl} spacing={0} container>
                                <Grid xs={6} item>
                                    <FormControl fullWidth>
                                        <Button
                                            color="primary"
                                            disabled={isSubmitting}
                                            onClick={onCancel}
                                            type="button"
                                        >
                                            {t('common:cancel')}
                                        </Button>
                                    </FormControl>
                                </Grid>
                                <Grid xs={6} item>
                                    <FormControl fullWidth>
                                        <Button
                                            color="secondary"
                                            disabled={isSubmitting}
                                            type="submit"
                                            variant="contained"
                                        >
                                            {t('common:submit')}
                                        </Button>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </>
    );
};

export default ActionCancelTransaction;
