import { Box, Grid, useMediaQuery, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../Session';
import {
    FullPurchaseTransactionDataFragment,
    PurchaseTransactionStage,
    UserType,
    VehicleDiagramComment,
    VehicleDiagramCommentPositionFields,
} from '../../../api';
import useCommonStyles from '../../../useCommonStyles';
import usePublic from '../../../utilities/usePublic';
import AddCommentDialog from './AddCommentDialog';
import Instruction from './Instrucation';
import Marker from './Marker';
import VehicleComment from './VehicleComment';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(10),
                padding: theme.spacing(3),
                paddingRight: theme.spacing(4),
            },
        },
        parent: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        backgroundImage: {
            width: '100%',
        },
        markerWrapper: {
            cursor: 'pointer',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0',
        },
        vehicleDiagramRoot: {
            position: 'relative',
        },
    })
);

export type VehicleDiagramBodyProps = {
    comments: VehicleDiagramComment[];
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

const VehicleDiagramBody = ({ comments, purchaseTransaction }: VehicleDiagramBodyProps) => {
    const commonStyles = useCommonStyles();
    const [openAddCommentDialog, setOpenAddCommnetDialog] = React.useState(false);

    const currentUser = useCurrentUser();

    const [position, setPosition] = React.useState<VehicleDiagramCommentPositionFields>({ top: 0, left: 0 });

    const { t } = useTranslation(['purchaseTransactionsPage']);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const vehicle = usePublic('assets/images/vehicleDiagram.png');

    const styles = useStyles();

    const vehicleElementRef = React.useRef(null);

    const onAddCommentClick = useCallback(
        event => {
            if (
                (purchaseTransaction.stage !== PurchaseTransactionStage.Completed &&
                    purchaseTransaction.stage !== PurchaseTransactionStage.Cancelled) ||
                currentUser.type === UserType.Admin ||
                currentUser.type === UserType.ValuationTeam
            ) {
                const parent = vehicleElementRef.current.getClientRects()[0];

                const left =
                    Math.round((((event.clientX - parent.x - 10) * 100) / parent.width + Number.EPSILON) * 100) / 100;
                const top =
                    Math.round((((event.clientY - parent.y - 10) * 100) / parent.height + Number.EPSILON) * 100) / 100;

                setPosition({ left, top });
                setOpenAddCommnetDialog(true);
            }
        },
        [currentUser.type, purchaseTransaction.stage]
    );

    return (
        <Box className={styles.root} mb={isSmall ? 10 : 0}>
            <Grid className={styles.parent} spacing={3} container>
                <Grid xs={12} item>
                    <Instruction />
                </Grid>
                <Grid lg={5} md={5} sm={12} xs={12} item>
                    <Box className={styles.vehicleDiagramRoot} onClick={onAddCommentClick}>
                        <img ref={vehicleElementRef} alt="" className={styles.backgroundImage} src={vehicle} />
                        <Box className={styles.markerWrapper}>
                            {comments &&
                                comments.length > 0 &&
                                comments.map(comment => (
                                    <Marker
                                        key={comment.id}
                                        index={comment.index}
                                        left={comment.position.left.toString()}
                                        top={comment.position.top.toString()}
                                        isSmall
                                    />
                                ))}
                        </Box>
                    </Box>
                </Grid>
                <Grid lg={7} md={7} sm={12} xs={12} item>
                    <Box mb={2}>
                        <Typography className={commonStyles.fontWeightBold}>
                            {t('purchaseTransactionsPage:vehicleDiagram.commentHeader')}
                        </Typography>
                    </Box>
                    <Box>
                        {comments &&
                            comments.length > 0 &&
                            comments.map(comment => (
                                <VehicleComment
                                    key={comment.id}
                                    comment={comment}
                                    purchaseTransaction={purchaseTransaction}
                                />
                            ))}
                    </Box>
                </Grid>
            </Grid>
            <AddCommentDialog
                onClose={() => setOpenAddCommnetDialog(false)}
                open={openAddCommentDialog}
                position={position}
                purchaseTransaction={purchaseTransaction}
            />
        </Box>
    );
};

export default VehicleDiagramBody;
