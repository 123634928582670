import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import timeZonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';
import { InitOptions } from 'i18next';

dayjs.extend(utcPlugin);
dayjs.extend(timeZonePlugin);
dayjs.extend(duration);

const createDefaultConfig = (timeZone: string): InitOptions => ({
    defaultNS: 'common',
    ns: [],

    interpolation: {
        escapeValue: false,
        format: (value: any, format: string) => {
            if (value instanceof Date) {
                return dayjs(value).tz(timeZone).format(format);
            }

            return value;
        },
        formatSeparator: ',',
    },

    react: {
        useSuspense: false,
    },

    load: 'currentOnly',
});

export default createDefaultConfig;
