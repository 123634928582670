import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { useLoading } from '../../components/LoadingProvider';
import { useHandleError } from '../../utilities/handleErrors';
import useValidator from '../../utilities/useValidator';
import validators from '../../utilities/validators';
import { ActivityHistoryHeader, ActivityHistoryBody, DownloadActivityHistoryFn } from './components';

export type FormValues = {
    fromDate: Date;
    toDate: Date;
};

const initialValues: FormValues = {
    fromDate: null,
    toDate: null,
};

const formValidator = validators.compose(validators.requiredDate('fromDate'), validators.requiredDate('toDate'));

export type ActivityHistoryProps = {
    downloadFile: DownloadActivityHistoryFn;
};

const ActivityHistory = ({ downloadFile }: ActivityHistoryProps) => {
    const { attach } = useLoading();

    const onSubmit = useHandleError(
        async (values: FormValues) => {
            const execute = async () => {
                await downloadFile({
                    filter: {
                        fromDate: dayjs(values.fromDate).startOf('day').toDate(),
                        toDate: dayjs(values.toDate).endOf('day').toDate(),
                    },
                });
            };

            attach(execute());
        },
        [attach, downloadFile]
    );

    const validate = useValidator(formValidator);

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
            {() => (
                <Form>
                    <ActivityHistoryHeader />
                    <ActivityHistoryBody />
                </Form>
            )}
        </Formik>
    );
};

export default ActivityHistory;
