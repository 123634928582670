import { Box } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FullSaleTransactionDataFragment, DownloadFileType } from '../../../api';
import FileViewerProvider from '../../../components/FileViewerProvider';
import { useLoading } from '../../../components/LoadingProvider';
import { BasicLayoutHeader } from '../../../layouts/BasicLayout';
import useBasicStyle from '../../../layouts/BasicLayout/useBasicStyles';
import { DownloadSaleDetailFn } from '../components';
import ViewSaleTransactionBody from './ViewSaleTransactionBody';
import ViewSaleTransactionHeader from './ViewSaleTransactionHeader';

export type ViewSaleTransactionProps = {
    saleTransaction: FullSaleTransactionDataFragment;
    goToEdition: () => void;
    onEdition: boolean;
    downloadSalesTransactionDetailFile: DownloadSaleDetailFn;
};

const ViewSaleTransaction = ({
    goToEdition,
    saleTransaction,
    onEdition,
    downloadSalesTransactionDetailFile,
}: ViewSaleTransactionProps) => {
    const { attach } = useLoading();
    const basicStyles = useBasicStyle();

    const history = useHistory();

    const downloadFile = useCallback(
        (type: DownloadFileType) => {
            const execute = async () => {
                await downloadSalesTransactionDetailFile({
                    downloadFileType: type,
                    transactionId: saleTransaction.id,
                });
            };

            attach(execute());
        },
        [attach, downloadSalesTransactionDetailFile, saleTransaction.id]
    );

    return (
        <FileViewerProvider>
            <BasicLayoutHeader onBack={history.goBack} />
            <Box className={basicStyles.mainDesktop}>
                <ViewSaleTransactionHeader
                    downloadExcel={() => downloadFile(DownloadFileType.Excel)}
                    downloadPdf={() => downloadFile(DownloadFileType.Pdf)}
                    goToEdition={goToEdition}
                    saleTransaction={saleTransaction}
                />
                <ViewSaleTransactionBody saleTransaction={saleTransaction} />
            </Box>
        </FileViewerProvider>
    );
};

export default ViewSaleTransaction;
