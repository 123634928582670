import { Button, FormControl } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../../../../useCommonStyles';
import { OTPFormValues } from './index';

const OTPFormSubmit = () => {
    const { t } = useTranslation(['otpPage']);
    const commonStyles = useCommonStyles();
    const { values, isSubmitting } = useFormikContext<OTPFormValues>();

    return (
        <FormControl fullWidth>
            <Button
                className={commonStyles.removeBoxShadow}
                color="secondary"
                disabled={isSubmitting || values.code.includes('')}
                size="large"
                type="submit"
                variant="contained"
            >
                {t('otpPage:submitButton')}
            </Button>
        </FormControl>
    );
};

export default OTPFormSubmit;
