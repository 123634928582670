import { Typography, Box, BoxProps } from '@material-ui/core';
import { alpha, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import cs from 'classnames';
import useCommonStyles from '../useCommonStyles';

export interface ContentWithLabelProps extends BoxProps {
    label: string;
    content: JSX.Element | React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            color: alpha('#717073', 0.8),
        },
        content: {
            marginLeft: 'auto',
            textAlign: 'right',
            wordBreak: 'break-word',
            color: 'rgb(0, 0, 0, 0.8)',
        },
        content2: {
            color: 'rgb(0, 0, 0, 0.8)',
        },
    })
);

const ContentWithLabel = ({ label, content, ...props }: ContentWithLabelProps) => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const { display, alignItems, ...otherProps } = props;

    return (
        <Box alignItems={alignItems || 'center'} display={display || 'flex'} {...otherProps}>
            <Typography className={cs(styles.label, commonStyles.fontWeightLight)}>{label}</Typography>
            {typeof content === 'string' ? (
                <Typography
                    className={cs(display !== 'block' ? styles.content : styles.content2, commonStyles.fontWeightLight)}
                >
                    {content}
                </Typography>
            ) : (
                content
            )}
        </Box>
    );
};

export default ContentWithLabel;
