import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { FullBiddingDataFragment, useUpdateBiddingMutation, PreviewSaleTransactionDataFragment } from '../../../../api';
import { useHandleError } from '../../../../utilities/handleErrors';
import EditBiddingSessionDetailsBody from './components/EditBiddingSessionDetailsBody';
import EditBiddingSessionDetailsFooter from './components/EditBiddingSessionDetailsFooter';
import EditBiddingSessionDetailsHeader from './components/EditBiddingSessionDetailsHeader';

type EditBiddingSessionDetailProps = {
    bidding: FullBiddingDataFragment;
    goToView: () => void;
};

type EditFormValues = {
    sessionName: string;
    notes: string;
    startPeriod: string | Date;
    endPeriod: string | Date;
    saleTransactions: PreviewSaleTransactionDataFragment[];
};

const EditBiddingSessionDetails = ({ bidding, goToView }: EditBiddingSessionDetailProps) => {
    const [updateBidding] = useUpdateBiddingMutation();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const initialValues: EditFormValues = useMemo(
        () => ({
            sessionName: bidding.sessionName,
            notes: bidding.notes,
            startPeriod: bidding.startPeriod,
            endPeriod: bidding.endPeriod,
            saleTransactions: bidding.biddingSaleTransactions.map(
                saleTransactionEntry => saleTransactionEntry.saleTransaction
            ),
        }),
        [bidding]
    );

    const onSubmit = useHandleError(
        async (values: EditFormValues) => {
            const { sessionName, notes } = values;
            await updateBidding({
                variables: { biddingId: bidding.id, fields: { sessionName, notes } },
            });
            goToView();
        },
        [bidding, goToView]
    );

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {() => (
                <Form>
                    <EditBiddingSessionDetailsHeader goToView={goToView} />
                    <EditBiddingSessionDetailsBody bidding={bidding} />
                    {isSmall && <EditBiddingSessionDetailsFooter />}
                </Form>
            )}
        </Formik>
    );
};

export default EditBiddingSessionDetails;
