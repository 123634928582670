import { Dialog, Slide, Transitions, Box, Typography, Button, DialogTitle, IconButton, Link } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik } from 'formik';
import React, { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import OutlinedDatePickerField from '../../../components/fields/OutlinedDatePickerField';
import useCommonStyles from '../../../useCommonStyles';
import useFilterMenuStyles from '../../SaleTransactions/components/useFilterMenuStyles';

export type FilterDialogProps = {
    open: boolean;
    setOpen: (value: boolean) => void;
    setValues: (bidOpeningOn, bidClosingOn) => void;
    bidOpeningOn: Date | null;
    bidClosingOn: Date | null;
};

const Transition = forwardRef((props: Transitions & { children?: React.ReactElement }, ref: React.Ref<unknown>) => (
    <Slide ref={ref} direction="down" {...props} />
));

const FilterMenu = ({ open, setOpen, setValues, bidOpeningOn, bidClosingOn }: FilterDialogProps) => {
    const { t } = useTranslation(['common', 'saleTransactionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useFilterMenuStyles();

    const onSubmit = useCallback(
        (values: { bidOpeningOn: Date | string; bidClosingOn: Date | string }) => {
            const { bidOpeningOn, bidClosingOn } = values;

            setValues(bidOpeningOn, bidClosingOn);
            setOpen(false);
        },
        [setOpen, setValues]
    );

    return (
        <Dialog TransitionComponent={Transition} open={open}>
            <DialogTitle className={styles.dialogTitle}>
                <Typography color="secondary">{t('saleTransactionsPage:filterBy')}</Typography>

                <IconButton className={styles.closeButton} onClick={() => setOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Formik initialValues={{ bidOpeningOn, bidClosingOn }} onSubmit={onSubmit}>
                {({ setFieldValue }) => (
                    <Form>
                        <Box className={styles.content} display="flex" flexDirection="column">
                            <Box mt={3} width="100%">
                                <OutlinedDatePickerField
                                    color="secondary"
                                    label={t('saleTransactionsPage:filter.bidOpeningOn')}
                                    name="bidOpeningOn"
                                    placeholder={t('saleTransactionsPage:filter.chooseDate')}
                                />
                            </Box>
                            <Link
                                className={commonStyles.displayFlex}
                                color="secondary"
                                component="a"
                                onClick={() => setFieldValue('bidOpeningOn', null)}
                                underline="none"
                            >
                                {t('saleTransactionsPage:button.resetDate')}
                            </Link>
                            <Box mt={3} width="100%">
                                <OutlinedDatePickerField
                                    color="secondary"
                                    label={t('saleTransactionsPage:filter.bidClosingOn')}
                                    name="bidClosingOn"
                                    placeholder={t('saleTransactionsPage:filter.chooseDate')}
                                />
                            </Box>
                            <Link
                                className={commonStyles.displayFlex}
                                color="secondary"
                                component="a"
                                onClick={() => setFieldValue('bidClosingOn', null)}
                                underline="none"
                            >
                                {t('saleTransactionsPage:button.resetDate')}
                            </Link>
                        </Box>
                        <Box className={styles.buttonContainer}>
                            <Button
                                className={styles.applyButton}
                                color="secondary"
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                {t('saleTransactionsPage:filter.applyFilters')}
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default FilterMenu;
