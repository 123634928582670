import { useMediaQuery } from '@material-ui/core';
import { MuiThemeProvider, useTheme } from '@material-ui/core/styles';
import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useCurrentUser } from '../../Session';
import { UserType } from '../../api';
import BasicLayout from '../../layouts/BasicLayout';
import basicTheme from '../../layouts/BasicLayout/basicTheme';
import BiddingSessionDetail from '../../pages/BiddingSessions/BiddingSessionDetail';

interface RouteParams {
    biddingId: string;
}

const BiddingSessionsDetailRoute = () => {
    const currentUser = useCurrentUser();
    const history = useHistory();
    const theme = useTheme();
    const params = useParams<RouteParams>();

    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    if (currentUser.type !== UserType.Admin) {
        return <Redirect to="/403" />;
    }

    if (isSmall) {
        return (
            <MuiThemeProvider theme={basicTheme}>
                <BiddingSessionDetail id={params.biddingId} />
            </MuiThemeProvider>
        );
    }

    return (
        <BasicLayout onBack={history.goBack}>
            <BiddingSessionDetail id={params.biddingId} />
        </BasicLayout>
    );
};

export default BiddingSessionsDetailRoute;
