import React, { useState, useCallback } from 'react';
import { PreviewSaleTransactionDataFragment } from '../../../api';
import ListPagination from '../../../components/ListPagination';
import NoRecordsFound from '../../../components/NoRecordsFound';
import { SaleTransactionsHeader, SaleTransactionsBody } from '../../SaleTransactions/components';
import FilterDialog from './FilterDialog';
import FilterMenu from './FilterMenu';

export type SalesTransactionsTabProps = {
    search: string;
    saleTransactions: PreviewSaleTransactionDataFragment[];
    page: number;
    count: number;
    pageSize: number;
    bidOpeningOn: Date | null;
    bidClosingOn: Date | null;
    setBidOpeningOn: (bidOpeningOn: Date | null) => void;
    setBidClosingOn: (bidClosingOn: Date | null) => void;
    setActivePage: (page: number) => void;
    searchOnChange: (searchValue: string) => void;
    downloadPdf: () => void;
    downloadExcel: () => void;
};

const SalesTransactionsTab = ({
    search,
    saleTransactions,
    page,
    pageSize,
    count,
    bidOpeningOn,
    bidClosingOn,
    setBidOpeningOn,
    setBidClosingOn,
    searchOnChange,
    setActivePage,
    downloadPdf,
    downloadExcel,
}: SalesTransactionsTabProps) => {
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);

    const setValues = useCallback(
        (bidOpeningOn, bidClosingOn) => {
            setBidOpeningOn(bidOpeningOn);
            setBidClosingOn(bidClosingOn);
        },
        [setBidOpeningOn, setBidClosingOn]
    );

    const header = (
        <SaleTransactionsHeader
            downloadExcel={downloadExcel}
            downloadPdf={downloadPdf}
            search={search}
            searchOnChange={searchOnChange}
            setShowFilterDialog={setShowFilterDialog}
            setShowFilterMenu={setShowFilterMenu}
        />
    );

    if (count === 0) {
        return (
            <>
                {header}
                <NoRecordsFound />
            </>
        );
    }

    return (
        <>
            <FilterDialog
                bidClosingOn={bidClosingOn}
                bidOpeningOn={bidOpeningOn}
                open={showFilterDialog}
                setOpen={setShowFilterDialog}
                setValues={setValues}
            />
            <FilterMenu
                bidClosingOn={bidClosingOn}
                bidOpeningOn={bidOpeningOn}
                open={showFilterMenu}
                setOpen={setShowFilterMenu}
                setValues={setValues}
            />
            {header}
            <SaleTransactionsBody saleTransactions={saleTransactions} hideAwardedBitAmount hideAwardedDealer />
            <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
        </>
    );
};

export default SalesTransactionsTab;
