import { saveAs } from 'file-saver';
import { ReactNode, useCallback, useReducer, useEffect } from 'react';
import { useGenerateBiddingSalesTransactionsFileQuery, DownloadFileType } from '../../../api';
import { useFlutter } from '../../../components/FlutterProvider';

export type DownloadBiddingSalesTransactionsFileProps = {
    downloadFileType: DownloadFileType;
    biddingId: string;
};

export type DownloadFile = (props: DownloadBiddingSalesTransactionsFileProps) => Promise<void>;

export type DownloadBiddingSalesTransactionsFileProviderProps = {
    children: (fn: DownloadFile) => JSX.Element | ReactNode;
};

type State = {
    props: DownloadBiddingSalesTransactionsFileProps | null;
    resolve: (() => void) | null;
};

type InitAction = { type: 'init' } & Pick<State, 'props' | 'resolve'>;

type EndAction = { type: 'end' };

type Action = InitAction | EndAction;

const initialState: State = { props: null, resolve: null };

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'init':
            return { props: action.props, resolve: action.resolve };

        case 'end':
            return initialState;

        default:
            return state;
    }
};

const DownloadBiddingSalesTransactionsFileProvider = ({
    children,
}: DownloadBiddingSalesTransactionsFileProviderProps) => {
    const [{ resolve, props }, dispatch] = useReducer(reducer, initialState);
    const { deviceFingerPrint, sendDownloadFile } = useFlutter();

    // get data from the API
    const { data, loading } = useGenerateBiddingSalesTransactionsFileQuery({
        fetchPolicy: 'network-only',
        variables: {
            ...props,
        },
        skip: !props,
    });

    const isGenerating = !!props;

    useEffect(() => {
        if (isGenerating && !loading && data) {
            const execute = async () => {
                if (deviceFingerPrint) {
                    await sendDownloadFile({ signedUrl: data.signedUrl, filename: '' });
                } else {
                    saveAs(data.signedUrl);
                }

                resolve();
            };

            execute();
        }
    }, [loading, isGenerating, data, deviceFingerPrint, sendDownloadFile, resolve]);

    const fn = useCallback(
        (props: DownloadBiddingSalesTransactionsFileProps) =>
            new Promise<void>(resolve => {
                dispatch({ type: 'init', props, resolve });
            }).then(result => {
                // reset
                dispatch({ type: 'end' });

                return result;
            }),
        [dispatch]
    );

    return <>{children(fn)}</>;
};

export default DownloadBiddingSalesTransactionsFileProvider;
