import { Dialog, Slide, Transitions, Box, Typography, Button, DialogTitle, IconButton, Link } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik } from 'formik';
import React, { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PurchaseTransactionStage } from '../../../../api';
import OutlinedDatePickerField from '../../../../components/fields/OutlinedDatePickerField';
import OutlinedSelectField from '../../../../components/fields/OutlinedSelectField';
import useCommonStyles from '../../../../useCommonStyles';
import usePurchaseTransactionStateFilterOptoins from '../../../../utilities/usePurchaseTransactionStateFilterOptoins';
import useFilterMenuStyles from '../../../PurchaseTransactions/components/useFilterMenuStyles';

export type FilterDialogProps = {
    open: boolean;
    setOpen: (value: boolean) => void;
    setValues: (stage, createdAt, targetHandoverDate) => void;
    stage: 'all' | PurchaseTransactionStage;
    createdAt: Date | null;
    targetHandoverDate: Date | null;
};

const Transition = forwardRef((props: Transitions & { children?: React.ReactElement }, ref: React.Ref<unknown>) => (
    <Slide ref={ref} direction="down" {...props} />
));

const FilterMenu = ({ open, setOpen, setValues, stage, createdAt, targetHandoverDate }: FilterDialogProps) => {
    const { t } = useTranslation(['common', 'purchaseTransactionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useFilterMenuStyles();

    const statuses = usePurchaseTransactionStateFilterOptoins();

    const onSubmit = useCallback(
        (values: { stage: string; createdAt: Date | string; targetHandoverDate: Date | string }) => {
            const { stage, createdAt, targetHandoverDate } = values;

            setValues(stage, createdAt, targetHandoverDate);
            setOpen(false);
        },
        [setOpen, setValues]
    );

    return (
        <Dialog TransitionComponent={Transition} open={open}>
            <DialogTitle className={styles.dialogTitle}>
                <Typography color="secondary">{t('purchaseTransactionsPage:filterBy')}</Typography>

                <IconButton className={styles.closeButton} onClick={() => setOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Formik initialValues={{ stage, createdAt, targetHandoverDate }} onSubmit={onSubmit}>
                {({ setFieldValue }) => (
                    <Form>
                        <Box className={styles.content} display="flex" flexDirection="column">
                            <Box mt={3} width="100%">
                                <OutlinedSelectField
                                    color="secondary"
                                    inputProps={{
                                        classes: {
                                            icon: commonStyles.fillSecondary,
                                        },
                                    }}
                                    label={t('purchaseTransactionsPage:state')}
                                    name="stage"
                                    options={statuses}
                                />
                            </Box>
                            <Box mt={3} width="100%">
                                <OutlinedDatePickerField
                                    color="secondary"
                                    label={t('purchaseTransactionsPage:filter.createdAt')}
                                    name="createdAt"
                                    placeholder={t('purchaseTransactionsPage:filter.chooseDate')}
                                />
                            </Box>
                            <Link
                                className={commonStyles.displayFlex}
                                color="secondary"
                                component="a"
                                onClick={() => setFieldValue('createdAt', null)}
                                underline="none"
                            >
                                {t('purchaseTransactionsPage:button.resetDate')}
                            </Link>
                            <Box mt={3} width="100%">
                                <OutlinedDatePickerField
                                    color="secondary"
                                    label={t('purchaseTransactionsPage:filter.targetHandoverDate')}
                                    name="targetHandoverDate"
                                    placeholder={t('purchaseTransactionsPage:filter.chooseDate')}
                                />
                            </Box>
                            <Link
                                className={commonStyles.displayFlex}
                                color="secondary"
                                component="a"
                                onClick={() => setFieldValue('targetHandoverDate', null)}
                                underline="none"
                            >
                                {t('purchaseTransactionsPage:button.resetDate')}
                            </Link>
                        </Box>
                        <Box className={styles.buttonContainer}>
                            <Button
                                className={styles.applyButton}
                                color="secondary"
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                {t('purchaseTransactionsPage:filter.applyFilters')}
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default FilterMenu;
