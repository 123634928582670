import { Box, Grid, useMediaQuery, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleDiagramCommentPositionFields } from '../../../../api';
import useCommonStyles from '../../../../useCommonStyles';
import usePublic from '../../../../utilities/usePublic';
import Instruction from '../../../PurchaseTransactions/VehicleDiagramComment/Instrucation';
import Marker from '../../../PurchaseTransactions/VehicleDiagramComment/Marker';
import AddCommentDialog from './AddCommentDialog';
import VehicleComment from './VehicleComment';

export type VehicleDiagramComment = {
    id?: string;
    index?: number;
    comment: string;
    position: {
        top: number;
        left: number;
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        parent: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        backgroundImage: {
            width: '100%',
        },
        markerWrapper: {
            cursor: 'pointer',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0',
        },
        vehicleDiagramRoot: {
            position: 'relative',
        },
    })
);

export type VehicleDiagramProps = {
    comments: VehicleDiagramComment[];
    addComment?: (value: VehicleDiagramComment) => void;
    removeComment?: (index: number) => void;
    isEditable?: boolean;
};

const VehicleDiagram = ({ comments, addComment, removeComment, isEditable }: VehicleDiagramProps) => {
    const commonStyles = useCommonStyles();
    const [openAddCommentDialog, setOpenAddCommentDialog] = React.useState(false);

    const [position, setPosition] = React.useState<VehicleDiagramCommentPositionFields>({ top: 0, left: 0 });

    const { t } = useTranslation(['purchaseTransactionsPage']);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const vehicle = usePublic('assets/images/vehicleDiagram.png');

    const styles = useStyles();

    const vehicleElementRef = React.useRef(null);

    const onAddCommentClick = useCallback(event => {
        const parent = vehicleElementRef.current.getClientRects()[0];

        const left = Math.round((((event.clientX - parent.x - 10) * 100) / parent.width + Number.EPSILON) * 100) / 100;
        const top = Math.round((((event.clientY - parent.y - 10) * 100) / parent.height + Number.EPSILON) * 100) / 100;

        setPosition({ left, top });
        setOpenAddCommentDialog(true);
    }, []);

    return (
        <Box className={styles.root} mb={isSmall ? 10 : 0}>
            <Grid className={styles.parent} spacing={3} container>
                <Grid xs={12} item>
                    <Instruction />
                </Grid>
                <Grid lg={5} md={5} sm={12} xs={12} item>
                    <Box className={styles.vehicleDiagramRoot} onClick={onAddCommentClick}>
                        <img ref={vehicleElementRef} alt="" className={styles.backgroundImage} src={vehicle} />
                        <Box className={styles.markerWrapper} style={{ cursor: addComment ? 'pointer' : 'default' }}>
                            {comments &&
                                comments.length > 0 &&
                                comments.map((comment, index: number) => (
                                    <Marker
                                        key={`marker-${index.toString()}`}
                                        index={index + 1}
                                        left={comment.position.left.toString()}
                                        top={comment.position.top.toString()}
                                        isSmall
                                    />
                                ))}
                        </Box>
                    </Box>
                </Grid>
                <Grid lg={7} md={7} sm={12} xs={12} item>
                    <Box mb={2}>
                        <Typography className={commonStyles.fontWeightBold}>
                            {t('purchaseTransactionsPage:vehicleDiagram.commentHeader')}
                        </Typography>
                    </Box>
                    <Box>
                        {comments &&
                            comments.length > 0 &&
                            comments.map((comment, index: number) => (
                                <VehicleComment
                                    key={`comment-${index.toString()}`}
                                    comment={comment}
                                    index={index}
                                    isEditable={isEditable}
                                    onRemove={() => (removeComment ? removeComment(index) : null)}
                                />
                            ))}
                    </Box>
                </Grid>
            </Grid>
            {addComment && (
                <AddCommentDialog
                    addComment={addComment}
                    onClose={() => setOpenAddCommentDialog(false)}
                    open={openAddCommentDialog}
                    position={position}
                />
            )}
        </Box>
    );
};

export default VehicleDiagram;
