import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
    CreateBiddingMutationVariables,
    PreviewSaleTransactionDataFragment,
    useCreateBiddingMutation,
} from '../../api';
import { useAlert } from '../../components/AlertProvider';
import { useHandleError } from '../../utilities/handleErrors';
import useValidator from '../../utilities/useValidator';
import validators from '../../utilities/validators';
import {
    CreateBiddingSessionBody,
    CreateBiddingSessionFooter,
    CreateBiddingSessionHeader,
} from './components/CreateBiddingSession';

export type FormValues = Pick<
    CreateBiddingMutationVariables['fields'],
    'sessionName' | 'notes' | 'startPeriod' | 'endPeriod'
> & {
    saleTransactions: PreviewSaleTransactionDataFragment[];
};

export type CreateBiddingSessionProps = {
    initialValues: FormValues;
};

const CreateBiddingSession = ({ initialValues }: CreateBiddingSessionProps) => {
    const { t } = useTranslation(['biddingSessionsPage']);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();
    const [createBidding] = useCreateBiddingMutation();

    const { show } = useAlert();

    const onSubmit = useHandleError(
        async ({ saleTransactions, ...fields }: FormValues) => {
            if (saleTransactions.length === 0) {
                // display error message
                show('error', t('biddingSessionsPage:alerts.saleTransactionsRequired'));

                return;
            }

            // then execute the mutation
            await createBidding({
                variables: {
                    fields: {
                        ...fields,
                        saleTransactionIds: saleTransactions.map(transaction => transaction.id),
                    },
                },
            });

            // display success message
            show('success', t('biddingSessionsPage:alerts.creationSuccessful'));

            // then go back from where we came
            history.goBack();
        },
        [createBidding, history, show]
    );

    const formValidator = useMemo(
        () =>
            validators.compose(
                validators.requiredString('sessionName'),
                validators.requiredString('notes'),
                validators.requiredDate('startPeriod', t('biddingSessionsPage:session.invalidDate')),
                validators.requiredDate('endPeriod', t('biddingSessionsPage:session.invalidDate'))
            ),
        [t]
    );

    const validate = useValidator(formValidator);

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
            {() => (
                <Form>
                    <CreateBiddingSessionHeader />
                    <CreateBiddingSessionBody />
                    {isSmall && <CreateBiddingSessionFooter />}
                </Form>
            )}
        </Formik>
    );
};

export default CreateBiddingSession;
