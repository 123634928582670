import { Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FullPurchaseTransactionDataFragment, PurchaseTransactionActivityDataFragment } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import ContentWithLabel from '../../../../components/ContentWithLabel';

export type TransactionTabProps = {
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

const TransactionTab = ({ purchaseTransaction }: TransactionTabProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common']);
    const { lastActivity } = purchaseTransaction;
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const getAuthorName = useCallback((activity: PurchaseTransactionActivityDataFragment) => {
        switch (activity.__typename) {
            case 'CreationActivity':
            case 'ValuationActivity':
            case 'PurchaseStageUpdateActivity':
            case 'PurchaseUpdateActivity':
                return activity.author.fullName;

            default:
                return '';
        }
    }, []);

    const useCardDesignForAccordion = !isSmall;

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion
                    title={t('purchaseTransactionsPage:accordionTitle.transactionDetail')}
                    useCardDesign={useCardDesignForAccordion}
                    defaultExpanded
                >
                    <Grid spacing={1} container>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.identifier}
                                label={t('purchaseTransactionsPage:transactionDetails.transactionNo')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.saleConsultant
                                        ? purchaseTransaction.saleConsultant.fullName
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:transactionDetails.assignedSalesConsultant')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={t('common:formats.shortDate', {
                                    date: new Date(purchaseTransaction.createdAt),
                                })}
                                label={t('purchaseTransactionsPage:transactionDetails.createdOn')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.createdBy.fullName}
                                label={t('purchaseTransactionsPage:transactionDetails.createdBy')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={t('common:formats.shortDate', {
                                    date: new Date(purchaseTransaction.updatedAt),
                                })}
                                label={t('purchaseTransactionsPage:transactionDetails.lastUpdatedOn')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.closedAt
                                        ? t('common:formats.shortDate', {
                                              date: new Date(purchaseTransaction.closedAt),
                                          })
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:transactionDetails.closedOn')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.internalRemarks || t('common:blankValue')}
                                display="block"
                                label={t('purchaseTransactionsPage:transactionDetails.internalRemark')}
                            />
                        </Grid>
                    </Grid>
                </Accordion>
            </Grid>

            <Grid xs={12} item>
                <Accordion
                    title={t('purchaseTransactionsPage:accordionTitle.activityLog')}
                    useCardDesign={useCardDesignForAccordion}
                    defaultExpanded
                >
                    {lastActivity && (
                        <Grid spacing={1} container>
                            <Grid xs={12} item>
                                <ContentWithLabel
                                    content={lastActivity.type}
                                    label={t('purchaseTransactionsPage:activity.action')}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <ContentWithLabel
                                    content={getAuthorName(lastActivity)}
                                    label={t('purchaseTransactionsPage:activity.updatedBy')}
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <ContentWithLabel
                                    content={t('common:formats.shortDate', {
                                        date: new Date(lastActivity.date),
                                    })}
                                    label={t('purchaseTransactionsPage:activity.updatedOn')}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default TransactionTab;
