import { AppBar, Toolbar, Box, Typography, useMediaQuery, Button, Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cs from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useBasicStyles from '../../../../layouts/BasicLayout/useBasicStyles';
import useCommonStyles from '../../../../useCommonStyles';

const CreateBiddingSessionHeader = () => {
    const { t } = useTranslation(['biddingSessionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const basicStyles = useBasicStyles();
    const history = useHistory();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { isSubmitting } = useFormikContext();

    if (isSmall) {
        return (
            <AppBar
                className={cs(basicStyles.appBar, basicStyles.lightBoxShadow, basicStyles.appBarShift)}
                elevation={0}
                position="fixed"
            >
                <Toolbar className={basicStyles.toolBar}>
                    <Box zIndex={1}>
                        <Link className={commonStyles.primary2} component="a" onClick={history.goBack} underline="none">
                            {t('common:cancel')}
                        </Link>
                    </Box>
                    <Box display="flex" justifyContent="center" position="absolute" width="100%">
                        <Typography className={cs(commonStyles.bigFontSize)}>
                            {t('biddingSessionsPage:createTitleMobile')}
                        </Typography>
                    </Box>
                    <Box ml="auto">
                        <Button
                            className={commonStyles.primary2}
                            disabled={isSubmitting}
                            style={{ padding: 0, minWidth: 'auto' }}
                            type="submit"
                            variant="text"
                        >
                            {t('common:save')}
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
        );
    }

    return (
        <Box alignItems="center" className={commonStyles.header} display="flex">
            <Typography className={commonStyles.title}>{t('biddingSessionsPage:createTitle')}</Typography>
        </Box>
    );
};

export default CreateBiddingSessionHeader;
