import { Tabs, Tab } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import InfoIcon from '@material-ui/icons/Info';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../Session';
import { SaleTransactionStage, UserType } from '../../api';
import CarIcon from '../../icons/carIcon.svg';

export type DesktopMenuTabsProps = {
    a11yProps: (index: string) => object;
    value: string;
    setValue: (value: string) => void;
    stage: SaleTransactionStage;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderBottom: '1px solid #C4C4C4',
            marginBottom: theme.spacing(3),
        },
    })
);

const DesktopMenuTabs = ({ value, setValue, a11yProps, stage }: DesktopMenuTabsProps) => {
    const { t } = useTranslation(['saleTransactionsPage']);
    const styles = useStyles();

    const currentUser = useCurrentUser();

    return (
        <Tabs
            classes={{ root: styles.root }}
            indicatorColor="secondary"
            onChange={(_, value) => setValue(value)}
            scrollButtons="auto"
            textColor="secondary"
            value={value}
        >
            {currentUser.type !== UserType.Dealer && (
                <Tab
                    icon={<InfoIcon />}
                    label={t('saleTransactionsPage:menu.transaction')}
                    value="transaction"
                    {...a11yProps('transaction')}
                />
            )}
            <Tab
                icon={<CarIcon className="MuiSvgIcon-root" />}
                label={t('saleTransactionsPage:menu.vehicle')}
                value="vehicle"
                {...a11yProps('vehicle')}
            />
            <Tab
                icon={<InfoIcon />}
                label={t('saleTransactionsPage:menu.bidding')}
                value="bidding"
                {...a11yProps('bidding')}
            />
            <Tab
                icon={<AssignmentIndIcon />}
                label={t('saleTransactionsPage:menu.handover')}
                value="handover"
                {...a11yProps('handover')}
            />
        </Tabs>
    );
};

export default DesktopMenuTabs;
