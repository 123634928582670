import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useDeliveryPeriod = (deliveryPeriod: number) => {
    const { t } = useTranslation(['purchaseTransactionsPage']);

    return useMemo(() => {
        switch (deliveryPeriod) {
            case 3:
                return t('purchaseTransactionsPage:filter.bids6Months3');

            case 2:
                return t('purchaseTransactionsPage:filter.bids4Months2');

            default:
                return t('purchaseTransactionsPage:filter.immediate1month');
        }
    }, [deliveryPeriod, t]);
};

export default useDeliveryPeriod;
