import { Grid } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../Session';
import { UserType, useGetUserListByTypeQuery } from '../../../../api';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import OutlinedSelectField from '../../../../components/fields/OutlinedSelectField';
import useCommonStyles from '../../../../useCommonStyles';
import { TransactionTabProps } from './TransactionTab';

const TransactionForm = ({ onEdition }: TransactionTabProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage']);
    const commonStyles = useCommonStyles();
    const currentUser = useCurrentUser();

    const { data: salesConsultantUserData } = useGetUserListByTypeQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            type: UserType.SaleConsultant,
        },
    });

    const { data: valuationTeamUserData } = useGetUserListByTypeQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            type: UserType.ValuationTeam,
        },
    });

    const { data: approverUserData } = useGetUserListByTypeQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            type: UserType.Approver,
        },
    });

    const saleConsultantUserOptions = useMemo(
        () =>
            (salesConsultantUserData?.users?.items || []).map(user => ({
                value: user.id,
                label: user.fullName,
            })),
        [salesConsultantUserData]
    );

    const valuationTeamUserOptions = useMemo(
        () =>
            (valuationTeamUserData?.users?.items || []).map(user => ({
                value: user.id,
                label: user.fullName,
            })),
        [valuationTeamUserData]
    );

    const approverUserOptions = useMemo(
        () =>
            (approverUserData?.users?.items || []).map(user => ({
                value: user.id,
                label: user.fullName,
            })),
        [approverUserData]
    );

    const assignedScOptions = approverUserOptions.concat(valuationTeamUserOptions).concat(saleConsultantUserOptions);

    return (
        <Grid spacing={2} container>
            {currentUser.type === UserType.Admin && (
                <Grid xs={12} item>
                    <OutlinedSelectField
                        color="secondary"
                        inputProps={{
                            classes: {
                                icon: commonStyles.fillSecondary,
                            },
                        }}
                        label={t('purchaseTransactionsPage:transactionDetails.assignedSalesConsultant')}
                        name="saleConsultantId"
                        options={assignedScOptions}
                        placeholder={t('purchaseTransactionsPage:transactionDetails.assignedSalesConsultant')}
                    />
                </Grid>
            )}
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={currentUser.type === UserType.SaleConsultant && onEdition}
                    label={t('purchaseTransactionsPage:transactionDetails.internalRemark')}
                    name="internalRemarks"
                    placeholder={t('purchaseTransactionsPage:transactionDetails.internalRemark')}
                />
            </Grid>
        </Grid>
    );
};

export default TransactionForm;
