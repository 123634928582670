import { Grid, Box, Typography } from '@material-ui/core';
import { alpha, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import cs from 'classnames';
import React, { useCallback } from 'react';
import { useCurrentUser } from '../../../Session';
import {
    FullPurchaseTransactionDataFragment,
    PurchaseTransactionStage,
    useRemoveVehicleDiagramCommentMutation,
    UserType,
    VehicleDiagramComment,
} from '../../../api';
import useCommonStyles from '../../../useCommonStyles';
import Marker from './Marker';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        commentContainer: {
            display: 'flex',
            marginBottom: '10px',
            gap: 10,
            alignItems: 'center',
        },
        comment: {
            display: 'flex',
            flex: '1',
            color: alpha('#000', 0.8),
            '& p': {
                wordBreak: 'break-all',
            },
        },
        removeIcon: {
            color: '#b23f51',
            cursor: 'pointer',
        },
    })
);

export type VehicleCommentProps = {
    comment: VehicleDiagramComment;
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

const VehicleComment = ({ comment, purchaseTransaction }: VehicleCommentProps) => {
    const vehicleDiagramCommentID = comment.id;
    const purchaseTransactionId = purchaseTransaction.id;
    const commonStyles = useCommonStyles();

    const [removeComment] = useRemoveVehicleDiagramCommentMutation({
        variables: { purchaseTransactionId, vehicleDiagramCommentID },
    });

    const styles = useStyles();

    const currentUser = useCurrentUser();

    const onRemoveComment = useCallback(() => {
        if (
            purchaseTransaction.stage !== PurchaseTransactionStage.Completed &&
            purchaseTransaction.stage !== PurchaseTransactionStage.Cancelled
        ) {
            removeComment();
        }
    }, [purchaseTransaction.stage, removeComment]);

    return (
        <Grid lg={6} xs={12} item>
            <Box className={styles.commentContainer}>
                <Box>
                    <Marker key={comment.index} index={comment.index} isSmall={false} />
                </Box>
                <Box className={styles.comment}>
                    <Typography className={cs(commonStyles.fontWeightLight, commonStyles.smallFontSize)}>
                        {comment.comment}
                    </Typography>
                </Box>
                {(currentUser.type === UserType.Admin || currentUser.type === UserType.ValuationTeam) && (
                    <RemoveCircleIcon className={styles.removeIcon} onClick={onRemoveComment} />
                )}
            </Box>
        </Grid>
    );
};

export default VehicleComment;
