import { useState, useEffect } from 'react';
import useBiddingDuration from './useBiddingDuration';

const useLiveDuration = (date?: Date | string) => {
    const compute = useBiddingDuration();

    const [duration, setDuration] = useState(compute(date));

    useEffect(() => {
        const interval = setInterval(() => {
            setDuration(compute(date));
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [setDuration, compute, date]);

    return duration;
};

export default useLiveDuration;
