import { Box, Typography, Button, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cs from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import MobileAppBar from '../../../components/MobileAppBar';
import useBasicStyles from '../../../layouts/BasicLayout/useBasicStyles';
import useCommonStyles from '../../../useCommonStyles';

export type ProfileHeaderProps = {
    goToView: () => void;
};

const ProfileUpdateHeader = ({ goToView }: ProfileHeaderProps) => {
    const { t } = useTranslation(['profilePage, common']);
    const classes = useBasicStyles();
    const commonStyles = useCommonStyles();
    const { isSubmitting } = useFormikContext();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    if (isSmall) {
        return (
            <MobileAppBar>
                <Box display="flex" justifyContent="center" width="100%">
                    <Typography className={cs(commonStyles.fontWeightBold, commonStyles.primary2)}>
                        {t('profilePage:title')}
                    </Typography>
                </Box>
            </MobileAppBar>
        );
    }

    return (
        <Box alignItems="center" className={classes.title} display="flex">
            <Typography>{t('profilePage:editTitle')}</Typography>
            <Box display="flex" ml="auto">
                <Box mr={1}>
                    <Button color="secondary" onClick={goToView} size="large" variant="outlined">
                        {t('common:cancel')}
                    </Button>
                </Box>
                <Box>
                    <Button color="secondary" disabled={isSubmitting} size="large" type="submit" variant="contained">
                        {t('common:save')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default ProfileUpdateHeader;
