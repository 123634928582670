import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FullSaleTransactionDataFragment,
    SalesTransactionAction,
    useUpdateAwardedBiddingEntryMutation,
    useUpdateSaleTransactionToForHandoverMutation,
    useUpdateSaleTransactionToCompletedMutation,
} from '../../../../api';
import Dialog from '../../../../components/Dialog';
import { useHandleError } from '../../../../utilities/handleErrors';
import ViewBiddingEntry from './ViewBiddingEntry';

export type SelectBidDialogProps = {
    open: boolean;
    onClose: () => void;
    transaction: FullSaleTransactionDataFragment;
};

export type FormValues = {
    biddingEntryId?: string;
};

const useStyles = makeStyles(theme =>
    createStyles({
        container: {
            padding: '0 20px',
        },
        formControl: {
            marginTop: '40px',
        },
        radioGroupContainer: {
            flexDirection: 'row',
        },
        radioItem: {
            fontWeight: 700,
            minWidth: 390,
            [theme.breakpoints.down('md')]: {
                minWidth: 'auto',
            },
        },
        rowItem: {
            padding: '8px 0',
            borderBottom: '1px solid #E5E5E5',
        },
        dialog: {
            maxWidth: '500px',
        },
        dialogContent: {
            minHeight: 'auto',
        },
    })
);

const PORSCHE_PREOWNED = 'PORSCHE_PREOWNED';

const SelectBidDialog = ({ transaction, open, onClose }: SelectBidDialogProps) => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const [updateAwardee] = useUpdateAwardedBiddingEntryMutation();
    const [updateSaleTransactionToCompleted] = useUpdateSaleTransactionToCompletedMutation();
    const [updateSaleTransactionToForHandover] = useUpdateSaleTransactionToForHandoverMutation();

    const styles = useStyles();

    const biddingId = transaction.latestBiddingSession?.id;
    const saleTransactionId = transaction.id;

    const entries = transaction.latestBiddingSessionEntry?.topBiddingEntries || [];
    const [selectedBid, setSelectedBid] = useState(entries[0]?.id ?? PORSCHE_PREOWNED);

    const onSubmit = useHandleError(async () => {
        if (selectedBid === PORSCHE_PREOWNED) {
            await updateSaleTransactionToCompleted({
                variables: {
                    transactionId: saleTransactionId,
                    action: SalesTransactionAction.Purchased,
                },
            });
        } else {
            await updateAwardee({
                variables: { biddingId, saleTransactionId, biddingEntryId: selectedBid },
            });

            await updateSaleTransactionToForHandover({
                variables: { transactionId: saleTransactionId },
            });
        }

        onClose();
    }, [biddingId, saleTransactionId, onClose, selectedBid]);

    const onChange = useCallback((entryId: string) => {
        setSelectedBid(entryId);
    }, []);

    return (
        <Dialog
            classes={{ paperWidthSm: styles.dialog }}
            dialogContentClassName={styles.dialogContent}
            maxWidth="sm"
            onCloseClick={onClose}
            open={open}
            title={t('saleTransactionsPage:sessionDetails.selectAwardee')}
        >
            <Formik initialValues={{}} onSubmit={onSubmit}>
                {({ isSubmitting }) => (
                    <Form className={styles.container}>
                        <Grid spacing={1} container>
                            <RadioGroup classes={{ root: styles.radioGroupContainer }} name="dealer">
                                {entries.map((entry, index) => {
                                    const isSelected = selectedBid === entry.id;

                                    const card = <ViewBiddingEntry entry={entry} index={index} />;

                                    const radio = (
                                        <Radio
                                            checked={isSelected}
                                            name="biddingEntryId"
                                            onChange={() => onChange(entry.id)}
                                            value={entry.id}
                                        />
                                    );

                                    return (
                                        <Grid key={entry.id} classes={{ root: styles.rowItem }} sm={12} xs={12} item>
                                            <FormControlLabel control={radio} label={card} />
                                        </Grid>
                                    );
                                })}
                                <Grid classes={{ root: styles.rowItem }} sm={12} xs={12} item>
                                    <FormControlLabel
                                        classes={{ label: styles.radioItem }}
                                        control={
                                            <Radio
                                                checked={selectedBid === PORSCHE_PREOWNED}
                                                name="biddingEntryId"
                                                onChange={() => onChange(PORSCHE_PREOWNED)}
                                                value={PORSCHE_PREOWNED}
                                            />
                                        }
                                        label={t('common:porschePreowned')}
                                    />
                                </Grid>
                            </RadioGroup>
                        </Grid>
                        <Grid className={styles.formControl} spacing={2} container>
                            <FormControl fullWidth>
                                <Button color="secondary" disabled={isSubmitting} type="submit" variant="contained">
                                    {t('common:select')}
                                </Button>
                            </FormControl>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default SelectBidDialog;
