import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useFilterMenuStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            borderBottom: '1px solid #c4c4c4',
            display: 'flex',
            justifyContent: 'space-between',
        },
        content: {
            padding: theme.spacing(3),
            paddingTop: 0,
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
        },
        appltFilters: {
            position: 'fixed',
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            padding: theme.spacing(6),
        },
        applyButton: {
            width: '100%',
        },
        buttonContainer: {
            width: '100%',
            marginBottom: theme.spacing(3),
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
    })
);

export default useFilterMenuStyles;
