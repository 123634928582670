import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../../components/Accordion';
import ManualInformationForm from './ManualInformationForm';

export type ManualInformationAccordionProps = {
    hasOneMonitoring: boolean;
    showForm: boolean;
};

const ManualInformationAccordion = ({ hasOneMonitoring, showForm }: ManualInformationAccordionProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage']);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const useCardDesignForAccordion = !isSmall || (hasOneMonitoring && !showForm);

    return (
        <Accordion
            title={t('purchaseTransactionsPage:accordionTitle.manualInformation')}
            useCardDesign={useCardDesignForAccordion}
            defaultExpanded
        >
            <ManualInformationForm onCreation />
        </Accordion>
    );
};

export default ManualInformationAccordion;
