import { useState } from 'react';
import { useGetPurchaseTransactionQuery } from '../../../api';
import VehicleDiagram from '../VehicleDiagram';
import { DownloadPurchaseTransactionDetailFileProvider } from '../components';
import EditPurchaseTransaction from './EditPurchaseTransaction';
import ViewPurchaseTransaction from './ViewPurchaseTransaction';

export type PurchaseTransactionDetailProps = {
    transactionId: string;
};

const PurchaseTransactionDetail = ({ transactionId }: PurchaseTransactionDetailProps) => {
    const [onEdition, setOnEdition] = useState<boolean>(false);
    const [showVehicleDetails, setShowVehicleDetails] = useState(false);

    const { data } = useGetPurchaseTransactionQuery({
        fetchPolicy: 'cache-and-network',
        variables: { transactionId },
    });

    const purchaseTransaction = data?.purchaseTransaction;

    if (!purchaseTransaction) {
        return null;
    }

    if (onEdition) {
        return (
            <EditPurchaseTransaction goToView={() => setOnEdition(false)} purchaseTransaction={purchaseTransaction} />
        );
    }

    if (showVehicleDetails) {
        return (
            <VehicleDiagram
                comments={purchaseTransaction.valuation.vehicleDiagramComments}
                goToView={() => setShowVehicleDetails(false)}
                purchaseTransaction={purchaseTransaction}
            />
        );
    }

    return (
        <DownloadPurchaseTransactionDetailFileProvider>
            {downloadFile => (
                <ViewPurchaseTransaction
                    downloadPurchaseTransactionDetailFile={downloadFile}
                    goToEdition={() => setOnEdition(true)}
                    goToVehicleDetails={() => setShowVehicleDetails(true)}
                    purchaseTransaction={purchaseTransaction}
                />
            )}
        </DownloadPurchaseTransactionDetailFileProvider>
    );
};

export default PurchaseTransactionDetail;
