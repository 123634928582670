import { Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullSaleTransactionDataFragment } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import ContentWithLabel from '../../../../components/ContentWithLabel';

export type HandoverTabProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

const HandoverTab = ({ saleTransaction }: HandoverTabProps) => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const useCardDesignForAccordion = !isSmall;

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion
                    title={t('saleTransactionsPage:accordionTitle.handoverDetails')}
                    useCardDesign={useCardDesignForAccordion}
                    defaultExpanded
                >
                    <Grid spacing={1} container>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    saleTransaction.handover?.targetHandoverDateTime
                                        ? t('common:formats.shortDate', {
                                              date: new Date(saleTransaction.handover?.targetHandoverDateTime),
                                          })
                                        : t('common:blankValue')
                                }
                                label={t('saleTransactionsPage:handover.targetHandoverDate')}
                            />
                        </Grid>
                    </Grid>
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default HandoverTab;
