import FlutterAction from '../FlutterAction';

export type FlutterCapabilities = {
    hasOneMonitoring: boolean;
    hasFingerPrint: boolean;
    hasFaceID: boolean;
};

export type FlutterStats = {
    deviceFingerPrint: string | null;
};

export type FlutterMeta = FlutterStats & { capabilities: FlutterCapabilities };

const action = new FlutterAction<void, FlutterMeta>('initialize', async response => response);

export default action;
