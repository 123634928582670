import { AppBar, Toolbar, Box, Typography, useMediaQuery, Button, Link, Paper } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import cs from 'classnames';
import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useBasicStyles from '../../../layouts/BasicLayout/useBasicStyles';
import useCommonStyles from '../../../useCommonStyles';
import { Page, useCreateSalesTransactionState } from './CreateSalesTransactionStateProvider';

export type CreateSaleTransactionHeaderProps = {
    currentPage: Page;
};

export const useSubmitLabel = (currentPage: Page) => {
    const { t } = useTranslation(['common']);

    return useMemo(() => {
        switch (currentPage) {
            case Page.VehiclePrefill:
            case Page.VehicleForm:
                return t('common:next');

            default:
                return t('common:save');
        }
    }, [currentPage, t]);
};

const useHeaderStyles = makeStyles(theme => ({
    header: {
        marginBottom: 0,
        paddingBottom: 0,
    },
}));

const CreateSaleTransactionHeader = ({ currentPage }: CreateSaleTransactionHeaderProps) => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const basicStyles = useBasicStyles();
    const headerStyles = useHeaderStyles();
    const history = useHistory();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const { actions } = useCreateSalesTransactionState();

    const { isSubmitting } = useFormikContext();

    const submitLabel = useSubmitLabel(currentPage);

    const onBack = useCallback(() => {
        switch (currentPage) {
            case Page.VehiclePrefill:
                history.goBack();
                break;

            case Page.VehicleForm:
                actions.setCurrentPage(Page.VehiclePrefill);
                break;

            case Page.OfferSession:
                actions.setCurrentPage(Page.VehicleForm);
                break;

            default:
                break;
        }
    }, [currentPage, history, actions]);

    if (isSmall) {
        return (
            <AppBar
                className={cs(basicStyles.appBar, basicStyles.lightBoxShadow, basicStyles.appBarShift)}
                elevation={0}
                position="fixed"
            >
                <Toolbar className={basicStyles.toolBar}>
                    <Box zIndex={1}>
                        <Link className={commonStyles.primary2} component="a" onClick={onBack} underline="none">
                            {currentPage === Page.VehiclePrefill ? t('common:cancel') : t('common:portalBackButton')}
                        </Link>
                    </Box>
                    <Box display="flex" justifyContent="center" position="absolute" width="100%">
                        <Typography className={commonStyles.bigFontSize}>
                            {t('saleTransactionsPage:createTitleMobile')}
                        </Typography>
                    </Box>
                    <Box ml="auto">
                        <Button
                            className={commonStyles.primary2}
                            style={{ padding: 0, minWidth: 'auto' }}
                            type="submit"
                            variant="text"
                        >
                            {submitLabel}
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
        );
    }

    return (
        <>
            <Paper
                className={cs(basicStyles.backPaper, basicStyles.lightBoxShadow, basicStyles.stickyHeader)}
                elevation={0}
            >
                <Link className={cs(basicStyles.linkBack)} component="a" onClick={onBack}>
                    <ArrowBackIosIcon fontSize="small" /> {t('common:portalBackButton')}
                </Link>

                <Box className={basicStyles.rightToolbar} ml="auto">
                    <Box className={cs([commonStyles.buttonGroup])}>
                        {currentPage !== Page.VehiclePrefill && (
                            <Button color="secondary" onClick={history.goBack} variant="outlined">
                                {t('common:cancel')}
                            </Button>
                        )}

                        <Button color="secondary" disabled={isSubmitting} type="submit" variant="contained">
                            {submitLabel}
                        </Button>
                    </Box>
                </Box>
            </Paper>

            <Box
                alignItems="center"
                className={cs([basicStyles.main, commonStyles.header, headerStyles.header])}
                display="flex"
            >
                <Typography className={commonStyles.title}>{t('saleTransactionsPage:createTitle')}</Typography>
            </Box>
        </>
    );
};

export default CreateSaleTransactionHeader;
