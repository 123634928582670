import { Box, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '../../../Session';
import { UserType } from '../../../api';
import useCommonStyles from '../../../useCommonStyles';

const PurchaseTransactionsFooter = () => {
    const commonStyles = useCommonStyles();
    const currentUser = useCurrentUser();
    const history = useHistory();

    const canCreate =
        currentUser.type === UserType.Admin ||
        currentUser.type === UserType.SaleConsultant ||
        currentUser.type === UserType.ValuationTeam ||
        currentUser.type === UserType.Approver;

    if (!canCreate) {
        return null;
    }

    return (
        <Box mt={7}>
            <Box bottom="1rem" position="fixed" right="2rem">
                <Fab
                    classes={{
                        root: commonStyles.fabRoot,
                    }}
                    onClick={() => history.push('/purchaseTransaction/create')}
                    size="large"
                >
                    <AddIcon />
                </Fab>
            </Box>
        </Box>
    );
};

export default PurchaseTransactionsFooter;
