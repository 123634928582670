import { useApolloClient, DocumentNode } from '@apollo/client';
import { saveAs } from 'file-saver';
import { ReactNode, useCallback, useState } from 'react';
import { useFlutter } from './FlutterProvider';
import { useLoadingController } from './LoadingProvider';

export type DownloadFn<Variables> = (props: Variables) => Promise<void>;

export type DownloadProviderProps<Query, Variables> = {
    children: (fn: DownloadFn<Variables>) => JSX.Element | ReactNode;
    getSignedUrl: (response: Query) => string | null;
    query: DocumentNode;
};

const DownloadProvider = <Query, Variables>({
    children,
    query,
    getSignedUrl,
}: DownloadProviderProps<Query, Variables>) => {
    const [loading, setLoading] = useState(false);
    const { deviceFingerPrint, sendDownloadFile } = useFlutter();

    // get the apollo client
    const client = useApolloClient();

    // use the loading controller
    useLoadingController(loading);

    const fn = useCallback(
        async (variables: Variables) => {
            try {
                // initialize loading
                setLoading(true);

                const response = await client.query<Query, Variables>({
                    query,
                    variables,
                    fetchPolicy: 'no-cache',
                });

                const signedUrl = getSignedUrl(response.data);

                if (signedUrl) {
                    if (deviceFingerPrint) {
                        await sendDownloadFile({ signedUrl, filename: '' });
                    } else {
                        saveAs(signedUrl);
                    }
                }
            } finally {
                // reset
                setLoading(false);
            }
        },
        [query, getSignedUrl, setLoading, client, sendDownloadFile, deviceFingerPrint]
    );

    return <>{children(fn)}</>;
};

export default DownloadProvider;
