import { Tabs, Tab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../../../useCommonStyles';

export type MenuTabsProps = {
    a11yProps: (index: string) => object;
    value: string;
    setValue: (value: string) => void;
};

const MenuTabs = ({ value, setValue, a11yProps }: MenuTabsProps) => {
    const { t } = useTranslation(['biddingSessionsPage']);
    const commonStyles = useCommonStyles();

    return (
        <Tabs
            classes={{
                root: commonStyles.subMenusRoot,
                indicator: commonStyles.subMenusindicator,
            }}
            onChange={(event, value) => setValue(value)}
            scrollButtons="off"
            textColor="secondary"
            value={value}
        >
            <Tab
                classes={{
                    root: commonStyles.subMenuTabRoot,
                    selected: commonStyles.subMenuTabSelected,
                    textColorSecondary: commonStyles.subMenusTextColor,
                }}
                label={t('biddingSessionsPage:approver.forAwarding')}
                value="forAwarding"
                {...a11yProps('forAwarding')}
            />
            <Tab
                classes={{
                    root: commonStyles.subMenuTabRoot,
                    selected: commonStyles.subMenuTabSelected,
                    textColorSecondary: commonStyles.subMenusTextColor,
                }}
                label={t('biddingSessionsPage:approver.currentBidding')}
                value="currentBidding"
                {...a11yProps('currentBidding')}
            />
        </Tabs>
    );
};

export default MenuTabs;
