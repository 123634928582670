import { Context, validators } from '@amille/simple-validators';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { PropertyPath } from 'lodash';
import { get } from 'lodash/fp';

type EnhancedContext = Context<{
    t: TFunction;
}>;

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const validEmail = (field: PropertyPath) =>
    validators.custom(field, (value, values, errors, context) => {
        if (value && !emailRegex.test(value)) {
            return context.defaultMessages.invalidEmail;
        }

        return null;
    });

const passwordMatch = (field: PropertyPath, sourceField: PropertyPath) =>
    validators.custom(field, (value, values, errors, context) => {
        if (value && value !== get(sourceField, values)) {
            return context.defaultMessages.passwordMismatch;
        }

        return null;
    });

const phoneRegex = /^[89][0-9]{7}$/;

const validPhone = (field: PropertyPath) =>
    validators.custom(field, (value, values, errors, context) => {
        if (value && !phoneRegex.test(value)) {
            return context.defaultMessages.invalidPhone;
        }

        return null;
    });

const integerRegex = /^[1-9]\d*$/;

const validInteger = (field: PropertyPath) =>
    validators.custom(field, (value, values, errors, context) => {
        if (!value || !integerRegex.test(value.toString())) {
            return context.defaultMessages.invalidValue;
        }

        return null;
    });

const maxChar = (field: PropertyPath, max: number) =>
    validators.custom(field, (value, values, errors, context: EnhancedContext) => {
        if (value && value.length > max) {
            return context.outerContext.t?.('common:formErrors.maxChar', { max }) ?? context.defaultMessages.maxChar;
        }

        return null;
    });

const validDateBeforeReferenceField = (field: PropertyPath, referenceField: PropertyPath, message?: string) =>
    validators.custom(field, (value: Date, values, errors, context: EnhancedContext) => {
        const referenceValue: Date = get(referenceField, values);

        const min = dayjs(referenceValue);
        const currentValue = dayjs(value);

        if (currentValue.isSame(min) || currentValue.isAfter(min)) {
            return context.outerContext.t?.('common:formErrors.minDate', { date: min }) ?? message;
        }

        return null;
    });

export default {
    ...validators,
    validEmail,
    validPhone,
    passwordMatch,
    validInteger,
    maxChar,
    validDateBeforeReferenceField,
};
