import { ApolloError } from '@apollo/client';
import { FormikHelpers, FormikValues } from 'formik';
import { set, get } from 'lodash/fp';
import { useCallback, useMemo } from 'react';
import { AlertContext, useAlert } from '../components/AlertProvider';

type FieldsRemap = { [field: string]: string };

type SubmitHandler<TValues> = (values: TValues, helpers: FormikHelpers<TValues>) => Promise<void>;

const mergeErrors = (errors, newErrors, remapFields: FieldsRemap) =>
    Object.entries(newErrors).reduce((acc, [key, value]) => {
        const field = get(key, remapFields) || key;

        return set(field, value, acc);
    }, errors);

const handleErrors =
    <TValues = FormikValues>(handler: SubmitHandler<TValues>, show: AlertContext['show'], remapFields?: FieldsRemap) =>
    async (values: TValues, helpers: FormikHelpers<TValues>) => {
        try {
            await handler(values, helpers);
        } catch (error) {
            if (error instanceof ApolloError) {
                const { $root: rootError, ...fieldErrors } = error.graphQLErrors.reduce((acc, graphqlError) => {
                    // we exclude exception
                    const { code, exception, ...fields } = graphqlError.extensions;

                    if (code === 'BAD_USER_INPUT') {
                        return mergeErrors(acc, fields, remapFields);
                    }

                    return { ...acc, $root: error.message };
                }, {});

                if (rootError) {
                    show('error', rootError);
                }

                helpers.setErrors(fieldErrors);
            }
        }
    };

export const useHandleErrorFunction = () => {
    const { show } = useAlert();

    return useCallback(
        <TValues = FormikValues>(callback: SubmitHandler<TValues>, remapFields?: FieldsRemap) =>
            handleErrors(callback, show, remapFields),
        [show]
    );
};

export const useHandleError = <TValues = FormikValues>(
    callback: SubmitHandler<TValues>,
    dependencies: ReadonlyArray<any>,
    remapFields?: FieldsRemap
) => {
    const fn = useHandleErrorFunction();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => fn(callback, remapFields), [...dependencies, remapFields, fn]);
};

export default handleErrors;
