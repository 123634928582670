import React, { useCallback, useState } from 'react';
import { useConfirmSignUpMutation, useSignUpMutation } from '../../api';
import { withErrorBoundary } from '../../layouts/RoutedErrorBoundary';
import { useHandleError } from '../../utilities/handleErrors';
import OTPPage, { OTPFormValues } from '../OTPPage';
import { SignUpFormValues, SignUpSuccessModal, prepareSignUpPayload } from './components';

export type SignUpPageValidationProps = {
    validation: {
        values: SignUpFormValues;
        token: string;
    };
};

const SignUpValidation = ({ validation }: SignUpPageValidationProps) => {
    const { values, token: initialToken } = validation;
    const { mobilePhone } = values;
    const [token, setToken] = useState(initialToken);
    const [confirm] = useConfirmSignUpMutation();
    const [signUp] = useSignUpMutation();
    const [completed, setCompleted] = useState(false);

    const handleSubmit = useHandleError(
        async ({ code }: OTPFormValues) => {
            await confirm({
                variables: {
                    token,
                    mobilePhone,
                    code: code.join(''),
                },
            });

            setCompleted(true);
        },
        [token, mobilePhone, setCompleted]
    );

    const resendCode = useCallback(() => {
        const fields = prepareSignUpPayload(values);
        signUp({ variables: { fields } })
            .then(({ data }) => setToken(data.registerNewAccount))
            .catch(console.warn);
    }, [setToken, signUp, values]);

    return (
        <>
            <SignUpSuccessModal show={completed} />
            <OTPPage handleSubmit={handleSubmit} resendCode={resendCode} />
        </>
    );
};

export default withErrorBoundary(SignUpValidation);
