import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import OutlinedInputField from '../../../components/fields/OutlinedInputField';

const TransactionForm = () => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    label={t('saleTransactionsPage:transactionDetails.internalRemarks')}
                    name="internalRemarks"
                    placeholder={t('saleTransactionsPage:transactionDetails.internalRemarks')}
                />
            </Grid>
        </Grid>
    );
};

export default TransactionForm;
