import { Grid, Box, Typography, Button, FormControl, Divider, Link, useMediaQuery } from '@material-ui/core';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import { merge } from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../../components/Accordion';
import { useFlutter } from '../../../../components/FlutterProvider';
import { useLoading } from '../../../../components/LoadingProvider';
import useCommonStyles from '../../../../useCommonStyles';
import { CreatePurchaseTransactionFormValues } from '../index';
import { CheckDuplicateCarPlate } from './DuplicateTransactionProvider';
import VehicleDetailsForm from './VehicleDetailsForm';

const useStyles = makeStyles(() =>
    createStyles({
        divider: {
            width: '40%',
        },
    })
);

export type VehicleDetailsAccordionProps = {
    showForm: boolean;
    hasOneMonitoring: boolean;
    setShowForm: (value: boolean) => void;
    checkDuplicateCarPlate: CheckDuplicateCarPlate;
};

const VehicleDetailsAccordion = ({
    showForm,
    hasOneMonitoring,
    setShowForm,
    checkDuplicateCarPlate,
}: VehicleDetailsAccordionProps) => {
    const { requestOneMonitoring } = useFlutter();
    const { values, setValues } = useFormikContext<CreatePurchaseTransactionFormValues>();
    const { attach } = useLoading();
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const { t } = useTranslation(['purchaseTransactionsPage']);

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const useCardDesignForAccordion = !isSmall || (hasOneMonitoring && !showForm);
    const hideTitle = !useCardDesignForAccordion;

    const proceedWithOneMonitoring = useCallback(() => {
        const execute = async () => {
            const data = await requestOneMonitoring();

            if (data.vehicle.number) {
                const nextValues = merge(values, data);

                checkDuplicateCarPlate(nextValues.vehicle).then(isDuplicated => {
                    if (!isDuplicated) {
                        setValues(nextValues);
                        setShowForm(true);
                    }
                });
            }
        };

        attach(execute());
    }, [attach, requestOneMonitoring, values, checkDuplicateCarPlate, setValues, setShowForm]);

    return (
        <Accordion
            hideTitle={hideTitle}
            title={t('purchaseTransactionsPage:accordionTitle.vehicleDetails')}
            useCardDesign={useCardDesignForAccordion}
            defaultExpanded
        >
            <Grid spacing={2} container>
                {showForm ? (
                    <Grid xs={12} item>
                        <VehicleDetailsForm dataManually={!values.vehicle.isOneMotoringData} onCreation />
                    </Grid>
                ) : (
                    <>
                        <Grid xs={12} item>
                            <Typography className={commonStyles.secondary2}>
                                {t('purchaseTransactionsPage:parfOrCoeRebateForRegisteredVehicle')}
                            </Typography>
                        </Grid>
                        <Grid xs={12} item>
                            <FormControl fullWidth>
                                <Button color="secondary" onClick={proceedWithOneMonitoring} variant="contained">
                                    {t('purchaseTransactionsPage:button.retrieveData')}
                                </Button>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} item>
                            <Box alignItems="center" display="flex">
                                <Divider className={styles.divider} />
                                <Box ml="auto" mr="auto">
                                    <Typography className={commonStyles.secondary2}>{t('common:or')}</Typography>
                                </Box>
                                <Divider className={styles.divider} />
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box textAlign="center">
                                <Link
                                    color="secondary"
                                    component="a"
                                    onClick={() => setShowForm(true)}
                                    underline="none"
                                >
                                    {t('purchaseTransactionsPage:button.enterDataManually')}
                                </Link>
                            </Box>
                        </Grid>
                    </>
                )}
            </Grid>
        </Accordion>
    );
};

export default VehicleDetailsAccordion;
