import { Dialog, DialogContent, Fab } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import { saveAs } from 'file-saver';
import { useCallback } from 'react';
import { NameOnlyFileDataFragment } from '../api';
import useCommonStyles from '../useCommonStyles';
import { useFlutter } from './FlutterProvider';
import { useLoading } from './LoadingProvider';

export type ViewPhotoDialogProps = {
    show?: boolean;
    file: NameOnlyFileDataFragment;
    handleOnClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogContent: {
            padding: 0,
        },
        image: {
            maxHeight: '100%',
            maxWidth: '100%',
            objectFit: 'contain',
        },
        fab: {
            position: 'absolute',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
        },
    })
);

const ViewPhotoDialog = ({ show, file, handleOnClose }: ViewPhotoDialogProps) => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const { deviceFingerPrint, sendDownloadFile } = useFlutter();
    const { attach } = useLoading();

    const downloadPhoto = useCallback(() => {
        if (deviceFingerPrint) {
            const execute = async () => {
                await sendDownloadFile({
                    filename: file.filename,
                    signedUrl: file.signedUrl,
                });
            };

            attach(execute());

            return;
        }

        saveAs(file.signedUrl, file.filename);
    }, [deviceFingerPrint, file, sendDownloadFile, attach]);

    if (!file) {
        return null;
    }

    return (
        <Dialog
            aria-labelledby="view-photo-dialog-title"
            classes={{ paper: commonStyles.dialog }}
            maxWidth="md"
            onClose={handleOnClose}
            open={show}
            fullWidth
        >
            <DialogContent className={styles.dialogContent}>
                <img alt="" className={styles.image} src={file.signedUrl} />
                <Fab className={styles.fab} color="secondary" onClick={downloadPhoto} size="small">
                    <GetAppIcon />
                </Fab>
            </DialogContent>
        </Dialog>
    );
};

export default ViewPhotoDialog;
