import { Menu, MenuItem } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSession } from '../../../Session';
import useBasicStyles from '../useBasicStyles';

export type ProfileMenusProps = {
    anchorEl: HTMLElement;
    handleClose: () => void;
};

const ProfileMenus = ({ anchorEl, handleClose }: ProfileMenusProps) => {
    const classes = useBasicStyles();
    const history = useHistory();
    const { t } = useTranslation(['common', 'profilePage', 'settingsPage']);

    const { setToken } = useSession();

    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            classes={{
                paper: classes.profileMenuPaper,
                list: classes.profileMenuList,
            }}
            getContentAnchorEl={null}
            onClose={handleClose}
            open={Boolean(anchorEl)}
            keepMounted
        >
            <MenuItem
                className={cs(classes.profileMenuItem, classes.selectedProfileMenuItem)}
                onClick={() => {
                    handleClose();
                    history.push('/profile');
                }}
            >
                <PersonIcon className={classes.profileMenuIcon} /> {t('profilePage:title')}
            </MenuItem>
            <MenuItem
                className={cs(classes.profileMenuItem)}
                onClick={() => {
                    handleClose();
                    setToken(null);
                }}
            >
                {t('common:logOut')}
            </MenuItem>
        </Menu>
    );
};

export default ProfileMenus;
