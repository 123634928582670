import { IconButton } from '@material-ui/core';
import { useCallback } from 'react';
import { useConsumeSingleSignInTokenMutation } from '../../../../api';
import { useFlutter } from '../../../../components/FlutterProvider';
import { useLoadingController } from '../../../../components/LoadingProvider';
import usePortalStyles from '../../../../layouts/PortalLayout/usePortalStyles';
import usePublic from '../../../../utilities/usePublic';
import { useAuthenticateHandler } from '../../LoginPageValidation';

const LoginBiometric = () => {
    const portalStyles = usePortalStyles();
    const biometric = usePublic('assets/images/biometric.svg');
    const faceId = usePublic('assets/images/faceId.svg');

    const { capabilities, checkBiometric, deviceFingerPrint: deviceFingerprint } = useFlutter();
    const { hasFingerPrint, hasFaceID } = capabilities;

    const hasBiometric = hasFingerPrint || hasFaceID;
    const singleSignInToken = localStorage.getItem('singleSignIn');
    const hasSingleSignIn = !!singleSignInToken;

    const [authenticate, { loading }] = useConsumeSingleSignInTokenMutation();
    const next = useAuthenticateHandler();

    useLoadingController(loading);

    const onLogin = useCallback(async () => {
        const success = await checkBiometric();

        if (success) {
            const { data } = await authenticate({
                variables: { deviceFingerprint, token: singleSignInToken },
            });

            next(data.authenticateWithToken);
        }
    }, [authenticate, next, checkBiometric, singleSignInToken, deviceFingerprint]);

    if (!hasBiometric || !hasSingleSignIn) {
        return null;
    }

    return (
        <IconButton className={portalStyles.biometricButton} onClick={onLogin} type="button">
            <img alt="biometric" src={hasFaceID ? faceId : biometric} />
        </IconButton>
    );
};

export default LoginBiometric;
