import React from 'react';
import { Redirect } from 'react-router-dom';
import { useCurrentUser } from '../../Session';
import { UserType } from '../../api';
import BasicLayout from '../../layouts/BasicLayout';
import SaleTransactions from '../../pages/SaleTransactions';
import { DownloadSalesTransactionsFileProvider } from '../../pages/SaleTransactions/components';

const SaleTransactionsRoute = () => {
    const currentUser = useCurrentUser();

    if (
        ![UserType.Admin, UserType.ValuationTeam, UserType.Dealer, UserType.Approver, UserType.SaleConsultant].includes(
            currentUser.type
        )
    ) {
        return <Redirect to="/403" />;
    }

    return (
        <BasicLayout>
            <DownloadSalesTransactionsFileProvider>
                {downloadFile => <SaleTransactions downloadSalesTransactionsFile={downloadFile} />}
            </DownloadSalesTransactionsFileProvider>
        </BasicLayout>
    );
};

export default SaleTransactionsRoute;
