import { Box, Typography } from '@material-ui/core';
import { alpha, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../../../useCommonStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textColor: {
            color: alpha('#000', 0.8),
        },
    })
);

const Instrucation = () => {
    const { t } = useTranslation(['purchaseTransactionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useStyles();

    return (
        <>
            <Box mb={1}>
                <Typography className={cs(commonStyles.fontWeightBold, styles.textColor)}>
                    {t('purchaseTransactionsPage:vehicleDiagram.instruction.title')}
                </Typography>
            </Box>
            <Typography className={cs(commonStyles.fontWeightLight, commonStyles.smallFontSize, styles.textColor)}>
                {t('purchaseTransactionsPage:vehicleDiagram.instruction.body')}
            </Typography>
        </>
    );
};

export default Instrucation;
