import { FormikProps, withFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { FullUserDataFragment, useListUsersQuery, UserActivationStage, UserType } from '../../api';
import ListPagination from '../../components/ListPagination';
import NoRecordsFound from '../../components/NoRecordsFound';
import { UsersHeader, UsersTable } from './component';

type ListContext = {
    search: string;
    page: number;
};

const pageSize = 6;

export type FilterValues = {
    filter: 'all' | UserActivationStage | UserType;
};

const UsersList = ({ values }: FormikProps<FilterValues>) => {
    const [{ page, search }, setListContext] = useState<ListContext>({
        search: '',
        page: 0,
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    const setActivePage = useCallback(
        (newPage: number) => {
            setListContext(state => ({ ...state, page: newPage }));
        },
        [setListContext]
    );

    const searchOnChange = useCallback(
        (searchValue: string) => {
            setListContext({ page: 0, search: searchValue });
        },
        [setListContext]
    );

    const { filter } = values;

    const { data, refetch } = useListUsersQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            page: { limit: pageSize, offset: page * pageSize },
            search,
            activationStage: filter === 'all' ? null : UserActivationStage[filter],
            type: filter === 'all' ? null : UserType[filter],
        },
    });

    const count = data?.users?.count || 0;
    const users: FullUserDataFragment[] = data?.users?.items || [];

    const onUpdates = useCallback(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        setListContext(state => ({ ...state, page: 0 }));
    }, [values, setListContext]);

    const header = <UsersHeader search={search} searchOnChange={searchOnChange} />;

    if (count === 0) {
        return (
            <>
                {header}
                <NoRecordsFound />
            </>
        );
    }

    return (
        <>
            {header}
            <UsersTable onUpdates={onUpdates} users={users} />
            <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
        </>
    );
};

export default withFormik({
    mapPropsToValues: () => ({ filter: 'all' }),
    handleSubmit: () => null,
})(UsersList);
