import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import InfoIcon from '@material-ui/icons/Info';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../../../useCommonStyles';
import CarIcon from '../../../icons/carIcon.svg';

export type MobileMenuTabsProps = {
    a11yProps: (index: string) => object;
    value: string;
    setValue: (value: string) => void;
};

const MobileMenuTabs = ({ value, setValue, a11yProps }: MobileMenuTabsProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage']);
    const commonStyles = useCommonStyles();

    return (
        <BottomNavigation
            className={commonStyles.bottomNavigation}
            onChange={(event, value) => setValue(value)}
            value={value}
        >
            <BottomNavigationAction
                className={commonStyles.bottomNavigationAction}
                classes={{ selected: commonStyles.bottomNavigationSelected, label: commonStyles.smallFontSize }}
                icon={<InfoIcon />}
                label={t('purchaseTransactionsPage:menu.transaction')}
                value="transaction"
                showLabel
                {...a11yProps('transaction')}
            />
            <BottomNavigationAction
                className={commonStyles.bottomNavigationAction}
                classes={{ selected: commonStyles.bottomNavigationSelected, label: commonStyles.smallFontSize }}
                icon={<CarIcon className="MuiSvgIcon-root" />}
                label={t('purchaseTransactionsPage:menu.vehicle')}
                value="vehicle"
                showLabel
                {...a11yProps('vehicle')}
            />
            <BottomNavigationAction
                className={commonStyles.bottomNavigationAction}
                classes={{ selected: commonStyles.bottomNavigationSelected, label: commonStyles.smallFontSize }}
                icon={<CarIcon className="MuiSvgIcon-root" />}
                label={t('purchaseTransactionsPage:menu.valuation')}
                value="valuation"
                showLabel
                {...a11yProps('valuation')}
            />
            <BottomNavigationAction
                className={commonStyles.bottomNavigationAction}
                classes={{ selected: commonStyles.bottomNavigationSelected, label: commonStyles.smallFontSize }}
                icon={<AssignmentIndIcon />}
                label={t('purchaseTransactionsPage:menu.handover')}
                value="handover"
                showLabel
                {...a11yProps('handover')}
            />
        </BottomNavigation>
    );
};

export default MobileMenuTabs;
