import { useMediaQuery } from '@material-ui/core';
import { MuiThemeProvider, useTheme } from '@material-ui/core/styles';
import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BasicLayout from '../layouts/BasicLayout';
import basicTheme from '../layouts/BasicLayout/basicTheme';
import ProfilePage from '../pages/Profile';

const ProfileRoute = (): ReactElement => {
    const [onEdition, setOnEdition] = useState(false);
    const history = useHistory();
    const theme = useTheme();

    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    if (isSmall) {
        return (
            <MuiThemeProvider theme={basicTheme}>
                <ProfilePage onEdition={onEdition} setOnEdition={setOnEdition} />
            </MuiThemeProvider>
        );
    }

    return (
        <BasicLayout onBack={onEdition ? undefined : history.goBack}>
            <ProfilePage onEdition={onEdition} setOnEdition={setOnEdition} />
        </BasicLayout>
    );
};

export default ProfileRoute;
