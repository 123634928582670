const useBrands = () => ({
    'Alfa Romeo': 'alfaRomeo.png',
    Alpina: 'alpina.png',
    Alpine: 'alpine.png',
    'Aston Martin': 'astonMartin.png',
    Audi: 'audi.png',
    Austin: 'austin.png',
    'B.M.W.': 'bmw.png',
    Bentley: 'bentley.png',
    BlueCar: 'bluecar.png',
    BYD: 'byd.png',
    Chana: 'chana.png',
    Chery: 'chery.png',
    Chevrolet: 'chevrolet.png',
    Chrysler: 'chrysler.png',
    Citroen: 'citroen.png',
    Daewoo: 'daewoo.png',
    Daihatsu: 'daihatsu.png',
    Daimler: 'daimler.svg',
    Datsun: 'datsun.png',
    Dodge: 'dodge.png',
    Dongfeng: 'dongfeng.png',
    Ferrari: 'ferrari.png',
    Fiat: 'fiat.png',
    Ford: 'ford.png',
    Foton: 'foton.png',
    Geely: 'geely.png',
    'Golden Dragon': 'goldenDragon.png',
    Hafei: 'hafei.png',
    Holden: 'holden.png',
    Honda: 'honda.png',
    Hummer: 'hummer.png',
    Hyundai: 'hyundai.png',
    Infiniti: 'infiniti.png',
    Isuzu: 'isuzu.png',
    Jaguar: 'jaguar.png',
    Jeep: 'jeep.png',
    Kia: 'kia.png',
    Lamborghini: 'lamborghini.png',
    Lancia: 'lancia.png',
    'Land Rover': 'landRover.png',
    Lexus: 'lexus.png',
    Lotus: 'lotus.png',
    'M.G.': 'mg.png',
    Maserati: 'maserati.png',
    Maxus: 'maxus.png',
    Maybach: 'maybach.png',
    Mazda: 'mazda.png',
    McLaren: 'mclaren.png',
    'Mercedes Benz': 'mercedesBenz.png',
    Mini: 'mini.svg',
    'Mini Mayfair': 'miniMayfair.svg',
    Mitsubishi: 'mitsubishi.png',
    Mitsuoka: 'mitsuoka.png',
    Morgan: 'morgan.png',
    Morris: 'morris.png',
    Naza: 'naza.png',
    Nissan: 'nissan.png',
    Opel: 'opel.png',
    Panther: 'panther.png',
    Perodua: 'perodua.png',
    Peugeot: 'peugeot.png',
    Porsche: 'porsche.svg',
    Proton: 'proton.png',
    Renault: 'renault.png',
    'Rolls Royce': 'rollsRoyce.svg',
    Rover: 'rover.png',
    Ruf: 'ruf.png',
    Saab: 'saab.png',
    Seat: 'seat.svg',
    Skoda: 'skoda.png',
    Smart: 'smart.png',
    SsangYong: 'ssangyong.png',
    Subaru: 'subaru.png',
    Sunbeam: 'sunbeam.png',
    Suzuki: 'suzuki.svg',
    'Td Cars': 'tdCars.png',
    Tesla: 'tesla.png',
    Toyota: 'toyota.png',
    Triumph: 'triumph.png',
    Volkswagen: 'volkswagen.png',
    Volvo: 'volvo.png',
    Wuling: 'wuling.png',
    Zotye: 'zotye.png',
});

export default useBrands;
