import { Box, Grid, Divider, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import React from 'react';
import { CreatePurchaseTransactionFormValues } from '..';
import { CheckDuplicateCarPlate } from './DuplicateTransactionProvider';
import ManualInformationAccordion from './ManualInformationAccordion';
import VehicleDetailsAccordion from './VehicleDetailsAccordion';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(10),
                paddingTop: theme.spacing(3),
                paddingBottom: theme.spacing(3),
            },
        },
        divider: {
            height: theme.spacing(4),
        },
    })
);

export type CreatePurchaseTransactionBodyProps = {
    showForm: boolean;
    hasOneMonitoring: boolean;
    setShowForm: (value: boolean) => void;
    checkDuplicateCarPlate: CheckDuplicateCarPlate;
};

const CreatePurchaseTransactionBody = ({
    showForm,
    hasOneMonitoring,
    setShowForm,
    checkDuplicateCarPlate,
}: CreatePurchaseTransactionBodyProps) => {
    const styles = useStyles();
    const { values } = useFormikContext<CreatePurchaseTransactionFormValues>();

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const existPaddingLeftAndRight = isSmall && hasOneMonitoring && !showForm;
    const existDivider = isSmall && showForm;

    return (
        <Box
            className={styles.root}
            mb={isSmall ? 10 : 0}
            pl={existPaddingLeftAndRight ? 3 : 1}
            pr={existPaddingLeftAndRight ? 4 : 1}
        >
            <Grid spacing={2} container>
                <Grid lg={6} md={6} sm={12} style={{ order: values.vehicle.isOneMotoringData ? 2 : 0 }} xs={12} item>
                    <VehicleDetailsAccordion
                        checkDuplicateCarPlate={checkDuplicateCarPlate}
                        hasOneMonitoring={hasOneMonitoring}
                        setShowForm={setShowForm}
                        showForm={showForm}
                    />
                </Grid>
                {existDivider && (
                    <Grid style={{ order: values.vehicle.isOneMotoringData ? 1 : 0 }} xs={12}>
                        <Divider className={styles.divider} />
                    </Grid>
                )}
                <Grid lg={6} md={6} sm={12} xs={12} item>
                    <ManualInformationAccordion hasOneMonitoring={hasOneMonitoring} showForm={showForm} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default CreatePurchaseTransactionBody;
