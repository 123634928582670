import FlutterAction from '../FlutterAction';

type Response = { success: boolean };

export type DownloadFileContext = {
    filename: string;
    signedUrl: string;
};

const action = new FlutterAction<({ filename, signedUrl }: DownloadFileContext) => void, Response, boolean>(
    'downloadFile',
    async response => response.success
);

export default action;
