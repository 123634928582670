import { Switch, Route } from 'react-router-dom';
import PrivateRouter from './PrivateRouter';
import PublicRouter from './PublicRouter';
import { useSession } from './Session';
import { withErrorBoundary } from './layouts/RoutedErrorBoundary';
import InternalErrorRoute from './routes/InternalErrorRoute';

const MainRouter = () => {
    const { hasSession } = useSession();
    const Router = hasSession ? PrivateRouter : PublicRouter;

    return (
        <Switch>
            <Route component={InternalErrorRoute} path="/500" exact />
            <Route component={Router} />
        </Switch>
    );
};

export default withErrorBoundary(MainRouter);
