import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCreateSaleTransactionMutation, useGetPurchaseTransactionsQuery } from '../../../../api';
import { useLoading } from '../../../../components/LoadingProvider';
import { useHandleErrorFunction } from '../../../../utilities/handleErrors';
import PurchaseTransactionItem from './PurchaseTransactionItem';

export type SaleTransactionsBodyProps = {
    open: boolean;
    onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogTitle: {
            borderBottom: '1px solid #c4c4c4',
            display: 'flex',
            justifyContent: 'space-between',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
        },
        items: {
            padding: theme.spacing(2),
            paddingTop: 0,
        },
        dialogActions: {
            marginTop: theme.spacing(3),
        },
        selectAllContainer: {
            marginBottom: theme.spacing(2),
        },
    })
);

const SaleTransactionsBody = ({ open, onClose }: SaleTransactionsBodyProps) => {
    const [selectAll, setSelectAll] = useState(false);

    const { t } = useTranslation(['common', 'saleTransactionsPage']);

    const styles = useStyles();

    const { data } = useGetPurchaseTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            filter: {
                isForNewSalesTransaction: true,
            },
            page: { offset: 0, limit: 99999 },
        },
    });

    const [createTransaction] = useCreateSaleTransactionMutation();

    const purchaseTransactions = useMemo(
        () => data?.purchaseTransactions?.items || [],
        [data?.purchaseTransactions?.items]
    );

    const [selectedTransactions, setSelected] = useState<string[]>([]);

    const { attach } = useLoading();
    const handleErrors = useHandleErrorFunction();
    const history = useHistory();

    const onSubmit = useCallback(() => {
        const selectedItems = selectAll
            ? purchaseTransactions.map(purchaseItem => purchaseItem.id)
            : selectedTransactions;

        const promise = Promise.all(
            selectedItems.map(transactionId =>
                createTransaction({
                    variables: {
                        fields: { purchaseTransactionId: transactionId, action: null },
                    },
                })
            )
        )
            .catch(handleErrors)
            .finally(() => history.push('/salesTransactions'));

        attach(promise);
    }, [attach, createTransaction, handleErrors, history, purchaseTransactions, selectAll, selectedTransactions]);

    const markAllSelectUnselect = useCallback(() => {
        const isChecked = !selectAll;

        setSelected(isChecked ? purchaseTransactions.map(purchaseTransaction => purchaseTransaction.id) : []);

        setSelectAll(isChecked);
    }, [purchaseTransactions, selectAll]);

    const getCheckboxControl = useCallback(() => {
        const selectAllCheckbox = <Checkbox checked={selectAll} onChange={markAllSelectUnselect} />;

        return selectAllCheckbox;
    }, [markAllSelectUnselect, selectAll]);

    return (
        <Dialog maxWidth="md" onClose={onClose} open={open} fullWidth>
            <DialogTitle className={styles.dialogTitle}>
                <Typography color="secondary">{t('common:select')}</Typography>

                <IconButton className={styles.closeButton} color="secondary" onClick={() => onClose()}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Box className={styles.items} mb={3} mt={3}>
                <Grid className={styles.selectAllContainer} xs={12}>
                    <FormControlLabel control={getCheckboxControl()} label={t('saleTransactionsPage:selectAll')} />
                </Grid>

                <Grid spacing={2} container>
                    {purchaseTransactions.map(purchaseTransaction => {
                        const isSelected = selectedTransactions.some(id => id === purchaseTransaction.id);

                        const onChange = () => {
                            const nextValue = isSelected
                                ? selectedTransactions.filter(id => id !== purchaseTransaction.id)
                                : [...selectedTransactions, purchaseTransaction.id];

                            setSelected(nextValue);
                            setSelectAll(nextValue.length === purchaseTransactions.length);
                        };

                        const card = <PurchaseTransactionItem purchaseTransaction={purchaseTransaction} />;

                        const checkbox = <Checkbox checked={isSelected} onChange={onChange} />;

                        return (
                            <Grid key={purchaseTransaction.id} sm={6} xs={12} item>
                                <FormControlLabel control={checkbox} label={card} />
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid className={styles.dialogActions} xs={12} item>
                    <FormControl fullWidth>
                        <Button color="secondary" onClick={onSubmit} variant="contained">
                            {t('common:select')}
                        </Button>
                    </FormControl>
                </Grid>
            </Box>
        </Dialog>
    );
};

export default SaleTransactionsBody;
