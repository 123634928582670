import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cs from 'classnames';
import React from 'react';

export type MarkerProps = {
    index: number;
    isSmall: boolean;
    left?: string;
    top?: string;
};

type StyleProps = {
    color?: string;
    left?: string;
    top?: string;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
    wrapper: {
        borderRadius: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        background: ({ color }) => color,
        textAlign: 'center',
    },
    indexer: {
        width: '30px',
        height: '30px',
    },
    pointer: {
        width: '20px',
        height: '20px',
        position: 'absolute',
        top: ({ top }) => `${top}%`,
        left: ({ left }) => `${left}%`,
    },
    text: {
        fontWeight: 'bold',
        color: theme.palette.secondary.contrastText,
    },
}));

const backgroundColors = ['#E63226', '#02A932', '#F26FFB', '#057DC9'];

const Marker = ({ isSmall, index, top, left }: MarkerProps) => {
    const color = backgroundColors[index % 4];

    const styles = useStyles({ color, top, left });

    const classes = isSmall ? cs(styles.wrapper, styles.pointer) : cs(styles.wrapper, styles.indexer);

    return (
        <Box className={classes}>
            <Typography className={styles.text}>{index}</Typography>
        </Box>
    );
};

export default Marker;
