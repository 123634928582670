import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SalesTransactionAction } from '../api';

const useSalesTransactionActionOptions = () => {
    const { t } = useTranslation(['saleTransactionsPage']);

    return useMemo(
        () => [
            { value: SalesTransactionAction.Purchased, label: t('saleTransactionsPage:action.purchased') },
            { value: SalesTransactionAction.Offers, label: t('saleTransactionsPage:action.offers') },
        ],
        [t]
    );
};

export default useSalesTransactionActionOptions;
