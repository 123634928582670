import { imageFileExtensions, pdfFileExtension } from '../../server/schema/constants';
import { NameOnlyFileDataFragment } from '../api';

export const getExtension = (filename: string) => filename.split('.').pop();

export const isImageFile = (extension: string) => imageFileExtensions.includes(extension.toLowerCase());

export const isPdfFile = (extension: string) => pdfFileExtension === extension.toLowerCase();

export const diffUploads = (
    uploads: (NameOnlyFileDataFragment | File)[],
    initialUploads: NameOnlyFileDataFragment[]
) => {
    const newUploads: File[] = [];
    let removedUploads: NameOnlyFileDataFragment[] = [...initialUploads];

    uploads.forEach(upload => {
        if (upload instanceof File) {
            // add the new file
            newUploads.push(upload);
        } else {
            // this file is kept
            removedUploads = removedUploads.filter(item => upload.id !== item.id);
        }
    });

    return { newUploads, removedUploads };
};

export const getAcceptExtensions = (allowedExtensions: string[]) =>
    allowedExtensions.map(extension => {
        if (isImageFile(extension)) {
            return `image/${extension}`;
        }

        if (isPdfFile(extension)) {
            return `application/${extension}`;
        }

        return extension;
    });
