import { Box, Grid, Typography } from '@material-ui/core';
import cs from 'classnames';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useCommonStyles from '../../../useCommonStyles';

const OTPPageBody = () => {
    const { t } = useTranslation(['otpPage']);
    const commonStyles = useCommonStyles();

    return (
        <Grid xs={12} item>
            <Typography
                align="center"
                className={cs(commonStyles.fontWeightBold, commonStyles.secondaryFont)}
                color="secondary"
                variant="h5"
            >
                {t('otpPage:title')}
            </Typography>
            <Box mb={3} mt={1.5}>
                <Typography
                    align="center"
                    className={cs(commonStyles.smallFontSize, commonStyles.fontWeightLight, commonStyles.primary)}
                >
                    <Trans i18nKey="otpPage:body">
                        a
                        <span
                            className={cs(
                                commonStyles.smallFontSize,
                                commonStyles.fontWightMedium,
                                commonStyles.secondary
                            )}
                        >
                            b
                        </span>
                        c
                    </Trans>
                </Typography>
            </Box>
        </Grid>
    );
};

export default OTPPageBody;
