import { Grid, Box } from '@material-ui/core';
import React from 'react';
import { PreviewBiddingDataFragment } from '../../../../../api';
import BiddingSessionsItem from './BiddingSessionsItem';

export type BiddingSessionsTabProps = {
    biddings: PreviewBiddingDataFragment[];
};

const BiddingDetailsTab = ({ biddings }: BiddingSessionsTabProps) => (
    <Box mb={3} mt={3}>
        <Grid spacing={2} container>
            {biddings.map(bidding => (
                <BiddingSessionsItem key={bidding.id} bidding={bidding} />
            ))}
        </Grid>
    </Box>
);

export default BiddingDetailsTab;
