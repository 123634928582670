import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const defaultOtherLocationOption = '__OTHERS__';

const useHandoverLocationOptions = (otherValue = defaultOtherLocationOption) => {
    const { t } = useTranslation(['common']);

    return useMemo(() => {
        const labels = [
            t('common:handoverLocation.headquarter'),
            t('common:handoverLocation.mainBranch'),
            t('common:handoverLocation.showroom1'),
            t('common:handoverLocation.showroom2'),
        ];

        return [
            ...labels.map(label => ({ value: label, label })),
            { value: otherValue, label: t('common:handoverLocation.others') },
        ];
    }, [t, otherValue]);
};

export default useHandoverLocationOptions;
