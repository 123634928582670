import { Box, Radio } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import { get } from 'lodash/fp';
import { useEffect, useCallback } from 'react';
import { NameOnlyFileDataFragment } from '../../api';
import { getExtension, isImageFile } from '../../utilities/file';
import { FileListProps } from './FileList';

export type FrontPhotoManagerProps = {
    name: string;
    attachmentFieldName: string;
    children: (renderPrefix: FileListProps['renderPrefix']) => JSX.Element;
};

type FileValue = File | NameOnlyFileDataFragment;

const FrontPhotoManager = ({ name, attachmentFieldName, children }: FrontPhotoManagerProps) => {
    const { values } = useFormikContext();
    const attachments = get(attachmentFieldName, values) as unknown as FileValue[] | undefined;

    const [{ value: frontPhoto }, , { setValue: setFrontPhoto }] = useField<FileValue>(name);

    useEffect(() => {
        // get images only
        const images = attachments.filter(file =>
            isImageFile(getExtension(file instanceof File ? file.name : file.filename))
        );

        if (images.length > 0) {
            // check if the front photo still exist
            const isIncluded =
                !!frontPhoto &&
                attachments.some(file =>
                    frontPhoto instanceof File
                        ? file === frontPhoto
                        : (file as NameOnlyFileDataFragment).id === frontPhoto?.id
                );

            if (!isIncluded) {
                // set first photo as front page photo
                setFrontPhoto(images[0]);
            }
        } else if (frontPhoto) {
            // reset it to null
            setFrontPhoto(null);
        }
    }, [attachments, frontPhoto, setFrontPhoto]);

    const renderSuffix = useCallback<FileListProps['renderPrefix']>(
        file => {
            const filename = file instanceof File ? file.name : file.filename;
            const extension = getExtension(filename);
            const isPhoto = isImageFile(extension);

            return (
                <Box width={45}>
                    {isPhoto && (
                        <Radio
                            checked={
                                file instanceof File
                                    ? file === frontPhoto
                                    : file.id === (frontPhoto as NameOnlyFileDataFragment)?.id
                            }
                            onChange={() => setFrontPhoto(file)}
                        />
                    )}
                </Box>
            );
        },
        [setFrontPhoto, frontPhoto]
    );

    return children(renderSuffix);
};

export default FrontPhotoManager;
