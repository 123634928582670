import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
    useDisablePushMutation,
    useEnablePushMutation,
    useGetSingleSignInTokenMutation,
    useToggleBiometricMutation,
} from '../../../api';
import { useAlert } from '../../../components/AlertProvider';
import { useFlutter } from '../../../components/FlutterProvider';
import { useLoadingController } from '../../../components/LoadingProvider';
import { FormValues } from '../index';

const useBiometric = () => {
    const { t } = useTranslation(['settingsPage']);
    const { deviceFingerPrint: deviceFingerprint, checkBiometric } = useFlutter();
    const { show } = useAlert();

    // get values from formik
    const { values, setFieldValue } = useFormikContext<FormValues>();
    const { biometric: value } = values;

    // get mutations
    const [toggleBiometric, { loading: toggleLoading }] = useToggleBiometricMutation();
    const [getToken, { loading: getTokenLoading }] = useGetSingleSignInTokenMutation();

    // track latest values
    const previousValueRef = useRef(value);

    useLoadingController(toggleLoading || getTokenLoading);

    useEffect(() => {
        if (previousValueRef.current === value) {
            // no changes
            return;
        }

        (async () => {
            if (value) {
                const checkedBiometric = await checkBiometric();

                // check with flutter first
                if (!checkedBiometric) {
                    // set it back to false
                    previousValueRef.current = false;
                    setFieldValue('biometric', false);

                    return;
                }
            } else {
                // remove token from local storage
                localStorage.removeItem('singleSignIn');
            }

            // update the remote setting
            await toggleBiometric({ variables: { deviceFingerprint, state: value } });

            if (value) {
                // get the token
                const response = await getToken({ variables: { deviceFingerprint } });
                const token = response.data.getSingleSignInToken;

                // update local storage
                localStorage.setItem('singleSignIn', token);
            } else {
                // remove token from local storage
                localStorage.removeItem('singleSignIn');
            }

            // update previous value
            previousValueRef.current = value;

            // display success message
            show('success', t('settingsPage:alerts.saveSuccessful'));
        })();
    }, [t, show, value, deviceFingerprint, checkBiometric, previousValueRef, setFieldValue, toggleBiometric, getToken]);
};

const usePush = () => {
    const { t } = useTranslation(['settingsPage']);
    const { deviceFingerPrint: deviceFingerprint, initializePush } = useFlutter();
    const { show } = useAlert();

    // get values from formik
    const { values, setFieldValue } = useFormikContext<FormValues>();
    const { pushNotification: value } = values;

    // get mutations
    const [enablePush, { loading: enableLoading }] = useEnablePushMutation();
    const [disablePush, { loading: disableLoading }] = useDisablePushMutation();

    useLoadingController(enableLoading || disableLoading);

    // track latest values
    const previousValueRef = useRef(value);

    useEffect(() => {
        if (previousValueRef.current === value) {
            // no changes
            return;
        }

        (async () => {
            if (value) {
                // check with flutter first to get the registration token
                const { registrationToken } = await initializePush();

                if (!registrationToken) {
                    // set it back to false
                    previousValueRef.current = false;
                    setFieldValue('pushNotification', false);

                    return;
                }

                // call the API
                await enablePush({ variables: { registrationToken, deviceFingerprint } });
            } else {
                await disablePush({ variables: { deviceFingerprint } });
            }

            // update previous value
            previousValueRef.current = value;

            // display success message
            show('success', t('settingsPage:alerts.saveSuccessful'));
        })();
    }, [t, show, value, deviceFingerprint, previousValueRef, setFieldValue, enablePush, disablePush, initializePush]);
};

const SettingsUpdates = () => {
    useBiometric();
    usePush();

    return null;
};

export default SettingsUpdates;
