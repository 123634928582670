import DayjsUtils from '@date-io/dayjs';
import { FormControl, FormLabel, FormHelperText, InputAdornment, IconButton } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import TodayIcon from '@material-ui/icons/Today';
import { MuiPickersUtilsProvider, DateTimePicker, DateTimePickerProps } from '@material-ui/pickers';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../../useCommonStyles';

export interface OutlinedDateTimePickerFieldProps extends Omit<DateTimePickerProps, 'value' | 'onChange'> {
    name: string;
}

const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            main: '#D5001C',
            contrastText: '#fff',
        },
        secondary: {
            main: '#D5001C',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: 'Porsche Next !important',
    },
});

const OutlinedDateTimePickerField = ({ name, label, ...props }: OutlinedDateTimePickerFieldProps) => {
    const { t } = useTranslation('common');
    const { disabled } = props;
    const [field, meta] = useField({ name });
    const { setFieldValue } = useFormikContext();
    const commonStyles = useCommonStyles();

    const hasError = !!meta.error && meta.touched;

    return (
        <FormControl fullWidth>
            {label && (
                <FormLabel className={commonStyles.formLabel} disabled={disabled}>
                    {label}
                </FormLabel>
            )}
            <ThemeProvider theme={defaultMaterialTheme}>
                <MuiPickersUtilsProvider utils={DayjsUtils}>
                    <DateTimePicker
                        // by default auto complete is off but props can always override it
                        autoComplete="off"
                        // spread props
                        {...props}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <TodayIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        // the error is defined from what we go in meta data
                        error={hasError}
                        // date format
                        format={t('common:formats.dateTimePickerFormat')}
                        inputVariant="outlined"
                        onChange={value => setFieldValue(name, value.toDate())}
                        value={field.value}
                        strictCompareDates
                    />
                </MuiPickersUtilsProvider>
            </ThemeProvider>
            <FormHelperText error={hasError}>{hasError ? meta.error : ''}</FormHelperText>
        </FormControl>
    );
};

export default OutlinedDateTimePickerField;
