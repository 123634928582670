import { Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SaleTransactionStage, useGetSaleTransactionsQuery } from '../../../api';
import TabPanel from '../../../components/TabPanel';
import useCommonStyles from '../../../useCommonStyles';
import MenuTabs from './AllSessionsMenuTabs';
import SalesTransactionsTab from './SalesTransactionsTab';

const a11yProps = (index: string) => ({
    id: `bidding-sessions-tab-${index}`,
    'aria-controls': `bidding-sessions-tabpanel-${index}`,
});

const pageSize = 9;

const AllBiddingSessions = () => {
    const { t } = useTranslation(['common']);
    const commonStyles = useCommonStyles();
    const [value, setValue] = useState('currentBiddingSessions');

    const [upcomingTransactionPage, setUpcomingTransactionPage] = useState(0);

    const { data: allUpcomingBiddingData } = useGetSaleTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            page: { limit: pageSize, offset: upcomingTransactionPage * pageSize },
            filter: {
                stages: [SaleTransactionStage.UpcomingBidding],
            },
        },
    });

    const upcomingTransactionCount = allUpcomingBiddingData?.saleTransactions?.count || 0;
    const upcomingTransactions = allUpcomingBiddingData?.saleTransactions?.items || [];

    const [currentTransactionPage, setCurrentTransactionPage] = useState(0);

    const { data: allCurrnetBiddingData } = useGetSaleTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            page: { limit: pageSize, offset: currentTransactionPage * pageSize },
            filter: {
                stages: [SaleTransactionStage.BiddingOpen],
            },
        },
    });

    const currentTransactionCount = allCurrnetBiddingData?.saleTransactions?.count || 0;
    const currentTransactions = allCurrnetBiddingData?.saleTransactions?.items || [];

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Typography className={commonStyles.title}>{t('common:titles.allBiddingSessions')}</Typography>
            </Grid>
            <Grid xs={12} item>
                <MenuTabs a11yProps={a11yProps} setValue={setValue} value={value} />
            </Grid>
            <Grid xs={12} item>
                <TabPanel index="currentBiddingSessions" value={value}>
                    <SalesTransactionsTab
                        count={currentTransactionCount}
                        page={currentTransactionPage}
                        pageSize={pageSize}
                        saleTransactions={currentTransactions}
                        setActivePage={(newPage: number) => setCurrentTransactionPage(newPage)}
                    />
                </TabPanel>
                <TabPanel index="upcomingBiddingSessions" value={value}>
                    <SalesTransactionsTab
                        count={upcomingTransactionCount}
                        page={upcomingTransactionPage}
                        pageSize={pageSize}
                        saleTransactions={upcomingTransactions}
                        setActivePage={(newPage: number) => setUpcomingTransactionPage(newPage)}
                    />
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default AllBiddingSessions;
