import React, { useCallback, useEffect, useState } from 'react';
import { BiddingStatus, useListBiddingsQuery } from '../../api';
import ListPagination from '../../components/ListPagination';
import BiddingSessionsBody from './components/BiddingSessionsBody';
import BiddingSessionsHeader from './components/BiddingSessionsHeader';

type ListContext = {
    search: string;
    page: number;
    status: BiddingStatus | null;
};

const pageSize = 8;

const BiddingSessions = () => {
    const [{ page, search, status }, setListContext] = useState<ListContext>({ search: '', page: 0, status: null });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    const { data } = useListBiddingsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            page: { limit: pageSize, offset: page * pageSize },
            filter: { search, status },
        },
    });

    const setActivePage = useCallback(
        (newPage: number) => {
            setListContext(state => ({ ...state, page: newPage }));
        },
        [setListContext]
    );

    const setStatus = useCallback(
        (newStatus: BiddingStatus | null) => {
            setListContext(state => ({ ...state, status: newStatus }));
        },
        [setListContext]
    );

    const count = data?.biddings.count || 0;
    const biddings = data?.biddings?.items || [];

    return (
        <>
            <BiddingSessionsHeader />
            <BiddingSessionsBody biddings={biddings} setStatus={setStatus} />
            <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
        </>
    );
};

export default BiddingSessions;
