import { Button, FormControl, Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import usePortalStyles from '../../../../layouts/PortalLayout/usePortalStyles';
import useCommonStyles from '../../../../useCommonStyles';
import LoginBiometric from './LoginBiometric';

export type LoginFormSubmitProps = { isSubmitting: boolean };

const LoginFormSubmit = ({ isSubmitting }: LoginFormSubmitProps) => {
    const { t } = useTranslation(['loginPage']);
    const commonStyles = useCommonStyles();
    const portalStyles = usePortalStyles();

    return (
        <Grid xs={12} item>
            <Box display="flex">
                <FormControl fullWidth>
                    <Button
                        className={portalStyles.loginButton}
                        classes={{ disabled: commonStyles.disabledSecondaryButton }}
                        color="secondary"
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        {t('loginPage:submitButton')}
                    </Button>
                </FormControl>
                <LoginBiometric />
            </Box>
        </Grid>
    );
};

export default LoginFormSubmit;
