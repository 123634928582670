import { AppBar, Toolbar } from '@material-ui/core';
import cs from 'classnames';
import useBasicStyles from '../layouts/BasicLayout/useBasicStyles';

export interface MobileAppBarProps {
    children?: JSX.Element | React.ReactNode;
}

const MobileAppBar = ({ children }: MobileAppBarProps) => {
    const classes = useBasicStyles();

    return (
        <AppBar
            className={cs(classes.appBar, classes.lightBoxShadow, classes.appBarShift)}
            elevation={0}
            position="fixed"
        >
            <Toolbar className={classes.toolBar}>{children}</Toolbar>
        </AppBar>
    );
};

export default MobileAppBar;
