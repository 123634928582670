import { Container, Box, Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import { OTPForm, OTPFormProps, OTPPageFooter, OTPPageBody, OTPPageHeader, OTPPageFooterProps } from './components';

export type { OTPFormValues } from './components';

export type OtpValidationProps = OTPFormProps & OTPPageFooterProps;

const OTPPage = ({ handleSubmit, resendCode }: OtpValidationProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Container maxWidth="xs">
            <Box pt={isSmall ? 12 : 5}>
                <Grid spacing={2} container>
                    <OTPPageHeader />
                    <OTPPageBody />
                    <OTPForm handleSubmit={handleSubmit} />
                    <OTPPageFooter resendCode={resendCode} />
                </Grid>
            </Box>
        </Container>
    );
};

export default OTPPage;
