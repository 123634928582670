import { Grid, Box, Typography, Card, CardActionArea, CardContent } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PreviewSaleTransactionDataFragment } from '../../../../../api';
import useCommonStyles from '../../../../../useCommonStyles';
import useVehicleLogo from '../../../../../utilities/useVehicleLogo';

export type SessionTransactionItemProps = {
    sessionTransaction: PreviewSaleTransactionDataFragment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            padding: 0,
        },
        header: {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.primary.main,
            '& svg': {
                marginLeft: 'auto',
            },
        },
        body: {
            padding: theme.spacing(2),
            backgroundColor: '#EDEDED',
            color: '#717073',
            '& .MuiGrid-item:last-child': {
                opacity: '0.6',
            },
        },
        stage: {
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            marginLeft: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
            borderRadius: theme.spacing(0.5),
        },
        label: {
            marginRight: theme.spacing(1),
        },
    })
);

const CreateSessionVehicleListingItem = ({ sessionTransaction }: SessionTransactionItemProps) => {
    const { t } = useTranslation(['common', 'saleTransactionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useStyles();

    const vehicleLogo = useVehicleLogo(sessionTransaction.vehicle.make);

    return (
        <Card elevation={0}>
            <CardActionArea>
                <CardContent className={styles.content}>
                    <Box className={styles.header}>
                        <Box display="flex">
                            <Typography className={commonStyles.primary2}>
                                {sessionTransaction.vehicle.make}&nbsp;{sessionTransaction.vehicle.model}
                            </Typography>
                            <Typography className={cs(commonStyles.primary2, styles.stage)}>
                                {sessionTransaction.stage}
                            </Typography>
                        </Box>
                        <Typography className={commonStyles.primary2}>{sessionTransaction.vehicle.number}</Typography>
                    </Box>
                    <Box alignItems="center" className={styles.body} display="flex">
                        <Grid spacing={1} container>
                            <Grid xs={12} item>
                                <Typography className={commonStyles.smallFontSize}>
                                    {t('common:formats.longDate', {
                                        date: new Date(sessionTransaction.vehicle.originalRegistrationDate),
                                    })}
                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                    {sessionTransaction.vehicle.mileage.toLocaleString()}
                                    &nbsp;
                                    {t('common:shortKilometer')}
                                </Typography>
                            </Grid>
                            <Grid xs={12} item>
                                <Box display="flex">
                                    <Typography className={cs(commonStyles.smallFontSize, styles.label)}>
                                        {t('saleTransactionsPage:label.createdOn')}:
                                    </Typography>
                                    <Typography className={commonStyles.smallFontSize}>
                                        {t('common:formats.longDate', {
                                            date: new Date(sessionTransaction.createdAt),
                                        })}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <img alt="carLogo" className={commonStyles.vehicleLogo} src={vehicleLogo} />
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default CreateSessionVehicleListingItem;
