import FlutterAction from '../FlutterAction';

export type PushContext = {
    registrationToken: string;
    deviceFingerprint: true;
};

const action = new FlutterAction<void, PushContext>('push', async response => response);

export default action;
