import { Box, List, ListItemText } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import cs from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useCurrentUser } from '../../../Session';
import { UserType } from '../../../api';
import { DrawerBodyProps } from './Mobile/DrawerBody';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        itemContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '90px',
            paddingLeft: '24px',
            paddingRight: '24px',
            borderBottom: '1px solid #E6E5E5',
        },
        item: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: '#717073',
        },
        list: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        activeColor: {
            color: theme.palette.secondary.main,
        },
    })
);

const MenuList = ({ setOpen }: DrawerBodyProps) => {
    const { t } = useTranslation(['homePage']);

    const history = useHistory();
    const location = useLocation();

    const user = useCurrentUser();

    const styles = useStyles();

    const redirect = useCallback(
        (route: string) => () => {
            history.push(route);
            setOpen(true);
        },
        [history, setOpen]
    );

    const getMenuStyles = useCallback(
        (path: string) =>
            location.pathname.toLowerCase() === path.toLowerCase() ? cs(styles.item, styles.activeColor) : styles.item,
        [location.pathname, styles.activeColor, styles.item]
    );

    return (
        <List className={styles.list} component="nav">
            {user.type === UserType.Admin && (
                <>
                    <Box className={styles.itemContainer} onClick={redirect('/')}>
                        <Box className={getMenuStyles('/')}>
                            <ListItemText primary={t('homePage:menuBar.dashboard')} />
                            <ChevronRightIcon />
                        </Box>
                    </Box>
                    <Box className={styles.itemContainer} onClick={redirect('/users')}>
                        <Box className={getMenuStyles('/users')}>
                            <ListItemText primary={t('homePage:menuBar.userDatabase')} />
                            <ChevronRightIcon />
                        </Box>
                    </Box>
                    <Box className={styles.itemContainer} onClick={redirect('/approvals')}>
                        <Box className={getMenuStyles('/approvals')}>
                            <ListItemText primary={t('homePage:menuBar.approvals')} />
                            <ChevronRightIcon />
                        </Box>
                    </Box>
                </>
            )}

            {[UserType.SaleConsultant, UserType.ValuationTeam].includes(user.type) && (
                <Box className={styles.itemContainer} onClick={redirect('/salesTransactions')}>
                    <Box className={getMenuStyles('/salesTransactions')}>
                        <ListItemText primary={t('homePage:menuBar.myBids')} />
                        <ChevronRightIcon />
                    </Box>
                </Box>
            )}

            {[UserType.Admin, UserType.ValuationTeam].includes(user.type) && (
                <Box className={styles.itemContainer} onClick={redirect('/salesTransactions')}>
                    <Box className={getMenuStyles('/salesTransactions')}>
                        <ListItemText primary={t('homePage:menuBar.salesTransactions')} />
                        <ChevronRightIcon />
                    </Box>
                </Box>
            )}

            {user.type === UserType.Dealer && (
                <>
                    <Box className={styles.itemContainer} onClick={redirect('/myBids')}>
                        <Box className={getMenuStyles('/myBids')}>
                            <ListItemText primary={t('homePage:menuBar.myBids')} />
                            <ChevronRightIcon />
                        </Box>
                    </Box>
                    <Box className={styles.itemContainer} onClick={redirect('/allBiddingSessions')}>
                        <Box className={getMenuStyles('/allBiddingSessions')}>
                            <ListItemText primary={t('homePage:menuBar.allBiddingSessions')} />
                            <ChevronRightIcon />
                        </Box>
                    </Box>
                </>
            )}

            {user.type === UserType.Approver && (
                <>
                    <Box className={styles.itemContainer} onClick={redirect('/transactionApprovals')}>
                        <Box className={getMenuStyles('/transactionApprovals')}>
                            <ListItemText primary={t('homePage:menuBar.approvals')} />
                            <ChevronRightIcon />
                        </Box>
                    </Box>
                    <Box className={styles.itemContainer} onClick={redirect('/approverBiddingSessions')}>
                        <Box className={getMenuStyles('/approverBiddingSessions')}>
                            <ListItemText primary={t('homePage:menuBar.biddingSessions')} />
                            <ChevronRightIcon />
                        </Box>
                    </Box>
                </>
            )}

            {user.type === UserType.Admin && (
                <>
                    <Box className={styles.itemContainer} onClick={redirect('/notifications')}>
                        <Box className={getMenuStyles('/notifications')}>
                            <ListItemText primary={t('homePage:menuBar.notificationsLog')} />
                            <ChevronRightIcon />
                        </Box>
                    </Box>
                    <Box className={styles.itemContainer} onClick={redirect('/activityHistory')}>
                        <Box className={getMenuStyles('/activityHistory')}>
                            <ListItemText primary={t('homePage:menuBar.activityHistory')} />
                            <ChevronRightIcon />
                        </Box>
                    </Box>
                </>
            )}

            <Box className={styles.itemContainer} onClick={redirect('/settings')}>
                <Box className={getMenuStyles('/settings')}>
                    <ListItemText primary={t('homePage:menuBar.settings')} />
                    <ChevronRightIcon />
                </Box>
            </Box>
        </List>
    );
};

export default MenuList;
