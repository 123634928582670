import { Grid, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PurchaseTransactionStage, useGetPurchaseTransactionsQuery, useGetSaleTransactionsQuery } from '../../../api';
import TabPanel from '../../../components/TabPanel';
import useCommonStyles from '../../../useCommonStyles';
import { DownloadListFn } from '../../PurchaseTransactions/components';
import { DownloadSalesTransactionsFileProvider } from '../../SaleTransactions/components';
import PurchaseHandoverTab from './PurchaseHandoverTab';
import SalesHandoverTab from './SalesHandoverTab';
import TransactionMenuTabs from './TransactionMenuTabs';

const a11yProps = (index: string) => ({
    id: `transaction-tab-${index}`,
    'aria-controls': `transactions-tabpanel-${index}`,
});

export type PurchaseHandoverListContext = {
    purchaseHandoverSearch: string;
    purchaseHandoverPage: number;
};

type SalesHandoverListContext = {
    salesHandoverSearch: string;
    salesHandoverPage: number;
};

const pageSize = 9;

type VtTransactionsProps = {
    downloadPurchaseTransactionsFile: DownloadListFn;
};

const VtTransactions = ({ downloadPurchaseTransactionsFile }: VtTransactionsProps) => {
    const { t } = useTranslation(['common']);
    const commonStyles = useCommonStyles();

    const [value, setValue] = useState('purchaseTransactions');
    const [purchaseTransactionStage, setPurchaseTransactionStage] = useState<'all' | PurchaseTransactionStage>('all');
    const [purchaseTransactionCreatedAt, setPurchaseTransactionCreatedAt] = useState<Date | null>(null);
    const [purchaseTransactionTargetHandoverDate, setPurchaseTransactionTargetHandoverDate] = useState<Date | null>(
        null
    );

    // Purchase handover data
    const [{ purchaseHandoverPage, purchaseHandoverSearch }, setPurchaseHandoverListContext] =
        useState<PurchaseHandoverListContext>({
            purchaseHandoverSearch: '',
            purchaseHandoverPage: 0,
        });

    const { data: purchaseHandoverData } = useGetPurchaseTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            page: { limit: pageSize, offset: purchaseHandoverPage * pageSize },
            filter: {
                search: purchaseHandoverSearch,
                stages: purchaseTransactionStage === 'all' ? null : [purchaseTransactionStage],
                createdAt: purchaseTransactionCreatedAt,
                handoverAt: purchaseTransactionTargetHandoverDate,
                onlySelf: true,
            },
        },
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [purchaseHandoverPage]);

    const setPurchaseHandoverActivePage = useCallback(
        (newPage: number) => {
            setPurchaseHandoverListContext(state => ({ ...state, purchaseHandoverPage: newPage }));
        },
        [setPurchaseHandoverListContext]
    );

    const purchaseHandoverSearchOnChange = useCallback(
        (searchValue: string) => {
            setPurchaseHandoverListContext(state => ({ ...state, currentBiddingSearch: searchValue }));
        },
        [setPurchaseHandoverListContext]
    );

    const purchaseHandoverCount = purchaseHandoverData?.purchaseTransactions?.count || 0;
    const purchaseHandoverTransactions = purchaseHandoverData?.purchaseTransactions?.items || [];

    // Sales handover data
    const [{ salesHandoverPage, salesHandoverSearch }, setSalesHandoverListContext] =
        useState<SalesHandoverListContext>({
            salesHandoverSearch: '',
            salesHandoverPage: 0,
        });
    const [salesTransactionCreatedAt, setSalesTransactionCreatedAt] = useState<Date | null>(null);
    const [salesTransactionTargetHandoverDate, setSalesTransactionTargetHandoverDate] = useState<Date | null>(null);
    const [awardedDealer, setAwardedDealer] = useState<string>('all');

    const { data: salesHandoverData } = useGetSaleTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            page: { limit: pageSize, offset: salesHandoverPage * pageSize },
            filter: {
                search: salesHandoverSearch,
                createdAt: salesTransactionCreatedAt,
                handoverAt: salesTransactionTargetHandoverDate,
                awardedDealer: awardedDealer === 'all' ? null : awardedDealer,
                onlySelf: true,
            },
        },
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [salesHandoverPage]);

    const setSalesHandoverActivePage = useCallback(
        (newPage: number) => {
            setSalesHandoverListContext(state => ({ ...state, salesHandoverPage: newPage }));
        },
        [setSalesHandoverListContext]
    );

    const salesHandoverSearchOnChange = useCallback(
        (searchValue: string) => {
            setSalesHandoverListContext(state => ({ ...state, currentBiddingSearch: searchValue }));
        },
        [setSalesHandoverListContext]
    );

    const salesHandoverCount = salesHandoverData?.saleTransactions?.count || 0;
    const salesHandoverTransactions = salesHandoverData?.saleTransactions?.items || [];

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Typography className={commonStyles.title}>{t('common:titles.myTransactions')}</Typography>
            </Grid>
            <Grid xs={12} item>
                <TransactionMenuTabs a11yProps={a11yProps} setValue={setValue} value={value} />
            </Grid>
            <Grid xs={12} item>
                <TabPanel index="purchaseTransactions" value={value}>
                    <PurchaseHandoverTab
                        count={purchaseHandoverCount}
                        createdAt={purchaseTransactionCreatedAt}
                        downloadPurchaseTransactionsFile={downloadPurchaseTransactionsFile}
                        page={purchaseHandoverPage}
                        pageSize={pageSize}
                        purchaseTransactions={purchaseHandoverTransactions}
                        search={purchaseHandoverSearch}
                        searchOnChange={purchaseHandoverSearchOnChange}
                        setActivePage={setPurchaseHandoverActivePage}
                        setCreatedAt={setPurchaseTransactionCreatedAt}
                        setStage={setPurchaseTransactionStage}
                        setTargetHandoverDate={setPurchaseTransactionTargetHandoverDate}
                        stage={purchaseTransactionStage}
                        targetHandoverDate={purchaseTransactionTargetHandoverDate}
                    />
                </TabPanel>
                <TabPanel index="salesTransactions" value={value}>
                    <DownloadSalesTransactionsFileProvider>
                        {downloadFile => (
                            <SalesHandoverTab
                                awardedDealer={awardedDealer}
                                count={salesHandoverCount}
                                createdAt={salesTransactionCreatedAt}
                                downloadSalesTransactionsFile={downloadFile}
                                page={salesHandoverPage}
                                pageSize={pageSize}
                                saleTransactions={salesHandoverTransactions}
                                search={salesHandoverSearch}
                                searchOnChange={salesHandoverSearchOnChange}
                                setActivePage={setSalesHandoverActivePage}
                                setAwardedDealer={setAwardedDealer}
                                setCreatedAt={setSalesTransactionCreatedAt}
                                setTargetHandoverDate={setSalesTransactionTargetHandoverDate}
                                targetHandoverDate={salesTransactionTargetHandoverDate}
                            />
                        )}
                    </DownloadSalesTransactionsFileProvider>
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default VtTransactions;
