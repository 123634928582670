import { InputAdornment } from '@material-ui/core';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import StorefrontIcon from '@material-ui/icons/Storefront';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';

export const passwordAdornment = (
    <InputAdornment position="start">
        <LockOutlinedIcon />
    </InputAdornment>
);

export const emailAdornment = (
    <InputAdornment position="start">
        <EmailOutlinedIcon />
    </InputAdornment>
);

export const phoneAdornment = (
    <InputAdornment position="start">
        <PhoneAndroidIcon />
    </InputAdornment>
);

export const personAdornment = (
    <InputAdornment position="start">
        <PersonOutlineOutlinedIcon />
    </InputAdornment>
);

export const businessAdornment = (
    <InputAdornment position="start">
        <StorefrontIcon />
    </InputAdornment>
);

export const peopleAdornment = (
    <InputAdornment position="start">
        <SupervisorAccountOutlinedIcon />
    </InputAdornment>
);
