import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormValues } from '..';
import OutlinedDatePickerField from '../../../components/fields/OutlinedDatePickerField';

const ActivityHistoryBody = () => {
    const { t } = useTranslation(['activityHistoryPage', 'common']);
    const { values, setFieldValue } = useFormikContext<FormValues>();

    useEffect(() => {
        if (values.fromDate && values.toDate && values.fromDate > values.toDate) {
            setFieldValue('toDate', null);
        }
    }, [setFieldValue, values]);

    return (
        <Grid spacing={2} container>
            <Grid lg={6} md={6} sm={12} xs={12} item>
                <OutlinedDatePickerField
                    color="secondary"
                    label={t('activityHistoryPage:label.from')}
                    maxDate={new Date()}
                    name="fromDate"
                    placeholder={t('activityHistoryPage:label.from')}
                />
            </Grid>
            <Grid lg={6} md={6} sm={12} xs={12} item>
                <OutlinedDatePickerField
                    color="secondary"
                    label={t('activityHistoryPage:label.to')}
                    maxDate={new Date()}
                    minDate={values.fromDate || new Date(2000, 1, 1)}
                    name="toDate"
                    placeholder={t('activityHistoryPage:label.to')}
                />
            </Grid>
        </Grid>
    );
};

export default ActivityHistoryBody;
