import { Box } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Pagination, PaginationItem } from '@material-ui/lab';

export type ListPaginationProps = {
    activePage: number;
    count: number;
    pageSize: number;
    setActivePage: (page: number) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& li:first-child, & li:last-child': {
                '& > .MuiPaginationItem-page': {
                    backgroundColor: `${theme.palette.secondary.main} !important`,
                    color: `${theme.palette.secondary.contrastText} !important`,
                    margin: `0 ${theme.spacing(2)}px`,
                },
            },
        },
        page: {
            border: 'none',
            backgroundColor: `${theme.palette.secondary.contrastText} !important`,
            color: `${theme.palette.secondary.main} !important`,
            margin: '0',
        },
        selected: {
            backgroundColor: `${theme.palette.secondary.main} !important`,
            color: `${theme.palette.secondary.contrastText} !important`,
        },
    })
);

const ListPagination = ({ activePage, count, pageSize, setActivePage }: ListPaginationProps) => {
    if (count < pageSize) {
        return null;
    }

    const styles = useStyles();

    return (
        <Box display="flex" justifyContent="center">
            <Pagination
                className={styles.root}
                color="secondary"
                count={Math.ceil(count / pageSize)}
                onChange={(event, page) => setActivePage(page - 1)}
                page={activePage}
                renderItem={item => (
                    <PaginationItem
                        {...item}
                        classes={{
                            selected: styles.selected,
                            page: styles.page,
                        }}
                    />
                )}
                shape="rounded"
                size="large"
                variant="outlined"
            />
        </Box>
    );
};

export default ListPagination;
