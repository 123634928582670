import { saveAs } from 'file-saver';
import { useState, useCallback, createContext, useContext } from 'react';
import { NameOnlyFileDataFragment } from '../api';
import { getExtension, isImageFile } from '../utilities/file';
import { useFlutter } from './FlutterProvider';
import { useLoading } from './LoadingProvider';
import ViewPhotoDialog from './ViewPhotoDialog';

export type FileViewerProviderProps = {
    children: JSX.Element | React.ReactNode;
};

export type OpenFileViewer = (file: NameOnlyFileDataFragment) => void;

const Context = createContext<OpenFileViewer | null>(null);

export const useFileViewer = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('File viewer not in context');
    }

    return context;
};

const FileViewerProvider = ({ children }: FileViewerProviderProps) => {
    const [file, setFile] = useState<NameOnlyFileDataFragment | null>(null);
    const { deviceFingerPrint, sendDownloadFile } = useFlutter();
    const { attach } = useLoading();

    const view: OpenFileViewer = useCallback(
        (file: NameOnlyFileDataFragment) => {
            const extension = getExtension(file.filename);

            if (isImageFile(extension)) {
                setFile(file);
            } else {
                if (deviceFingerPrint) {
                    const execute = async () => {
                        await sendDownloadFile({
                            filename: file.filename,
                            signedUrl: file.signedUrl,
                        });
                    };

                    attach(execute());

                    return;
                }

                saveAs(file.signedUrl, file.filename);
            }
        },
        [deviceFingerPrint, attach, sendDownloadFile]
    );

    const onClose = useCallback(() => {
        setFile(null);
    }, [setFile]);

    return (
        <Context.Provider value={view}>
            <ViewPhotoDialog file={file} handleOnClose={onClose} show={!!file} />
            {children}
        </Context.Provider>
    );
};

export default FileViewerProvider;
