import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const defaultOtherBrandOption = '__OTHERS__';

const useBrandOptions = (otherValue = defaultOtherBrandOption) => {
    const { t } = useTranslation(['common']);

    return useMemo(() => {
        const options = [
            t('common:brands.toyota'),
            t('common:brands.mercedesBenz'),
            t('common:brands.bmw'),
            t('common:brands.honda'),
            t('common:brands.mazda'),
            t('common:brands.hyundai'),
        ].map(label => ({ value: label, label }));

        options.push({ value: otherValue, label: t('common:brands.others') });

        return options;
    }, [t, otherValue]);
};

export default useBrandOptions;
