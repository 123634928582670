import { Snackbar } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import { createContext, useContext, useMemo, useReducer, ReactNode } from 'react';

export type AlertContext = {
    show: (severity: Color, message: string) => void;
    close: () => void;
};

const Context = createContext<AlertContext | null>(null);

type State = {
    open: boolean;
    severity: Color;
    message: string;
};

type ShowAction = { type: 'show' } & Pick<State, 'severity' | 'message'>;

type CloseAction = { type: 'close' };

type Action = ShowAction | CloseAction;

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'show':
            return { open: true, message: action.message, severity: action.severity };

        case 'close':
            return { ...state, open: false };

        default:
            return state;
    }
};

const initialState: State = {
    open: false,
    severity: 'info',
    message: '',
};

export type AlertProviderProps = {
    children: JSX.Element | ReactNode;
};

export const useAlert = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('Alert context not provided');
    }

    return context;
};

const AlertProvider = ({ children }: AlertProviderProps) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const context = useMemo(
        (): AlertContext => ({
            show: (severity: Color, message: string) => {
                dispatch({ type: 'show', severity, message });
            },
            close: () => {
                dispatch({ type: 'close' });
            },
        }),
        [dispatch]
    );

    const { close } = context;

    return (
        <Context.Provider value={context}>
            <Snackbar autoHideDuration={6000} onClose={close} open={state.open}>
                <Alert elevation={6} severity={state.severity} variant="filled">
                    {state.message}
                </Alert>
            </Snackbar>
            {children}
        </Context.Provider>
    );
};

export default AlertProvider;
