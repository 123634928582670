import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useGeneralConditionOptions = () => {
    const { t } = useTranslation(['generalConditionOptions']);

    return useMemo(() => {
        const labels = [
            t('generalConditionOptions:new'),
            t('generalConditionOptions:almostNew'),
            t('generalConditionOptions:good'),
            t('generalConditionOptions:bad'),
        ];

        return labels.map(label => ({ value: label, label }));
    }, [t]);
};

export default useGeneralConditionOptions;
