import FlutterChannel, { FlutterMessage } from './FlutterChannel';
import * as flutterActions from './actions';
import {
    FlutterCapabilities,
    OneMonitoringData,
    PushContext,
    FlutterMeta,
    FlutterStats,
    IncomingPushContext,
    DownloadFileContext,
} from './actions';

export type { FlutterCapabilities, OneMonitoringData, PushContext, FlutterMeta, FlutterStats, DownloadFileContext };

const actions = Object.values(flutterActions);

export class FlutterInterface {
    private readonly channel: FlutterChannel;

    constructor() {
        this.channel = new FlutterChannel();
    }

    public async initialize(): Promise<FlutterMeta> {
        return flutterActions.initialize.attachChannel(this.channel).send();
    }

    public async getOneMonitoringData(): Promise<OneMonitoringData> {
        return flutterActions.oneMonitoring.attachChannel(this.channel).send();
    }

    public async initializePush(): Promise<PushContext> {
        return flutterActions.push.attachChannel(this.channel).send();
    }

    public async checkBiometric(): Promise<boolean> {
        return flutterActions.biometric.attachChannel(this.channel).send();
    }

    public listenPushes(listener: (push: IncomingPushContext) => void) {
        return flutterActions.readPush.listen(listener);
    }

    public sendDownloadFile(send: ({ filename, signedUrl }: DownloadFileContext) => void): Promise<boolean> {
        return flutterActions.downloadFile.attachChannel(this.channel).send(send);
    }

    public async receive({ type, ...response }: FlutterMessage<any>) {
        for (const action of actions) {
            if (action.identifier === type) {
                action.receive(response);
                break;
            }
        }
    }
}

const getInstance = () => {
    if (!window.flutterInterface) {
        window.flutterInterface = new FlutterInterface();
    }

    return window.flutterInterface;
};

export default getInstance();
