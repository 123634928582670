import { useMediaQuery } from '@material-ui/core';
import { MuiThemeProvider, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import BasicLayout from '../layouts/BasicLayout';
import basicTheme from '../layouts/BasicLayout/basicTheme';
import Settings from '../pages/Settings';

const SettingsRoute = () => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();

    if (isSmall) {
        return (
            <MuiThemeProvider theme={basicTheme}>
                <Settings />
            </MuiThemeProvider>
        );
    }

    return (
        <BasicLayout onBack={history.goBack}>
            <Settings />
        </BasicLayout>
    );
};

export default SettingsRoute;
