import { Redirect } from 'react-router-dom';
import { useCurrentUser } from '../Session';
import { UserType } from '../api';
import BasicLayout from '../layouts/BasicLayout';
import ActivityHistory from '../pages/ActivityHistory';
import { DownloadActivityHistoryFileProvider } from '../pages/ActivityHistory/components';

const ActivityHistoryRoute = () => {
    const currentUser = useCurrentUser();

    if (currentUser.type !== UserType.Admin) {
        return <Redirect to="/403" />;
    }

    return (
        <BasicLayout>
            <DownloadActivityHistoryFileProvider>
                {downloadFile => <ActivityHistory downloadFile={downloadFile} />}
            </DownloadActivityHistoryFileProvider>
        </BasicLayout>
    );
};

export default ActivityHistoryRoute;
