import { Box } from '@material-ui/core';
import BiddingRulesAndRegulationsBody from './BiddingRulesAndRegulationsBody';
import BiddingRulesAndRegulationsHeader from './BiddingRulesAndRegulationsHeader';

export type BiddingRulesAndRegulationsProps = {
    setShowBiddingRulesAndRegulations: (value: boolean) => void;
};

const BiddingRulesAndRegulations = ({ setShowBiddingRulesAndRegulations }: BiddingRulesAndRegulationsProps) => (
    <Box>
        <BiddingRulesAndRegulationsHeader setShowBiddingRulesAndRegulations={setShowBiddingRulesAndRegulations} />
        <BiddingRulesAndRegulationsBody />
    </Box>
);

export default BiddingRulesAndRegulations;
