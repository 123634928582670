import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PreviewUserDataFragment } from '../../../api';
import useCommonStyles from '../../../useCommonStyles';
import ApprovalsRow from './ApprovalsRow';

export type ApprovalsTableProps = {
    users: PreviewUserDataFragment[];
    onUpdates: () => void;
};

const ApprovalsTable = ({ users, onUpdates }: ApprovalsTableProps) => {
    const { t } = useTranslation(['approvalsPage']);
    const commonStyles = useCommonStyles();

    return (
        <Box className={commonStyles.tableWrapper} mb={3}>
            <Table className={commonStyles.table}>
                <TableHead>
                    <TableRow className={commonStyles.tableRow}>
                        <TableCell>{t('approvalsPage:headers.fullName')}</TableCell>
                        <TableCell>{t('approvalsPage:headers.email')}</TableCell>
                        <TableCell>{t('approvalsPage:headers.mobile')}</TableCell>
                        <TableCell>{t('approvalsPage:headers.type')}</TableCell>
                        <TableCell>{t('approvalsPage:headers.additionalInformation')}</TableCell>
                        <TableCell>{t('approvalsPage:headers.actions')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map(user => (
                        <ApprovalsRow key={user.id} onUpdates={onUpdates} user={user} />
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default ApprovalsTable;
