import { Grid, Typography } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetSaleTransactionsQuery, SaleTransactionStage, DownloadFileType } from '../../api';
import { useLoading } from '../../components/LoadingProvider';
import TabPanel from '../../components/TabPanel';
import useCommonStyles from '../../useCommonStyles';
import { DownloadSaleListFn } from '../SaleTransactions/components';
import { MenuTabs, SalesTransactionsTab } from './components';

const a11yProps = (index: string) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
});

type ForAwardingTransactionListContext = {
    forAwardingTransactionSearch: string;
    forAwardingTransactionPage: number;
};

type CurrentBiddingListContext = {
    currentBiddingSearch: string;
    currentBiddingPage: number;
};

const pageSize = 9;

export type ApproverBiddingSessionsProps = {
    downloadSalesTransactionsFile: DownloadSaleListFn;
};

const ApproverBiddingSessions = ({ downloadSalesTransactionsFile }: ApproverBiddingSessionsProps) => {
    const { t } = useTranslation(['common']);
    const commonStyles = useCommonStyles();

    const [value, setValue] = useState('forAwarding');
    const { attach } = useLoading();

    const [forAwardingBidOpeningOn, setForAwardingBidOpeningOn] = useState<Date | null>(null);
    const [forAwardingBidClosingOn, setForAwardingBidClosingOn] = useState<Date | null>(null);

    const [{ forAwardingTransactionPage, forAwardingTransactionSearch }, setForAwardingTransactionListContext] =
        useState<ForAwardingTransactionListContext>({
            forAwardingTransactionSearch: '',
            forAwardingTransactionPage: 0,
        });

    const { data: forAwardingTransactionsData } = useGetSaleTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            page: { limit: pageSize, offset: forAwardingTransactionPage * pageSize },
            filter: {
                search: forAwardingTransactionSearch,
                searchFields: ['vehicle.number'],
                stages: [SaleTransactionStage.ForAwarding],
                bidOpeningOn: forAwardingBidOpeningOn,
                bidClosingOn: forAwardingBidClosingOn,
            },
        },
    });

    const setForAwardingTransactionActivePage = useCallback(
        (newPage: number) => {
            setForAwardingTransactionListContext(state => ({ ...state, forAwardingTransactionPage: newPage }));
        },
        [setForAwardingTransactionListContext]
    );

    const forAwardingTransactionSearchOnChange = useCallback(
        (searchValue: string) => {
            setForAwardingTransactionListContext(state => ({ ...state, forAwardingTransactionSearch: searchValue }));
        },
        [setForAwardingTransactionListContext]
    );

    const forAwardingTransactionCount = forAwardingTransactionsData?.saleTransactions?.count || 0;
    const forAwardingTransactions = forAwardingTransactionsData?.saleTransactions?.items || [];

    const downloadForAwardingTransactionsFile = useCallback(
        (type: DownloadFileType) => {
            const execute = async () => {
                await downloadSalesTransactionsFile({
                    downloadFileType: type,
                    filter: {
                        search: forAwardingTransactionSearch,
                        searchFields: ['vehicle.number'],
                        stages: [SaleTransactionStage.ForAwarding],
                    },
                });
            };

            attach(execute());
        },
        [attach, downloadSalesTransactionsFile, forAwardingTransactionSearch]
    );

    const [currentBiddingBidOpeningOn, setCurrentBiddingBidOpeningOn] = useState<Date | null>(null);
    const [currentBiddingBidClosingOn, setCurrentBiddingBidClosingOn] = useState<Date | null>(null);

    const [{ currentBiddingPage, currentBiddingSearch }, setCurrentBiddingListContext] =
        useState<CurrentBiddingListContext>({
            currentBiddingSearch: '',
            currentBiddingPage: 0,
        });

    const { data: currentBiddingsData } = useGetSaleTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            page: { limit: pageSize, offset: currentBiddingPage * pageSize },
            filter: {
                search: currentBiddingSearch,
                searchFields: ['vehicle.number'],
                stages: [SaleTransactionStage.BiddingOpen],
                bidOpeningOn: currentBiddingBidOpeningOn,
                bidClosingOn: currentBiddingBidClosingOn,
            },
        },
    });

    const setCurrentBiddingActivePage = useCallback(
        (newPage: number) => {
            setCurrentBiddingListContext(state => ({ ...state, currentBiddingPage: newPage }));
        },
        [setCurrentBiddingListContext]
    );

    const currentBiddingSearchOnChange = useCallback(
        (searchValue: string) => {
            setCurrentBiddingListContext(state => ({ ...state, currentBiddingSearch: searchValue }));
        },
        [setCurrentBiddingListContext]
    );

    const currentBiddingCount = currentBiddingsData?.saleTransactions?.count || 0;
    const currentBiddings = currentBiddingsData?.saleTransactions?.items || [];

    const downloadCurrentBiddingsFile = useCallback(
        (type: DownloadFileType) => {
            const execute = async () => {
                await downloadSalesTransactionsFile({
                    downloadFileType: type,
                    filter: {
                        search: currentBiddingSearch,
                        searchFields: ['vehicle.number'],
                        stages: [SaleTransactionStage.BiddingOpen],
                    },
                });
            };

            attach(execute());
        },
        [attach, currentBiddingSearch, downloadSalesTransactionsFile]
    );

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Typography className={commonStyles.title}>{t('common:titles.biddingSessions')}</Typography>
            </Grid>
            <Grid xs={12} item>
                <MenuTabs a11yProps={a11yProps} setValue={setValue} value={value} />
            </Grid>
            <Grid xs={12} item>
                <TabPanel index="forAwarding" value={value}>
                    <SalesTransactionsTab
                        bidClosingOn={forAwardingBidClosingOn}
                        bidOpeningOn={forAwardingBidOpeningOn}
                        count={forAwardingTransactionCount}
                        downloadExcel={() => downloadForAwardingTransactionsFile(DownloadFileType.Excel)}
                        downloadPdf={() => downloadForAwardingTransactionsFile(DownloadFileType.Pdf)}
                        page={forAwardingTransactionPage}
                        pageSize={pageSize}
                        saleTransactions={forAwardingTransactions}
                        search={forAwardingTransactionSearch}
                        searchOnChange={forAwardingTransactionSearchOnChange}
                        setActivePage={setForAwardingTransactionActivePage}
                        setBidClosingOn={setForAwardingBidClosingOn}
                        setBidOpeningOn={setForAwardingBidOpeningOn}
                    />
                </TabPanel>
                <TabPanel index="currentBidding" value={value}>
                    <SalesTransactionsTab
                        bidClosingOn={currentBiddingBidClosingOn}
                        bidOpeningOn={currentBiddingBidOpeningOn}
                        count={currentBiddingCount}
                        downloadExcel={() => downloadCurrentBiddingsFile(DownloadFileType.Excel)}
                        downloadPdf={() => downloadCurrentBiddingsFile(DownloadFileType.Pdf)}
                        page={currentBiddingPage}
                        pageSize={pageSize}
                        saleTransactions={currentBiddings}
                        search={currentBiddingSearch}
                        searchOnChange={currentBiddingSearchOnChange}
                        setActivePage={setCurrentBiddingActivePage}
                        setBidClosingOn={setCurrentBiddingBidClosingOn}
                        setBidOpeningOn={setCurrentBiddingBidOpeningOn}
                    />
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default ApproverBiddingSessions;
