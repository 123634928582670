import { useApolloClient } from '@apollo/client';
import PubSub from 'pubsub-js';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
    useGetSelfQuery,
    GetSelfQuery,
    RefreshSessionDocument,
    RefreshSessionMutation,
    RefreshSessionMutationVariables,
} from './api';

export type SessionData = {
    token: string | null;
    setToken: (token: string | null) => void;
    data: {
        iat: number;
        exp: number;
        userId: { $oid: string };
    } | null;
    hasSession: boolean;
    currentUser: GetSelfQuery['getSelf'] | null;
};

export const Context = createContext<SessionData | null>(null);

export const useSession = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('Context for session is missing');
    }

    return context;
};

export const useCurrentUser = () => {
    const { currentUser } = useSession();

    if (!currentUser) {
        throw new Error('Current user not available');
    }

    return currentUser;
};

export type SessionProviderProps = {
    children: JSX.Element | ReactNode;
};

export const storageKey = 'session';

const getTokenFromStorage = (): string | null => localStorage.getItem(storageKey);

const validateTokenData = (token?: string) => {
    if (!token) {
        // no token
        return null;
    }

    const parts = token.split('.');

    if (parts.length !== 3) {
        // invalid token
        return null;
    }

    const rawData = atob(parts[1]);

    try {
        const data = JSON.parse(rawData) as SessionData['data'];

        if (data.exp * 1000 > new Date().getTime()) {
            return data;
        }
    } catch (error) {
        // invalid data
        console.warn(error);
    }

    return null;
};

const SessionProvider = ({ children }: SessionProviderProps) => {
    // token state initialize on the local storage
    const [token, setToken] = useState(getTokenFromStorage);
    // data state extracted from the token (and validated)
    const data = useMemo(() => validateTokenData(token), [token]);
    // apollo client
    const client = useApolloClient();

    const updateToken = useCallback(
        (newToken: string | null) => {
            if (newToken) {
                // update storage
                localStorage.setItem(storageKey, newToken);
            } else {
                // clean the storage
                localStorage.removeItem(storageKey);
                // on logout we also reset the cache
                client.cache.reset();
            }

            setToken(newToken);
        },
        [setToken, client]
    );

    // then the apollo call
    const {
        data: apiResponseData,
        loading,
        called,
        refetch,
    } = useGetSelfQuery({
        skip: !data,
        fetchPolicy: 'cache-and-network',
    });

    // refetch whenever the token changed
    useEffect(() => {
        if (token) {
            refetch();
        }
    }, [refetch, token]);

    // get the current user from the response
    const currentUser = apiResponseData?.getSelf || null;

    // reset the token is the data seems to be invalid
    useEffect(() => {
        if (!data) {
            updateToken(null);
        }
    }, [data, updateToken]);

    // check if the api seems to not get us any data for this token
    const invalidResponse = called && !loading && data && !currentUser;

    useEffect(() => {
        if (invalidResponse) {
            // unset the token
            updateToken(null);
        }
    }, [invalidResponse, updateToken]);

    useEffect(() => {
        if (!data) {
            return () => undefined;
        }

        let mounted = true;

        const fn = async () => {
            if (!mounted) {
                // do nothing
                return;
            }

            const left = data.exp - Date.now() / 1000;

            // renew 10mn ahead maximum
            if (left > 600) {
                // do nothing
                setTimeout(fn, 1000);

                // stop here
                return;
            }

            try {
                const response = await client.mutate<RefreshSessionMutation, RefreshSessionMutationVariables>({
                    mutation: RefreshSessionDocument,
                });

                if (mounted) {
                    updateToken(response?.data?.response);
                }
            } catch (error) {
                // print it out
                console.error(error);

                if (mounted) {
                    // reset token
                    updateToken(null);
                }
            }
        };

        setTimeout(fn, 200);

        return () => {
            mounted = false;
        };
    }, [data, client, updateToken]);

    useEffect(() => {
        const pubToken = PubSub.subscribe('unauthenticated', () => updateToken(null));

        return () => PubSub.unsubscribe(pubToken);
    }, [updateToken]);

    // compute the session context
    const session = useMemo(
        () => ({
            token,
            setToken: updateToken,
            data,
            hasSession: !!data,
            currentUser,
        }),
        [token, updateToken, data, currentUser]
    );

    return <Context.Provider value={session}>{children}</Context.Provider>;
};

export default SessionProvider;
