import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../useCommonStyles';

const NoRecordsFound = () => {
    const commonStyles = useCommonStyles();
    const { t } = useTranslation(['common']);

    return (
        <Box>
            <Typography className={commonStyles.fontWeightLight}>{t('common:noRecordsFound')}</Typography>
        </Box>
    );
};

export default NoRecordsFound;
