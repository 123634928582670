import { ReactNode } from 'react';
import {
    ExportExcelForActivityLogsDocument as query,
    ExportExcelForActivityLogsQuery as Query,
    ExportExcelForActivityLogsQueryVariables as Variables,
} from '../../../api';
import DownloadProvider, { DownloadFn } from '../../../components/DownloadProvider';

export type DownloadActivityHistoryFn = DownloadFn<Variables>;

export type DownloadActivityHistoryFileProviderProps = {
    children: (fn: DownloadActivityHistoryFn) => JSX.Element | ReactNode;
};

const getSignedUrl = (data: Query) => data?.signedUrl || null;

const DownloadActivityHistoryFileProvider = ({ children }: DownloadActivityHistoryFileProviderProps) => (
    <DownloadProvider getSignedUrl={getSignedUrl} query={query}>
        {children}
    </DownloadProvider>
);

export default DownloadActivityHistoryFileProvider;
