import { Typography, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type ErrorDialogProps = {
    fields: string[];
    onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorText: {
            color: theme.palette.error.main,
        },
        errorDescription: {
            marginBottom: theme.spacing(2),
        },
    })
);

const ErrorDialog = ({ fields, onClose }: ErrorDialogProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common']);

    const styles = useStyles();

    return (
        fields &&
        fields.length > 0 && (
            <Dialog maxWidth="sm" onClose={onClose} open={fields && fields.length > 0} fullWidth>
                <DialogTitle>
                    <Typography className={styles.errorText}>
                        {t('purchaseTransactionsPage:errors.errorTitle')}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <Typography className={styles.errorDescription} component="h3">
                            {t('purchaseTransactionsPage:errors.errorDescription')}
                        </Typography>
                        <Grid component="ul" spacing={1} container>
                            {fields.map(field => (
                                <Grid sm={6} xs={12} item>
                                    <Box component="li">{field}</Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={onClose} variant="contained" autoFocus>
                        {t('common:ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    );
};

export default ErrorDialog;
