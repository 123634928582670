import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserType, FullUserDataFragment } from '../../../api';
import OutlinedInputField from '../../../components/fields/OutlinedInputField';
import OutlinedSelectField from '../../../components/fields/OutlinedSelectField';
import useCommonStyles from '../../../useCommonStyles';
import useUserActivationStageOptions from '../../../utilities/useUserActivationStageOptions';
import useUserTypeOptions from '../../../utilities/useUserTypeOptions';
import { UserEditDialogForm } from '../component/UserEditDialog';

export type HandoverFormProps = {
    user: FullUserDataFragment;
};

const UserEditForm = ({ user }: HandoverFormProps) => {
    const { t } = useTranslation(['usersPage', 'common']);
    const commonStyles = useCommonStyles();
    const { values, setFieldValue } = useFormikContext<UserEditDialogForm>();
    const { type: userType } = values;

    const userTypes = useUserTypeOptions();

    const statuses = useUserActivationStageOptions(user.activationStage);

    useEffect(() => {
        if (userType !== UserType.SaleConsultant) {
            // reset brand
            setFieldValue('brand', { main: '' });
        } else {
            setFieldValue('brand', { main: t('common:brands.porsche') });
        }

        if (userType !== UserType.Dealer) {
            // remove business
            setFieldValue('business', undefined);
        }
    }, [userType, setFieldValue, t]);

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    label={t('usersPage:headers.fullName')}
                    name="fullName"
                    placeholder={t('usersPage:headers.fullName')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    label={t('usersPage:headers.email')}
                    name="email"
                    placeholder={t('usersPage:headers.email')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    label={t('usersPage:headers.mobile')}
                    name="mobilePhone.number"
                    placeholder={t('usersPage:headers.mobile')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedSelectField
                    color="secondary"
                    inputProps={{
                        classes: {
                            icon: commonStyles.fillSecondary,
                        },
                    }}
                    label={t('usersPage:editDialog.userTypeLabel')}
                    name="type"
                    options={userTypes}
                    placeholder={t('usersPage:personResponsible')}
                />
            </Grid>
            {userType === UserType.Dealer && (
                <>
                    <Grid xs={12} item>
                        <OutlinedInputField
                            color="secondary"
                            label={t('usersPage:editDialog.dealerBusinessName')}
                            name="business.name"
                            placeholder={t('usersPage:editDialog.dealerBusinessName')}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <OutlinedInputField
                            color="secondary"
                            label={t('usersPage:editDialog.dealerRoc')}
                            name="business.registrationNumber"
                            placeholder={t('usersPage:editDialog.dealerRoc')}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <OutlinedInputField
                            color="secondary"
                            label={t('usersPage:editDialog.dealerBusinessAddress')}
                            name="business.address"
                            placeholder={t('usersPage:editDialog.dealerBusinessAddress')}
                        />
                    </Grid>
                </>
            )}

            <Grid xs={12} item>
                <OutlinedSelectField
                    color="secondary"
                    inputProps={{
                        classes: {
                            icon: commonStyles.fillSecondary,
                        },
                    }}
                    label={t('usersPage:editDialog.statusLabel')}
                    name="activationStage"
                    options={statuses}
                    placeholder={t('usersPage:editDialog.statusLabel')}
                />
            </Grid>
        </Grid>
    );
};

export default UserEditForm;
