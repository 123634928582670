/* eslint-disable max-len */
import {
    Box,
    Grid,
    Card,
    CardActionArea,
    CardContent,
    Typography,
    CardMedia,
    Divider,
    useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import cs from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { cardDefaultImage } from '../../../../server/schema/constants';
import { useCurrentUser } from '../../../Session';
import {
    PreviewSaleTransactionDataFragment,
    UserType,
    BiddingStatus,
    SalesTransactionAction,
    SaleTransactionStage,
} from '../../../api';
import useCommonStyles from '../../../useCommonStyles';
import usePublic from '../../../utilities/usePublic';
import useSalesTransactionStatus from '../../../utilities/useSalesTransactionStatus';
import useSalesTransactionStatusStyles from '../../../utilities/useSalesTransactionStatusStyles';
import useVehicleLogo from '../../../utilities/useVehicleLogo';
import { useCancelFormatter } from '../ViewSaleTransaction/components/ActionCancelTransaction';
import RemainBiddingDuration from './RemainBiddingDuration';

export type SaleTransactionsItemProps = {
    saleTransaction: PreviewSaleTransactionDataFragment;
    hideAwardedDealer?: boolean;
    hideAwardedBitAmount?: boolean;
    hideOpenCloseBidDate?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            padding: 0,
        },
        header: {
            position: 'relative',
            maxHeight: '170px',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        vehicleNumber: {
            fontSize: '14px',
            color: '#000',
        },
        body: {
            backgroundColor: '#EDEDED',
            color: '#717073',
            [theme.breakpoints.down('sm')]: {
                backgroundColor: theme.palette.primary.contrastText,
            },
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        footer: {
            textAlign: 'center',
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            padding: theme.spacing(1.5),
        },
        label: {
            marginRight: theme.spacing(1),
        },
        info: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        carImage: {
            height: '170px',
            width: '100%',
            borderTopRightRadius: theme.spacing(1),
            borderTopLeftRadius: theme.spacing(1),
        },
        carImageContain: {
            backgroundSize: 'contain',
        },
        noBoxShadow: {
            boxShadow: 'none',
        },
        creadedOn: {
            color: 'rgb(113, 112, 115, 0.6)',
        },
    })
);

const SaleTransactionsItem = ({
    saleTransaction,
    hideAwardedDealer,
    hideAwardedBitAmount,
    hideOpenCloseBidDate,
}: SaleTransactionsItemProps) => {
    const { t } = useTranslation(['common', 'saleTransactionsPage']);

    const currentUser = useCurrentUser();
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const history = useHistory();

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const defaultImage = usePublic(cardDefaultImage);

    const vehicleLogo = useVehicleLogo(saleTransaction.vehicle.make);
    const transactionStatus = useSalesTransactionStatus(saleTransaction.stage);
    const statusStyle = useSalesTransactionStatusStyles(saleTransaction.stage);

    const { formatReasonWithMessage } = useCancelFormatter();

    const showRemainBiddingDuration =
        currentUser.type === UserType.Dealer && saleTransaction.latestBiddingSession?.status === BiddingStatus.Current;

    const lastBid = saleTransaction.latestBiddingSessionEntry?.lastUserBid?.amount || null;

    const isPurschedByPreowned = saleTransaction.action === SalesTransactionAction.Purchased;
    const isCancelled = saleTransaction.stage === SaleTransactionStage.Cancelled;

    const lastCancelledActivity =
        saleTransaction.lastActivity.__typename === 'SaleCancelActivity' ? saleTransaction.lastActivity : null;

    const awardedDealerName = isPurschedByPreowned
        ? t('common:porschePreowned')
        : saleTransaction.awardedBiddingEntry?.dealer?.fullName;

    const shouldShowAwarded =
        (!!saleTransaction.awardedBiddingEntry?.dealer?.fullName || isPurschedByPreowned) && !isCancelled;

    return (
        <Grid lg={4} md={6} sm={12} xs={12} item>
            <Card className={commonStyles.card}>
                <CardActionArea onClick={() => history.push(`/salesTransactions/${saleTransaction.id}`)}>
                    <CardContent className={styles.content}>
                        <Box className={styles.header}>
                            <CardMedia
                                className={cs([
                                    styles.carImage,
                                    !saleTransaction.frontPagePhoto?.signedUrl && styles.carImageContain,
                                ])}
                                image={saleTransaction.frontPagePhoto?.signedUrl || defaultImage}
                            />
                            {/* <Box display="flex" justifyContent="flex-end"> */}
                            {showRemainBiddingDuration && <RemainBiddingDuration transaction={saleTransaction} />}
                            {/* </Box> */}
                        </Box>

                        <Box className={styles.body}>
                            <Box pt={showRemainBiddingDuration ? 6 : 0}>
                                <Grid spacing={2} container>
                                    <Grid xs={12} item>
                                        <Box display="flex" pl={2} pr={2}>
                                            <Box>
                                                <Box display="flex">
                                                    <Typography className={commonStyles.fontWeightBold} color="primary">
                                                        {
                                                            // eslint-disable-next-line max-len
                                                            `${saleTransaction.vehicle.make} ${saleTransaction.vehicle.model}`
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        className={cs(
                                                            commonStyles.commonTransactionStatus,
                                                            commonStyles.transactionStatus2,
                                                            statusStyle
                                                        )}
                                                    >
                                                        {transactionStatus}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        className={commonStyles.fontWeightLight}
                                                        color="primary"
                                                    >
                                                        {saleTransaction.vehicle.number}
                                                    </Typography>
                                                </Box>
                                                {currentUser.type !== UserType.Dealer && (
                                                    <>
                                                        <Box mt={2}>
                                                            <Typography className={cs(commonStyles.smallFontSize)}>
                                                                {t('common:formats.longDate', {
                                                                    date: new Date(saleTransaction.createdAt),
                                                                })}
                                                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                                                {saleTransaction.vehicle.mileage.toLocaleString()}
                                                                &nbsp;
                                                                {t('common:shortKilometer')}
                                                            </Typography>
                                                        </Box>

                                                        <Box display="flex">
                                                            <Typography
                                                                className={cs(
                                                                    commonStyles.smallFontSize,
                                                                    styles.label,
                                                                    styles.creadedOn
                                                                )}
                                                            >
                                                                {t('saleTransactionsPage:label.createdOn')}:
                                                            </Typography>
                                                            <Typography
                                                                className={cs(
                                                                    commonStyles.smallFontSize,
                                                                    styles.creadedOn
                                                                )}
                                                            >
                                                                {t('common:formats.longDate', {
                                                                    date: new Date(saleTransaction.createdAt),
                                                                })}
                                                            </Typography>
                                                        </Box>

                                                        {isPurschedByPreowned && !saleTransaction.latestBiddingSession && (
                                                            <Box display="flex" mt={2}>
                                                                <Typography
                                                                    className={cs(
                                                                        commonStyles.smallFontSize,
                                                                        styles.label
                                                                    )}
                                                                >
                                                                    {t('saleTransactionsPage:list.awardedDealer')}
                                                                </Typography>
                                                                <Typography className={commonStyles.smallFontSize}>
                                                                    {awardedDealerName ?? t('common:blankValue')}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </>
                                                )}
                                            </Box>
                                            <Box marginLeft="auto">
                                                <Box display="flex">
                                                    <img
                                                        alt="carLogo"
                                                        className={commonStyles.vehicleLogo}
                                                        src={vehicleLogo}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>

                                    {saleTransaction.latestBiddingSession && (
                                        <>
                                            <Grid xs={12} item>
                                                <Divider className={commonStyles.divider} />
                                            </Grid>
                                            <Grid xs={12} item>
                                                <Box alignItems="center" display="flex" pl={2} pr={2}>
                                                    <Grid spacing={1} container>
                                                        <Grid xs={12} item>
                                                            <Box>
                                                                <Typography
                                                                    className={cs(styles.label)}
                                                                    color="secondary"
                                                                >
                                                                    {t('saleTransactionsPage:sessionDetails.title')}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        {!hideOpenCloseBidDate && (
                                                            <>
                                                                <Grid xs={12} item>
                                                                    <Box display="flex">
                                                                        <Typography
                                                                            className={cs(
                                                                                commonStyles.smallFontSize,
                                                                                styles.label
                                                                            )}
                                                                        >
                                                                            {t(
                                                                                // eslint-disable-next-line max-len
                                                                                'saleTransactionsPage:sessionDetails.bidOpeningOn'
                                                                            )}
                                                                            :
                                                                        </Typography>
                                                                        <Typography
                                                                            className={commonStyles.smallFontSize}
                                                                        >
                                                                            {t('common:formats.longDateTime', {
                                                                                date: new Date(
                                                                                    // eslint-disable-next-line max-len
                                                                                    saleTransaction.latestBiddingSession.startPeriod
                                                                                ),
                                                                            })}
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid xs={12} item>
                                                                    <Box display="flex">
                                                                        <Typography
                                                                            className={cs(
                                                                                commonStyles.smallFontSize,
                                                                                styles.label
                                                                            )}
                                                                        >
                                                                            {t(
                                                                                // eslint-disable-next-line max-len
                                                                                'saleTransactionsPage:sessionDetails.bidClosingDate'
                                                                            )}
                                                                            :
                                                                        </Typography>
                                                                        <Typography
                                                                            className={commonStyles.smallFontSize}
                                                                        >
                                                                            {t('common:formats.longDateTime', {
                                                                                date: new Date(
                                                                                    // eslint-disable-next-line max-len
                                                                                    saleTransaction.latestBiddingSession.endPeriod
                                                                                ),
                                                                            })}
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                            </>
                                                        )}

                                                        {currentUser.type === UserType.Dealer && lastBid && (
                                                            <Grid xs={12} item>
                                                                <Box display="flex">
                                                                    <Typography
                                                                        className={cs(
                                                                            commonStyles.smallFontSize,
                                                                            styles.label
                                                                        )}
                                                                    >
                                                                        {t('saleTransactionsPage:bidDrawer.lastBid')}:
                                                                    </Typography>
                                                                    <Typography className={commonStyles.smallFontSize}>
                                                                        {lastBid
                                                                            ? `${t(
                                                                                  'common:currency'
                                                                              )}${lastBid?.toLocaleString()}`
                                                                            : t('common:blankValue')}
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                        )}

                                                        {!hideAwardedDealer && shouldShowAwarded && (
                                                            <Grid xs={12} item>
                                                                <Box display="flex">
                                                                    <Typography
                                                                        className={cs(
                                                                            commonStyles.smallFontSize,
                                                                            styles.label
                                                                        )}
                                                                    >
                                                                        {t('saleTransactionsPage:list.awardedDealer')}
                                                                    </Typography>
                                                                    <Typography className={commonStyles.smallFontSize}>
                                                                        {awardedDealerName ?? t('common:blankValue')}
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                        )}

                                                        {!hideAwardedBitAmount && (
                                                            <Grid xs={12} item>
                                                                <Box display="flex">
                                                                    <Typography
                                                                        className={cs(
                                                                            commonStyles.smallFontSize,
                                                                            styles.label
                                                                        )}
                                                                    >
                                                                        {t(
                                                                            'saleTransactionsPage:list.awardedBidAmount'
                                                                        )}
                                                                    </Typography>
                                                                    <Typography className={commonStyles.smallFontSize}>
                                                                        {saleTransaction.awardedBiddingEntry
                                                                            ? saleTransaction.awardedBiddingEntry.amount
                                                                            : t('common:blankValue')}
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                        )}

                                                        {isCancelled && lastCancelledActivity && (
                                                            <Grid xs={12} item>
                                                                <Box display="flex">
                                                                    <Typography
                                                                        className={cs(
                                                                            commonStyles.smallFontSize,
                                                                            styles.label
                                                                        )}
                                                                    >
                                                                        {t('saleTransactionsPage:list.cancelled')}
                                                                    </Typography>
                                                                    <Typography className={commonStyles.smallFontSize}>
                                                                        {formatReasonWithMessage(
                                                                            lastCancelledActivity.reason,
                                                                            lastCancelledActivity.reasonMessage
                                                                        )}
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                    {!isSmall && <ChevronRightIcon color="secondary" />}
                                                </Box>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Box>
                        </Box>
                        {isSmall && (
                            <Box className={styles.footer}>
                                <Typography className={cs(commonStyles.fontWeightLight, commonStyles.smallFontSize)}>
                                    {t('common:viewDetails')}
                                </Typography>
                            </Box>
                        )}
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
};

export default SaleTransactionsItem;
