import { Box, Grid, Card, CardActionArea, CardContent, Typography, Avatar, Divider } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import cs from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { BiddingStatus, PreviewBiddingDataFragment } from '../../../../../api';
import useCommonStyles from '../../../../../useCommonStyles';

export type BiddingSessionsItemProps = {
    bidding: PreviewBiddingDataFragment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        infoContainer: {
            display: 'flex',
        },
        cardActionFocusHighlight: {
            backgroundColor: '#fff',
        },
        sessionImage: {
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
        },
        container: {
            display: 'flex',
        },
        title: {
            marginBottom: theme.spacing(1.25),
        },
        description: {
            fontSize: '14px',
            color: '#717073',
            marginBottom: theme.spacing(1.25),
        },
        content: {
            padding: 0,
        },
        body: {
            padding: theme.spacing(2),
            backgroundColor: '#EDEDED',
            color: '#717073',
            '& .MuiGrid-item:last-child': {
                opacity: '0.6',
            },
        },
        stage: {
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            marginLeft: theme.spacing(1),
            borderRadius: theme.spacing(0.5),
        },
        upcomingStage: {
            backgroundColor: theme.palette.secondary.main,
        },
        pastStage: {
            backgroundColor: '#717073',
        },
        currentStage: {
            backgroundColor: '#60C093',
        },
        biddingStatus: {
            marginLeft: 0,
            marginRight: 0,
        },
        dateContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        dateTime: {
            display: 'flex',
            justifyContent: 'space-between',
            flex: 1,
            marginBottom: '6px',
        },
        label: {
            marginRight: theme.spacing(1),
            fontSize: '14px',
        },
        cardContent: {
            padding: 0,
        },
    })
);

const BiddingSessionsItem = ({ bidding }: BiddingSessionsItemProps) => {
    const { t } = useTranslation(['common', 'biddingSessionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const history = useHistory();

    const getStatusText = useCallback(() => {
        // for different status text
        switch (bidding.status) {
            case BiddingStatus.Current:
                return (
                    <Typography
                        className={cs(
                            commonStyles.commonTransactionStatus,
                            commonStyles.transactionStatus,
                            styles.biddingStatus,
                            styles.currentStage
                        )}
                    >
                        {t('biddingSessionsPage:status.current')}
                    </Typography>
                );

            case BiddingStatus.Past:
                return (
                    <Typography
                        className={cs(
                            commonStyles.commonTransactionStatus,
                            commonStyles.transactionStatus,
                            styles.biddingStatus,
                            styles.pastStage
                        )}
                    >
                        {t('biddingSessionsPage:status.past')}
                    </Typography>
                );

            case BiddingStatus.Upcoming:
                return (
                    <Typography
                        className={cs(
                            commonStyles.commonTransactionStatus,
                            commonStyles.transactionStatus,
                            styles.biddingStatus,
                            styles.upcomingStage
                        )}
                    >
                        {t('biddingSessionsPage:status.upcoming')}
                    </Typography>
                );

            default:
                return null;
        }
    }, [bidding, commonStyles, styles, t]);

    return (
        <Grid lg={3} md={6} sm={12} xs={12} item>
            <Card className={commonStyles.card}>
                <CardActionArea
                    classes={{ focusHighlight: styles.cardActionFocusHighlight }}
                    onClick={() => history.push(`/biddingSession/${bidding.id}`)}
                >
                    <CardContent className={styles.cardContent}>
                        <Box>
                            <Grid container>
                                <Grid className={styles.infoContainer} xs={12} item>
                                    <Box p={2} width="100%">
                                        <Box display="flex">
                                            <Avatar className={styles.sessionImage}>
                                                {bidding.sessionName.charAt(0)}
                                            </Avatar>
                                            <Box ml={2} mt={1}>
                                                <Typography
                                                    className={cs(
                                                        commonStyles.primary,
                                                        commonStyles.fontWeightBold,
                                                        styles.title
                                                    )}
                                                >
                                                    {bidding.sessionName}
                                                </Typography>
                                                <Typography className={styles.description}>{bidding.notes}</Typography>
                                                {getStatusText()}
                                            </Box>
                                            <Box ml="auto" mt={1}>
                                                <ChevronRightIcon color="secondary" />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid xs={12} item>
                                    <Divider className={commonStyles.divider} />
                                </Grid>
                                <Grid xs={12} item>
                                    <Box p={2}>
                                        <Box className={styles.dateTime}>
                                            <Typography className={styles.label} color="secondary">
                                                {t('biddingSessionsPage:list.startTimeDate')}
                                            </Typography>
                                            <Typography className={styles.label} color="secondary">
                                                {t('common:formats.longDateTime', {
                                                    date: new Date(bidding.startPeriod),
                                                })}
                                            </Typography>
                                        </Box>

                                        <Box className={styles.dateTime}>
                                            <Typography className={styles.label} color="secondary">
                                                {t('biddingSessionsPage:list.endTimeDate')}
                                            </Typography>
                                            <Typography className={styles.label} color="secondary">
                                                {t('common:formats.longDateTime', {
                                                    date: new Date(bidding.endPeriod),
                                                })}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
};

export default BiddingSessionsItem;
