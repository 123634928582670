import { Grid, Box } from '@material-ui/core';
import React from 'react';
import { FullBiddingDataFragment } from '../../../../api';
import BiddingSessionsDetailItem from './components/BiddingSessionsDetailItem';

export type ViewBiddingSessionDetailBodyProps = {
    bidding: FullBiddingDataFragment;
};

const ViewBiddingSessionDetailBody = ({ bidding }: ViewBiddingSessionDetailBodyProps) => (
    <Box mb={3} mt={3}>
        <Grid spacing={4} container>
            {bidding.biddingSaleTransactions.map(saleTransactionEntry => (
                <BiddingSessionsDetailItem
                    key={saleTransactionEntry.saleTransaction.id}
                    saleTransaction={saleTransactionEntry.saleTransaction}
                    session={bidding}
                />
            ))}
        </Grid>
    </Box>
);

export default ViewBiddingSessionDetailBody;
