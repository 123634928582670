import ProfileViewBody, { ProfileViewBodyProps } from './ProfileViewBody';
import ProfileViewHeader, { ProfileHeaderProps } from './ProfileViewHeader';

export type ProfileViewProps = ProfileViewBodyProps & ProfileHeaderProps;

const ProfileView = ({ goToEdition, user }: ProfileViewProps) => (
    <>
        <ProfileViewHeader goToEdition={goToEdition} />
        <ProfileViewBody user={user} />
    </>
);

export default ProfileView;
