import { Redirect } from 'react-router-dom';
import { useCurrentUser } from '../Session';
import { UserType } from '../api';
import BasicLayout from '../layouts/BasicLayout';
import ApproverBiddingSessions from '../pages/ApproverBiddingSessions';
import { DownloadSalesTransactionsFileProvider } from '../pages/SaleTransactions/components';

const ApproverBiddingSessionsRoute = () => {
    const currentUser = useCurrentUser();

    if (currentUser.type !== UserType.Approver) {
        return <Redirect to="/403" />;
    }

    return (
        <BasicLayout>
            <DownloadSalesTransactionsFileProvider>
                {downloadFile => <ApproverBiddingSessions downloadSalesTransactionsFile={downloadFile} />}
            </DownloadSalesTransactionsFileProvider>
        </BasicLayout>
    );
};

export default ApproverBiddingSessionsRoute;
