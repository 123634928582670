import { Button, Avatar } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import cs from 'classnames';
import { useState } from 'react';
import { useCurrentUser } from '../../../Session';
import useBasicStyles from '../useBasicStyles';
import ProfileMenuItems from './ProfileMenuItems';

const ProfileMenuButton = () => {
    const classes = useBasicStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const currentUser = useCurrentUser();

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <Button className={cs(classes.profileMenuButton)} color="secondary" onClick={handleClick}>
                <Avatar className={cs(classes.profilePicture)} src={currentUser.profilePicture?.signedUrl} />
                {currentUser.fullName}
                <KeyboardArrowDownIcon />
            </Button>
            <ProfileMenuItems anchorEl={anchorEl} handleClose={handleClose} />
        </>
    );
};

export default ProfileMenuButton;
