import { Grid, Box, Typography } from '@material-ui/core';
import { alpha, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import cs from 'classnames';
import React from 'react';
import useCommonStyles from '../../../../useCommonStyles';
import Marker from '../../../PurchaseTransactions/VehicleDiagramComment/Marker';
import { VehicleDiagramComment } from '.';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        commentContainer: {
            display: 'flex',
            marginBottom: '10px',
            gap: 10,
            alignItems: 'center',
        },
        comment: {
            display: 'flex',
            flex: '1',
            color: alpha('#000', 0.8),
            '& p': {
                wordBreak: 'break-all',
            },
        },
        removeIcon: {
            color: '#b23f51',
            cursor: 'pointer',
        },
    })
);

export type VehicleCommentProps = {
    comment: VehicleDiagramComment;
    index: number;
    onRemove: () => void;
    isEditable?: boolean;
};

const VehicleComment = ({ comment, index, onRemove, isEditable }: VehicleCommentProps) => {
    const commonStyles = useCommonStyles();

    const styles = useStyles();

    return (
        <Grid lg={6} xs={12} item>
            <Box className={styles.commentContainer}>
                <Box>
                    <Marker index={index + 1} isSmall={false} />
                </Box>
                <Box className={styles.comment}>
                    <Typography className={cs(commonStyles.fontWeightLight, commonStyles.smallFontSize)}>
                        {comment.comment}
                    </Typography>
                </Box>
                {onRemove && isEditable && <RemoveCircleIcon className={styles.removeIcon} onClick={onRemove} />}
            </Box>
        </Grid>
    );
};

export default VehicleComment;
