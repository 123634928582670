import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const BiddingRulesAndRegulationsBody = () => {
    const { t } = useTranslation(['saleTransactionsPage']);

    return (
        <Box pb={2} pl={4} pr={4} pt={9}>
            <Typography>{t('saleTransactionsPage:biddingRulesAndRegulation.body')}</Typography>
        </Box>
    );
};

export default BiddingRulesAndRegulationsBody;
