import { Box, Grid, Card, CardActionArea, CardContent, Typography, Divider, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PreviewPurchaseTransactionDataFragment } from '../../../api';
import useCommonStyles from '../../../useCommonStyles';
import usePurchaseTransactionStatus from '../../../utilities/usePurchaseTransactionStatus';
import usePurchaseTransactionStatusStyles from '../../../utilities/usePurchaseTransactionStatusStyles';
import useVehicleLogo from '../../../utilities/useVehicleLogo';

export type Vehicle = {
    model: string;
    registrationNo: string;
    kilometer: number;
};

export type PurchaseTransaction = {
    id: string;
    stage: string;
    vehicle: Vehicle;
    updatedAt: Date;
    createdAt: Date;
};

export type PurchaseTransactionsItemProps = {
    purchaseTransaction: PreviewPurchaseTransactionDataFragment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            padding: 0,
        },
        header: {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.primary.main,
            '& svg': {
                marginLeft: 'auto',
            },
            [theme.breakpoints.down('sm')]: {
                backgroundColor: theme.palette.primary.contrastText,
            },
        },
        body: {
            padding: theme.spacing(2),
            backgroundColor: '#EDEDED',
            color: '#717073',
            '& .MuiGrid-item:last-child': {
                opacity: '0.6',
            },
            [theme.breakpoints.down('sm')]: {
                backgroundColor: theme.palette.primary.contrastText,
            },
        },
        footer: {
            textAlign: 'center',
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            padding: theme.spacing(1.5),
        },
        label: {
            marginRight: theme.spacing(1),
        },
    })
);

const PurchaseTransactionsItem = ({ purchaseTransaction }: PurchaseTransactionsItemProps) => {
    const { t } = useTranslation(['common', 'purchaseTransactionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const vehicleLogo = useVehicleLogo(purchaseTransaction.vehicle.make);
    const purchaseTransactionStatus = usePurchaseTransactionStatus(purchaseTransaction.stage);
    const statusStyles = usePurchaseTransactionStatusStyles(purchaseTransaction.stage);
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid lg={4} md={6} sm={12} xs={12} item>
            <Card className={commonStyles.card}>
                <CardActionArea onClick={() => history.push(`/purchaseTransaction/${purchaseTransaction.id}`)}>
                    <CardContent className={styles.content}>
                        <Box className={styles.header}>
                            <Box display="flex">
                                <Box>
                                    <Box display="flex">
                                        <Typography
                                            className={isSmall ? commonStyles.fontWeightBold : commonStyles.primary2}
                                        >
                                            {`${purchaseTransaction.vehicle.make} ${purchaseTransaction.vehicle.model}`}
                                        </Typography>
                                        <Typography
                                            className={cs(
                                                commonStyles.commonTransactionStatus,
                                                commonStyles.transactionStatus2,
                                                statusStyles
                                            )}
                                        >
                                            {purchaseTransactionStatus}
                                        </Typography>
                                    </Box>
                                    <Typography
                                        className={
                                            isSmall
                                                ? cs(commonStyles.fontWeightLight, commonStyles.smallFontSize)
                                                : cs(
                                                      commonStyles.primary2,
                                                      commonStyles.fontWeightLight,
                                                      commonStyles.smallFontSize
                                                  )
                                        }
                                    >
                                        {purchaseTransaction.vehicle.number}
                                    </Typography>
                                </Box>
                                <Box marginLeft="auto">
                                    <Box display="flex">
                                        {isSmall ? (
                                            <img alt="carLogo" className={commonStyles.vehicleLogo} src={vehicleLogo} />
                                        ) : (
                                            <ChevronRightIcon className={commonStyles.primary2} />
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {isSmall && <Divider className={commonStyles.divider} />}
                        <Box alignItems="center" className={styles.body} display="flex">
                            <Grid spacing={1} container>
                                <Grid xs={12} item>
                                    <Typography
                                        className={cs(commonStyles.smallFontSize, commonStyles.fontWeightLight)}
                                    >
                                        {t('common:formats.longDate', {
                                            date: new Date(purchaseTransaction.vehicle.originalRegistrationDate),
                                        })}
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        {purchaseTransaction.vehicle.mileage.toLocaleString()}
                                        &nbsp;
                                        {t('common:shortKilometer')}
                                    </Typography>
                                </Grid>
                                <Grid xs={12} item>
                                    <Box display="flex">
                                        <Typography
                                            className={cs(
                                                commonStyles.smallFontSize,
                                                styles.label,
                                                commonStyles.fontWeightLight
                                            )}
                                            color="secondary"
                                        >
                                            {t('purchaseTransactionsPage:label.latestValidation')}:
                                        </Typography>
                                        <Typography
                                            className={cs(commonStyles.smallFontSize, commonStyles.fontWeightLight)}
                                        >
                                            {t('common:currency')}{' '}
                                            {(purchaseTransaction.valuation?.latestValuation || 0).toLocaleString()}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid xs={12} item>
                                    <Box display="flex">
                                        <Typography
                                            className={cs(
                                                commonStyles.smallFontSize,
                                                styles.label,
                                                commonStyles.fontWeightLight
                                            )}
                                        >
                                            {t('purchaseTransactionsPage:valuation.valuatedBy')}:
                                        </Typography>
                                        <Typography
                                            className={cs(commonStyles.smallFontSize, commonStyles.fontWeightLight)}
                                        >
                                            {purchaseTransaction.valuation?.valuatedBy?.fullName}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid xs={12} item>
                                    <Box display="flex">
                                        <Typography
                                            className={cs(
                                                commonStyles.smallFontSize,
                                                styles.label,
                                                commonStyles.fontWeightLight
                                            )}
                                        >
                                            {t('purchaseTransactionsPage:transactionDetails.assignedSC')}:
                                        </Typography>
                                        <Typography
                                            className={cs(commonStyles.smallFontSize, commonStyles.fontWeightLight)}
                                        >
                                            {purchaseTransaction.saleConsultant?.fullName}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid xs={12} item>
                                    <Box display="flex">
                                        <Typography
                                            className={cs(
                                                commonStyles.smallFontSize,
                                                styles.label,
                                                commonStyles.fontWeightLight
                                            )}
                                        >
                                            {t('purchaseTransactionsPage:label.createdOn')}:
                                        </Typography>
                                        <Typography
                                            className={cs(commonStyles.smallFontSize, commonStyles.fontWeightLight)}
                                        >
                                            {t('common:formats.longDate', {
                                                date: new Date(purchaseTransaction.createdAt),
                                            })}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            {!isSmall && <img alt="carLogo" className={commonStyles.vehicleLogo} src={vehicleLogo} />}
                        </Box>
                        {isSmall && (
                            <Box className={styles.footer}>
                                <Typography className={cs(commonStyles.fontWeightLight, commonStyles.smallFontSize)}>
                                    {t('purchaseTransactionsPage:viewDetails')}
                                </Typography>
                            </Box>
                        )}
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
};

export default PurchaseTransactionsItem;
