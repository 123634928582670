import React from 'react';
import { withErrorBoundary } from '../../layouts/RoutedErrorBoundary';
import HomePageBody from './components/HomePageBody';
import HomePageHeader from './components/HomePageHeader';

const HomePage = () => (
    <>
        <HomePageHeader />
        <HomePageBody />
    </>
);
export default withErrorBoundary(HomePage);
