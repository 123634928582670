import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useCurrentUser } from '../../Session';
import { DeviceSettingsType } from '../../api';
import { useFlutter } from '../../components/FlutterProvider';
import { SettingsHeader, SettingsBody } from './components';
import SettingsUpdates from './components/SettingsUpdates';

export type FormValues = {
    biometric: boolean;
    pushNotification: boolean;
};

const noSubmit = () => undefined;

const Settings = () => {
    const { deviceFingerPrint } = useFlutter();

    const { deviceSettings } = useCurrentUser();

    const initialValues = useMemo((): FormValues => {
        const find = (type: DeviceSettingsType) =>
            deviceSettings.some(setting => setting.type === type && setting.deviceFingerprint === deviceFingerPrint);

        return {
            biometric: find(DeviceSettingsType.Biometric),
            pushNotification: find(DeviceSettingsType.Push),
        };
    }, [deviceFingerPrint, deviceSettings]);

    return (
        <Formik key={deviceFingerPrint || 'none'} initialValues={initialValues} onSubmit={noSubmit}>
            {() => (
                <Form>
                    <SettingsUpdates />
                    <SettingsHeader />
                    <SettingsBody />
                </Form>
            )}
        </Formik>
    );
};

export default Settings;
