import { Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../../components/Accordion';
import ValuationForm from './ValuationForm';

const ValuationTab = () => {
    const { t } = useTranslation(['purchaseTransactionsPage']);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion
                    title={t('purchaseTransactionsPage:accordionTitle.valuation')}
                    useCardDesign={!isSmall}
                    defaultExpanded
                >
                    <ValuationForm />
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default ValuationTab;
