import { CreateSaleTransactionMutationVariables } from '../../../api';
import CreateSalesTransactionInner from './CreateSalesTransactionInner';
import CreateSalesTransactionStateProvider from './CreateSalesTransactionStateProvider';

export type CreateSaleTransactionFormValues = CreateSaleTransactionMutationVariables['fields'] & {
    photos: File[];
    frontPagePhoto?: File;
};

const CreateSaleTransaction = () => (
    <CreateSalesTransactionStateProvider>
        <CreateSalesTransactionInner />
    </CreateSalesTransactionStateProvider>
);

export default CreateSaleTransaction;
