import { Container, Box, Grid } from '@material-ui/core';
import { withErrorBoundary } from '../../layouts/RoutedErrorBoundary';
import { LoginFooter, LoginHeader, LoginTitle, LoginFormProps, LoginForm } from './components';

export type LoginPageProps = LoginFormProps;

const LoginPage = ({ next, initialValues }: LoginPageProps) => (
    <Container maxWidth="sm">
        <Box pb={10} pt={10}>
            <Grid spacing={2} container>
                <LoginHeader />
                <LoginTitle />
                <LoginForm initialValues={initialValues} next={next} />
                <LoginFooter />
            </Grid>
        </Box>
    </Container>
);

export default withErrorBoundary(LoginPage);
