import { Drawer, Grid, Box, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { FullPurchaseTransactionDataFragment, useUpdateTransactionToCompleteMutation } from '../../../../api';
import BooleanCheckbox from '../../../../components/fields/BooleanCheckbox';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import { useHandleError } from '../../../../utilities/handleErrors';
import useValidator from '../../../../utilities/useValidator';
import validators from '../../../../utilities/validators';

export type FormValues = {
    setOfKeys: number;
    ownerHandbookCollected: boolean;
};

export type CompleteTransactionDrawerProps = {
    purchaseTransaction: FullPurchaseTransactionDataFragment;
    open: boolean;
    onClose: () => void;
};

const formValidator = validators.compose(validators.requiredNumber('setOfKeys'), validators.validInteger('setOfKeys'));

const CompleteTransactionDrawer = ({ purchaseTransaction, open, onClose }: CompleteTransactionDrawerProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage']);
    const [updateToComplete] = useUpdateTransactionToCompleteMutation();

    const initialValues: FormValues = {
        setOfKeys: purchaseTransaction.vehicle.setOfKeys,
        ownerHandbookCollected: !!purchaseTransaction.vehicle.ownerHandbookCollected,
    };

    const onSubmit = useHandleError(
        async ({ setOfKeys, ownerHandbookCollected }: FormValues) => {
            await updateToComplete({
                variables: { transactionId: purchaseTransaction.id, setOfKeys, ownerHandbookCollected },
            });

            onClose();
        },
        [updateToComplete]
    );

    const validate = useValidator(formValidator);

    return (
        <Drawer anchor="bottom" onClose={onClose} open={open}>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
                {() => (
                    <Form>
                        <Box p={2}>
                            <Grid spacing={2} container>
                                <Grid xs={12} item>
                                    <OutlinedInputField
                                        color="secondary"
                                        label={t('purchaseTransactionsPage:vehicle.setOfKeys')}
                                        name="setOfKeys"
                                        placeholder={t('purchaseTransactionsPage:vehicle.setOfKeys')}
                                        type="number"
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <BooleanCheckbox
                                        color="secondary"
                                        label={t('purchaseTransactionsPage:vehicle.ownerHandbookCollected')}
                                        name="ownerHandbookCollected"
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <Box display="flex" justifyContent="flex-end">
                                        <Button color="secondary" type="submit" variant="contained">
                                            {t('purchaseTransactionsPage:button.complete')}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Drawer>
    );
};

export default CompleteTransactionDrawer;
