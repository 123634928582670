import { Link, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../useCommonStyles';

export interface MobileFilterLinkProps {
    setShowFilterDialog: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            display: 'flex',
            alignItems: 'center',
        },
    })
);

const MobileFilterLink = ({ setShowFilterDialog }: MobileFilterLinkProps) => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const { t } = useTranslation(['common']);

    return (
        <Link className={styles.link} component="a" onClick={() => setShowFilterDialog(true)} underline="none">
            <Typography className={commonStyles.fontWeightLight}>{t('common:filterBy')}</Typography>{' '}
            <ExpandMoreIcon fontSize="small" />
        </Link>
    );
};

export default MobileFilterLink;
