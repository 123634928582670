import { Box, Button, Dialog, FormControl, Grid, IconButton, DialogTitle, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Formik, Form } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    FullPurchaseTransactionDataFragment,
    PurchaseTransactionStage,
    useAddVehicleDiagramCommentMutation,
    VehicleDiagramCommentPositionFields,
} from '../../../api';
import OutlinedInputField from '../../../components/fields/OutlinedInputField';
import { useHandleError } from '../../../utilities/handleErrors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 20,
        },
        root: {
            margin: 0,
            padding: theme.spacing(2),
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '16px',
            borderBottom: '1px solid #c4c4c4',
        },
        inputItem: {
            marginBottom: theme.spacing(1),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
        },
    })
);

export type AddCommentDialogProps = {
    open: boolean;
    onClose: () => void;
    position: VehicleDiagramCommentPositionFields;
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

export type AddCommentFormValues = {
    comment: string;
};

const AddCommentDialog = ({ open, onClose, position, purchaseTransaction }: AddCommentDialogProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common']);

    const styles = useStyles();

    const [addVehicleComment] = useAddVehicleDiagramCommentMutation();

    const onAddComment = useHandleError(
        async (values: AddCommentFormValues) => {
            if (purchaseTransaction.stage !== PurchaseTransactionStage.Completed) {
                const { comment } = values;

                const purchaseTransactionId = purchaseTransaction.id;
                const fields = { comment, position };

                await addVehicleComment({
                    variables: { purchaseTransactionId, fields },
                });

                onClose();
            }
        },
        [addVehicleComment, onClose, position, purchaseTransaction.id, purchaseTransaction.stage]
    );

    return (
        <Dialog className={styles.container} maxWidth="xs" onClose={onClose} open={open} fullWidth>
            <DialogTitle className={styles.root} disableTypography>
                <Typography color="secondary">{t('purchaseTransactionsPage:vehicleDiagram.title')}</Typography>

                <IconButton className={styles.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Formik initialValues={{ comment: '' }} onSubmit={onAddComment}>
                {({ isSubmitting }) => (
                    <Form className={styles.container}>
                        <Box>
                            <Grid className={styles.inputItem} xs={12} item>
                                <OutlinedInputField
                                    color="secondary"
                                    label={t('purchaseTransactionsPage:vehicleDiagram.comments')}
                                    name="comment"
                                    placeholder={t('purchaseTransactionsPage:vehicleDiagram.commentPlaceholder')}
                                    rows={4}
                                    multiline
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <FormControl fullWidth>
                                    <Button color="secondary" disabled={isSubmitting} type="submit" variant="contained">
                                        {t('common:add')}
                                    </Button>
                                </FormControl>
                            </Grid>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default AddCommentDialog;
