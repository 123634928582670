import React from 'react';
import BasicLayout from '../../layouts/BasicLayout';
import Notifications from '../../pages/Notifications';

const UsersRoute = () => (
    <BasicLayout>
        <Notifications />
    </BasicLayout>
);

export default UsersRoute;
