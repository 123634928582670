import { Button, FormControl, Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../../../../useCommonStyles';

const SignUpFormSubmit = () => {
    const { t } = useTranslation(['signUpPage']);
    const commonStyles = useCommonStyles();
    const { isSubmitting } = useFormikContext();

    return (
        <Grid xs={12} item>
            <FormControl fullWidth>
                <Button
                    classes={{ disabled: commonStyles.disabledSecondaryButton }}
                    color="secondary"
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                >
                    {t('signUpPage:submitButton')}
                </Button>
            </FormControl>
        </Grid>
    );
};

export default SignUpFormSubmit;
