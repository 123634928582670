import { DialogProps } from '@material-ui/core';
import { SyntheticEvent } from 'react';

const onCloseDialog: DialogProps['onClose'] = (event, reason) => {
    if (reason === 'backdropClick') {
        (event as SyntheticEvent<{}>).stopPropagation();
    }
};

export default onCloseDialog;
