import React from 'react';
import { FullPurchaseTransactionDataFragment, VehicleDiagramComment } from '../../api';
import VehicleDiagramBody from './VehicleDiagramComment/VehicleDiagramBody';
import VehicleDiagramHeader from './VehicleDiagramComment/VehicleDiagramHeader';

export type VehicleDiagramProps = {
    goToView: () => void;
    comments: VehicleDiagramComment[];
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

const VehicleDiagram = ({ purchaseTransaction, goToView, comments }: VehicleDiagramProps) => (
    <>
        <VehicleDiagramHeader goToView={goToView} />
        <VehicleDiagramBody comments={comments} purchaseTransaction={purchaseTransaction} />
    </>
);

export default VehicleDiagram;
