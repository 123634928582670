import { Redirect } from 'react-router-dom';
import { useCurrentUser } from '../Session';
import { UserType } from '../api';
import BasicLayout from '../layouts/BasicLayout';
import TransactionApprovals from '../pages/TransactionApprovals';

const TransactionApprovalsRoute = () => {
    const currentUser = useCurrentUser();

    if (currentUser.type !== UserType.Approver) {
        return <Redirect to="/403" />;
    }

    return (
        <BasicLayout>
            <TransactionApprovals />
        </BasicLayout>
    );
};

export default TransactionApprovalsRoute;
