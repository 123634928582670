import React, { useState } from 'react';
import { useGetBiddingQuery } from '../../api';
import { DownloadBiddingSalesTransactionsFileProvider } from './components';
import EditBiddingSessionDetails from './components/EditBiddingSessionDetails';
import ViewBiddingSessionDetail from './components/ViewBiddingSessionDetail';

export type BiddingSessionDetailProps = {
    id: string;
};

const BiddingSessionDetail = ({ id }: BiddingSessionDetailProps) => {
    const [onEdition, setOnEdition] = useState<boolean>(false);

    const { data } = useGetBiddingQuery({
        fetchPolicy: 'cache-and-network',
        variables: { biddingId: id },
    });

    const bidding = data?.bidding;

    if (!bidding) {
        return null;
    }

    if (onEdition) {
        return <EditBiddingSessionDetails bidding={bidding} goToView={() => setOnEdition(false)} />;
    }

    return (
        <DownloadBiddingSalesTransactionsFileProvider>
            {downloadFile => (
                <ViewBiddingSessionDetail
                    bidding={bidding}
                    downloadBiddingSalesTransactionsFile={downloadFile}
                    goToEdition={() => setOnEdition(true)}
                />
            )}
        </DownloadBiddingSalesTransactionsFileProvider>
    );
};

export default BiddingSessionDetail;
