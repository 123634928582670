import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useFilterDialogStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            marginTop: theme.spacing(8),
            padding: theme.spacing(2),
        },
        appltFilters: {
            position: 'fixed',
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
        },
        applyButton: {
            width: '100%',
        },
    })
);

export default useFilterDialogStyles;
