import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import usePortalStyles from '../../../layouts/PortalLayout/usePortalStyles';

const LoginTitle = () => {
    const portalStyles = usePortalStyles();
    const { t } = useTranslation(['loginPage']);
    const theme = useTheme();

    const isSmall = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Grid xs={12} item>
            <Box className={cs(portalStyles.welcomeBox)} mb={isSmall ? 5 : 4} position="relative">
                <Typography align="center" className={cs(portalStyles.welcomeTitle)}>
                    {t('loginPage:welcomeMessage')}
                </Typography>
            </Box>
        </Grid>
    );
};

export default LoginTitle;
