import { Card, CardContent, Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { useTranslation } from 'react-i18next';
import { FullSaleTransactionDataFragment, BiddingStatus } from '../../../../api';
import useCommonStyles from '../../../../useCommonStyles';
import useLiveDuration from '../../../../utilities/useLiveDuration';

export type BiddingLiveDurationProps = {
    transaction: FullSaleTransactionDataFragment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'absolute',
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            transform: 'translate(0, -50%)',
            width: '100%',
        },
        title: {
            textAlign: 'center',
            color: '#717073',
            fontSize: '13px',
        },
        content: {
            marginLeft: theme.spacing(1),
            fontSize: '15px',
        },
    })
);

type biddingLiveDurationValues = {
    date: Date | string;
    title: string;
};

const BiddingLiveDuration = ({ transaction }: BiddingLiveDurationProps) => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const { t } = useTranslation(['saleTransactionsPage']);

    const getBiddingLiveDurationValues = (value: BiddingStatus): biddingLiveDurationValues => {
        switch (value) {
            case BiddingStatus.Upcoming:
                return {
                    date: transaction.latestBiddingSession.startPeriod,
                    title: t('saleTransactionsPage:dealerBiddings.biddingOpensIn'),
                };

            case BiddingStatus.Current:
                return {
                    date: transaction.latestBiddingSession.endPeriod,
                    title: t('saleTransactionsPage:dealerBiddings.remainBiddingDuration'),
                };

            default:
                return { date: null, title: '' };
        }
    };

    const { date, title } = getBiddingLiveDurationValues(transaction.latestBiddingSession?.status);
    const liveDuration = useLiveDuration(date);

    return (
        <Box className={styles.root}>
            <Card className={commonStyles.cardBoxShadow}>
                <CardContent>
                    <Box>
                        <Typography className={styles.title}>{title}</Typography>
                        <Box display="flex" justifyContent="center">
                            <ScheduleIcon color="secondary" />
                            <Typography className={styles.content} color="secondary">
                                {liveDuration}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default BiddingLiveDuration;
