import { Grid } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FullPurchaseTransactionDataFragment, useGetPurchaseTransactionsQuery } from '../../../../api';
import ListPagination from '../../../../components/ListPagination';
import NoRecordsFound from '../../../../components/NoRecordsFound';
import HistoricalReferenceCard from './HistoricalReferenceCard';

export type HistoricalReferencesProps = {
    purchaseTransaction: FullPurchaseTransactionDataFragment;
    historicalReferenceFilter: string[];
};

const pageSize = 9;

type ListContext = {
    make: string;
    model: string;
    originalRegistrationDate?: Date;
    page: number;
};

const initialContext: ListContext = {
    make: '',
    model: '',
    originalRegistrationDate: null,
    page: 0,
};

const HistoricalReferences = ({ historicalReferenceFilter, purchaseTransaction }: HistoricalReferencesProps) => {
    const { t } = useTranslation('historicalReferenceFilterOptions');

    const [{ page, make, model, originalRegistrationDate }, setListContext] = useState<ListContext>(initialContext);

    const { data } = useGetPurchaseTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            page: { limit: pageSize, offset: page * pageSize },
            filter: { make, model, originalRegistrationDate },
        },
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    const setActivePage = useCallback(
        (newPage: number) => {
            setListContext(state => ({ ...state, page: newPage }));
        },
        [setListContext]
    );

    useEffect(() => {
        const makeFilter = historicalReferenceFilter.find(
            filter => filter === t('historicalReferenceFilterOptions:make.value')
        );

        const modelFilter = historicalReferenceFilter.find(
            filter => filter === t('historicalReferenceFilterOptions:model.value')
        );

        const registrationFilter = historicalReferenceFilter.find(
            filter => filter === t('historicalReferenceFilterOptions:registration.value')
        );

        setListContext(state => ({
            ...state,
            make: makeFilter ? purchaseTransaction?.vehicle?.make : '',
            model: modelFilter ? purchaseTransaction?.vehicle?.model : '',
            originalRegistrationDate: registrationFilter
                ? (purchaseTransaction?.vehicle?.originalRegistrationDate as Date)
                : null,
        }));
    }, [historicalReferenceFilter, setListContext, purchaseTransaction, t]);

    const count = data?.purchaseTransactions?.count || 0;
    const historicalReferences =
        data?.purchaseTransactions?.items.filter(
            historicalReference => historicalReference.id !== purchaseTransaction.id
        ) || [];

    if (count === 0) {
        return <NoRecordsFound />;
    }

    return (
        <Grid spacing={3} container>
            {historicalReferences.map(historicalReference => (
                <Grid key={historicalReference.id} xs={12} item>
                    <HistoricalReferenceCard
                        historicalReferenceTransaction={historicalReference}
                        purchaseTransaction={purchaseTransaction}
                    />
                </Grid>
            ))}
            <Grid xs={12} item>
                <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
            </Grid>
        </Grid>
    );
};

export default HistoricalReferences;
