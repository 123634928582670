import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSession } from '../../Session';
import {
    useAuthenticateMutation,
    useConfirmAuthenticationMutation,
    useUpdateRegistrationTokenMutation,
    UserSession,
    User,
    DeviceSettingsType,
} from '../../api';
import { useFlutter } from '../../components/FlutterProvider';
import { withErrorBoundary } from '../../layouts/RoutedErrorBoundary';
import { useHandleError } from '../../utilities/handleErrors';
import OTPPage, { OTPFormValues } from '../OTPPage';
import { LoginFormValues } from './components';

export type LoginPageValidationProps = {
    validation: {
        values: LoginFormValues;
        token: string;
    };
};

export const useAuthenticateHandler = () => {
    const { setToken } = useSession();
    const history = useHistory();
    const { deviceFingerPrint: deviceFingerprint, initializePush } = useFlutter();
    const [updateRegistrationTokenMutation] = useUpdateRegistrationTokenMutation();

    return useCallback(
        async (data: Pick<UserSession, 'token' | 'signInToken'> & { user: Pick<User, 'deviceSettings'> }) => {
            // update token
            setToken(data.token);

            if (data.signInToken) {
                // update local storage
                localStorage.setItem('singleSignIn', data.signInToken);
            } else {
                // empty local storage for single sign in
                localStorage.removeItem('singleSignIn');
            }

            const isEnabledPush = data.user.deviceSettings.some(
                setting => setting.type === DeviceSettingsType.Push && setting.deviceFingerprint === deviceFingerprint
            );

            if (isEnabledPush) {
                const { registrationToken } = await initializePush();
                updateRegistrationTokenMutation({
                    variables: {
                        deviceFingerprint,
                        registrationToken,
                    },
                });
            }

            // then redirect
            setTimeout(() => {
                history.replace('/');
            });
        },
        [deviceFingerprint, history, initializePush, setToken, updateRegistrationTokenMutation]
    );
};

const LoginPageValidation = ({ validation }: LoginPageValidationProps) => {
    const { values, token: initialToken } = validation;
    const { mobilePhone } = values;
    const [token, setToken] = useState(initialToken);
    const history = useHistory();
    const { deviceFingerPrint: deviceFingerprint } = useFlutter();

    const { setToken: setSessionToken } = useSession();

    const [authenticate] = useAuthenticateMutation();
    const [confirm] = useConfirmAuthenticationMutation();

    const next = useAuthenticateHandler();
    const handleSubmit = useHandleError(
        async ({ code }: OTPFormValues) => {
            const { data } = await confirm({
                variables: {
                    token,
                    code: code.join(''),
                    mobilePhone,
                    deviceFingerprint,
                },
            });

            // proceed with the authentication
            next(data.confirmAuthentication);
        },
        [token, mobilePhone, confirm, setSessionToken, history, deviceFingerprint]
    );

    const resendCode = useCallback(() => {
        authenticate({ variables: values })
            .then(({ data }) => setToken(data.authenticate))
            .catch(console.warn);
    }, [setToken, values, authenticate]);

    return <OTPPage handleSubmit={handleSubmit} resendCode={resendCode} />;
};

export default withErrorBoundary(LoginPageValidation);
