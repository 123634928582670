import { useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import PortalLayout from '../layouts/PortalLayout';
import { LoginPage, LoginPageValidation, LoginPageValidationProps } from '../pages/Login';
import { useRuntimeConfig } from '../runtimeConfig';

type PendingData = LoginPageValidationProps['validation'];

type LocationState = { mobilePhone?: string };

const LoginRoute = () => {
    const [pendingData, setPendingData] = useState<PendingData | null>(null);
    const { countryCode } = useRuntimeConfig();

    const { mobilePhone: initialMobilePhone = '' } = useLocation<LocationState>().state || {};

    const initialValues = useMemo(
        (): PendingData['values'] => ({
            mobilePhone: { internationalCode: countryCode, number: initialMobilePhone },
            password: '',
        }),
        [initialMobilePhone, countryCode]
    );

    const onBack = useMemo(() => {
        if (pendingData?.token) {
            return () =>
                setPendingData({
                    values: { ...pendingData.values, password: '' },
                    token: null,
                });
        }

        return undefined;
    }, [pendingData, setPendingData]);

    if (pendingData?.token) {
        return (
            <PortalLayout onBack={onBack} onLight>
                <LoginPageValidation validation={pendingData} />
            </PortalLayout>
        );
    }

    return (
        <PortalLayout onBack={onBack} onLight>
            <LoginPage initialValues={pendingData?.values || initialValues} next={setPendingData} />
        </PortalLayout>
    );
};

export default LoginRoute;
