import { Grid, Typography } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    PurchaseTransactionStage,
    useGetPurchaseTransactionsQuery,
    useGetSaleTransactionsQuery,
    SaleTransactionStage,
} from '../../api';
import TabPanel from '../../components/TabPanel';
import useCommonStyles from '../../useCommonStyles';
import { DownloadPurchaseTransactionsFileProvider } from '../PurchaseTransactions/components';
import { DownloadSalesTransactionsFileProvider } from '../SaleTransactions/components';
import { MenuTabs, PurchaseTransactionsTab, SalesTransactionsTab } from './components';

const a11yProps = (index: string) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
});

type PurchaseTransactionListContext = {
    purchaseTransactionSearch: string;
    purchaseTransactionPage: number;
};

type SalesTransactionListContext = {
    salesTransactionSearch: string;
    salesTransactionPage: number;
};

const pageSize = 9;

const TransactionApprovals = () => {
    const { t } = useTranslation(['common']);
    const commonStyles = useCommonStyles();

    const [value, setValue] = useState('purchaseTransactions');
    const [purchaseTransactionStage, setPurchaseTransactionStage] = useState<'all' | PurchaseTransactionStage>('all');
    const [purchaseTransactionCreatedAt, setPurchaseTransactionCreatedAt] = useState<Date | null>(null);
    const [purchaseTransactionTargetHandoverDate, setPurchaseTransactionTargetHandoverDate] = useState<Date | null>(
        null
    );

    const [{ purchaseTransactionPage, purchaseTransactionSearch }, setPurchaseTransactionListContext] =
        useState<PurchaseTransactionListContext>({ purchaseTransactionSearch: '', purchaseTransactionPage: 0 });

    const { data: purchaseTransactionsData } = useGetPurchaseTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            page: { limit: pageSize, offset: purchaseTransactionPage * pageSize },
            filter: {
                search: purchaseTransactionSearch,
                stages: purchaseTransactionStage === 'all' ? null : [purchaseTransactionStage],
                createdAt: purchaseTransactionCreatedAt,
                handoverAt: purchaseTransactionTargetHandoverDate,
            },
            sort: {
                stagePriority: PurchaseTransactionStage.PendingApproval,
            },
        },
    });

    const setPurchaseTransactionActivePage = useCallback(
        (newPage: number) => {
            setPurchaseTransactionListContext(state => ({ ...state, purchaseTransactionPage: newPage }));
        },
        [setPurchaseTransactionListContext]
    );

    const purchaseTransactionSearchOnChange = useCallback(
        (searchValue: string) => {
            setPurchaseTransactionListContext(state => ({ ...state, purchaseTransactionSearch: searchValue }));
        },
        [setPurchaseTransactionListContext]
    );

    const purchaseTransactionCount = purchaseTransactionsData?.purchaseTransactions?.count || 0;
    const purchaseTransactions = purchaseTransactionsData?.purchaseTransactions?.items || [];

    const [{ salesTransactionPage, salesTransactionSearch }, setSalesTransactionListContext] =
        useState<SalesTransactionListContext>({ salesTransactionSearch: '', salesTransactionPage: 0 });

    const [salesTransactionCreatedAt, setSalesTransactionCreatedAt] = useState<Date | null>(null);
    const [salesTransactionStage, setSalesTransactionStage] = useState<'all' | SaleTransactionStage>('all');
    const [salesTransactionTargetHandoverDate, setSalesTransactionTargetHandoverDate] = useState<Date | null>(null);
    const [awardedDealer, setAwardedDealer] = useState<string>('all');

    const { data: salesTransactionsData } = useGetSaleTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            page: { limit: pageSize, offset: salesTransactionPage * pageSize },
            filter: {
                search: salesTransactionSearch,
                stages: salesTransactionStage === 'all' ? null : [salesTransactionStage],
                createdAt: salesTransactionCreatedAt,
                handoverAt: salesTransactionTargetHandoverDate,
                awardedDealer: awardedDealer === 'all' ? null : awardedDealer,
            },
            sort: {
                stagePriority: SaleTransactionStage.PendingApproval,
            },
        },
    });

    const setSalesTransactionActivePage = useCallback(
        (newPage: number) => {
            setSalesTransactionListContext(state => ({ ...state, salesTransactionPage: newPage }));
        },
        [setSalesTransactionListContext]
    );

    const salesTransactionSearchOnChange = useCallback(
        (searchValue: string) => {
            setSalesTransactionListContext(state => ({ ...state, salesTransactionSearch: searchValue }));
        },
        [setSalesTransactionListContext]
    );

    const salesTransactionCount = salesTransactionsData?.saleTransactions?.count || 0;
    const salesTransactions = salesTransactionsData?.saleTransactions?.items || [];

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Typography className={commonStyles.title}>{t('common:titles.approvals')}</Typography>
            </Grid>
            <Grid xs={12} item>
                <MenuTabs a11yProps={a11yProps} setValue={setValue} value={value} />
            </Grid>
            <Grid xs={12} item>
                <TabPanel index="purchaseTransactions" value={value}>
                    <DownloadPurchaseTransactionsFileProvider>
                        {downloadFile => (
                            <PurchaseTransactionsTab
                                count={purchaseTransactionCount}
                                createdAt={purchaseTransactionCreatedAt}
                                downloadPurchaseTransactionsFile={downloadFile}
                                page={purchaseTransactionPage}
                                pageSize={pageSize}
                                purchaseTransactions={purchaseTransactions}
                                search={purchaseTransactionSearch}
                                searchOnChange={purchaseTransactionSearchOnChange}
                                setActivePage={setPurchaseTransactionActivePage}
                                setCreatedAt={setPurchaseTransactionCreatedAt}
                                setStage={setPurchaseTransactionStage}
                                setTargetHandoverDate={setPurchaseTransactionTargetHandoverDate}
                                stage={purchaseTransactionStage}
                                targetHandoverDate={purchaseTransactionTargetHandoverDate}
                            />
                        )}
                    </DownloadPurchaseTransactionsFileProvider>
                </TabPanel>
                <TabPanel index="salesTransactions" value={value}>
                    <DownloadSalesTransactionsFileProvider>
                        {downloadFile => (
                            <SalesTransactionsTab
                                awardedDealer={awardedDealer}
                                count={salesTransactionCount}
                                createdAt={salesTransactionCreatedAt}
                                downloadSalesTransactionsFile={downloadFile}
                                page={salesTransactionPage}
                                pageSize={pageSize}
                                saleTransactions={salesTransactions}
                                search={salesTransactionSearch}
                                searchOnChange={salesTransactionSearchOnChange}
                                setActivePage={setSalesTransactionActivePage}
                                setAwardedDealer={setAwardedDealer}
                                setCreatedAt={setSalesTransactionCreatedAt}
                                setStage={setSalesTransactionStage}
                                setTargetHandoverDate={setSalesTransactionTargetHandoverDate}
                                stage={salesTransactionStage}
                                targetHandoverDate={salesTransactionTargetHandoverDate}
                            />
                        )}
                    </DownloadSalesTransactionsFileProvider>
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default TransactionApprovals;
