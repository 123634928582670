import { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router';
import PortalLayout from '../layouts/PortalLayout';
import { ResetPasswordValidation, ResetPasswordPage } from '../pages/ResetPassword';

type LocationState = { mobilePhone?: string };

const ResetPasswordRoute = () => {
    const history = useHistory();
    const { mobilePhone } = useLocation<LocationState>().state || {};
    const [token, setToken] = useState('');

    const onBack = useCallback(() => history.push('/signIn', { mobilePhone }), [history, mobilePhone]);

    useEffect(() => {
        if (!mobilePhone) {
            // move to login page
            history.push('/signIn');
        }
    }, [mobilePhone, history]);

    if (!mobilePhone) {
        // nothing to render
        return null;
    }

    return (
        <PortalLayout onBack={onBack} onLight>
            {token ? (
                <ResetPasswordPage token={token} />
            ) : (
                <ResetPasswordValidation mobilePhone={mobilePhone} next={setToken} />
            )}
        </PortalLayout>
    );
};

export default ResetPasswordRoute;
