import { TableCell, TableRow } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { contact } from '../../../../server/schema/constants';
import { FullNotificationDataFragment, NotificationChannel } from '../../../api';
import useCommonStyles from '../../../useCommonStyles';
import getNotificationType from '../../../utilities/getNotificationType';

export type NotificationRowProps = {
    notice: FullNotificationDataFragment;
};

const NotificationRow = ({ notice }: NotificationRowProps) => {
    const { t } = useTranslation(['notifications', 'common']);
    const commonStyles = useCommonStyles();

    const getNotificationDetails = useCallback(
        (notice: FullNotificationDataFragment): string => {
            switch (notice.__typename) {
                case 'AwardedNoticeNotification':
                    return t('notifications:details.awardedNotice');

                case 'HandoverDateChangeNoticeNotification':
                    return t('notifications:details.handoverDateChange');

                case 'PendingAcceptanceNoticeNotification':
                    return t('notifications:details.pendingAcceptance');

                case 'PendingValuationNoticeNotification':
                    return t('notifications:details.pendingValuation');

                case 'SignUpApprovalNotification':
                    return t('notifications:details.signUpApproval');

                case 'SignUpRejectNotification':
                    return t('notifications:details.signUpReject', { contact });

                case 'UpcomingBiddingNoticeNotification':
                    return t('notifications:details.upcomingBidding');

                case 'PurchaseTransactionApprovalNoticeNotification':
                case 'SalesTransactionApprovalNoticeNotification':
                    return t('notifications:details.pendingApproval');

                case 'PurchaseTransactionApprovedNoticeNotification':
                case 'SalesTransactionApprovedNoticeNotification':
                    return t('notifications:details.transactionApproved');

                case 'PurchaseTransactionRejectedNoticeNotification':
                case 'SalesTransactionRejectedNoticeNotification':
                    return t('notifications:details.transactionRejected');

                case 'SalesTransactionBroadcastedNoticeNotification':
                    return t('notifications:details.transactionBroadcasted', { content: notice.content });

                case 'SalesTransactionCommentedNoticeNotification':
                    return t('notifications:details.transactionCommented', { content: notice.content });

                default:
                    return '';
            }
        },
        [t]
    );

    const getTransactionNo = useCallback((note: FullNotificationDataFragment) => {
        switch (note.__typename) {
            case 'AwardedNoticeNotification':
            case 'UpcomingBiddingNoticeNotification':
                return note.saleTransactions.map(st => st.identifier).join(', ');

            case 'HandoverDateChangeNoticeNotification':
            case 'PendingValuationNoticeNotification':
            case 'PendingAcceptanceNoticeNotification':
            case 'PurchaseTransactionApprovalNoticeNotification':
            case 'PurchaseTransactionApprovedNoticeNotification':
            case 'PurchaseTransactionRejectedNoticeNotification':
                return note.purchaseTransaction.identifier;

            case 'SalesTransactionApprovalNoticeNotification':
            case 'SalesTransactionApprovedNoticeNotification':
            case 'SalesTransactionRejectedNoticeNotification':
            case 'SalesTransactionBroadcastedNoticeNotification':
            case 'SalesTransactionCommentedNoticeNotification':
                return note.salesTransaction?.identifier;

            default:
                return '';
        }
    }, []);

    const getChannels = useCallback(
        () =>
            notice.channels?.map(channel => {
                switch (channel) {
                    case NotificationChannel.Email:
                        return t('notifications:channel.email');
                    case NotificationChannel.Push:
                        return t('notifications:channel.pushNotification');
                    case NotificationChannel.Sms:
                        return t('notifications:channel.sms');
                    default:
                        return '';
                }
            }),
        [notice.channels, t]
    );

    return (
        <TableRow className={commonStyles.tableRow}>
            <TableCell>{getTransactionNo(notice)}</TableCell>
            <TableCell>
                {t('common:formats.longDateTime', {
                    date: new Date(notice.createdAt),
                })}
            </TableCell>
            <TableCell>{notice.user?.mobilePhone.number}</TableCell>
            <TableCell>{getNotificationType(notice.type)}</TableCell>
            <TableCell>{getChannels()?.join(', ')}</TableCell>
            <TableCell>{getNotificationDetails(notice)}</TableCell>
        </TableRow>
    );
};

export default NotificationRow;
