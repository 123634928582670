import { Redirect } from 'react-router-dom';
import { useCurrentUser } from '../../Session';
import { UserType } from '../../api';
import BasicLayout from '../../layouts/BasicLayout';
import PurchaseTransactionsList from '../../pages/PurchaseTransactions/PurchaseTransactionsList';
import { DownloadPurchaseTransactionsFileProvider } from '../../pages/PurchaseTransactions/components';

const PurchaseTransactionsRoute = () => {
    const currentUser = useCurrentUser();

    if (
        currentUser.type !== UserType.Admin &&
        currentUser.type !== UserType.SaleConsultant &&
        currentUser.type !== UserType.ValuationTeam &&
        currentUser.type !== UserType.Approver
    ) {
        return <Redirect to="/403" />;
    }

    return (
        <BasicLayout>
            <DownloadPurchaseTransactionsFileProvider>
                {downloadFile => <PurchaseTransactionsList downloadPurchaseTransactionsFile={downloadFile} />}
            </DownloadPurchaseTransactionsFileProvider>
        </BasicLayout>
    );
};

export default PurchaseTransactionsRoute;
