import PubSub from 'pubsub-js';
import FlutterChannel from './FlutterChannel';

class FlutterAction<Args = {}, Response = {}, ResolvedValue = Response> {
    public readonly identifier: string;

    private readonly handleResponse: (response: Response) => Promise<ResolvedValue>;

    private channel: FlutterChannel | null;

    constructor(identifier: string, handleResponse: (response: Response) => Promise<ResolvedValue>) {
        this.identifier = identifier;
        this.handleResponse = handleResponse;
        this.channel = null;
    }

    private get pubSubChannel(): string {
        return `flutter.${this.identifier}`;
    }

    private get pubSubListenerChannel(): string {
        return `${this.pubSubChannel}.listener`;
    }

    public attachChannel(channel: FlutterChannel): FlutterAction<Args, Response, ResolvedValue> {
        this.channel = channel;

        return this;
    }

    public send(args: Args): Promise<ResolvedValue> {
        if (this.channel) {
            this.channel.sendMessage({ ...args, type: this.identifier });
        }

        return new Promise(resolve => {
            PubSub.subscribeOnce(this.pubSubChannel, (channel: string, response: ResolvedValue) => resolve(response));
        });
    }

    public listen(listener: (response: ResolvedValue) => void) {
        return PubSub.subscribe(this.pubSubListenerChannel, (channel: string, data: ResolvedValue) => listener(data));
    }

    public receive(response: Response) {
        this.handleResponse(response)
            .then(resolvedValue => {
                // resolve pending promises
                PubSub.publish(this.pubSubChannel, resolvedValue);
                // call listeners
                PubSub.publish(this.pubSubListenerChannel, resolvedValue);
            })
            .catch(console.error);
    }
}

export default FlutterAction;
