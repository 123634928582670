import { Box, Button, Dialog, DialogContent, FormControl, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FullSaleTransactionDataFragment, useUpdateSaleTransactionToForAwardingMutation } from '../../../../api';
import useCommonStyles from '../../../../useCommonStyles';
import usePublic from '../../../../utilities/usePublic';

type ActionCloseTransactionProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

const useStyles = makeStyles(theme =>
    createStyles({
        confirmDialogContent: {
            padding: 0,
        },
    })
);

const ActionCloseTransaction = ({ saleTransaction }: ActionCloseTransactionProps) => {
    const [isOpen, setOpen] = useState(false);
    const { t } = useTranslation(['saleTransactionsPage']);

    const commonStyles = useCommonStyles();
    const styles = useStyles();

    const exclamation = usePublic('assets/icons/exclamation.svg');

    const [updateToForAwarding] = useUpdateSaleTransactionToForAwardingMutation({
        variables: { transactionId: saleTransaction.id },
    });

    const onOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const onClose = useCallback(() => {
        setOpen(false);
    }, []);

    const onConfirm = useCallback(async () => {
        await updateToForAwarding();

        setOpen(false);
    }, [updateToForAwarding]);

    return (
        <>
            <Button
                className={cs(commonStyles.removeBoxShadow, commonStyles.defaultBorderRadius)}
                // classes={{ label: styles.closeTransactionLabel }}
                color="secondary"
                onClick={onOpen}
                size="large"
                variant="contained"
            >
                {t('saleTransactionsPage:button.close')}
            </Button>
            <Dialog
                aria-labelledby="submitted-dialog-title"
                classes={{ paper: commonStyles.defaultBorderRadius }}
                maxWidth="xs"
                onClose={onClose}
                open={isOpen}
                disableEscapeKeyDown
                fullWidth
            >
                <DialogContent className={styles.confirmDialogContent}>
                    <Box mb={4} ml={4} mr={4} mt={2} textAlign="center">
                        <Box>
                            <img alt="exclamation-icon" src={exclamation} />
                        </Box>
                        <Box mb={2} mt={1}>
                            <Typography className={cs(commonStyles.primary, commonStyles.fontWightMedium)}>
                                {t('saleTransactionsPage:closeTransaction.title')}
                            </Typography>
                        </Box>
                        <Typography className={commonStyles.dialogDescription}>
                            {t('saleTransactionsPage:closeTransaction.text')}
                        </Typography>

                        <Box mt={3}>
                            <Grid spacing={2} container>
                                <Grid xs={6} item>
                                    <FormControl fullWidth>
                                        <Button
                                            className={cs(
                                                commonStyles.removeBoxShadow,
                                                commonStyles.defaultBorderRadius
                                            )}
                                            onClick={onClose}
                                            size="large"
                                            variant="contained"
                                        >
                                            {t('common:cancel')}
                                        </Button>
                                    </FormControl>
                                </Grid>
                                <Grid xs={6} item>
                                    <FormControl fullWidth>
                                        <Button
                                            className={commonStyles.defaultBorderRadius}
                                            color="secondary"
                                            onClick={onConfirm}
                                            size="large"
                                            variant="contained"
                                        >
                                            {t('common:confirm')}
                                        </Button>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ActionCloseTransaction;
