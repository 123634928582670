import React from 'react';
import { Redirect } from 'react-router-dom';
import { useCurrentUser } from '../../Session';
import { UserType } from '../../api';
import BasicLayout from '../../layouts/BasicLayout';
import BiddingSessions from '../../pages/BiddingSessions';

const BiddingSessionsRoute = () => {
    const currentUser = useCurrentUser();

    if (currentUser.type !== UserType.Admin) {
        return <Redirect to="/403" />;
    }

    return (
        <BasicLayout>
            <BiddingSessions />
        </BasicLayout>
    );
};

export default BiddingSessionsRoute;
