import { Redirect } from 'react-router-dom';
import { useCurrentUser } from '../Session';
import { UserType } from '../api';
import BasicLayout from '../layouts/BasicLayout';
import Approvals from '../pages/Approvals';

const ApprovalsRoute = () => {
    const currentUser = useCurrentUser();

    if (currentUser.type !== UserType.Admin) {
        return <Redirect to="/403" />;
    }

    return (
        <BasicLayout>
            <Approvals />
        </BasicLayout>
    );
};

export default ApprovalsRoute;
