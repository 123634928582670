import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import CreateSessionAccordion from './components/CreateSessionAccordion';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(10),
                padding: theme.spacing(3),
                paddingRight: theme.spacing(4),
            },
        },
    })
);

const CreateBiddingSessionBody = () => {
    const styles = useStyles();

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box className={styles.root} mb={isSmall ? 10 : 0}>
            <Grid container>
                <Grid xs={12} item>
                    <CreateSessionAccordion />
                </Grid>
            </Grid>
        </Box>
    );
};

export default CreateBiddingSessionBody;
