import { Grid, InputAdornment, Box } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../Session';
import { UserType } from '../../../api';
import BooleanCheckbox from '../../../components/fields/BooleanCheckbox';
import OutlinedDatePickerField from '../../../components/fields/OutlinedDatePickerField';
import OutlinedInputField from '../../../components/fields/OutlinedInputField';
import OutlinedSelectField from '../../../components/fields/OutlinedSelectField';
import useCommonStyles from '../../../useCommonStyles';
import useImportMethodOptions from '../../../utilities/useImportMethodOptions';
import useYesNoOptions from '../../../utilities/useYesNoOptions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        adornmentRoot: {
            '& > p': {
                color: theme.palette.secondary.main,
            },
        },
    })
);

export type VehicleFormProps = {
    onCreateion?: boolean;
};

const VehicleForm = ({ onCreateion }: VehicleFormProps) => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const yesNoOptions = useYesNoOptions();
    const importMethodOptions = useImportMethodOptions();
    const currentUser = useCurrentUser();

    return (
        <Grid spacing={2} container>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.vehicleNo')}
                    name="vehicle.number"
                    placeholder={t('saleTransactionsPage:vehicle.vehicleNo')}
                    required
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={
                        !onCreateion &&
                        ![UserType.Admin, UserType.SaleConsultant, UserType.Approver].includes(currentUser.type)
                    }
                    endAdornment={
                        <InputAdornment classes={{ root: styles.adornmentRoot }} position="end">
                            {t('common:shortKilometer')}
                        </InputAdornment>
                    }
                    label={t('saleTransactionsPage:vehicle.mileage')}
                    name="vehicle.mileage"
                    placeholder={t('saleTransactionsPage:vehicle.mileage')}
                    type="number"
                    required
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedSelectField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    inputProps={{
                        classes: {
                            icon: commonStyles.fillSecondary,
                        },
                    }}
                    label={t('saleTransactionsPage:vehicle.vehicleToBeExported')}
                    name="vehicle.beExported"
                    options={yesNoOptions}
                    placeholder={t('saleTransactionsPage:vehicle.vehicleToBeExported')}
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedDatePickerField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.intendedDeregistrationDate')}
                    name="vehicle.intendedDeregistrationDate"
                    placeholder={t('saleTransactionsPage:vehicle.intendedDeregistrationDate')}
                    required
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.vehicleMake')}
                    name="vehicle.make"
                    placeholder={t('saleTransactionsPage:vehicle.vehicleMake')}
                    required
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.vehicleModel')}
                    name="vehicle.model"
                    placeholder={t('saleTransactionsPage:vehicle.vehicleModel')}
                    required
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.primaryColour')}
                    name="vehicle.primaryColour"
                    placeholder={t('saleTransactionsPage:vehicle.primaryColour')}
                    required
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.secondaryColour')}
                    name="vehicle.secondaryColour"
                    placeholder={t('saleTransactionsPage:vehicle.secondaryColour')}
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.manufacturingYear')}
                    name="vehicle.manufacturingYear"
                    placeholder={t('saleTransactionsPage:vehicle.manufacturingYear')}
                    type="number"
                    required
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.engineNo')}
                    name="vehicle.engineNumber"
                    placeholder={t('saleTransactionsPage:vehicle.engineNo')}
                    required
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.chassisNo')}
                    name="vehicle.chassisNumber"
                    placeholder={t('saleTransactionsPage:vehicle.chassisNo')}
                    required
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.maxPowerOutput')}
                    name="vehicle.maximumPowerOutput"
                    placeholder={t('saleTransactionsPage:vehicle.maxPowerOutput')}
                    required
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.openMarketValue')}
                    name="vehicle.openMarketValue"
                    placeholder={t('saleTransactionsPage:vehicle.openMarketValue')}
                    type="number"
                    required
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedDatePickerField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.originalRegistrationDate')}
                    name="vehicle.originalRegistrationDate"
                    placeholder={t('saleTransactionsPage:vehicle.originalRegistrationDate')}
                    required
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedDatePickerField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.firstRegistrationDate')}
                    name="vehicle.firstRegistrationDate"
                    placeholder={t('saleTransactionsPage:vehicle.firstRegistrationDate')}
                    required
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.transferCount')}
                    name="vehicle.transferCount"
                    placeholder={t('saleTransactionsPage:vehicle.transferCount')}
                    type="number"
                    required
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.actualArfPaid')}
                    name="vehicle.actualARFPaid"
                    placeholder={t('saleTransactionsPage:vehicle.actualArfPaid')}
                    type="number"
                    required
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.opcCashRebateEligibility')}
                    name="vehicle.opcCashRebateEligibility"
                    placeholder={t('saleTransactionsPage:vehicle.opcCashRebateEligibility')}
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedDatePickerField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.opcCashRebateEligibilityExpiryDate')}
                    name="vehicle.opcCashRebateEligibilityExpiryDate"
                    placeholder={t('saleTransactionsPage:vehicle.opcCashRebateEligibilityExpiryDate')}
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.opcCashRebateAmount')}
                    name="vehicle.opcCashRebateAmount"
                    placeholder={t('saleTransactionsPage:vehicle.opcCashRebateAmount')}
                    type="number"
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedSelectField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    inputProps={{
                        classes: {
                            icon: commonStyles.fillSecondary,
                        },
                    }}
                    label={t('saleTransactionsPage:vehicle.parfEligibility')}
                    name="vehicle.parfEligibility"
                    options={yesNoOptions}
                    placeholder={t('saleTransactionsPage:vehicle.parfEligibility')}
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedDatePickerField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.parfEligibilityExpiryDate')}
                    name="vehicle.parfEligibilityDate"
                    placeholder={t('saleTransactionsPage:vehicle.parfEligibilityExpiryDate')}
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.parfRebateValue')}
                    name="vehicle.parfRebateAmount"
                    placeholder={t('saleTransactionsPage:vehicle.parfRebateValue')}
                    type="number"
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedDatePickerField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.coeExpiryDate')}
                    name="vehicle.coeExpiryDate"
                    placeholder={t('saleTransactionsPage:vehicle.coeExpiryDate')}
                    required
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.coeCategory')}
                    name="vehicle.coeCategory"
                    placeholder={t('saleTransactionsPage:vehicle.coeCategory')}
                    required
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.coePeriod')}
                    name="vehicle.coePeriodYear"
                    placeholder={t('saleTransactionsPage:vehicle.coePeriod')}
                    type="number"
                    required
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.qpPaid')}
                    name="vehicle.qpPaid"
                    placeholder={t('saleTransactionsPage:vehicle.qpPaid')}
                    type="number"
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.pqpPaid')}
                    name="vehicle.pqpPaid"
                    placeholder={t('saleTransactionsPage:vehicle.pqpPaid')}
                    type="number"
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.coeRebateAmount')}
                    name="vehicle.coeRebateAmount"
                    placeholder={t('saleTransactionsPage:vehicle.coeRebateAmount')}
                    type="number"
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.totalRebateAmount')}
                    name="vehicle.totalRebateAmount"
                    placeholder={t('saleTransactionsPage:vehicle.totalRebateAmount')}
                    type="number"
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={!onCreateion && ![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)}
                    label={t('saleTransactionsPage:vehicle.ltaMessage')}
                    name="vehicle.ltaMessage"
                    placeholder={t('saleTransactionsPage:vehicle.ltaMessage')}
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedSelectField
                    color="secondary"
                    disabled={
                        !onCreateion &&
                        ![UserType.Admin, UserType.SaleConsultant, UserType.Approver].includes(currentUser.type)
                    }
                    inputProps={{
                        classes: {
                            icon: commonStyles.fillSecondary,
                        },
                    }}
                    label={t('saleTransactionsPage:vehicle.importMethod')}
                    name="vehicle.importMethod"
                    options={importMethodOptions}
                    placeholder={t('saleTransactionsPage:vehicle.importMethod')}
                    required
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    label={t('saleTransactionsPage:vehicle.setOfKeys')}
                    name="vehicle.setOfKeys"
                    placeholder={t('saleTransactionsPage:vehicle.setOfKeys')}
                    type="number"
                    required
                />
            </Grid>
            <Grid md={6} sm={12} xs={12} item>
                <Box alignItems="center" display="flex" height="100%">
                    <BooleanCheckbox
                        color="secondary"
                        label={t('saleTransactionsPage:vehicle.ownerHandbookCollected')}
                        name="vehicle.ownerHandbookCollected"
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default VehicleForm;
