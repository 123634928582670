import { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import { useCurrentUser } from '../Session';
import { UserType } from '../api';
import BasicLayout from '../layouts/BasicLayout';
import HomePage from '../pages/HomePage';

const HomeRoute = (): ReactElement => {
    const currentUser = useCurrentUser();

    switch (currentUser.type) {
        case UserType.SaleConsultant:
            return <Redirect to="/salesTransactions" />;

        case UserType.ValuationTeam:
            return <Redirect to="/myTransactions" />;

        case UserType.Dealer:
            return <Redirect to="/myBids" />;

        case UserType.Approver:
            return <Redirect to="/transactionApprovals" />;

        default:
            return (
                <BasicLayout onGrayBackground>
                    <HomePage />
                </BasicLayout>
            );
    }
};

export default HomeRoute;
