import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import OutlinedDatePickerField from '../../../components/fields/OutlinedDatePickerField';
import OutlinedSelectField from '../../../components/fields/OutlinedSelectField';
import useCommonStyles from '../../../useCommonStyles';
import useSalesTransactionActionOptions from '../../../utilities/useSalesTransactionActionOptions';

const TradeInDetailsForm = ({ isEditable = false }: { isEditable?: boolean }) => {
    const { t } = useTranslation(['saleTransactionsPage']);
    const commonStyles = useCommonStyles();
    const salesTransactionActionOptions = useSalesTransactionActionOptions();

    return (
        <Grid spacing={2} container>
            <Grid md={6} sm={12} xs={12} item>
                <OutlinedDatePickerField
                    color="secondary"
                    label={t('saleTransactionsPage:handover.targetHandoverDate')}
                    name="handover.targetHandoverDateTime"
                    placeholder={t('saleTransactionsPage:handover.targetHandoverDate')}
                    required
                />
            </Grid>
            {!isEditable && (
                <Grid md={6} sm={12} xs={12} item>
                    <OutlinedSelectField
                        color="secondary"
                        inputProps={{
                            classes: {
                                icon: commonStyles.fillSecondary,
                            },
                        }}
                        label={t('saleTransactionsPage:tradeInDetails.action')}
                        name="action"
                        options={salesTransactionActionOptions}
                        placeholder={t('saleTransactionsPage:tradeInDetails.action')}
                        required
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default TradeInDetailsForm;
