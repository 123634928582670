export type MessageHandler = { postMessage(message: string): void };

export type FlutterMessage<Args> = { type: string } & Args;

export class FlutterChannel {
    public get isEnabled() {
        return !!this.messageHandler;
    }

    private get messageHandler(): MessageHandler | undefined {
        return window.flutterChannel;
    }

    public sendMessage<Args>(message: FlutterMessage<Args>) {
        const { messageHandler } = this;

        if (messageHandler) {
            messageHandler.postMessage(JSON.stringify(message));
        }
    }
}

export default FlutterChannel;
