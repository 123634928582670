import { ReactNode, useCallback, useReducer, useEffect } from 'react';
import { useCheckDuplicateTransactionQuery, VehicleFields } from '../../../../api';
import { useLoadingController } from '../../../../components/LoadingProvider';
import DuplicateTransactionsConfirmDialog from './DuplicateTransactionsConfirmDialog';

export type CheckDuplicateCarPlate = (vehicle: VehicleFields) => Promise<boolean>;

export type DuplicateTransactionProviderProps = {
    children: (fn: CheckDuplicateCarPlate) => JSX.Element | ReactNode;
};

type State = {
    vehicle: VehicleFields | null;
    resolve: ((result: boolean) => void) | null;
};

type InitAction = { type: 'init' } & Pick<State, 'vehicle' | 'resolve'>;

type EndAction = { type: 'end' };

type Action = InitAction | EndAction;

const initialState: State = { vehicle: null, resolve: null };

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'init':
            return { vehicle: action.vehicle, resolve: action.resolve };

        case 'end':
            return initialState;

        default:
            return state;
    }
};

const DuplicateTransactionProvider = ({ children }: DuplicateTransactionProviderProps) => {
    const [{ resolve, vehicle }, dispatch] = useReducer(reducer, initialState);

    // get data from the API
    const { data, loading } = useCheckDuplicateTransactionQuery({
        fetchPolicy: 'network-only',
        variables: {
            vehicleNumber: vehicle?.number,
        },
        skip: !vehicle,
    });

    const isChecking = !!vehicle;
    const isDuplicated = isChecking && !!data?.isDuplicated;

    // display loading
    useLoadingController(loading);

    // automatically resolve it if there is no duplicates
    useEffect(() => {
        if (isChecking && !loading && !isDuplicated) {
            resolve(false);
        }
    }, [loading, resolve, isChecking, isDuplicated]);

    const fn = useCallback(
        (vehicle: VehicleFields) =>
            new Promise<boolean>(resolve => {
                dispatch({ type: 'init', vehicle, resolve });
            }).then(result => {
                // reset
                dispatch({ type: 'end' });

                return result;
            }),
        [dispatch]
    );

    return (
        <>
            <DuplicateTransactionsConfirmDialog resolve={resolve} show={isDuplicated && !!vehicle} />
            {children(fn)}
        </>
    );
};

export default DuplicateTransactionProvider;
