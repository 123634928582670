import { makeStyles, alpha } from '@material-ui/core/styles';

const usePortalStyles = makeStyles(theme => ({
    '@global': {
        body: {
            fontFamily: 'Porsche Next',
        },
    },

    root: {
        minHeight: '100vh',
        backgroundColor: '#110D0E',
    },

    whiteBackground: {
        minHeight: '100vh',
        backgroundColor: '#fff',
    },

    linkBack: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1rem',
        zIndex: 1,
        marginRight: 'auto',
    },

    toolBar: {
        borderBottom: '0.1rem solid #2b2b2b',
        display: 'flex',
        justifyContent: 'center',
    },

    toolBarLight: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#fff',
    },

    appBarLight: {
        boxShadow: 'none',
        backgroundColor: '#fff',
        [theme.breakpoints.up('sm')]: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        },
    },

    appBarLogo: {
        height: theme.spacing(5),
    },

    signUpLabel: {
        fontFamily: 'Porsche Next !important',
        width: '100%',
        color: alpha(theme.palette.primary.main, 0.2),
        textAlign: 'center',
        fontSize: '4rem',
        fontWeight: 'bold',
        position: 'absolute',
        bottom: 8,
        [theme.breakpoints.up('sm')]: {
            fontSize: '5rem',
        },
    },

    logo: {
        height: '5rem',
        backgroundSize: 'Contain',
        [theme.breakpoints.down('sm')]: {
            height: '3.5rem',
        },
    },

    carMedia: {
        height: '12.5rem',
        marginTop: '1rem',
    },

    inputField: {
        backgroundColor: '#2E2E2E99',
        '& :-webkit-autofill': {
            '-webkit-text-fill-color': '#D5001C',
            '-webkit-box-shadow': '0 0 0px 1000px #1B1B1B inset',
        },
    },

    inputFieldLight: {
        backgroundColor: '#ffffff',
        '& :-webkit-autofill': {
            '-webkit-text-fill-color': theme.palette.primary.main,
            '-webkit-box-shadow': '0 0 0px 1000px #ffffff inset',
        },
    },

    biometricButton: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: theme.spacing(1),
        marginLeft: '1rem',
        '& img': {
            height: '1.5rem',
        },
    },

    loginButton: {
        height: '100%',
    },

    welcomeBox: {
        margin: 0,
        padding: '40px 0',
    },

    welcomeTitle: {
        color: theme.palette.primary.main,
        fontSize: '1.125rem',
        fontWeight: 300,
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.725rem',
            marginTop: theme.spacing(3),
        },
    },

    resetPasswordField: {
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.secondary.main,
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.main,
        },
    },
}));

export default usePortalStyles;
