import { Grid, Box, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { FullPurchaseTransactionDataFragment } from '../../../../api';
import TabPanel from '../../../../components/TabPanel';
import { MobileMenuTabs, DesktopMenuTabs } from '../../components';
import HandoverTab from './HandoverTab';
import TransactionTab from './TransactionTab';
import ValuationTab from './ValuationTab';
import VehicleInfo from './VehicleInfo';
import VehicleTab from './VehicleTab';

export type EditPurchaseTransactionBodyProps = {
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

const a11yProps = (index: string) => ({
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(10),
            },
        },
    })
);

const EditPurchaseTransactionBody = ({ purchaseTransaction }: EditPurchaseTransactionBodyProps) => {
    const styles = useStyles();
    const [value, setValue] = useState('transaction');

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Box className={styles.root} mb={isSmall ? 10 : 0} pl={isSmall ? 1 : 0} pr={isSmall ? 1 : 0}>
                <Grid spacing={2} container>
                    <Grid lg={5} md={5} sm={12} xs={12} item>
                        <Box pl={isSmall ? 2 : 0} pr={isSmall ? 2 : 1}>
                            <VehicleInfo purchaseTransaction={purchaseTransaction} />
                        </Box>
                    </Grid>
                    <Grid lg={7} md={7} sm={12} xs={12} item>
                        {!isSmall && <DesktopMenuTabs a11yProps={a11yProps} setValue={setValue} value={value} />}
                        <Box pl={isSmall ? 0.5 : 0} pr={isSmall ? 0.5 : 0}>
                            <TabPanel index="transaction" value={value}>
                                <TransactionTab onEdition />
                            </TabPanel>
                            <TabPanel index="vehicle" value={value}>
                                <VehicleTab />
                            </TabPanel>
                            <TabPanel index="valuation" value={value}>
                                <ValuationTab />
                            </TabPanel>
                            <TabPanel index="handover" value={value}>
                                <HandoverTab />
                            </TabPanel>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {isSmall && <MobileMenuTabs a11yProps={a11yProps} setValue={setValue} value={value} />}
        </>
    );
};

export default EditPurchaseTransactionBody;
