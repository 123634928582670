import { ReactNode } from 'react';
import {
    GeneratePurchaseTransactionsFileDocument as query,
    GeneratePurchaseTransactionsFileQuery as Query,
    GeneratePurchaseTransactionsFileQueryVariables as Variables,
} from '../../../api';
import DownloadProvider, { DownloadFn } from '../../../components/DownloadProvider';

export type DownloadListFn = DownloadFn<Variables>;

export type DownloadPurchaseTransactionsFileProviderProps = {
    children: (fn: DownloadListFn) => JSX.Element | ReactNode;
};

const getSignedUrl = (data: Query) => data?.signedUrl || null;

const DownloadPurchaseTransactionsFileProvider = ({ children }: DownloadPurchaseTransactionsFileProviderProps) => (
    <DownloadProvider getSignedUrl={getSignedUrl} query={query}>
        {children}
    </DownloadProvider>
);

export default DownloadPurchaseTransactionsFileProvider;
