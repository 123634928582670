import { Grid, FormControl, Button } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

export type ProfileUpdateFooterProps = {
    goToView: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cancelButtonLabel: {
            color: '#A5A5A5',
        },
    })
);

const ProfileUpdateFooter = ({ goToView }: ProfileUpdateFooterProps) => {
    const { t } = useTranslation(['profilePage, common']);
    const { isSubmitting } = useFormikContext();
    const styles = useStyles();

    return (
        <Grid spacing={2} container>
            <Grid xs={6} item>
                <FormControl fullWidth>
                    <Button
                        classes={{ label: styles.cancelButtonLabel }}
                        onClick={goToView}
                        size="large"
                        variant="outlined"
                    >
                        {t('common:cancel')}
                    </Button>
                </FormControl>
            </Grid>
            <Grid xs={6} item>
                <FormControl fullWidth>
                    <Button color="secondary" disabled={isSubmitting} size="large" type="submit" variant="contained">
                        {t('common:save')}
                    </Button>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default ProfileUpdateFooter;
