import { Box } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MenuList from '../MenuList';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            overflow: 'auto',
            flex: '1 1 auto',
        },
    })
);

export type DrawerBodyProps = {
    setOpen: (value: boolean) => void;
};

const DrawerBody = ({ setOpen }: DrawerBodyProps) => {
    const styles = useStyles();

    return (
        <Box className={styles.root}>
            <MenuList setOpen={setOpen} />
        </Box>
    );
};

export default DrawerBody;
