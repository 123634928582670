import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../Session';
import { UserType, SaleTransactionStage } from '../../../api';
import OutlinedDatePickerField from '../../../components/fields/OutlinedDatePickerField';

export type HandoverFormProps = {
    currentStage?: SaleTransactionStage;
};

const HandoverForm = ({ currentStage }: HandoverFormProps) => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const currentUser = useCurrentUser();

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <OutlinedDatePickerField
                    color="secondary"
                    disabled={
                        currentUser.type !== UserType.Admin &&
                        currentUser.type !== UserType.Approver &&
                        currentUser.type !== UserType.ValuationTeam &&
                        currentUser.type !== UserType.SaleConsultant
                    }
                    label={t('saleTransactionsPage:handover.targetHandoverDate')}
                    name="handover.targetHandoverDateTime"
                    placeholder={t('saleTransactionsPage:handover.targetHandoverDate')}
                    required
                />
            </Grid>
        </Grid>
    );
};

export default HandoverForm;
