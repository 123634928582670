import { Grid, Box, Typography, Card, CardContent } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PreviewPurchaseTransactionDataFragment } from '../../../../api';
import useCommonStyles from '../../../../useCommonStyles';
import useVehicleLogo from '../../../../utilities/useVehicleLogo';

export type Vehicle = {
    model: string;
    registrationNo: string;
    kilometer: number;
};

export type PurchaseTransactionItemProps = {
    purchaseTransaction: PreviewPurchaseTransactionDataFragment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            padding: 0,
        },
        header: {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.primary.main,
            '& svg': {
                marginLeft: 'auto',
            },
        },
        body: {
            padding: theme.spacing(2),
            backgroundColor: '#EDEDED',
            color: '#717073',
            '& .MuiGrid-item:last-child': {
                opacity: '0.6',
            },
        },
        stage: {
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            marginLeft: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
            borderRadius: theme.spacing(0.5),
        },
        label: {
            marginRight: theme.spacing(1),
        },
    })
);

const PurchaseTransactionItem = ({ purchaseTransaction }: PurchaseTransactionItemProps) => {
    const { t } = useTranslation(['common', 'saleTransactionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useStyles();

    const vehicleLogo = useVehicleLogo(purchaseTransaction.vehicle.make);

    return (
        <Card className={commonStyles.card}>
            <CardContent className={styles.content}>
                <Box className={styles.header}>
                    <Box display="flex">
                        <Typography className={cs(commonStyles.primary2)}>
                            {`${purchaseTransaction.vehicle.make} ${purchaseTransaction.vehicle.model}`}
                        </Typography>
                        <Typography
                            className={cs(commonStyles.commonTransactionStatus, commonStyles.transactionStatus)}
                        >
                            {purchaseTransaction.stage}
                        </Typography>
                    </Box>
                    <Typography
                        className={cs(commonStyles.primary2, commonStyles.fontWeightLight, commonStyles.smallFontSize)}
                    >
                        {purchaseTransaction.vehicle.number}
                    </Typography>
                </Box>
                <Box className={styles.body} display="flex">
                    <Grid spacing={1} container>
                        <Grid xs={12} item>
                            <Typography className={commonStyles.smallFontSize}>
                                {t('common:formats.longDate', {
                                    date: new Date(purchaseTransaction.updatedAt),
                                })}
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                {purchaseTransaction.vehicle.mileage.toLocaleString()}
                                &nbsp;
                                {t('common:shortKilometer')}
                            </Typography>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.smallFontSize, styles.label)} color="secondary">
                                    {t('saleTransactionsPage:label.latestValidation')}:
                                </Typography>
                                <Typography className={commonStyles.smallFontSize}>
                                    {t('common:currency')}
                                    {purchaseTransaction.vehicle.mileage.toLocaleString()}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.smallFontSize, styles.label)}>
                                    {t('saleTransactionsPage:label.createdOn')}:
                                </Typography>
                                <Typography className={commonStyles.smallFontSize}>
                                    {t('common:formats.longDate', {
                                        date: new Date(purchaseTransaction.createdAt),
                                    })}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <img alt="carLogo" className={commonStyles.vehicleLogo} src={vehicleLogo} />
                </Box>
            </CardContent>
        </Card>
    );
};

export default PurchaseTransactionItem;
