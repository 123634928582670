import { Dialog as MaterialDialog, DialogProps as MDialogProps } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme, withStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import cs from 'classnames';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
        },
        dialogContent: {
            overflowY: 'auto',
            minHeight: '250px',
            maxHeight: '580px',
        },
    })
);

type IDialogProps = MDialogProps & {
    title?: React.ReactNode;
    onCloseClick?: () => void;
    dialogContentClassName?: string;
};

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const Dialog = (props: React.PropsWithChildren<IDialogProps>) => {
    const styles = useStyles();

    const { title, open, onCloseClick, children, maxWidth, dialogContentClassName, ...otherProps } = props;

    return (
        <MaterialDialog maxWidth={maxWidth || 'md'} onClose={onCloseClick} open={open} {...otherProps}>
            <MuiDialogTitle className={styles.root} disableTypography>
                <Typography color="secondary">{title}</Typography>
                {onCloseClick ? (
                    <IconButton className={styles.closeButton} color="secondary" onClick={onCloseClick}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
            <DialogContent className={cs(styles.dialogContent, dialogContentClassName)} dividers>
                {children}
            </DialogContent>
        </MaterialDialog>
    );
};

export default Dialog;
