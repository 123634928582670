import { Box, Typography, useMediaQuery, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '../../../Session';
import { UserType } from '../../../api';
import MobileFilterLink from '../../../components/MobileFilterLink';
import SearchInputField from '../../../components/fields/SearchInputField';
import useCommonStyles from '../../../useCommonStyles';

export type PurchaseTransactionsHeaderProps = {
    search: string;
    title?: string;
    searchOnChange: (searchValue: string) => void;
    setShowFilterDialog: (value: boolean) => void;
    setShowFilterMenu: (value: boolean) => void;
    downloadPdf?: () => void;
    downloadExcel?: () => void;
};

const PurchaseTransactionsHeader = ({
    search,
    title,
    searchOnChange,
    setShowFilterDialog,
    setShowFilterMenu,
    downloadPdf,
    downloadExcel,
}: PurchaseTransactionsHeaderProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const theme = useTheme();
    const history = useHistory();
    const currentUser = useCurrentUser();

    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const canCreate =
        currentUser.type === UserType.Admin ||
        currentUser.type === UserType.SaleConsultant ||
        currentUser.type === UserType.ValuationTeam ||
        currentUser.type === UserType.Approver;

    if (isSmall) {
        return (
            <Box>
                {title && (
                    <Box mb={3}>
                        <Typography className={commonStyles.title}>{title}</Typography>
                    </Box>
                )}
                <Box width="100%">
                    <SearchInputField onChange={searchOnChange} placeholder={t('common:search')} value={search} />
                </Box>
                <Box mt={3}>
                    <Box alignItems="center" display="flex" width="100%">
                        <Box mr="auto">
                            <MobileFilterLink setShowFilterDialog={setShowFilterDialog} />
                        </Box>
                        <Box className={commonStyles.buttonGroup}>
                            {
                                // eslint-disable-next-line max-len
                                isSmall &&
                                    currentUser.type !== UserType.SaleConsultant &&
                                    currentUser.type !== UserType.Dealer && (
                                        <Button
                                            color="secondary"
                                            onClick={downloadPdf}
                                            size="small"
                                            variant="contained"
                                        >
                                            {
                                                // eslint-disable-next-line max-len
                                                <>
                                                    <GetAppIcon
                                                        className={commonStyles.normalFontSize}
                                                        fontSize="small"
                                                    />{' '}
                                                    {t('common:pdf')}
                                                </>
                                            }
                                        </Button>
                                    )
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <Box alignItems="center" className={commonStyles.header} display="flex" justifyContent="flex-end">
            {title && (
                <Box>
                    <Typography className={commonStyles.title}>{title}</Typography>
                </Box>
            )}
            <Box ml="auto">
                <SearchInputField onChange={searchOnChange} placeholder={t('common:search')} value={search} />
            </Box>
            <Box className={cs(commonStyles.buttonGroup, commonStyles.buttonGroupHeader)}>
                <Button color="secondary" onClick={() => setShowFilterMenu(true)} size="large" variant="contained">
                    {t('purchaseTransactionsPage:filterBy')} <ExpandMoreIcon fontSize="small" />
                </Button>

                {currentUser.type !== UserType.SaleConsultant && currentUser.type !== UserType.Dealer && (
                    <>
                        <Button color="secondary" onClick={downloadExcel} size="large" variant="contained">
                            <GetAppIcon fontSize="small" /> {t('common:exportInExcel')}
                        </Button>
                        <Button color="secondary" onClick={downloadPdf} size="large" variant="contained">
                            <GetAppIcon fontSize="small" /> {t('common:exportInPdf')}
                        </Button>
                    </>
                )}
                {canCreate && (
                    <Button
                        color="secondary"
                        onClick={() => history.push('/purchaseTransaction/create')}
                        size="large"
                        variant="contained"
                    >
                        <AddIcon fontSize="small" /> {t('common:create')}
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default PurchaseTransactionsHeader;
