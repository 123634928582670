import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { FullPurchaseTransactionDataFragment } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import ContentWithLabel from '../../../../components/ContentWithLabel';
import FileList from '../../../../components/attachments/FileList';

export type HandoverTabProps = {
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

const HandoverTab = ({ purchaseTransaction }: HandoverTabProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common']);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const useCardDesignForAccordion = !isSmall;

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion
                    title={t('purchaseTransactionsPage:accordionTitle.handoverDetails')}
                    useCardDesign={useCardDesignForAccordion}
                    defaultExpanded
                >
                    <Grid spacing={1} container>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.handover.personResponsible.fullName}
                                label={t('purchaseTransactionsPage:handover.personResponsible')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.handover.targetHandoverDateTime
                                        ? t('common:formats.shortDate', {
                                              date: new Date(purchaseTransaction.handover.targetHandoverDateTime),
                                          })
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:handover.targetHandoverDate')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.handover.targetHandoverDateTime
                                        ? t('common:formats.time', {
                                              date: new Date(purchaseTransaction.handover.targetHandoverDateTime),
                                          })
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:handover.targetHandoverTime')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.handover.handoverLocation || t('common:blankValue')}
                                label={t('purchaseTransactionsPage:handover.handoverLocation')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.handover.actualHandoverDate
                                        ? t('common:formats.time', {
                                              date: new Date(purchaseTransaction.handover.actualHandoverDate),
                                          })
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:handover.actualHandoverDate')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.vehicle.purchaseAgreementNumber
                                        ? purchaseTransaction.vehicle.purchaseAgreementNumber
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:vehicle.purchaseAgreementNumber')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.vehicle.saleAgreementNumber
                                        ? purchaseTransaction.vehicle.saleAgreementNumber
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:vehicle.saleAgreementNumber')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.handover.remarks || t('common:blankValue')}
                                display="block"
                                label={t('purchaseTransactionsPage:handover.handoverRemarks')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.handover.vpaAttachment ? (
                                        <FileList files={[purchaseTransaction.handover.vpaAttachment]} />
                                    ) : (
                                        <Typography>{t('common:blankValue')}</Typography>
                                    )
                                }
                                display="block"
                                label={t('purchaseTransactionsPage:handover.vpaAttachment')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.handover.attachments.length > 0 ? (
                                        <FileList files={purchaseTransaction.handover.attachments} />
                                    ) : (
                                        <Typography>{t('common:blankValue')}</Typography>
                                    )
                                }
                                display="block"
                                label={t('purchaseTransactionsPage:handover.otherAttachments')}
                            />
                        </Grid>
                    </Grid>
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default HandoverTab;
