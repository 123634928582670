import React from 'react';
import { PreviewSaleTransactionDataFragment } from '../../../api';
import ListPagination from '../../../components/ListPagination';
import NoRecordsFound from '../../../components/NoRecordsFound';
import { SaleTransactionsBody } from '../../SaleTransactions/components';

export type SalesTransactionsTabProps = {
    saleTransactions: PreviewSaleTransactionDataFragment[];
    page: number;
    count: number;
    pageSize: number;
    setActivePage: (page: number) => void;
};

const SalesTransactionsTab = ({
    saleTransactions,
    page,
    pageSize,
    count,
    setActivePage,
}: SalesTransactionsTabProps) => {
    if (count === 0) {
        return <NoRecordsFound />;
    }

    return (
        <>
            <SaleTransactionsBody saleTransactions={saleTransactions} hideAwardedBitAmount hideAwardedDealer />
            <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
        </>
    );
};

export default SalesTransactionsTab;
