import React from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../../../components/Accordion';
import SessionForm from '../../Forms/SessionForm';

const CreateSessionAccordion = () => {
    const { t } = useTranslation(['biddingSessionsPage']);

    return (
        <Accordion title={t('biddingSessionsPage:accordionTitle.biddingSession')} defaultExpanded useCardDesign>
            <SessionForm />
        </Accordion>
    );
};

export default CreateSessionAccordion;
