import { AppBar, Toolbar, Box, Typography, useMediaQuery, Button, Link } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import useBasicStyles from '../../../../../layouts/BasicLayout/useBasicStyles';
import useCommonStyles from '../../../../../useCommonStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cancelButton: {
            marginRight: '10px',
        },
    })
);

type EditBiddingSessionDetailsHeaderProps = {
    goToView: () => void;
};

const EditBiddingSessionDetailsHeader = ({ goToView }: EditBiddingSessionDetailsHeaderProps) => {
    const { t } = useTranslation(['biddingSessionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const basicStyles = useBasicStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = useStyles();

    if (isSmall) {
        return (
            <AppBar
                className={cs(basicStyles.appBar, basicStyles.lightBoxShadow, basicStyles.appBarShift)}
                elevation={0}
                position="fixed"
            >
                <Toolbar className={basicStyles.toolBar}>
                    <Box zIndex={1}>
                        <Link color="secondary" component="a" onClick={goToView} underline="none">
                            {t('common:cancel')}
                        </Link>
                    </Box>
                    <Box display="flex" justifyContent="center" position="absolute" width="100%">
                        <Typography className={cs(commonStyles.bigFontSize)} color="secondary">
                            {t('biddingSessionsPage:editTitle')}
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        );
    }

    return (
        <Box alignItems="center" className={commonStyles.header} display="flex">
            <Typography className={commonStyles.title}>{t('biddingSessionsPage:editTitle')}</Typography>
            <Box ml="auto">
                <Button className={styles.cancelButton} color="secondary" onClick={goToView} variant="outlined">
                    {t('common:cancel')}
                </Button>
                <Button color="secondary" type="submit" variant="contained">
                    {t('common:save')}
                </Button>
            </Box>
        </Box>
    );
};

export default EditBiddingSessionDetailsHeader;
