import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { SignUpMutationVariables, UserType, useSignUpMutation } from '../../../../api';
import FormAutoTouch from '../../../../components/FormAutoTouch';
import { useHandleError } from '../../../../utilities/handleErrors';
import { defaultOtherBrandOption } from '../../../../utilities/useBrandOptions';
import useValidator from '../../../../utilities/useValidator';
import validators from '../../../../utilities/validators';
import SignUpFormFields from './SignUpFormFields';
import SignUpFormSubmit from './SignUpFormSubmit';

export type SignUpFormValues = {
    fullName: string;
    password: string;
    confirmPassword: string;
    email: string;
    brand: { main: string; other?: string };
    mobilePhone: { internationalCode: string; number: string };
    type: UserType | '';
    business?: {
        name: string;
        address: string;
        registrationNumber: string;
    };
};

const formValidator = validators.compose(
    validators.requiredString('fullName'),
    validators.requiredString('password'),
    validators.requiredString('confirmPassword'),
    validators.passwordMatch('confirmPassword', 'password'),
    validators.requiredString('email'),
    validators.validEmail('email'),
    validators.requiredString('mobilePhone.number'),
    validators.validPhone('mobilePhone.number'),
    validators.requiredString('type'),

    // validation for sale consultant
    validators.only(
        ({ type }) => type === UserType.SaleConsultant,
        validators.compose(
            validators.requiredString('brand.main'),
            validators.only(
                ({ brand: { main } }) => main === defaultOtherBrandOption,
                validators.requiredString('brand.other')
            )
        )
    ),

    // validation for dealer
    validators.only(
        ({ type }) => type === UserType.Dealer,
        validators.compose(
            validators.requiredString('business.name'),
            validators.requiredString('business.address'),
            validators.requiredString('business.registrationNumber')
        )
    )
);

export type SignUpFormProps = {
    next: ({ values, token }: { values: SignUpFormValues; token: string }) => void;
    initialValues: SignUpFormValues;
};

export const prepareSignUpPayload = (values: SignUpFormValues): SignUpMutationVariables['fields'] => {
    // omit confirmPassword and extract brand
    const { confirmPassword, brand, type, ...fields } = values;

    if (!type) {
        throw new Error('User type missing in data');
    }

    // override brand to keep a single string
    return { ...fields, type, brand: brand.other || brand.main };
};

const fieldsRemap = { brand: 'brand.main' };

const SignUpForm = ({ next, initialValues }: SignUpFormProps) => {
    const [signUp] = useSignUpMutation();

    const onSubmit = useHandleError(
        async (values: SignUpFormValues) => {
            const fields = prepareSignUpPayload(values);
            const { data } = await signUp({ variables: { fields } });

            next({
                // reset confirm password
                values: { ...values, confirmPassword: '' },
                token: data.registerNewAccount,
            });
        },
        [next, signUp],
        fieldsRemap
    );

    const validate = useValidator(formValidator);

    return (
        <Grid xs={12} item>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
                {() => (
                    <Form>
                        <FormAutoTouch />
                        <Grid spacing={2} container>
                            <SignUpFormFields />
                            <SignUpFormSubmit />
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    );
};

export default SignUpForm;
