import { Grid, Box, Button, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useField, useFormikContext } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PreviewSaleTransactionDataFragment, useGetSaleTransactionsQuery } from '../../../../../api';
import Dialog from '../../../../../components/Dialog';
import { FormValues } from '../../../CreateBiddingSession';
import CreateSessionVehicleListingItem from './CreateSessionVehicleListingItem';

export type SessionTransactionsBodyProps = {
    open: boolean;
    onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        items: {
            padding: theme.spacing(2),
            paddingTop: 0,
        },
        dialogActions: {
            marginTop: theme.spacing(3),
        },
        cardFormControlLabel: {
            width: '100%',
        },
    })
);

const CreateSessionVehicleListings = ({ open, onClose }: SessionTransactionsBodyProps) => {
    const [selectAll, setSelectAll] = useState(false);

    const { t } = useTranslation(['common', 'biddingSessionsPage']);
    const { setFieldValue } = useFormikContext<FormValues>();

    const [{ value: selectedTransactions }, , { setValue }] =
        useField<PreviewSaleTransactionDataFragment[]>('saleTransactions');

    const styles = useStyles();

    const { data } = useGetSaleTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            page: { limit: 99999, offset: 0 },
            filter: { forNewBiddingSessions: true },
        },
    });

    const saleTransactions = useMemo(() => data?.saleTransactions.items || [], [data?.saleTransactions.items]);

    const onSubmit = useCallback(() => {
        setFieldValue('saleTransactions', selectAll ? saleTransactions : selectedTransactions);

        onClose();
    }, [onClose, saleTransactions, selectAll, selectedTransactions, setFieldValue]);

    const markAllSelectUnselect = useCallback(() => {
        const isChecked = !selectAll;

        setValue(isChecked ? [...saleTransactions] : []);
        setSelectAll(isChecked);
    }, [saleTransactions, selectAll, setValue]);

    const getCheckboxControl = useCallback(
        () => <Checkbox checked={selectAll} onChange={markAllSelectUnselect} />,
        [markAllSelectUnselect, selectAll]
    );

    return (
        <Dialog maxWidth="md" onCloseClick={onClose} open={open} title={t('common:select')} fullWidth>
            <FormControlLabel control={getCheckboxControl()} label={t('biddingSessionsPage:selectAll')} />
            <Box className={styles.items} mb={3} mt={3}>
                <Grid spacing={2} container>
                    {saleTransactions.map(saleTransaction => {
                        const isSelected = selectedTransactions.some(selected => selected.id === saleTransaction.id);

                        const onChange = () => {
                            const nextValue = isSelected
                                ? selectedTransactions.filter(transaction => transaction.id !== saleTransaction.id)
                                : [...selectedTransactions, saleTransaction];

                            setValue(nextValue);
                            setSelectAll(nextValue.length === saleTransactions.length);
                        };

                        const card = <CreateSessionVehicleListingItem sessionTransaction={saleTransaction} />;
                        const checkbox = <Checkbox checked={isSelected} onChange={onChange} />;

                        return (
                            <Grid key={saleTransaction.id} sm={6} xs={12} item>
                                <FormControlLabel
                                    key={saleTransaction.id}
                                    className={styles.cardFormControlLabel}
                                    classes={{
                                        label: styles.cardFormControlLabel,
                                    }}
                                    control={checkbox}
                                    label={card}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid className={styles.dialogActions} xs={12} item>
                    <FormControl fullWidth>
                        <Button color="secondary" onClick={onSubmit} variant="contained">
                            {t('common:select')}
                        </Button>
                    </FormControl>
                </Grid>
            </Box>
        </Dialog>
    );
};

export default CreateSessionVehicleListings;
