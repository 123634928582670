import { Box, Typography, useMediaQuery, Link, Paper, Button } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import cs from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import MobileAppBar from '../../../components/MobileAppBar';
import useBasicStyles from '../../../layouts/BasicLayout/useBasicStyles';
import useCommonStyles from '../../../useCommonStyles';

export type EditSaleTransactionHeaderProps = {
    goToView: () => void;
};

const useHeaderStyles = makeStyles(theme => ({
    header: {
        marginBottom: 0,
        paddingBottom: 0,
    },
}));

const EditSaleTransactionHeader = ({ goToView }: EditSaleTransactionHeaderProps) => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const basicStyles = useBasicStyles();
    const headerStyles = useHeaderStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const { isSubmitting } = useFormikContext();

    if (isSmall) {
        return (
            <MobileAppBar>
                <Box zIndex={1}>
                    <Link className={commonStyles.primary2} component="button" onClick={goToView} underline="none">
                        {t('common:cancel')}
                    </Link>
                </Box>
                <Box display="flex" justifyContent="center" position="absolute" pr={2} width="100%">
                    <Typography className={commonStyles.fontWeightBold}>
                        {t('saleTransactionsPage:editTitle')}
                    </Typography>
                </Box>
                <Box ml="auto" zIndex={1}>
                    <Link className={commonStyles.primary2} component="button" type="submit" underline="none">
                        {t('common:save')}
                    </Link>
                </Box>
            </MobileAppBar>
        );
    }

    return (
        <>
            <Paper
                className={cs(basicStyles.backPaper, basicStyles.lightBoxShadow, basicStyles.stickyHeader)}
                elevation={0}
            >
                <Link className={cs(basicStyles.linkBack)} component="a" onClick={goToView}>
                    <ArrowBackIosIcon fontSize="small" /> {t('common:portalBackButton')}
                </Link>

                <Box className={basicStyles.rightToolbar} ml="auto">
                    <Box className={cs([commonStyles.buttonGroup])}>
                        <Button color="secondary" onClick={goToView} variant="outlined">
                            {t('common:cancel')}
                        </Button>

                        <Button color="secondary" disabled={isSubmitting} type="submit" variant="contained">
                            {t('common:save')}
                        </Button>
                    </Box>
                </Box>
            </Paper>
            <Box
                alignItems="center"
                className={cs([basicStyles.main, commonStyles.header, headerStyles.header])}
                display="flex"
            >
                <Typography className={commonStyles.title}>{t('saleTransactionsPage:editTitle')}</Typography>
            </Box>
        </>
    );
};

export default EditSaleTransactionHeader;
