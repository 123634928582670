import { Grid, Box } from '@material-ui/core';
import { PreviewPurchaseTransactionDataFragment } from '../../../api';
import { PurchaseTransactionsItem } from '.';

export type PurchaseTransactionsBodyProps = {
    purchaseTransactions: PreviewPurchaseTransactionDataFragment[];
};

const PurchaseTransactionsBody = ({ purchaseTransactions }: PurchaseTransactionsBodyProps) => (
    <Box mb={3} mt={3}>
        <Grid spacing={2} container>
            {purchaseTransactions.map(purchaseTransaction => (
                <PurchaseTransactionsItem key={purchaseTransaction.id} purchaseTransaction={purchaseTransaction} />
            ))}
        </Grid>
    </Box>
);

export default PurchaseTransactionsBody;
