import { Route, Switch, Redirect } from 'react-router-dom';
import { useSession } from './Session';
import { UserActivationStage } from './api';
import ActivityHistoryRoute from './routes/ActivityHistoryRoute';
import ApprovalsRoute from './routes/ApprovalsRoute';
import ApproverBiddingSessionsRoute from './routes/ApproverBiddingSessionsRoute';
import BiddingSessionsRoute from './routes/BiddingSessions';
import BiddingSessionsDetailRoute from './routes/BiddingSessions/BiddingSessionsDetailRoute';
import CreateBiddingSessionsRoute from './routes/BiddingSessions/CreateBiddingSessionsRoute';
import { AllBiddingSessionsRoute, MyBidsRoute } from './routes/DealerBidsRoute';
import HomeRoute from './routes/HomeRoute';
import NotFoundRoute from './routes/NotFoundRoute';
import NotificationsRoute from './routes/NotificationsRoute';
import ProfileRoute from './routes/ProfileRoute';
import CreatePurchaseTransactionRoute from './routes/PurchaseTransactions/CreatePurchaseTransactionRoute';
import PurchaseTransactionDetailRoute from './routes/PurchaseTransactions/PurchaseTransactionDetailRoute';
import PurchaseTransactionsRoute from './routes/PurchaseTransactions/PurchaseTransactionsRoute';
import SaleTransactionsRoute from './routes/SaleTransactions';
import CreateSaleTransactionRoute from './routes/SaleTransactions/CreateSaleTransactionRoute';
import SaleTransactionDetailRoute from './routes/SaleTransactions/SaleTransactionDetailRoute';
import SettingsRoute from './routes/SettingsRoute';
import TransactionApprovalsRoute from './routes/TransactionApprovalsRoute';
import TransactionsRoute from './routes/TransactionsRoute';
import UsersRoute from './routes/UsersList';

const MainRouter = () => {
    const { currentUser } = useSession();

    if (!currentUser) {
        // data is still loading
        return null;
    }

    if (currentUser.activationStage !== UserActivationStage.Active) {
        return <span>todo: activation pending or account disabled page</span>;
    }

    return (
        <Switch>
            <Redirect path="/signIn" to="/" />
            <Route component={HomeRoute} path="/" exact />
            <Route component={ProfileRoute} path="/profile" exact />
            <Route component={ApprovalsRoute} path="/approvals" exact />
            <Route component={UsersRoute} path="/users" exact />
            <Route component={PurchaseTransactionsRoute} path="/purchaseTransactions" exact />
            <Route component={CreatePurchaseTransactionRoute} path="/purchaseTransaction/create" exact />
            <Route component={PurchaseTransactionDetailRoute} path="/purchaseTransaction/:transactionId" exact />
            <Route component={SaleTransactionsRoute} path="/salesTransactions" exact />
            <Route component={CreateSaleTransactionRoute} path="/salesTransactions/create" exact />
            <Route component={SaleTransactionDetailRoute} path="/salesTransactions/:id" exact />
            <Route component={BiddingSessionsRoute} path="/biddingSessions" exact />
            <Route component={CreateBiddingSessionsRoute} path="/biddingSession/create" exact />
            <Route component={BiddingSessionsDetailRoute} path="/biddingSession/:biddingId" exact />
            <Route component={SettingsRoute} path="/settings" exact />
            <Route component={NotificationsRoute} path="/notifications" exact />
            <Route component={TransactionApprovalsRoute} path="/transactionApprovals" exact />
            <Route component={ApproverBiddingSessionsRoute} path="/approverBiddingSessions" exact />
            <Route component={TransactionsRoute} path="/myTransactions" exact />
            <Route component={MyBidsRoute} path="/myBids" exact />
            <Route component={AllBiddingSessionsRoute} path="/allBiddingSessions" exact />
            <Route component={ActivityHistoryRoute} path="/activityHistory" exact />
            <Route component={NotFoundRoute} />
        </Switch>
    );
};

export default MainRouter;
