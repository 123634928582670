import { Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { FullPurchaseTransactionDataFragment } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import ContentWithLabel from '../../../../components/ContentWithLabel';
import useDeliveryPeriod from '../../../../utilities/useDeliveryPeriod';

export type VehicleTabProps = {
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

const VehicleTab = ({ purchaseTransaction }: VehicleTabProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common']);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const deliveryPeriod = useDeliveryPeriod(purchaseTransaction.handover?.deliveryPeriod);
    const useCardDesignForAccordion = !isSmall;

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion
                    title={t('purchaseTransactionsPage:accordionTitle.vehicleDetails')}
                    useCardDesign={useCardDesignForAccordion}
                    defaultExpanded
                >
                    <Grid spacing={1} container>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.vehicle.number}
                                label={t('purchaseTransactionsPage:vehicle.vehicleNo')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.vehicle.mileage.toLocaleString()}
                                label={t('purchaseTransactionsPage:vehicle.mileage')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.customer.ownerIdType}
                                label={t('purchaseTransactionsPage:owner.ownerIdType')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.customer.ownerId}
                                label={t('purchaseTransactionsPage:owner.ownerId')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.vehicle.beExported ? t('common:yes') : t('common:no')}
                                label={t('purchaseTransactionsPage:vehicle.vehicleToBeExported')}
                            />
                        </Grid>

                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={deliveryPeriod}
                                label={t('purchaseTransactionsPage:handover.deliveryPeriod')}
                            />
                        </Grid>

                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={t('common:formats.shortDate', {
                                    date: new Date(purchaseTransaction.vehicle.intendedDeregistrationDate),
                                })}
                                label={t('purchaseTransactionsPage:vehicle.intendedDeregistrationDate')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.vehicle.make}
                                label={t('purchaseTransactionsPage:vehicle.vehicleMake')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.vehicle.model}
                                label={t('purchaseTransactionsPage:vehicle.vehicleModel')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.vehicle.primaryColour}
                                label={t('purchaseTransactionsPage:vehicle.primaryColour')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.vehicle.secondaryColour}
                                label={t('purchaseTransactionsPage:vehicle.secondaryColour')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.vehicle.manufacturingYear.toString()}
                                label={t('purchaseTransactionsPage:vehicle.manufacturingYear')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.vehicle.engineNumber}
                                label={t('purchaseTransactionsPage:vehicle.engineNo')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.vehicle.chassisNumber}
                                label={t('purchaseTransactionsPage:vehicle.chassisNo')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.vehicle.maximumPowerOutput}
                                label={t('purchaseTransactionsPage:vehicle.maxPowerOutput')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.vehicle.openMarketValue.toLocaleString()}
                                label={t('purchaseTransactionsPage:vehicle.openMarketValue')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={t('common:formats.shortDate', {
                                    date: new Date(purchaseTransaction.vehicle.originalRegistrationDate),
                                })}
                                label={t('purchaseTransactionsPage:vehicle.originalRegistrationDate')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={t('common:formats.shortDate', {
                                    date: new Date(purchaseTransaction.vehicle.firstRegistrationDate),
                                })}
                                label={t('purchaseTransactionsPage:vehicle.firstRegistrationDate')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.vehicle.transferCount.toLocaleString()}
                                label={t('purchaseTransactionsPage:vehicle.transferCount')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.vehicle.actualARFPaid.toLocaleString()}
                                label={t('purchaseTransactionsPage:vehicle.actualArfPaid')}
                            />
                        </Grid>
                        {(purchaseTransaction.vehicle.opcCashRebateEligibility ||
                            purchaseTransaction.vehicle.opcCashRebateEligibilityExpiryDate ||
                            purchaseTransaction.vehicle.opcCashRebateAmount) && (
                            <>
                                <Grid xs={12} item>
                                    <ContentWithLabel
                                        content={purchaseTransaction.vehicle.opcCashRebateEligibility}
                                        label={t('purchaseTransactionsPage:vehicle.opcCashRebateEligibility')}
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <ContentWithLabel
                                        content={
                                            purchaseTransaction.vehicle.opcCashRebateEligibilityExpiryDate
                                                ? t('common:formats.shortDate', {
                                                      date: new Date(
                                                          purchaseTransaction.vehicle.opcCashRebateEligibilityExpiryDate
                                                      ),
                                                  })
                                                : t('common:blankValue')
                                        }
                                        label={t('purchaseTransactionsPage:vehicle.opcCashRebateEligibilityExpiryDate')}
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <ContentWithLabel
                                        content={
                                            purchaseTransaction.vehicle.opcCashRebateAmount
                                                ? purchaseTransaction.vehicle.opcCashRebateAmount.toLocaleString()
                                                : t('common:blankValue')
                                        }
                                        label={t('purchaseTransactionsPage:vehicle.opcCashRebateAmount')}
                                    />
                                </Grid>
                            </>
                        )}

                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.vehicle.parfEligibility ? t('common:yes') : t('common:no')}
                                label={t('purchaseTransactionsPage:vehicle.parfEligibility')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.vehicle.parfEligibilityDate
                                        ? t('common:formats.shortDate', {
                                              date: new Date(purchaseTransaction.vehicle.parfEligibilityDate),
                                          })
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:vehicle.parfEligibilityExpiryDate')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.vehicle.parfRebateAmount
                                        ? purchaseTransaction.vehicle.parfRebateAmount.toLocaleString()
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:vehicle.parfRebateValue')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={t('common:formats.shortDate', {
                                    date: new Date(purchaseTransaction.vehicle.coeExpiryDate),
                                })}
                                label={t('purchaseTransactionsPage:vehicle.coeExpiryDate')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.vehicle.coeCategory}
                                label={t('purchaseTransactionsPage:vehicle.coeCategory')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={purchaseTransaction.vehicle.coePeriodYear.toLocaleString()}
                                label={t('purchaseTransactionsPage:vehicle.coePeriod')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.vehicle.qpPaid
                                        ? purchaseTransaction.vehicle.qpPaid.toLocaleString()
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:vehicle.qpPaid')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.vehicle.pqpPaid
                                        ? purchaseTransaction.vehicle.pqpPaid.toLocaleString()
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:vehicle.pqpPaid')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.vehicle.coeRebateAmount
                                        ? purchaseTransaction.vehicle.coeRebateAmount.toLocaleString()
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:vehicle.coeRebateAmount')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.vehicle.totalRebateAmount
                                        ? purchaseTransaction.vehicle.totalRebateAmount.toLocaleString()
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:vehicle.totalRebateAmount')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.vehicle.ltaMessage
                                        ? purchaseTransaction.vehicle.ltaMessage
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:vehicle.ltaMessage')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.vehicle.importMethod
                                        ? purchaseTransaction.vehicle.importMethod
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:vehicle.importMethod')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.vehicle.setOfKeys
                                        ? purchaseTransaction.vehicle.setOfKeys.toLocaleString()
                                        : t('common:blankValue')
                                }
                                label={t('purchaseTransactionsPage:vehicle.setOfKeys')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    purchaseTransaction.vehicle.ownerHandbookCollected
                                        ? t('common:yes')
                                        : t('common:no')
                                }
                                label={t('purchaseTransactionsPage:vehicle.ownerHandbookCollected')}
                            />
                        </Grid>
                    </Grid>
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default VehicleTab;
