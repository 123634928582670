import { createContext, ReactElement, ReactNode, useContext } from 'react';
import { FlutterInterface } from './flutterInterface';

export type RuntimeConfig = {
    version: string;
    publicPath: string;

    timeZone: string;
    countryCode: string;

    locales: string[];
    defaultLocale: string;

    sentry: {
        dsn?: string;
        release?: string;
        environment?: string;
        tracing: boolean;
        tracesSampleRate: number;
    };

    flutterInterface: FlutterInterface;
};

export type RemoteRuntimeConfig = Omit<RuntimeConfig, 'flutterInterface'>;

export const RuntimeContext = createContext<RuntimeConfig>(null);

export const useRuntimeConfig = () => useContext(RuntimeContext);

export type RuntimeProviderProps = {
    runtime: RuntimeConfig;
    children: ReactElement | ReactNode;
};

export const RuntimeProvider = ({ runtime, children }: RuntimeProviderProps) => (
    <RuntimeContext.Provider value={runtime}>{children}</RuntimeContext.Provider>
);
