import { Card, CardHeader, CardContent } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import { ReactElement, ReactNode } from 'react';
import useCommonStyles from '../../../useCommonStyles';

export type ProfileFormContentProps = {
    children: ReactElement | ReactNode | null;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            [theme.breakpoints.down('sm')]: {
                marginTop: theme.spacing(16),
                marginBottom: theme.spacing(2),
            },
        },
        cardContent: {
            paddingLeft: theme.spacing(2.5),
            paddingRight: theme.spacing(2.5),
        },
        profileHeader: {
            backgroundColor: theme.palette.primary.main,
            height: theme.spacing(15),
            [theme.breakpoints.down('sm')]: {
                height: theme.spacing(0),
                paddingBottom: 0,
                paddingTop: 0,
            },
        },
    })
);

const ProfileContainer = ({ children }: ProfileFormContentProps): ReactElement => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();

    return (
        <Card className={cs(styles.main, commonStyles.cardBoxShadow)}>
            <CardHeader className={styles.profileHeader} />
            <CardContent className={styles.cardContent}>{children}</CardContent>
        </Card>
    );
};

export default ProfileContainer;
