import { FormControl, FormControlLabel, Checkbox, FormControlLabelProps } from '@material-ui/core';
import { useField } from 'formik';
import { ChangeEvent } from 'react';

export interface BooleanCheckboxProps extends Omit<FormControlLabelProps, 'control'> {
    color?: 'primary' | 'secondary' | 'default';
    name: string;
}

const BooleanCheckbox = ({ name, color, ...props }: BooleanCheckboxProps) => {
    const { disabled } = props;
    const [{ value }, , { setValue }] = useField<boolean>({ name });

    return (
        <FormControl fullWidth>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        color={color}
                        disabled={disabled}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setValue(event.target.checked)}
                    />
                }
                disabled={disabled}
                {...props}
            />
        </FormControl>
    );
};

export default BooleanCheckbox;
