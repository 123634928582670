import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useMemo } from 'react';
import { PurchaseTransactionStage } from '../api';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        initialValuation: {
            color: '#9741D6',
            border: '1px solid #8C4CCF',
        },
        pendingAcceptance: {
            color: '#5DB4EA',
            border: '1px solid #5CB4E9',
        },
        handOver: {
            color: '#E6CD41',
            border: '1px solid #E3CC5C',
        },
        finalValuation: {
            color: '#D5001C',
            border: '1px solid #D5001C',
        },
        pendingApproval: {
            color: '#FF8900',
            border: '1px solid #FFA945',
        },
        completed: {
            color: '#15A764',
            border: '1px solid #15A764',
        },
        cancelled: {
            color: '#8B8B8B',
            border: '1px solid #BFBFBF',
        },
    })
);

const usePurchaseTransactionStatusStyles = (transactionStatus: PurchaseTransactionStage) => {
    const styles = useStyles();

    return useMemo(() => {
        switch (transactionStatus) {
            case PurchaseTransactionStage.InitialValuation:
                return styles.initialValuation;

            case PurchaseTransactionStage.PendingAcceptance:
                return styles.pendingAcceptance;

            case PurchaseTransactionStage.FinalValuation:
                return styles.finalValuation;

            case PurchaseTransactionStage.PendingApproval:
                return styles.pendingApproval;

            case PurchaseTransactionStage.Handover:
                return styles.handOver;

            case PurchaseTransactionStage.Completed:
                return styles.completed;

            case PurchaseTransactionStage.Cancelled:
                return styles.cancelled;

            default:
                return styles.initialValuation;
        }
    }, [transactionStatus, styles]);
};

export default usePurchaseTransactionStatusStyles;
