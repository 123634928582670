import { Grid, Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../components/Accordion';
import { useFlutter } from '../../../components/FlutterProvider';
import Switch from '../../../components/fields/Switch';

const SettingsBody = () => {
    const { t } = useTranslation(['settingsPage']);
    const { capabilities, deviceFingerPrint } = useFlutter();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box ml={isSmall ? 2 : 0} mr={isSmall ? 2 : 0} mt={isSmall ? 12 : 0}>
            <Grid spacing={2} container>
                <Grid lg={6} md={6} sm={12} xs={12} item>
                    <Accordion title={t('settingsPage:accordionTitle.login')} defaultExpanded>
                        <Grid spacing={2} container>
                            <Grid xs={12} item>
                                <Switch
                                    disabled={!(capabilities.hasFingerPrint || capabilities.hasFaceID)}
                                    label={t('settingsPage:label.biometric')}
                                    labelPlacement="start"
                                    name="biometric"
                                />
                            </Grid>
                        </Grid>
                    </Accordion>
                </Grid>
                <Grid lg={6} md={6} sm={12} xs={12} item>
                    <Accordion title={t('settingsPage:accordionTitle.notifications')} defaultExpanded>
                        <Grid spacing={2} container>
                            <Grid xs={12} item>
                                <Switch
                                    disabled={!deviceFingerPrint}
                                    label={t('settingsPage:label.pushNotification')}
                                    labelPlacement="start"
                                    name="pushNotification"
                                />
                            </Grid>
                        </Grid>
                    </Accordion>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SettingsBody;
