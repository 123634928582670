import { Box, IconButton, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../../../../../useCommonStyles';

export type BiddingRulesAndRegulationsHeaderProps = {
    setShowBiddingRulesAndRegulations: (value: boolean) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'fixed',
            width: '100%',
            backgroundColor: '#fff',
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(2),
        },
    })
);

const BiddingRulesAndRegulationsHeader = ({
    setShowBiddingRulesAndRegulations,
}: BiddingRulesAndRegulationsHeaderProps) => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const { t } = useTranslation(['saleTransactionsPage']);

    return (
        <Box className={styles.container}>
            <Box alignItems="center" display="flex" position="relative">
                <Box zIndex={1}>
                    <IconButton color="secondary" onClick={() => setShowBiddingRulesAndRegulations(false)}>
                        <ArrowBackIosIcon className={commonStyles.bigFontSize} />
                    </IconButton>
                </Box>
                <Box display="flex" justifyContent="center" position="absolute" width="100%">
                    <Typography color="secondary">
                        {t('saleTransactionsPage:biddingRulesAndRegulation.title')}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default BiddingRulesAndRegulationsHeader;
