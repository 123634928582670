import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../Session';
import { FullSaleTransactionDataFragment, UserType } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import useCommonStyles from '../../../../useCommonStyles';
import ViewBiddingEntry from './ViewBiddingEntry';

export type TopBidsProps = {
    saleTransaction: FullSaleTransactionDataFragment;
    useCardDesignForAccordion?: boolean;
    title?: string;
};

const TopBids = ({ saleTransaction, useCardDesignForAccordion, title }: TopBidsProps) => {
    const { t } = useTranslation(['saleTransactionsPage']);
    const user = useCurrentUser();

    const commonStyles = useCommonStyles();

    const usedTitle = title ?? t('saleTransactionsPage:sessionDetails.topBids.title');

    const entries = saleTransaction.latestBiddingSessionEntry?.topBiddingEntries ?? [];

    if (user.type !== UserType.Admin && user.type !== UserType.SaleConsultant) {
        // user cannot see biddings
        return null;
    }

    return (
        <Grid xs={12} item>
            <Accordion title={usedTitle} useCardDesign={useCardDesignForAccordion} defaultExpanded>
                <div style={{ width: '100%' }}>
                    {entries?.map((entry, index) => (
                        <ViewBiddingEntry key={entry.id} entry={entry} index={index} />
                    ))}

                    {!entries?.length && (
                        <Typography className={commonStyles.fontWeightLight}>
                            {t('saleTransactionsPage:sessionDetails.topBids.noBids')}
                        </Typography>
                    )}
                </div>
            </Accordion>
        </Grid>
    );
};

export default TopBids;
