import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PurchaseTransactionStage } from '../api';

export const defaultAllOption = 'all';

const usePurchaseTransactionStateFilterOptoins = (allValue = defaultAllOption) => {
    const { t } = useTranslation(['purchaseTransactionsPage']);

    return useMemo(
        () => [
            { value: allValue, label: t('purchaseTransactionsPage:filter.all') },
            {
                value: PurchaseTransactionStage.InitialValuation,
                label: t('purchaseTransactionsPage:filter.initialValuation'),
            },
            {
                value: PurchaseTransactionStage.PendingAcceptance,
                label: t('purchaseTransactionsPage:filter.pendingAcceptance'),
            },
            {
                value: PurchaseTransactionStage.FinalValuation,
                label: t('purchaseTransactionsPage:filter.finalValuation'),
            },
            {
                value: PurchaseTransactionStage.PendingApproval,
                label: t('purchaseTransactionsPage:filter.pendingApproval'),
            },
            { value: PurchaseTransactionStage.Handover, label: t('purchaseTransactionsPage:filter.handover') },
            { value: PurchaseTransactionStage.Completed, label: t('purchaseTransactionsPage:filter.completed') },
            { value: PurchaseTransactionStage.Cancelled, label: t('purchaseTransactionsPage:filter.cancelled') },
        ],
        [allValue, t]
    );
};

export default usePurchaseTransactionStateFilterOptoins;
