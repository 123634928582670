import React, { useState, useCallback } from 'react';
import { PreviewPurchaseTransactionDataFragment, DownloadFileType, PurchaseTransactionStage } from '../../../../api';
import ListPagination from '../../../../components/ListPagination';
import { useLoading } from '../../../../components/LoadingProvider';
import NoRecordsFound from '../../../../components/NoRecordsFound';
import {
    PurchaseTransactionsHeader,
    PurchaseTransactionsBody,
    DownloadListFn,
} from '../../../PurchaseTransactions/components';
import FilterDialog from './FilterDialog';
import FilterMenu from './FilterMenu';

export type PurchaseTransactionsTabProps = {
    search: string;
    purchaseTransactions: PreviewPurchaseTransactionDataFragment[];
    page: number;
    count: number;
    pageSize: number;
    stage: PurchaseTransactionStage | 'all';
    createdAt: Date | null;
    targetHandoverDate: Date | null;
    setStage: (stage: PurchaseTransactionStage | 'all') => void;
    setCreatedAt: (createdAt: Date | null) => void;
    setTargetHandoverDate: (targetHandoverDate: Date | null) => void;
    setActivePage: (page: number) => void;
    searchOnChange: (searchValue: string) => void;
    downloadPurchaseTransactionsFile: DownloadListFn;
};

const PurchaseTransactionsTab = ({
    search,
    purchaseTransactions,
    page,
    pageSize,
    count,
    stage,
    createdAt,
    targetHandoverDate,
    setCreatedAt,
    setStage,
    setTargetHandoverDate,
    searchOnChange,
    setActivePage,
    downloadPurchaseTransactionsFile,
}: PurchaseTransactionsTabProps) => {
    const [showFilterDialog, setShowFilterDialog] = useState<boolean>(false);
    const [showFilterMenu, setShowFilterMenu] = useState<boolean>(false);
    const { attach } = useLoading();

    const downloadFile = useCallback(
        (type: DownloadFileType) => {
            const execute = async () => {
                await downloadPurchaseTransactionsFile({
                    downloadFileType: type,
                    filter: {
                        search,
                        stages: stage === 'all' ? null : [stage],
                        createdAt,
                        handoverAt: targetHandoverDate,
                    },
                    sort: {
                        stagePriority: PurchaseTransactionStage.PendingApproval,
                    },
                });
            };

            attach(execute());
        },
        [attach, createdAt, downloadPurchaseTransactionsFile, search, stage, targetHandoverDate]
    );

    const setValues = useCallback(
        (stage, createdAt, targetHandoverDate) => {
            setStage(stage);
            setCreatedAt(createdAt);
            setTargetHandoverDate(targetHandoverDate);
        },
        [setStage, setCreatedAt, setTargetHandoverDate]
    );

    const header = (
        <PurchaseTransactionsHeader
            downloadExcel={() => downloadFile(DownloadFileType.Excel)}
            downloadPdf={() => downloadFile(DownloadFileType.Pdf)}
            search={search}
            searchOnChange={searchOnChange}
            setShowFilterDialog={setShowFilterDialog}
            setShowFilterMenu={setShowFilterMenu}
        />
    );

    if (count === 0) {
        return (
            <>
                {header}
                <NoRecordsFound />
            </>
        );
    }

    return (
        <>
            <FilterDialog
                createdAt={createdAt}
                open={showFilterDialog}
                setOpen={setShowFilterDialog}
                setValues={setValues}
                stage={stage}
                targetHandoverDate={targetHandoverDate}
            />
            <FilterMenu
                createdAt={createdAt}
                open={showFilterMenu}
                setOpen={setShowFilterMenu}
                setValues={setValues}
                stage={stage}
                targetHandoverDate={targetHandoverDate}
            />
            {header}
            <PurchaseTransactionsBody purchaseTransactions={purchaseTransactions} />
            <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
        </>
    );
};

export default PurchaseTransactionsTab;
