import { Switch, Route, Redirect } from 'react-router-dom';
import LoginRoute from './routes/LoginRoute';
import ResetPasswordRoute from './routes/ResetPasswordRoute';
import SignUpRoute from './routes/SignUpRoute';

const PublicRouter = () => (
    <Switch>
        <Route component={LoginRoute} path="/signIn" exact />
        <Route component={SignUpRoute} path="/signUp" exact />
        <Route component={ResetPasswordRoute} path="/resetPassword" exact />
        <Redirect to="/signIn" />
    </Switch>
);

export default PublicRouter;
