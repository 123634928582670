import { useMediaQuery } from '@material-ui/core';
import { MuiThemeProvider, useTheme } from '@material-ui/core/styles';
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useCurrentUser } from '../../Session';
import { UserType } from '../../api';
import BasicLayout from '../../layouts/BasicLayout';
import basicTheme from '../../layouts/BasicLayout/basicTheme';
import SaleTransactionDetail from '../../pages/SaleTransactions/SaleTransactionDetail';

interface RouteParams {
    id: string;
}

const SaleTransactionDetailRoute = () => {
    const currentUser = useCurrentUser();
    const theme = useTheme();
    const params = useParams<RouteParams>();

    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    if (![UserType.Admin, UserType.SaleConsultant, UserType.Dealer].includes(currentUser.type)) {
        return <Redirect to="/403" />;
    }

    if (isSmall) {
        return (
            <MuiThemeProvider theme={basicTheme}>
                <SaleTransactionDetail id={params.id} />
            </MuiThemeProvider>
        );
    }

    return (
        <BasicLayout disabledPadding>
            <SaleTransactionDetail id={params.id} />
        </BasicLayout>
    );
};

export default SaleTransactionDetailRoute;
