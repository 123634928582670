import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PurchaseTransactionStage, useGetPurchaseTransactionsQuery } from '../../../api';
import { DownloadListFn } from '../../PurchaseTransactions/components';
import PurchaseHandoverTab from './PurchaseHandoverTab';
import { PurchaseHandoverListContext } from './VtTransactions';

const pageSize = 9;

type ScTransactionsProps = {
    downloadPurchaseTransactionsFile: DownloadListFn;
};

const ScTransactions = ({ downloadPurchaseTransactionsFile }: ScTransactionsProps) => {
    const { t } = useTranslation(['common']);
    const [purchaseTransactionStage, setPurchaseTransactionStage] = useState<'all' | PurchaseTransactionStage>('all');
    const [purchaseTransactionCreatedAt, setPurchaseTransactionCreatedAt] = useState<Date | null>(null);
    const [purchaseTransactionTargetHandoverDate, setPurchaseTransactionTargetHandoverDate] = useState<Date | null>(
        null
    );

    const [{ purchaseHandoverPage, purchaseHandoverSearch }, setPurchaseHandoverListContext] =
        useState<PurchaseHandoverListContext>({
            purchaseHandoverSearch: '',
            purchaseHandoverPage: 0,
        });

    const { data: purchaseHandoverData } = useGetPurchaseTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            page: { limit: pageSize, offset: purchaseHandoverPage * pageSize },
            filter: {
                search: purchaseHandoverSearch,
                stages: purchaseTransactionStage === 'all' ? null : [purchaseTransactionStage],
                createdAt: purchaseTransactionCreatedAt,
                handoverAt: purchaseTransactionTargetHandoverDate,
            },
        },
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [purchaseHandoverPage]);

    const setPurchaseHandoverActivePage = useCallback(
        (newPage: number) => {
            setPurchaseHandoverListContext(state => ({ ...state, purchaseHandoverPage: newPage }));
        },
        [setPurchaseHandoverListContext]
    );

    const purchaseHandoverSearchOnChange = useCallback(
        (searchValue: string) => {
            setPurchaseHandoverListContext(state => ({ ...state, purchaseHandoverSearch: searchValue }));
        },
        [setPurchaseHandoverListContext]
    );

    const purchaseHandoverCount = purchaseHandoverData?.purchaseTransactions?.count || 0;
    const purchaseHandoverTransactions = purchaseHandoverData?.purchaseTransactions?.items || [];

    return (
        <PurchaseHandoverTab
            count={purchaseHandoverCount}
            createdAt={purchaseTransactionCreatedAt}
            downloadPurchaseTransactionsFile={downloadPurchaseTransactionsFile}
            page={purchaseHandoverPage}
            pageSize={pageSize}
            purchaseTransactions={purchaseHandoverTransactions}
            search={purchaseHandoverSearch}
            searchOnChange={purchaseHandoverSearchOnChange}
            setActivePage={setPurchaseHandoverActivePage}
            setCreatedAt={setPurchaseTransactionCreatedAt}
            setStage={setPurchaseTransactionStage}
            setTargetHandoverDate={setPurchaseTransactionTargetHandoverDate}
            stage={purchaseTransactionStage}
            targetHandoverDate={purchaseTransactionTargetHandoverDate}
            title={t('common:titles.myTransactions')}
        />
    );
};

export default ScTransactions;
