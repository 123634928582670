import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useImportMethodOptions = () => {
    const { t } = useTranslation(['importMethodOptions']);

    return useMemo(() => {
        const labels = [t('importMethodOptions:agent'), t('importMethodOptions:parallelImport')];

        return labels.map(label => ({ value: label, label }));
    }, [t]);
};

export default useImportMethodOptions;
