import DuplicateSalesTransactionProvider from '../components/DuplicateSalesTransactionProvider';
import CreateOfferSessionForm from './CreateOfferSessionForm';
import CreateSalesTransactionForm from './CreateSalesTransactionForm';
import { Page, useCreateSalesTransactionState } from './CreateSalesTransactionStateProvider';
import VehiclePrefillForm from './VehiclePrefillForm';

const CreateSalesTransactionInner = () => {
    const {
        state: { currentPage },
    } = useCreateSalesTransactionState();

    switch (currentPage) {
        case Page.VehiclePrefill:
            return <VehiclePrefillForm />;

        case Page.VehicleForm:
            return (
                <DuplicateSalesTransactionProvider>
                    {checkDuplicateCarPlate => (
                        <CreateSalesTransactionForm checkDuplicateCarPlate={checkDuplicateCarPlate} />
                    )}
                </DuplicateSalesTransactionProvider>
            );

        default:
            return <CreateOfferSessionForm />;
    }
};

export default CreateSalesTransactionInner;
