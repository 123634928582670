import { Grid, Chip, Box, FormControl } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import OutlinedDateTimePickerField from '../../../../components/fields/OutlinedDateTimePickerField';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import { FormValues } from '../../CreateBiddingSession';
import CreateSessionVehicleListings from '../CreateBiddingSession/components/CreateSessionVehicleListings';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: '24px',

            [theme.breakpoints.down('md')]: {
                padding: 12,
            },
        },
        chooseVehicleListingBtn: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '14px 14px',
            fontSize: '1rem',
        },
        itemTitle: {
            [theme.breakpoints.up('md')]: {
                marginTop: theme.spacing(2.5),
            },
            color: 'rgb(113, 112, 115, 0.8)',
        },
        gridMargin: {
            marginBottom: '24px',
            '&:first-child': {
                marginTop: '24px',
            },
        },
        selectedItems: {
            display: 'flex',
            gap: '20px',
            flexWrap: 'wrap',
            marginTop: '16px',
        },
        chip: {
            border: 'none',
            backgroundColor: '#EDEDED',
        },
    })
);

export type SessionFormProps = {
    editMode?: boolean;
};

const SessionForm = ({ editMode }: SessionFormProps) => {
    const { t } = useTranslation(['biddingSessionsPage', 'common']);
    const { values, setFieldValue } = useFormikContext<FormValues>();

    const { saleTransactions, startPeriod, endPeriod } = values;

    const [open, setOpen] = useState(false);

    const styles = useStyles();

    useEffect(() => {
        if (!startPeriod || !endPeriod || new Date(startPeriod).getTime() >= new Date(endPeriod).getTime()) {
            setFieldValue('endPeriod', null);
        }
    }, [endPeriod, setFieldValue, startPeriod]);

    return (
        <Box className={styles.container} p={3}>
            <Grid spacing={4} container>
                <Grid xs={12} item>
                    <Grid spacing={2} container>
                        <Grid className={styles.itemTitle} lg={2} md={2} sm={12} xs={12}>
                            {t('biddingSessionsPage:session.bidSessionName')}
                        </Grid>
                        <Grid lg={10} md={10} sm={12} xs={12}>
                            <OutlinedInputField
                                color="secondary"
                                name="sessionName"
                                placeholder={t('biddingSessionsPage:session.bidSessionName')}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid xs={12} item>
                    <Grid spacing={2} container>
                        <Grid className={styles.itemTitle} lg={2} md={2} sm={12} xs={12}>
                            {t('biddingSessionsPage:session.bidNotes')}
                        </Grid>
                        <Grid lg={10} md={10} sm={12} xs={12}>
                            <OutlinedInputField
                                color="secondary"
                                name="notes"
                                placeholder={t('biddingSessionsPage:session.enterDescription')}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid xs={12} item>
                    <Grid spacing={2} container>
                        <Grid className={styles.itemTitle} lg={2} md={2} sm={12} xs={12}>
                            {t('biddingSessionsPage:session.vehicleListings')}
                        </Grid>
                        <Grid lg={10} md={10} sm={12} xs={12}>
                            <FormControl fullWidth>
                                {saleTransactions.length > 0 && (
                                    <Box className={styles.selectedItems}>
                                        {saleTransactions.map(saleTransaction => (
                                            <Chip
                                                className={styles.chip}
                                                color="secondary"
                                                label={saleTransaction.vehicle.number}
                                                variant="outlined"
                                            />
                                        ))}
                                    </Box>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid xs={12} item>
                    <Grid spacing={2} container>
                        <Grid className={styles.itemTitle} lg={2} md={2} sm={12} xs={12}>
                            {t('biddingSessionsPage:session.startPeriod')}
                        </Grid>
                        <Grid lg={10} md={10} sm={12} xs={12}>
                            <OutlinedDateTimePickerField
                                color="secondary"
                                disabled={editMode}
                                name="startPeriod"
                                placeholder={t('biddingSessionsPage:session.startPeriod')}
                                disablePast
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid xs={12} item>
                    <Grid spacing={2} container>
                        <Grid className={styles.itemTitle} lg={2} md={2} sm={12} xs={12}>
                            {t('biddingSessionsPage:session.endPeriod')}
                        </Grid>
                        <Grid lg={10} md={10} sm={12} xs={12}>
                            <OutlinedDateTimePickerField
                                color="secondary"
                                disabled={!startPeriod || editMode}
                                minDate={startPeriod ? dayjs(startPeriod).toDate() : ''}
                                name="endPeriod"
                                placeholder={t('biddingSessionsPage:session.endPeriod')}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <CreateSessionVehicleListings onClose={() => setOpen(false)} open={open} />
        </Box>
    );
};

export default SessionForm;
