import { InputBase } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

export interface SearchInputFieldProps {
    placeholder?: string;
    value: string;
    onChange: (value: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            position: 'relative',
            borderRadius: theme.spacing(1),
            backgroundColor: '#F6F6F6',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 'auto',
                backgroundColor: '#fff',
                border: `solid 1px ${theme.palette.secondary.main} !important`,
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: '0.5',
        },
        inputRoot: {
            color: 'inherit',
            minHeight: '3rem',
            width: '100%',
            height: '100%',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            minWidth: '200px',
        },
    })
);

const SearchInputField = ({ placeholder, value, onChange }: SearchInputFieldProps) => {
    const styles = useStyles();

    return (
        <div className={styles.search}>
            <div className={styles.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                classes={{
                    root: styles.inputRoot,
                    input: styles.inputInput,
                }}
                onChange={event => onChange(event.target.value)}
                placeholder={placeholder}
                value={value}
            />
        </div>
    );
};

export default SearchInputField;
