import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useAuthenticateMutation } from '../../../../api';
import { useHandleError } from '../../../../utilities/handleErrors';
import useValidator from '../../../../utilities/useValidator';
import validators from '../../../../utilities/validators';
import LoginFormFields from './LoginFormFields';
import LoginFormFooter from './LoginFormFooter';
import LoginFormSubmit from './LoginFormSubmit';

export type LoginFormValues = {
    mobilePhone: { internationalCode: string; number: string };
    password: string;
};

const formValidator = validators.compose(
    validators.requiredString('mobilePhone.number'),
    validators.validPhone('mobilePhone.number'),
    validators.requiredString('password')
);

export type LoginFormProps = {
    next: ({ values, token }: { values: LoginFormValues; token: string }) => void;
    initialValues: LoginFormValues;
};

const LoginForm = ({ next, initialValues }: LoginFormProps) => {
    const [authenticate] = useAuthenticateMutation();

    const onSubmit = useHandleError(
        async (values: LoginFormValues) => {
            const { data } = await authenticate({ variables: values });

            next({ values, token: data.authenticate });
        },
        [next, authenticate]
    );

    const validate = useValidator(formValidator);

    return (
        <Grid xs={12} item>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
                {({ isSubmitting }) => (
                    <Form>
                        <Grid spacing={2} container>
                            <LoginFormFields />
                            <LoginFormFooter />
                            <LoginFormSubmit isSubmitting={isSubmitting} />
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    );
};

export default LoginForm;
