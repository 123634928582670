import { Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../../components/Accordion';
import VehicleDetailsForm from '../../CreatePurchaseTransaction/components/VehicleDetailsForm';
import { PurchaseTransactionFormValues } from '.';

const VehicleTab = () => {
    const { t } = useTranslation(['purchaseTransactionsPage']);
    const { values } = useFormikContext<PurchaseTransactionFormValues>();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion
                    title={t('purchaseTransactionsPage:accordionTitle.vehicleDetails')}
                    useCardDesign={!isSmall}
                    defaultExpanded
                >
                    <VehicleDetailsForm dataManually={!values.vehicle.isOneMotoringData} onEdition />
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default VehicleTab;
