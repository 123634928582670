import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserActivationStage } from '../api';

const useUserActivationStageOptions = (currentUserStage: UserActivationStage) => {
    const { t } = useTranslation(['usersPage']);

    return useMemo(() => {
        switch (currentUserStage) {
            case UserActivationStage.New:
                return [
                    { value: UserActivationStage.New, label: t('usersPage:status.new') },
                    { value: UserActivationStage.Active, label: t('usersPage:status.approved') },
                    { value: UserActivationStage.Rejected, label: t('usersPage:status.rejected') },
                ];

            case UserActivationStage.Active:
            case UserActivationStage.Disabled:
                return [
                    { value: UserActivationStage.Active, label: t('usersPage:status.enabled') },
                    { value: UserActivationStage.Disabled, label: t('usersPage:status.disabled') },
                ];

            case UserActivationStage.Rejected:
                return [
                    { value: UserActivationStage.Active, label: t('usersPage:status.enabled') },
                    { value: UserActivationStage.Disabled, label: t('usersPage:status.disabled') },
                    { value: UserActivationStage.Rejected, label: t('usersPage:status.rejected') },
                ];

            default:
                return [];
        }
    }, [t, currentUserStage]);
};

export default useUserActivationStageOptions;
