import { MuiThemeProvider, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useCurrentUser } from '../../Session';
import { UserType } from '../../api';
import BasicLayout from '../../layouts/BasicLayout';
import basicTheme from '../../layouts/BasicLayout/basicTheme';
import CreateSaleTransaction from '../../pages/SaleTransactions/CreateSaleTransaction';

const CreateSaleTransactionRoute = () => {
    const currentUser = useCurrentUser();
    const theme = useTheme();

    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    if (![UserType.Admin, UserType.SaleConsultant].includes(currentUser.type)) {
        return <Redirect to="/403" />;
    }

    if (isSmall) {
        return (
            <MuiThemeProvider theme={basicTheme}>
                <CreateSaleTransaction />
            </MuiThemeProvider>
        );
    }

    return (
        <BasicLayout disabledPadding>
            <CreateSaleTransaction />
        </BasicLayout>
    );
};

export default CreateSaleTransactionRoute;
