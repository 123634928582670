import { Box, Grid, Card, CardContent, Typography, CardActionArea } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FullSaleTransactionDataFragment, PreviewSaleTransactionDataFragment } from '../../../../../api';
import useCommonStyles from '../../../../../useCommonStyles';
import useSalesTransactionStatus from '../../../../../utilities/useSalesTransactionStatus';
import useVehicleLogo from '../../../../../utilities/useVehicleLogo';

export type SaleTransactionHistoryCardProps = {
    historicalReferenceTransaction: PreviewSaleTransactionDataFragment;
    saleTransaction: FullSaleTransactionDataFragment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            borderRadius: theme.spacing(1),
            textDecoration: 'none',
        },
        content: {
            padding: '0 !important',
        },
        header: {
            padding: theme.spacing(2),
            borderTopLeftRadius: theme.spacing(1),
            borderTopRightRadius: theme.spacing(1),
            backgroundColor: '#EDEDED',
            '& svg': {
                marginLeft: 'auto',
            },
        },
        body: {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.primary.main,
            borderBottomLeftRadius: theme.spacing(1),
            borderBottomRightRadius: theme.spacing(1),
            color: '#717073',
            '& .MuiGrid-item': {
                opacity: '0.8',
            },
        },
        vehicleInfo: {
            maxWidth: `calc(100% - ${theme.spacing(13)}px)`,
            '& > p': {
                marginBottom: theme.spacing(0.5),
            },
        },
        stage: {
            margin: 0,
        },
        label: {
            marginRight: theme.spacing(1),
        },
        duplicated: {
            backgroundColor: '#B23F51',
        },
    })
);

const SaleTransactionHistoryCard = ({
    saleTransaction,
    historicalReferenceTransaction,
}: SaleTransactionHistoryCardProps) => {
    const { t } = useTranslation(['common', 'saleTransactionsPage', 'historicalReferenceFilterOptions']);
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const vehicleLogo = useVehicleLogo(historicalReferenceTransaction.vehicle.make);
    const transactionStatus = useSalesTransactionStatus(historicalReferenceTransaction.stage);

    return (
        <Card className={styles.card} component={Link} elevation={0}>
            <CardActionArea
                className={styles.card}
                component={Link}
                to={`/salesTransactions/${historicalReferenceTransaction.id}`}
            >
                <CardContent className={styles.content}>
                    <Box className={styles.header}>
                        <Box display="flex">
                            <Box className={styles.vehicleInfo}>
                                <Typography
                                    className={cs(
                                        commonStyles.commonTransactionStatus,
                                        commonStyles.transactionStatus,
                                        styles.stage
                                    )}
                                >
                                    {transactionStatus}
                                </Typography>
                                <Typography className={commonStyles.fontWeightBold}>
                                    {
                                        // eslint-disable-next-line max-len
                                        `${historicalReferenceTransaction.vehicle.make} ${historicalReferenceTransaction.vehicle.model}`
                                    }
                                </Typography>
                                <Typography className={commonStyles.fontWeightLight}>
                                    {historicalReferenceTransaction.vehicle.number}
                                </Typography>
                            </Box>
                            <Box alignItems="center" display="flex" ml="auto">
                                <img alt="carLogo" className={commonStyles.vehicleLogo} src={vehicleLogo} />
                            </Box>
                        </Box>
                    </Box>
                    <Box className={styles.body} display="flex">
                        <Grid spacing={1} container>
                            <Grid xs={12} item>
                                <Typography className={commonStyles.primary2}>
                                    {t('common:formats.longDate', {
                                        date: new Date(historicalReferenceTransaction.vehicle.originalRegistrationDate),
                                    })}
                                    &nbsp;&nbsp;|&nbsp;&nbsp;
                                    {historicalReferenceTransaction.vehicle.mileage.toLocaleString()}
                                    &nbsp;
                                    {t('common:shortKilometer')}
                                </Typography>
                            </Grid>
                            <Grid xs={12} item>
                                <Box display="flex">
                                    <Typography className={commonStyles.secondary3}>
                                        {t('saleTransactionsPage:label.offerDate')}
                                    </Typography>
                                    <Typography
                                        className={cs(
                                            commonStyles.primary2,
                                            commonStyles.autoMarginLeft,
                                            commonStyles.rightTextAlign
                                        )}
                                    >
                                        {t('common:formats.shortDate', {
                                            date: new Date(
                                                historicalReferenceTransaction.awardedBiddingEntry?.placedOn
                                            ),
                                        })}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid xs={12} item>
                                <Box display="flex">
                                    <Typography className={commonStyles.secondary3}>
                                        {t('saleTransactionsPage:label.offerAmount')}
                                    </Typography>
                                    <Typography
                                        className={cs(
                                            commonStyles.primary2,
                                            commonStyles.autoMarginLeft,
                                            commonStyles.rightTextAlign
                                        )}
                                    >
                                        {historicalReferenceTransaction.awardedBiddingEntry?.amount
                                            ? `${t(
                                                  'common:currency'
                                                  // eslint-disable-next-line max-len
                                              )}${historicalReferenceTransaction.awardedBiddingEntry?.amount.toLocaleString()}`
                                            : t('common:blankValue')}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default SaleTransactionHistoryCard;
