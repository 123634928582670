import { Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullSaleTransactionDataFragment } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import HandoverForm from '../../Forms/HandoverForm';

export type HandoverTabProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

const HandoverTab = ({ saleTransaction }: HandoverTabProps) => {
    const { t } = useTranslation(['saleTransactionsPage']);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion
                    title={t('saleTransactionsPage:accordionTitle.handover')}
                    useCardDesign={!isSmall}
                    defaultExpanded
                >
                    <HandoverForm currentStage={saleTransaction.stage} />
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default HandoverTab;
