import { Box, Typography, useMediaQuery, IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MobileAppBar from '../../../components/MobileAppBar';
import useCommonStyles from '../../../useCommonStyles';

const SettingsHeader = () => {
    const { t } = useTranslation(['settingsPage', 'common']);
    const commonStyles = useCommonStyles();
    const history = useHistory();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    if (isSmall) {
        return (
            <MobileAppBar>
                <Box zIndex={1}>
                    <IconButton className={commonStyles.primary2} onClick={history.goBack}>
                        <ArrowBackIosIcon className={commonStyles.bigFontSize} />
                    </IconButton>
                </Box>
                <Box display="flex" justifyContent="center" position="absolute" pr={4} width="100%">
                    <Typography className={commonStyles.fontWeightBold}>{t('settingsPage:title')}</Typography>
                </Box>
            </MobileAppBar>
        );
    }

    return (
        <Box alignItems="center" className={commonStyles.header} display="flex">
            <Typography>{t('settingsPage:title')}</Typography>
        </Box>
    );
};

export default SettingsHeader;
