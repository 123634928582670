import { Box, Grid } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import OTPFormSubmit from './OTPFormSubmit';
import OTPInput from './OTPInput';

export type OTPFormValues = { code: string[] };

export type OTPFormProps = {
    handleSubmit: (values: OTPFormValues, helpers: FormikHelpers<OTPFormValues>) => Promise<void>;
};

const initialValues = { code: ['', '', '', ''] };

const OTPForm = ({ handleSubmit }: OTPFormProps) => (
    <Grid xs={12} item>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {() => (
                <Form>
                    <Box display="flex" justifyContent="center" mb={7}>
                        <OTPInput index={0} autoFocus />
                        <OTPInput index={1} />
                        <OTPInput index={2} />
                        <OTPInput index={3} />
                    </Box>
                    <OTPFormSubmit />
                </Form>
            )}
        </Formik>
    </Grid>
);

export default OTPForm;
