import { Grid } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { useTranslation } from 'react-i18next';
import {
    maxValuationPhotos,
    maxUploadFileSize,
    pdfFileExtension,
    imageFileExtensions,
} from '../../../../../server/schema/constants';
import { useCurrentUser } from '../../../../Session';
import { UserType } from '../../../../api';
import { AttachmentButton, FrontPhotoManager } from '../../../../components/attachments';
import OutlinedInputField from '../../../../components/fields/OutlinedInputField';
import OutlinedSelectField from '../../../../components/fields/OutlinedSelectField';
import useCommonStyles from '../../../../useCommonStyles';
import useGeneralConditionOptions from '../../../../utilities/useGeneralConditionOptions';

const maxFileSizeInKB = maxUploadFileSize * 1000 * 1000;

const allowedExtensions = [pdfFileExtension, ...imageFileExtensions];

const ValuationForm = () => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const generalConditionOptions = useGeneralConditionOptions();
    const currentUser = useCurrentUser();

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={currentUser.type !== UserType.Admin}
                    label={t('purchaseTransactionsPage:valuation.initialValuation')}
                    name="valuation.initialValuation"
                    placeholder={t('purchaseTransactionsPage:valuation.initialValuation')}
                    type="number"
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={
                        currentUser.type !== UserType.Admin &&
                        currentUser.type !== UserType.ValuationTeam &&
                        currentUser.type !== UserType.Approver
                    }
                    label={t('purchaseTransactionsPage:valuation.latestValuation')}
                    name="valuation.latestValuation"
                    placeholder={t('purchaseTransactionsPage:valuation.latestValuation')}
                    type="number"
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    disabled={currentUser.type !== UserType.Admin}
                    label={t('purchaseTransactionsPage:valuation.actualValuation')}
                    name="valuation.actualValuation"
                    placeholder={t('purchaseTransactionsPage:valuation.actualValuation')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    label={t('purchaseTransactionsPage:valuation.valuatedBy')}
                    name="valuation.valuatedBy"
                    placeholder={t('purchaseTransactionsPage:valuation.valuatedBy')}
                    disabled
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedSelectField
                    color="secondary"
                    disabled={
                        currentUser.type !== UserType.Admin &&
                        currentUser.type !== UserType.ValuationTeam &&
                        currentUser.type !== UserType.Approver
                    }
                    inputProps={{
                        classes: {
                            icon: commonStyles.fillSecondary,
                        },
                    }}
                    label={t('purchaseTransactionsPage:valuation.generalCondition')}
                    name="valuation.generalCondition"
                    options={generalConditionOptions}
                    placeholder={t('purchaseTransactionsPage:valuation.generalCondition')}
                />
            </Grid>
            <Grid xs={12} item>
                <FrontPhotoManager attachmentFieldName="valuation.photos" name="frontPagePhoto">
                    {renderPrefix => (
                        <AttachmentButton
                            allowedExtensions={allowedExtensions}
                            buttonText={t('common:upload')}
                            icon={<PublishIcon fontSize="small" />}
                            label={t('purchaseTransactionsPage:valuation.photos')}
                            max={maxValuationPhotos}
                            maxSize={maxFileSizeInKB}
                            name="valuation.photos"
                            renderPrefix={renderPrefix}
                        />
                    )}
                </FrontPhotoManager>
            </Grid>
        </Grid>
    );
};

export default ValuationForm;
