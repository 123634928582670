import React from 'react';
import { useCurrentUser } from '../../Session';
import { UserType } from '../../api';
import { DownloadPurchaseTransactionsFileProvider } from '../PurchaseTransactions/components';
import ScTransactions from './components/ScTransactions';
import VtTransactions from './components/VtTransactions';

const MyTransactions = () => {
    const currentUser = useCurrentUser();

    if (currentUser.type === UserType.ValuationTeam) {
        return (
            <DownloadPurchaseTransactionsFileProvider>
                {downloadFile => <VtTransactions downloadPurchaseTransactionsFile={downloadFile} />}
            </DownloadPurchaseTransactionsFileProvider>
        );
    }

    return (
        <DownloadPurchaseTransactionsFileProvider>
            {downloadFile => <ScTransactions downloadPurchaseTransactionsFile={downloadFile} />}
        </DownloadPurchaseTransactionsFileProvider>
    );
};

export default MyTransactions;
