import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

export type AttachFn<T = any> = (promise: Promise<T>) => Promise<T>;

export type LoadingContext = {
    loading: boolean;
    attach: <T>(promise: Promise<T>) => Promise<T>;
};

const Context = createContext<LoadingContext | null>(null);

export type LoadingProviderProps = {
    children: JSX.Element | ReactNode;
};

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: Math.max(theme.zIndex.modal, theme.zIndex.drawer) + 1,
        color: '#fff',
    },
}));

export const useLoading = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('Loading context not provided');
    }

    return context;
};

export const useLoadingController = (isLoading: boolean) => {
    const { attach } = useLoading();

    useEffect(() => {
        if (!isLoading) {
            return () => undefined;
        }

        let mounted = true;
        let resolveFn: () => void = null;

        const promise = new Promise<void>(resolve => {
            if (!mounted) {
                resolve();
            } else {
                resolveFn = resolve;
            }
        });

        attach(promise);

        return () => {
            mounted = false;

            if (resolveFn) {
                resolveFn();
            }
        };
    }, [isLoading, attach]);
};

export const LoadingController = ({ isLoading = false }) => {
    useLoadingController(isLoading);

    return null;
};

const LoadingProvider = ({ children }: LoadingProviderProps) => {
    const classes = useStyles();
    const [activePromises, setActivePromises] = useState(0);

    const attach = useCallback(
        <T extends unknown>(promise: Promise<T>) => {
            // first add the promise to the state
            setActivePromises(state => state + 1);

            // create complete handler
            const onComplete = () => setActivePromises(state => state - 1);

            // then enhance the promise
            return promise.finally(onComplete);
        },
        [setActivePromises]
    );

    const loading = activePromises > 0;

    const context = useMemo(() => ({ loading, attach }), [attach, loading]);

    return (
        <Context.Provider value={context}>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {children}
        </Context.Provider>
    );
};

export default LoadingProvider;
