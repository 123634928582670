import { ReactNode } from 'react';
import {
    GenerateSalesTransactionsFileDocument as query,
    GenerateSalesTransactionsFileQuery as Query,
    GenerateSalesTransactionsFileQueryVariables as Variables,
} from '../../../api';
import DownloadProvider, { DownloadFn } from '../../../components/DownloadProvider';

export type DownloadListFileFn = DownloadFn<Variables>;

export type DownloadSalesTransactionsFileProviderProps = {
    children: (fn: DownloadListFileFn) => JSX.Element | ReactNode;
};

const getSignedUrl = (response: Query) => response.signedUrl || null;

const DownloadSalesTransactionsFileProvider = ({ children }: DownloadSalesTransactionsFileProviderProps) => (
    <DownloadProvider getSignedUrl={getSignedUrl} query={query}>
        {children}
    </DownloadProvider>
);

export default DownloadSalesTransactionsFileProvider;
