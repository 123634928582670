import React, { useState, useCallback } from 'react';
import { PreviewSaleTransactionDataFragment, DownloadFileType, SaleTransactionStage } from '../../../../api';
import ListPagination from '../../../../components/ListPagination';
import { useLoading } from '../../../../components/LoadingProvider';
import NoRecordsFound from '../../../../components/NoRecordsFound';
import { SaleTransactionsHeader, SaleTransactionsBody, DownloadSaleListFn } from '../../../SaleTransactions/components';
import FilterDialog from './FilterDialog';
import FilterMenu from './FilterMenu';

export type SalesTransactionsTabProps = {
    search: string;
    saleTransactions: PreviewSaleTransactionDataFragment[];
    page: number;
    count: number;
    pageSize: number;
    stage: SaleTransactionStage | 'all';
    createdAt: Date | null;
    targetHandoverDate: Date | null;
    awardedDealer: string;
    setStage: (stage: SaleTransactionStage | 'all') => void;
    setCreatedAt: (createdAt: Date | null) => void;
    setTargetHandoverDate: (targetHandoverDate: Date | null) => void;
    setAwardedDealer: (awardedDealer: string) => void;
    setActivePage: (page: number) => void;
    searchOnChange: (searchValue: string) => void;
    downloadSalesTransactionsFile: DownloadSaleListFn;
};

const SalesTransactionsTab = ({
    search,
    saleTransactions,
    page,
    pageSize,
    count,
    stage,
    createdAt,
    targetHandoverDate,
    awardedDealer,
    setCreatedAt,
    setStage,
    setTargetHandoverDate,
    setAwardedDealer,
    searchOnChange,
    setActivePage,
    downloadSalesTransactionsFile,
}: SalesTransactionsTabProps) => {
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const { attach } = useLoading();

    const downloadFile = useCallback(
        (type: DownloadFileType) => {
            const execute = async () => {
                await downloadSalesTransactionsFile({
                    downloadFileType: type,
                    filter: {
                        search,
                        stages: stage === 'all' ? null : [stage],
                        createdAt,
                        handoverAt: targetHandoverDate,
                        awardedDealer: awardedDealer === 'all' ? null : awardedDealer,
                    },
                    sort: {
                        stagePriority: SaleTransactionStage.PendingApproval,
                    },
                });
            };

            attach(execute());
        },
        [attach, awardedDealer, createdAt, downloadSalesTransactionsFile, search, stage, targetHandoverDate]
    );

    const setValues = useCallback(
        (salesStage, salesCreatedDate, targetHandoverDate, awardedDealer) => {
            setStage(salesStage);
            setCreatedAt(salesCreatedDate);
            setTargetHandoverDate(targetHandoverDate);
            setAwardedDealer(awardedDealer);
        },
        [setStage, setCreatedAt, setTargetHandoverDate, setAwardedDealer]
    );

    const header = (
        <SaleTransactionsHeader
            downloadExcel={() => downloadFile(DownloadFileType.Excel)}
            downloadPdf={() => downloadFile(DownloadFileType.Pdf)}
            search={search}
            searchOnChange={searchOnChange}
            setShowFilterDialog={setShowFilterDialog}
            setShowFilterMenu={setShowFilterMenu}
        />
    );

    if (count === 0) {
        return (
            <>
                {header}
                <NoRecordsFound />
            </>
        );
    }

    return (
        <>
            <FilterDialog
                awardedDealer={awardedDealer}
                createdAt={createdAt}
                open={showFilterDialog}
                setOpen={setShowFilterDialog}
                setValues={setValues}
                stage={stage}
                targetHandoverDate={targetHandoverDate}
            />
            <FilterMenu
                awardedDealer={awardedDealer}
                createdAt={createdAt}
                open={showFilterMenu}
                setOpen={setShowFilterMenu}
                setValues={setValues}
                stage={stage}
                targetHandoverDate={targetHandoverDate}
            />
            {header}
            <SaleTransactionsBody saleTransactions={saleTransactions} hideOpenCloseBidDate />
            <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
        </>
    );
};

export default SalesTransactionsTab;
