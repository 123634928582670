import { CardMedia, Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import usePublic from '../../../utilities/usePublic';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        media: {
            height: '13rem',
            backgroundSize: 'Contain',
            marginBottom: '1rem',
            [theme.breakpoints.up('sm')]: {
                height: '15rem',
            },
        },
    })
);

const ResetPasswordHeader = () => {
    const styles = useStyles();
    const image = usePublic('assets/images/otpValidation.svg');

    return (
        <Grid xs={12} item>
            <CardMedia className={styles.media} image={image} />
        </Grid>
    );
};

export default ResetPasswordHeader;
