import { Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../../components/Accordion';
import TransactionForm from '../../Forms/TransactionForm';

const TransactionTab = () => {
    const { t } = useTranslation(['saleTransactionsPage']);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion
                    title={t('saleTransactionsPage:accordionTitle.transactionDetail')}
                    useCardDesign={!isSmall}
                    defaultExpanded
                >
                    <TransactionForm />
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default TransactionTab;
