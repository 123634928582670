import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FullPurchaseTransactionDataFragment } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import OutlinedFilterField from '../../../../components/fields/OutlinedFilterField';
import useHistoricalReferenceFilterOptions from '../../../../utilities/useHistoricalReferenceFilterOptions';
import HistoricalReferences from './HistoricalReferences';

export type ValuationHistoricProps = {
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

const useStyles = makeStyles(() =>
    createStyles({
        filterSelect: {
            '& .MuiSelect-select:focus': {
                backgroundColor: 'transparent',
            },
            '& > fieldset': {
                border: 'none',
                boxShadow: '0px 1px 5px 1px #e2e2e2',
            },
        },
    })
);

const ValuationHistoric = ({ purchaseTransaction }: ValuationHistoricProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage', 'common', 'historicalReferenceFilterOptions']);
    const styles = useStyles();

    const filterDefaultValue = t('historicalReferenceFilterOptions:make.value');
    const [historicalReferenceFilter, setHistoricalReferenceFilter] = useState<string[]>([]);

    const registrationDate = purchaseTransaction?.vehicle?.originalRegistrationDate;
    const historicalReferenceFilterOptions = useHistoricalReferenceFilterOptions({
        make: purchaseTransaction?.vehicle?.make,
        model: purchaseTransaction?.vehicle?.model,
        registrationDate: registrationDate ? t('common:formats.year', { date: new Date(registrationDate) }) : '',
    });

    useEffect(() => {
        setHistoricalReferenceFilter([filterDefaultValue]);
    }, [filterDefaultValue]);

    const handleHistoricalReferenceFilterChange = useCallback(
        (event: React.ChangeEvent<{ value: string[] }>) => {
            if (event.target.value.length > 0) {
                setHistoricalReferenceFilter(event.target.value);
            }
        },
        [setHistoricalReferenceFilter]
    );

    const handleDeleteHistoricalReferenceFilter = useCallback(
        (value: string) => {
            setHistoricalReferenceFilter(state => state.filter(filter => filter !== value));
        },
        [setHistoricalReferenceFilter]
    );

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Accordion
            title={t('purchaseTransactionsPage:accordionTitle.valuationHistoricalReference')}
            useCardDesign={!isSmall}
            defaultExpanded
        >
            <Box mt={2} width="100%">
                <Grid spacing={2} container>
                    <Grid xs={12} item>
                        <OutlinedFilterField
                            className={styles.filterSelect}
                            color="secondary"
                            handleDeleteFilter={handleDeleteHistoricalReferenceFilter}
                            onChange={handleHistoricalReferenceFilterChange}
                            options={historicalReferenceFilterOptions}
                            placeholder={t('common:filter')}
                            value={historicalReferenceFilter}
                            multiple
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <HistoricalReferences
                            historicalReferenceFilter={historicalReferenceFilter}
                            purchaseTransaction={purchaseTransaction}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Accordion>
    );
};

export default ValuationHistoric;
