import { Avatar, Box, Divider, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cs from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { maxUploadFileSize, imageFileExtensions } from '../../../../server/schema/constants';
import { FullUserDataFragment, UserType } from '../../../api';
import OutlinedInputField from '../../../components/fields/OutlinedInputField';
import OutlinedSelectField from '../../../components/fields/OutlinedSelectField';
import useBasicStyle from '../../../layouts/BasicLayout/useBasicStyles';
import useCommonStyles from '../../../useCommonStyles';
import useUserTypeOptions from '../../../utilities/useUserTypeOptions';
import ProfilePictureButton from './ProfilePictureButton';
import { ProfileUpdateFormValues } from './ProfileUpdate';

export type ProfileUpdateBodyProps = {
    user: FullUserDataFragment;
};

const useFieldStates = (user: FullUserDataFragment) => {
    const isAdmin = user.type === UserType.Admin;

    return {
        fullName: !isAdmin,
        mobile: !isAdmin,
        email: !isAdmin,
        type: !isAdmin,
    };
};

const maxFileSizeInKB = maxUploadFileSize * 1000 * 1000;

const ProfileUpdateBody = ({ user }: ProfileUpdateBodyProps) => {
    const { t } = useTranslation(['profilePage', 'common', 'errors']);
    const { values } = useFormikContext<ProfileUpdateFormValues>();
    const { profilePicture } = values;
    const commonStyles = useCommonStyles();
    const basicStyles = useBasicStyle();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const states = useFieldStates(user);

    const userTypeOptions = useUserTypeOptions();

    const profilePictureUrl =
        profilePicture instanceof File ? URL.createObjectURL(profilePicture) : profilePicture?.signedUrl;

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Box mt={isSmall ? 0 : -7}>
                    <Box
                        alignItems="center"
                        bottom={0}
                        display="flex"
                        justifyContent={isSmall ? 'center' : 'flex-start'}
                    >
                        <Avatar className={basicStyles.profilePhoto} src={profilePictureUrl} />
                        <ProfilePictureButton
                            allowedExtensions={imageFileExtensions}
                            maxSize={maxFileSizeInKB}
                            name="profilePicture"
                        />
                        <Box ml={isSmall ? 0 : 2} mt={isSmall ? 7 : 4.5}>
                            <Typography
                                className={cs(commonStyles.bigFontSize, commonStyles.fontWeightBold)}
                                color="secondary"
                            >
                                {user.fullName}
                            </Typography>
                            {!isSmall && (
                                <Typography className={cs(commonStyles.smallFontSize, commonStyles.secondary2)}>
                                    {user.type}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid lg={5} md={8} sm={12} xs={12} item>
                <Box mt={5}>
                    <Grid spacing={2} container>
                        {user.type === UserType.Admin && (
                            <Grid xs={12} item>
                                <OutlinedInputField
                                    label={t('profilePage:fields.databaseIdPlaceholder')}
                                    name="id"
                                    placeholder={t('profilePage:fields.databaseIdPlaceholder')}
                                    disabled
                                />
                            </Grid>
                        )}
                        <Grid xs={12} item>
                            <OutlinedInputField
                                color="secondary"
                                disabled={states.fullName}
                                label={t('profilePage:fields.fullNamePlaceholder')}
                                name="fullName"
                                placeholder={t('profilePage:fields.fullNamePlaceholder')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <OutlinedInputField
                                color="secondary"
                                disabled={states.email}
                                label={t('profilePage:fields.emailPlaceholder')}
                                name="email"
                                placeholder={t('profilePage:fields.emailPlaceholder')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <OutlinedInputField
                                color="secondary"
                                disabled={states.mobile}
                                label={t('profilePage:fields.mobilePlaceholder')}
                                name="mobilePhone.number"
                                placeholder={t('profilePage:fields.mobilePlaceholder')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <OutlinedSelectField
                                color="secondary"
                                disabled={states.type}
                                label={t('profilePage:fields.userTypePlaceholder')}
                                name="type"
                                options={userTypeOptions}
                                placeholder={t('profilePage:fields.userTypePlaceholder')}
                            />
                        </Grid>
                        {values.type === UserType.SaleConsultant && (
                            <Grid xs={12} item>
                                <OutlinedInputField
                                    color="secondary"
                                    label={t('profilePage:fields.brandPlaceholder')}
                                    name="brand"
                                    placeholder={t('profilePage:fields.brandPlaceholder')}
                                />
                            </Grid>
                        )}
                        {values.type === UserType.Dealer && (
                            <>
                                <Grid xs={12} item>
                                    <OutlinedInputField
                                        color="secondary"
                                        label={t('profilePage:fields.dealerBusinessNamePlaceholder')}
                                        name="business.name"
                                        placeholder={t('profilePage:fields.dealerBusinessNamePlaceholder')}
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <OutlinedInputField
                                        color="secondary"
                                        label={t('profilePage:fields.dealerRocPlaceholder')}
                                        name="business.registrationNumber"
                                        placeholder={t('profilePage:fields.dealerRocPlaceholder')}
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <OutlinedInputField
                                        color="secondary"
                                        label={t('profilePage:fields.dealerBusinessAddressPlaceholder')}
                                        name="business.address"
                                        placeholder={t('profilePage:fields.dealerBusinessAddressPlaceholder')}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid xs={12} item>
                            <Box mb={1} mt={1}>
                                <Divider />
                            </Box>
                        </Grid>
                        <Grid xs={12} item>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.secondary2)}>
                                    {t('profilePage:registeredOn')}
                                </Typography>
                                <Typography className={cs(commonStyles.autoMarginLeft)}>
                                    {t('common:formats.shortDate', { date: new Date(user.registeredAt) })}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};

export default ProfileUpdateBody;
