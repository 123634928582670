import { CardMedia, Grid } from '@material-ui/core';
import usePortalStyles from '../../../layouts/PortalLayout/usePortalStyles';
import usePublic from '../../../utilities/usePublic';

const SignUpHeader = () => {
    const portalStyles = usePortalStyles();
    const appvantageLogo = usePublic('assets/images/porscheLogo.png');

    return (
        <Grid xs={12} item>
            <CardMedia className={portalStyles.logo} image={appvantageLogo} />
        </Grid>
    );
};

export default SignUpHeader;
