import React from 'react';
import { Redirect } from 'react-router-dom';
import { useCurrentUser } from '../../Session';
import { UserType } from '../../api';
import BasicLayout from '../../layouts/BasicLayout';
import MyTransactions from '../../pages/MyTransactions';

const TransactionsRoute = () => {
    const currentUser = useCurrentUser();

    if (![UserType.ValuationTeam, UserType.SaleConsultant].includes(currentUser.type)) {
        return <Redirect to="/403" />;
    }

    return (
        <BasicLayout>
            <MyTransactions />
        </BasicLayout>
    );
};

export default TransactionsRoute;
