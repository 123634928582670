import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import usePublic, { usePublicFn } from '../../../../utilities/usePublic';
import useBasicStyles from '../../useBasicStyles';
import SidePanelMenu from './SidePanelMenu';

export type PanelListItem = {
    icon: string;
    text: string;
    to: string;
};

export type SidePanelMenuProps = {
    items: PanelListItem[];
    open: boolean;
};

export type DrawerHeaderProps = {
    open: boolean;
};

const DrawerHeader = ({ open }: DrawerHeaderProps) => {
    const { t } = useTranslation(['homePage']);

    const classes = useBasicStyles();

    const appvantageLogo = usePublic('assets/images/porscheLogo.png');
    const logo = usePublic('assets/images/porscheFavicon.png');

    const publicFn = usePublicFn();

    const items: PanelListItem[] = useMemo(
        () => [
            { icon: publicFn('assets/menuIcons/dashboard-24px.svg'), text: t('homePage:menuBar.dashboard'), to: '/' },
            {
                icon: publicFn('assets/menuIcons/account_circle-24px.svg'),
                text: t('homePage:menuBar.userDatabase'),
                to: '/users',
            },
            {
                icon: publicFn('assets/menuIcons/gpp_good-24px.svg'),
                text: t('homePage:menuBar.approvals'),
                to: '/approvals',
            },
            // {
            //     icon: publicFn('assets/menuIcons/Group_6028.svg'),
            //     text: t('homePage:menuBar.purchaseTransactionRecords'),
            //     to: '/purchaseTransactions',
            // },
            {
                icon: publicFn('assets/menuIcons/Group_6029.svg'),
                text: t('homePage:menuBar.salesTransactionRecords'),
                to: '/salesTransactions',
            },
            // {
            //     icon: publicFn('assets/menuIcons/receipt_long-24px.svg'),
            //     text: t('homePage:menuBar.biddingSessions'),
            //     to: '/biddingSessions',
            // },
            {
                icon: publicFn('assets/menuIcons/circle_notifications-24px.svg'),
                text: t('homePage:menuBar.notificationsLog'),
                to: '/notifications',
            },
            {
                icon: publicFn('assets/menuIcons/history.svg'),
                text: t('homePage:menuBar.activityHistory'),
                to: '/activityHistory',
            },
        ],
        [t, publicFn]
    );

    return (
        <Box>
            <Box className={classes.logoWrapper}>
                <img alt="logo" className={classes.logo} src={open ? appvantageLogo : logo} />
            </Box>
            <SidePanelMenu items={items} open={open} />
        </Box>
    );
};

export default DrawerHeader;
