import { useGetSelfQuery } from '../../api';
import { ProfileUpdate, ProfileView } from './components';

export type ProfileProps = {
    onEdition: boolean;
    setOnEdition: (boolean) => void;
};

const Profile = ({ onEdition, setOnEdition }: ProfileProps) => {
    const { data } = useGetSelfQuery({ fetchPolicy: 'cache-and-network' });
    const user = data?.getSelf;

    if (!user) {
        return null;
    }

    if (onEdition) {
        return <ProfileUpdate goToView={() => setOnEdition(false)} user={user} />;
    }

    return <ProfileView goToEdition={() => setOnEdition(true)} user={user} />;
};

export default Profile;
