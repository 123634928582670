import { ReactNode } from 'react';
import {
    GenerateSalesTransactionDetailFileDocument as query,
    GenerateSalesTransactionDetailFileQuery as Query,
    GenerateSalesTransactionDetailFileQueryVariables as Variables,
} from '../../../api';
import DownloadProvider, { DownloadFn } from '../../../components/DownloadProvider';

export type DownloadDetailFileFn = DownloadFn<Variables>;

export type DownloadSalesTransactionDetailFileProviderProps = {
    children: (fn: DownloadDetailFileFn) => JSX.Element | ReactNode;
};

const getSignedUrl = (data: Query) => data?.signedUrl || null;

const DownloadSalesTransactionDetailFileProvider = ({ children }: DownloadSalesTransactionDetailFileProviderProps) => (
    <DownloadProvider getSignedUrl={getSignedUrl} query={query}>
        {children}
    </DownloadProvider>
);

export default DownloadSalesTransactionDetailFileProvider;
