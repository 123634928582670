import { Grid, Link, Typography } from '@material-ui/core';
import cs from 'classnames';
import React from 'react';
import useCommonStyles from '../../../useCommonStyles';

export type OTPPageFooterProps = {
    resendCode: () => void;
};

const OTPPageFooter = ({ resendCode }: OTPPageFooterProps) => {
    const commonStyles = useCommonStyles();

    const linkElement = (
        <Link
            className={cs(commonStyles.smallFontSize, commonStyles.fontWightMedium)}
            color="secondary"
            component="a"
            onClick={resendCode}
        >
            Resend OTP
        </Link>
    );

    return (
        <Grid xs={12} item>
            <Typography
                align="center"
                className={cs(commonStyles.smallFontSize, commonStyles.fontWeightLight, commonStyles.primary)}
            >
                Didn’t get an OTP Code? {linkElement}
            </Typography>
        </Grid>
    );
};

export default OTPPageFooter;
