import useBrands from './useBrands';
import usePublic from './usePublic';

const useVehicleLogo = (brandName: string) => {
    const brands = useBrands();

    const brandKeys = Object.keys(brands);
    const index = brandKeys.findIndex(brandKey => brandKey.toLowerCase() === brandName.toLowerCase());
    const brandKey = brandKeys[index];

    const logoFileName = index !== -1 ? brands[brandKey] : 'others.svg';

    return usePublic(`assets/vehicleLogos/${logoFileName}`);
};

export default useVehicleLogo;
