import { Grid, Box, Button, Dialog, DialogContent, Typography, FormControl } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FullPurchaseTransactionDataFragment, useUpdateTransactionToFinalValuationMutation } from '../../../../api';
import useCommonStyles from '../../../../useCommonStyles';
import onCloseDialog from '../../../../utilities/constants/onCloseDialog';

export type AcceptValuationConfirmDialogProps = {
    show?: boolean;
    setHideConfirmDialog: () => void;
    purchaseTransaction: FullPurchaseTransactionDataFragment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogContent: {
            padding: 0,
        },
        button: {
            borderBottom: 'none',
            borderRadius: 0,
            width: '50%',
            '&:first-child': {
                borderLeft: 'none',
                borderRight: 'none',
            },
            '&:last-child': {
                borderRight: 'none',
            },
        },
        cancelLabel: {
            color: '#A5A5A5',
        },
    })
);

const AcceptValuationConfirmDialog = ({
    show,
    setHideConfirmDialog,
    purchaseTransaction,
}: AcceptValuationConfirmDialogProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useStyles();

    const [updateToFinalValuation] = useUpdateTransactionToFinalValuationMutation({
        variables: { transactionId: purchaseTransaction.id },
    });

    const onUpdateToFinalValuation = useCallback(() => {
        setHideConfirmDialog();
        updateToFinalValuation();
    }, [setHideConfirmDialog, updateToFinalValuation]);

    return (
        <Dialog
            aria-labelledby="submitted-dialog-title"
            classes={{ paper: commonStyles.defaultBorderRadius }}
            maxWidth="xs"
            onClose={onCloseDialog}
            open={show}
            disableEscapeKeyDown
            fullWidth
        >
            <DialogContent className={styles.dialogContent}>
                <Box mb={4} ml={4} mr={4} mt={2} textAlign="center">
                    <Box mb={2} mt={1}>
                        <Typography className={cs(commonStyles.primary, commonStyles.fontWightMedium)}>
                            {t('purchaseTransactionsPage:acceptDialog.title')}
                        </Typography>
                    </Box>
                    <Typography className={commonStyles.dialogDescription}>
                        {t('purchaseTransactionsPage:acceptDialog.body')}
                    </Typography>
                    <Box mt={3}>
                        <Grid spacing={2} container>
                            <Grid xs={6} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={cs(commonStyles.removeBoxShadow, commonStyles.defaultBorderRadius)}
                                        classes={{ label: styles.cancelLabel }}
                                        onClick={setHideConfirmDialog}
                                        size="large"
                                        variant="contained"
                                    >
                                        {t('purchaseTransactionsPage:acceptDialog.button.cancel')}
                                    </Button>
                                </FormControl>
                            </Grid>
                            <Grid xs={6} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={commonStyles.defaultBorderRadius}
                                        color="primary"
                                        onClick={onUpdateToFinalValuation}
                                        size="large"
                                        variant="contained"
                                    >
                                        {t('purchaseTransactionsPage:acceptDialog.button.continue')}
                                    </Button>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default AcceptValuationConfirmDialog;
