import { Tabs, Tab } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

export type DesktopMenuTabsProps = {
    a11yProps: (index: string) => object;
    value: string;
    setValue: (value: string) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderBottom: '1px solid #C4C4C4',
            marginBottom: theme.spacing(3),
        },
    })
);

const DesktopMenuTabs = ({ value, setValue, a11yProps }: DesktopMenuTabsProps) => {
    const { t } = useTranslation(['biddingSessionsPage']);
    const styles = useStyles();

    return (
        <Tabs
            classes={{ root: styles.root }}
            indicatorColor="secondary"
            onChange={(_, value) => setValue(value)}
            scrollButtons="auto"
            textColor="secondary"
            value={value}
        >
            <Tab label={t('biddingSessionsPage:menu.all')} value="all" {...a11yProps('all')} />
            <Tab label={t('biddingSessionsPage:menu.upcoming')} value="upcoming" {...a11yProps('upcoming')} />
            <Tab label={t('biddingSessionsPage:menu.current')} value="current" {...a11yProps('current')} />
            <Tab label={t('biddingSessionsPage:menu.past')} value="past" {...a11yProps('past')} />
        </Tabs>
    );
};

export default DesktopMenuTabs;
