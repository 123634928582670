import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetSaleTransactionsQuery } from '../../../api';
import ListPagination from '../../../components/ListPagination';
import NoRecordsFound from '../../../components/NoRecordsFound';
import useCommonStyles from '../../../useCommonStyles';
import { SaleTransactionsBody } from '../../SaleTransactions/components';

const pageSize = 9;

const MyBids = () => {
    const [page, setPage] = useState(0);
    const { t } = useTranslation(['common']);
    const commonStyles = useCommonStyles();

    const { data } = useGetSaleTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            page: { limit: pageSize, offset: page * pageSize },
            filter: {
                onlySelf: true,
            },
        },
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    const count = data?.saleTransactions?.count || 0;
    const saleTransactions = data?.saleTransactions?.items || [];

    const header = (
        <Box mb={3}>
            <Typography className={commonStyles.title}>{t('common:titles.myBids')}</Typography>
        </Box>
    );

    if (count === 0) {
        return (
            <>
                {header}
                <NoRecordsFound />
            </>
        );
    }

    return (
        <>
            {header}
            <SaleTransactionsBody saleTransactions={saleTransactions} hideAwardedBitAmount hideAwardedDealer />
            <ListPagination
                activePage={page + 1}
                count={count}
                pageSize={pageSize}
                setActivePage={(newPage: number) => setPage(newPage)}
            />
        </>
    );
};

export default MyBids;
