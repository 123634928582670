import { Box, Grid, Typography, IconButton, Link } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { NameOnlyFileDataFragment } from '../../api';
import useCommonStyles from '../../useCommonStyles';
import { getExtension, isImageFile, isPdfFile } from '../../utilities/file';
import { useFileViewer } from '../FileViewerProvider';

export type FileListProps = {
    renderPrefix?: (file: File | NameOnlyFileDataFragment, index: number) => JSX.Element;
    files: (File | NameOnlyFileDataFragment)[];
    removeFile?: (file: File | NameOnlyFileDataFragment) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        removeButton: {
            color: '#B43333',
            marginLeft: 'auto',
        },
        icon: {
            color: theme.palette.secondary.main,
            marginRight: theme.spacing(1),
        },
        fileLink: {
            display: 'flex',
            overflow: 'hidden',
        },
    })
);

const FileList = ({ files, removeFile, renderPrefix }: FileListProps) => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();
    const view = useFileViewer();

    const getFileIcon = (extension: string) => {
        if (isImageFile(extension)) {
            return <PhotoLibraryIcon className={styles.icon} fontSize="small" />;
        }

        if (isPdfFile(extension)) {
            return <PictureAsPdfIcon className={styles.icon} fontSize="small" />;
        }

        return <AttachFileIcon className={styles.icon} fontSize="small" />;
    };

    return (
        <Grid spacing={1} container>
            {files.map((file, index) => {
                const filename = file instanceof File ? file.name : file.filename;
                const extension = getExtension(filename);
                const filenameOnly = filename.split('.').slice(0, -1).join('.');

                return (
                    <Grid key={index.toString()} xs={12} item>
                        <Box alignItems="center" display="flex">
                            {renderPrefix && renderPrefix(file, index)}
                            {getFileIcon(extension)}
                            {!(file instanceof File) ? (
                                <Link
                                    className={styles.fileLink}
                                    color="secondary"
                                    component="a"
                                    onClick={() => view(file)}
                                    underline="none"
                                >
                                    <Typography className={commonStyles.ellipsisTextOverflow}>
                                        {filenameOnly}
                                    </Typography>
                                    <Typography>.{extension}</Typography>
                                </Link>
                            ) : (
                                <>
                                    <Typography className={commonStyles.ellipsisTextOverflow}>
                                        {filenameOnly}
                                    </Typography>
                                    <Typography>.{extension}</Typography>
                                </>
                            )}
                            {removeFile && (
                                <IconButton
                                    className={styles.removeButton}
                                    onClick={() => removeFile(file)}
                                    size="small"
                                >
                                    <RemoveCircleIcon />
                                </IconButton>
                            )}
                        </Box>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default FileList;
