import { ReactNode } from 'react';
import {
    GeneratePurchaseTransactionDetailFileDocument as query,
    GeneratePurchaseTransactionDetailFileQuery as Query,
    GeneratePurchaseTransactionDetailFileQueryVariables as Variables,
} from '../../../api';
import DownloadProvider, { DownloadFn } from '../../../components/DownloadProvider';

export type DownloadDetailFn = DownloadFn<Variables>;

export type DownloadPurchaseTransactionDetailFileProviderProps = {
    children: (fn: DownloadDetailFn) => JSX.Element | ReactNode;
};

const getSignedUrl = (data: Query) => data?.signedUrl || null;

const DownloadPurchaseTransactionDetailFileProvider = ({
    children,
}: DownloadPurchaseTransactionDetailFileProviderProps) => (
    <DownloadProvider getSignedUrl={getSignedUrl} query={query}>
        {children}
    </DownloadProvider>
);

export default DownloadPurchaseTransactionDetailFileProvider;
