import { Box } from '@material-ui/core';
import { ReactElement } from 'react';

type divProps = JSX.IntrinsicElements['div'];

export interface TabPanelProps extends Omit<divProps, 'aria-labelledby' | 'hidden' | 'id' | 'role'> {
    children?: ReactElement | null;
    index: string;
    value: string;
}

const TabPanel = ({ children, value, index, ...props }: TabPanelProps) => (
    <div
        aria-labelledby={`simple-tab-${index}`}
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        role="tabpanel"
        {...props}
    >
        {value === index && <Box>{children}</Box>}
    </div>
);

export default TabPanel;
