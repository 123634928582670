import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from '../layouts/RoutedErrorBoundary';

const NotFoundPage = () => {
    const { t } = useTranslation(['common']);

    return (
        <div>
            <h1>{t('common:errors:404:title')}</h1>
            <p>{t('common:errors:404:description')}</p>
        </div>
    );
};

export default withErrorBoundary(NotFoundPage);
