import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useYesNoOptions = () => {
    const { t } = useTranslation(['common']);

    return useMemo(() => {
        const options = [
            { value: true, label: t('common:yes') },
            { value: false, label: t('common:no') },
        ];

        return options;
    }, [t]);
};

export default useYesNoOptions;
