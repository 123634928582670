import { Box, Button, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useCommonStyles from '../../../../useCommonStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(2),
            display: 'flex',
        },
    })
);

const CreateBiddingSessionFooter = () => {
    const styles = useStyles();
    const { t } = useTranslation(['common']);
    const commonStyles = useCommonStyles();
    const history = useHistory();
    const { isSubmitting } = useFormikContext();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    if (isSmall) {
        return null;
    }

    return (
        <Box alignItems="center" className={styles.root}>
            <Box ml="auto">
                <Box className={commonStyles.buttonGroup}>
                    <Button color="secondary" onClick={history.goBack} size="large" variant="outlined">
                        {t('common:cancel')}
                    </Button>
                    <Button color="secondary" disabled={isSubmitting} size="large" type="submit" variant="contained">
                        {t('common:save')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default CreateBiddingSessionFooter;
