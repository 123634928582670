import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { useCallback, useState } from 'react';
import { DownloadFileType, PreviewSaleTransactionDataFragment } from '../../../api';
import ListPagination from '../../../components/ListPagination';
import { useLoading } from '../../../components/LoadingProvider';
import NoRecordsFound from '../../../components/NoRecordsFound';
import {
    SaleTransactionsHeader,
    SaleTransactionsBody,
    DownloadSaleListFn,
    SaleTransactionsFooter,
} from '../../SaleTransactions/components';
import FilterDialog from './SalesFilterDialog';
import FilterMenu from './SalesFilterMenu';

export type SalesHandoverTabProps = {
    search: string;
    saleTransactions: PreviewSaleTransactionDataFragment[];
    page: number;
    count: number;
    pageSize: number;
    createdAt: Date | null;
    targetHandoverDate: Date | null;
    awardedDealer: string;
    setTargetHandoverDate: (targetHandoverDate: Date | null) => void;
    setAwardedDealer: (awardedDealer: string) => void;
    setCreatedAt: (createdAt: Date | null) => void;
    setActivePage: (page: number) => void;
    searchOnChange: (searchValue: string) => void;
    downloadSalesTransactionsFile: DownloadSaleListFn;
};

const SalesHandoverTab = ({
    search,
    saleTransactions,
    page,
    pageSize,
    count,
    createdAt,
    targetHandoverDate,
    awardedDealer,
    setTargetHandoverDate,
    setAwardedDealer,
    setCreatedAt,
    searchOnChange,
    setActivePage,
    downloadSalesTransactionsFile,
}: SalesHandoverTabProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [showFilterDialog, setShowFilterDialog] = useState(false);

    const [showFilterMenu, setShowFilterMenu] = useState(false);

    const { attach } = useLoading();

    const downloadFile = useCallback(
        (type: DownloadFileType) => {
            const execute = async () => {
                await downloadSalesTransactionsFile({
                    downloadFileType: type,
                    filter: {
                        search,
                        createdAt,
                        handoverAt: targetHandoverDate,
                        awardedDealer: awardedDealer === 'all' ? null : awardedDealer,
                    },
                });
            };

            attach(execute());
        },
        [attach, awardedDealer, createdAt, downloadSalesTransactionsFile, search, targetHandoverDate]
    );

    const setValues = useCallback(
        (createdAt, targetHandoverDate, awardedDealer) => {
            setCreatedAt(createdAt);
            setTargetHandoverDate(targetHandoverDate);
            setAwardedDealer(awardedDealer);
        },
        [setCreatedAt, setTargetHandoverDate, setAwardedDealer]
    );

    const header = (
        <SaleTransactionsHeader
            downloadExcel={() => downloadFile(DownloadFileType.Excel)}
            downloadPdf={() => downloadFile(DownloadFileType.Pdf)}
            search={search}
            searchOnChange={searchOnChange}
            setShowFilterDialog={setShowFilterDialog}
            setShowFilterMenu={setShowFilterMenu}
        />
    );

    if (count === 0) {
        return (
            <>
                {header}
                <NoRecordsFound />
                {isSmall && <SaleTransactionsFooter />}
            </>
        );
    }

    return (
        <>
            <FilterDialog
                awardedDealer={awardedDealer}
                createdAt={createdAt}
                open={showFilterDialog}
                setOpen={setShowFilterDialog}
                setValues={setValues}
                targetHandoverDate={targetHandoverDate}
            />
            <FilterMenu
                awardedDealer={awardedDealer}
                createdAt={createdAt}
                open={showFilterMenu}
                setOpen={setShowFilterMenu}
                setValues={setValues}
                targetHandoverDate={targetHandoverDate}
            />
            {header}
            <SaleTransactionsBody saleTransactions={saleTransactions} hideAwardedBitAmount />
            <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
            {isSmall && <SaleTransactionsFooter />}
        </>
    );
};

export default SalesHandoverTab;
