import { Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PublishIcon from '@material-ui/icons/Publish';
import { useField, useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    imageFileExtensions,
    maxSalesTransactionPhotos,
    maxUploadFileSize,
    pdfFileExtension,
} from '../../../../../server/schema/constants';
import Accordion from '../../../../components/Accordion';
import { AttachmentButton, FrontPhotoManager } from '../../../../components/attachments';
import { VehicleForm } from '../../Forms';
// import TradeInDetailsForm from '../../Forms/TradeInDetailsForm';
import VehicleDiagram, { VehicleDiagramComment } from '../../components/VehicleDiagram';

const maxFileSizeInKB = maxUploadFileSize * 1000 * 1000;
const allowedExtensions = [pdfFileExtension, ...imageFileExtensions];

const VehicleDiagramField = ({ name }: { name: string }) => {
    const [field] = useField({ name });

    const { setFieldValue } = useFormikContext();

    const addComment = useCallback(
        (value: VehicleDiagramComment) => {
            setFieldValue(name, [...field.value, value]);
        },
        [setFieldValue, field, name]
    );

    const removeComment = useCallback(
        (index: number) => {
            const newComments = field.value
                ?.filter((comment: VehicleDiagramComment, i: number) => i !== index)
                ?.map((comment: VehicleDiagramComment, i: number) => ({
                    ...comment,
                    index: i + 1,
                }));

            setFieldValue(name, newComments);
        },
        [setFieldValue, field, name]
    );

    return <VehicleDiagram addComment={addComment} comments={field.value} removeComment={removeComment} isEditable />;
};

const VehicleTab = () => {
    const { t } = useTranslation(['saleTransactionsPage']);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion
                    title={t('saleTransactionsPage:accordionTitle.vehicleDetails')}
                    useCardDesign={!isSmall}
                    defaultExpanded
                >
                    <VehicleForm />
                </Accordion>
                <Accordion
                    title={t('saleTransactionsPage:accordionTitle.uploadPhotos')}
                    useCardDesign={!isSmall}
                    defaultExpanded
                >
                    <FrontPhotoManager attachmentFieldName="photos" name="frontPagePhoto">
                        {renderPrefix => (
                            <AttachmentButton
                                allowedExtensions={allowedExtensions}
                                buttonText={t('common:upload')}
                                icon={<PublishIcon fontSize="small" />}
                                label={t('saleTransactionsPage:transactionDetails.photos')}
                                max={maxSalesTransactionPhotos}
                                maxSize={maxFileSizeInKB}
                                name="photos"
                                renderPrefix={renderPrefix}
                            />
                        )}
                    </FrontPhotoManager>
                </Accordion>
                <Accordion
                    title={t('saleTransactionsPage:accordionTitle.vehicleCondition')}
                    useCardDesign={!isSmall}
                    defaultExpanded
                >
                    <VehicleDiagramField name="comments" />
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default VehicleTab;
