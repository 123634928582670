import { IconButton, InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useState } from 'react';
import OutlinedInputField, { OutlinedInputFieldProps } from './OutlinedInputField';

export type OutlinedPasswordFieldProps = Omit<OutlinedInputFieldProps, 'endAdornment' | 'type'>;

const OutlinedPasswordField = (props: OutlinedPasswordFieldProps) => {
    // show state
    const [show, setShow] = useState(false);

    const endAdornment = (
        <InputAdornment position="end">
            <IconButton color="secondary" onClick={() => setShow(state => !state)}>
                {show ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    );

    return (
        <OutlinedInputField
            // spread props
            {...props}
            // the type is changing
            endAdornment={endAdornment}
            // add the adornment to toggle the visibility
            type={show ? 'text' : 'password'}
        />
    );
};

export default OutlinedPasswordField;
