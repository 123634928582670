import { useMediaQuery } from '@material-ui/core';
import { MuiThemeProvider, useTheme } from '@material-ui/core/styles';
import { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { useCurrentUser } from '../../Session';
import { UserType } from '../../api';
import BasicLayout from '../../layouts/BasicLayout';
import basicTheme from '../../layouts/BasicLayout/basicTheme';
import CreatePurchaseTransaction, {
    CreatePurchaseTransactionFormValues,
} from '../../pages/PurchaseTransactions/CreatePurchaseTransaction';
import { DuplicateTransactionProvider } from '../../pages/PurchaseTransactions/CreatePurchaseTransaction/components';
import { useRuntimeConfig } from '../../runtimeConfig';

const CreatePurchaseTransactionRoute = () => {
    const currentUser = useCurrentUser();
    const theme = useTheme();

    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const { countryCode } = useRuntimeConfig();

    const initialValues = useMemo(
        (): CreatePurchaseTransactionFormValues => ({
            saleConsultantId: null,
            vehicle: {
                number: '',
                beExported: true,
                intendedDeregistrationDate: null,
                make: '',
                model: '',
                primaryColour: '',
                manufacturingYear: null,
                engineNumber: '',
                chassisNumber: '',
                maximumPowerOutput: '',
                openMarketValue: null,
                originalRegistrationDate: null,
                firstRegistrationDate: null,
                transferCount: null,
                actualARFPaid: null,
                parfEligibility: true,
                parfEligibilityDate: null,
                parfRebateAmount: null,
                coeExpiryDate: null,
                coeCategory: '',
                coePeriodYear: null,
                qpPaid: null,
                coeRebateAmount: null,
                mileage: null,
                purchaseAgreementNumber: '',
                saleAgreementNumber: '',
                opcCashRebateEligibilityExpiryDate: null,
                isOneMotoringData: false,
                importMethod: '',
                totalRebateAmount: null,
            },
            customer: {
                fullName: '',
                email: '',
                mobilePhone: { internationalCode: countryCode, number: '' },
                ownerIdType: '',
                ownerId: '',
            },
            internalRemarks: '',
            handover: {
                personResponsible: '',
                targetHandoverDateTime: null,
                handoverLocationField: { main: '' },
                deliveryPeriod: 0,
            },
        }),
        [countryCode]
    );

    if (
        ![UserType.Admin, UserType.ValuationTeam, UserType.SaleConsultant, UserType.Approver].includes(currentUser.type)
    ) {
        return <Redirect to="/403" />;
    }

    if (isSmall) {
        return (
            <MuiThemeProvider theme={basicTheme}>
                <DuplicateTransactionProvider>
                    {checkDuplicateCarPlate => (
                        <CreatePurchaseTransaction
                            checkDuplicateCarPlate={checkDuplicateCarPlate}
                            initialValues={initialValues}
                        />
                    )}
                </DuplicateTransactionProvider>
            </MuiThemeProvider>
        );
    }

    return (
        <BasicLayout>
            <DuplicateTransactionProvider>
                {checkDuplicateCarPlate => (
                    <CreatePurchaseTransaction
                        checkDuplicateCarPlate={checkDuplicateCarPlate}
                        initialValues={initialValues}
                    />
                )}
            </DuplicateTransactionProvider>
        </BasicLayout>
    );
};

export default CreatePurchaseTransactionRoute;
