// if some values does not exist in the initial values
// and yet is added later on and there is an error on it
// submitting will not set touched to true for this field
// Formik owner is stubborn on this
import { useFormikContext } from 'formik';
import { isEmpty, keys, isObject } from 'lodash/fp';
import { useEffect } from 'react';

const toPath = (parts: (string | number)[]) =>
    parts.reduce<string>((acc, part) => {
        if (typeof part === 'string') {
            return acc ? `${acc}.${part}` : part;
        }

        if (typeof part === 'number') {
            return `${acc}[${part}]`;
        }

        return acc;
    }, '');

const FormAutoTouch = () => {
    const { isSubmitting, isValidating, errors, setFieldTouched } = useFormikContext();

    useEffect(() => {
        if (isSubmitting && !isValidating && !isEmpty(errors)) {
            const touchOnErrors = (fields, prefix: (string | number)[] = []) => {
                for (const key of keys(fields)) {
                    const item = fields[key];

                    if (isObject(item) || Array.isArray(item)) {
                        touchOnErrors(item, [...prefix, key]);
                    }

                    if (typeof item === 'string' || typeof item === 'number') {
                        const path = toPath([...prefix, key]);
                        setFieldTouched(path, true, false);
                    }
                }
            };

            touchOnErrors(errors);
        }
    }, [isSubmitting, isValidating, errors, setFieldTouched]);

    return null;
};

export default FormAutoTouch;
