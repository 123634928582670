import { Button, TableCell, TableRow, Box } from '@material-ui/core';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PreviewUserDataFragment, useApproveAccountMutation, useRejectAccountMutation } from '../../../api';
import useCommonStyles from '../../../useCommonStyles';

export type ApprovalsRowProps = {
    user: PreviewUserDataFragment;
    onUpdates: () => void;
};

const ApprovalsRow = ({ user, onUpdates }: ApprovalsRowProps) => {
    const { t } = useTranslation(['approvalsPage']);
    const commonStyles = useCommonStyles();

    const userId = user.id;
    const [approve] = useApproveAccountMutation({ variables: { userId } });
    const [reject] = useRejectAccountMutation({ variables: { userId } });

    const onApprove = useCallback(() => approve().then(onUpdates), [onUpdates, approve]);
    const onReject = useCallback(() => reject().then(onUpdates), [onUpdates, reject]);

    const getAdditionalInfo = useCallback((user: PreviewUserDataFragment) => {
        switch (user.__typename) {
            case 'SaleConsultantUser':
                return user.brand;

            case 'DealerUser':
                return (
                    <>
                        {user.business.name}, {user.business.registrationNumber}, {user.business.address}
                    </>
                );

            default:
                return '';
        }
    }, []);

    return (
        <TableRow className={commonStyles.tableRow}>
            <TableCell>{user.fullName}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>{user.mobilePhone.number}</TableCell>
            <TableCell>{user.type}</TableCell>
            <TableCell>{getAdditionalInfo(user)}</TableCell>
            <TableCell>
                <Box className={commonStyles.buttonGroup}>
                    <Button color="secondary" onClick={onApprove} size="large" variant="contained">
                        {t('approvalsPage:actions.approve')}
                    </Button>
                    <Button color="secondary" onClick={onReject} size="large" variant="contained">
                        {t('approvalsPage:actions.reject')}
                    </Button>
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default ApprovalsRow;
