import { Tabs, Tab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../../../useCommonStyles';

export type MenuTabsProps = {
    a11yProps: (index: string) => object;
    value: string;
    setValue: (value: string) => void;
};

const AllSessionsMenuTabs = ({ value, setValue, a11yProps }: MenuTabsProps) => {
    const { t } = useTranslation(['saleTransactionsPage']);
    const commonStyles = useCommonStyles();

    return (
        <Tabs
            classes={{ root: commonStyles.subMenusRoot, indicator: commonStyles.subMenusindicator }}
            indicatorColor="secondary"
            onChange={(_, value) => setValue(value)}
            scrollButtons="off"
            textColor="secondary"
            value={value}
        >
            <Tab
                classes={{
                    root: commonStyles.subMenuTabRoot,
                    selected: commonStyles.subMenuTabSelected,
                    textColorSecondary: commonStyles.subMenusTextColor,
                }}
                label={t('saleTransactionsPage:myTransactions.purchaseTransactions')}
                value="purchaseTransactions"
                {...a11yProps('purchaseTransactions')}
            />
            <Tab
                classes={{
                    root: commonStyles.subMenuTabRoot,
                    selected: commonStyles.subMenuTabSelected,
                    textColorSecondary: commonStyles.subMenusTextColor,
                }}
                label={t('saleTransactionsPage:myTransactions.salesTransactions')}
                value="salesTransactions"
                {...a11yProps('salesTransactions')}
            />
        </Tabs>
    );
};

export default AllSessionsMenuTabs;
