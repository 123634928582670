import { Box, Button, FormControl } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSession } from '../../../../Session';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logoutButton: {
            height: theme.spacing(8),
            color: '#ffffff',
            borderRadius: '0',
        },
    })
);

export type DrawerFooterProps = {
    open: boolean;
};

const DrawerFooter = ({ open }: DrawerFooterProps) => {
    const styles = useStyles();
    const { t } = useTranslation(['common']);

    const { setToken } = useSession();

    return (
        <Box>
            <FormControl fullWidth>
                <Button
                    className={styles.logoutButton}
                    color="primary"
                    onClick={() => setToken(null)}
                    variant="contained"
                >
                    {t('common:logOut')}
                </Button>
            </FormControl>
        </Box>
    );
};

export default DrawerFooter;
