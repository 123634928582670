import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../../../useCommonStyles';

const HomePageHeader = () => {
    const { t } = useTranslation(['homePage']);
    const commonStyles = useCommonStyles();

    return (
        <Box alignItems="center" className={commonStyles.header} display="flex" mb={3}>
            <Typography className={commonStyles.title}>{t('homePage:title')}</Typography>
        </Box>
    );
};

export default HomePageHeader;
