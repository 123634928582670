import FlutterAction from '../FlutterAction';

export type IncomingPushContext = { [key: string]: string };

const action = new FlutterAction<void, { data: IncomingPushContext }, IncomingPushContext>(
    'readPush',
    async response => response.data
);

export default action;
