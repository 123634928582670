import { AppBar, Toolbar, Drawer, IconButton, CardMedia, Paper, Link, useMediaQuery, Box } from '@material-ui/core';
import { MuiThemeProvider, useTheme, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MenuIcon from '@material-ui/icons/Menu';
import cs from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import usePublic from '../../utilities/usePublic';
import basicTheme from './basicTheme';
import { DesktopDrawerHeader } from './components/Desktop';
import { MobileDrawerHeader, MobileDrawerFooter, MobileDrawerBody } from './components/Mobile';
import ProfileMenu from './components/ProfileMenu';
import useBasicStyles from './useBasicStyles';

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        drawerContainer: {
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
        },
    })
);

export type BasicLayoutProps = {
    children: ReactElement | ReactNode | null;
    onBack?: () => void;
    onGrayBackground?: Boolean;
    disabledPadding?: boolean;
};

export type BasicLayoutHeaderProps = {
    onBack: () => void;
};

export const BasicLayoutHeader = ({ onBack }: BasicLayoutHeaderProps) => {
    const classes = useBasicStyles();
    const { t } = useTranslation(['common']);

    return (
        <Paper className={cs(classes.backPaper, classes.lightBoxShadow)} elevation={0}>
            <Link className={cs(classes.linkBack)} component="a" onClick={onBack}>
                <ArrowBackIosIcon fontSize="small" /> {t('common:portalBackButton')}
            </Link>
        </Paper>
    );
};

const BasicLayout = ({ children, onBack, onGrayBackground, disabledPadding }: BasicLayoutProps): ReactElement => {
    const classes = useBasicStyles();
    const theme = useTheme();
    const styles = useStyles();

    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const [open, setOpen] = React.useState(true);

    const appvantageLogo = usePublic('assets/images/porscheLogo.png');

    return (
        <MuiThemeProvider theme={basicTheme}>
            <div className={classes.root}>
                <AppBar
                    className={cs(classes.appBar, classes.lightBoxShadow, { [classes.appBarShift]: open })}
                    elevation={0}
                    position="fixed"
                >
                    <Toolbar className={classes.toolBar}>
                        <IconButton className={classes.menuIcon} edge="start" onClick={() => setOpen(!open)}>
                            <MenuIcon />
                        </IconButton>
                        {isSmall ? <CardMedia className={classes.logo} image={appvantageLogo} /> : <ProfileMenu />}
                    </Toolbar>
                </AppBar>
                <Drawer
                    className={cs(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: cs(classes.drawerPaper, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                    variant="permanent"
                >
                    {isSmall ? (
                        <Box className={styles.drawerContainer}>
                            <MobileDrawerHeader setOpen={setOpen} />
                            <MobileDrawerBody setOpen={setOpen} />
                            <MobileDrawerFooter open={open} />
                        </Box>
                    ) : (
                        <DesktopDrawerHeader open={open} />
                    )}
                </Drawer>
                <div className={cs(classes.content, { [classes.grayBackground]: onGrayBackground })}>
                    {onBack && <BasicLayoutHeader onBack={onBack} />}
                    <main className={disabledPadding ? undefined : classes.main}>{children}</main>
                </div>
            </div>
        </MuiThemeProvider>
    );
};

export default BasicLayout;
