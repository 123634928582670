import { Dialog, Slide, Transitions, Link, Box, Typography, Button } from '@material-ui/core';
import cs from 'classnames';
import { Form, Formik } from 'formik';
import React, { forwardRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SaleTransactionStage, useGetAwardedDealersQuery } from '../../../../api';
import MobileAppBar from '../../../../components/MobileAppBar';
import OutlinedDatePickerField from '../../../../components/fields/OutlinedDatePickerField';
import OutlinedSelectField from '../../../../components/fields/OutlinedSelectField';
import useCommonStyles from '../../../../useCommonStyles';
import useSalesTransactionStateFilterOptoins from '../../../../utilities/useSalesTransactionStateFilterOptoins';
import useFilterDialogStyles from '../../../SaleTransactions/components/useFilterDialogStyles';

export type FilterDialogProps = {
    open: boolean;
    setOpen: (value: boolean) => void;
    setValues: (stage, createdAt, targetHandoverDate, awardedDealer) => void;
    stage: 'all' | SaleTransactionStage;
    createdAt: Date | null;
    targetHandoverDate: Date | null;
    awardedDealer: string;
};

const Transition = forwardRef((props: Transitions & { children?: React.ReactElement }, ref: React.Ref<unknown>) => (
    <Slide ref={ref} direction="down" {...props} />
));

const FilterDialog = ({
    open,
    setOpen,
    setValues,
    stage,
    createdAt,
    targetHandoverDate,
    awardedDealer,
}: FilterDialogProps) => {
    const { t } = useTranslation(['common', 'saleTransactionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useFilterDialogStyles();

    const statuses = useSalesTransactionStateFilterOptoins();

    const onSubmit = useCallback(
        (values: {
            stage: string;
            createdAt: Date | string;
            targetHandoverDate: Date | string;
            awardedDealer: string;
        }) => {
            const { stage, createdAt, targetHandoverDate, awardedDealer } = values;

            setValues(stage, createdAt, targetHandoverDate, awardedDealer);
            setOpen(false);
        },
        [setOpen, setValues]
    );

    const { data: awardedDealersData } = useGetAwardedDealersQuery({
        fetchPolicy: 'cache-and-network',
    });

    const awardedDealerOptions = useMemo(
        () => [
            { value: 'all', label: t('saleTransactionsPage:filter.all') },
            ...(awardedDealersData?.users?.items || []).map(user => ({
                value: user.id,
                label: user.fullName,
            })),
        ],
        [awardedDealersData, t]
    );

    return (
        <Dialog TransitionComponent={Transition} open={open} fullScreen>
            <Formik initialValues={{ stage, createdAt, targetHandoverDate, awardedDealer }} onSubmit={onSubmit}>
                {({ setFieldValue }) => (
                    <>
                        <MobileAppBar>
                            <Link
                                className={cs(commonStyles.displayFlex, commonStyles.primary2)}
                                component="a"
                                onClick={() => setOpen(false)}
                                underline="none"
                            >
                                {t('common:cancel')}
                            </Link>
                            <Box display="flex" justifyContent="center" width="100%">
                                <Typography className={cs(commonStyles.bigFontSize)}>{t('common:filter')}</Typography>
                            </Box>
                            <Link
                                className={cs(commonStyles.displayFlex, commonStyles.primary2)}
                                component="a"
                                onClick={() => {
                                    setFieldValue('stage', 'all');
                                    setFieldValue('createdAt', null);
                                    setFieldValue('targetHandoverDate', null);
                                    setFieldValue('awardedDealer', 'all');
                                }}
                                underline="none"
                            >
                                {t('common:reset')}
                            </Link>
                        </MobileAppBar>
                        <Form>
                            <Box className={styles.content} display="flex" flexDirection="column">
                                <Box mt={3} width="100%">
                                    <OutlinedSelectField
                                        color="secondary"
                                        inputProps={{
                                            classes: {
                                                icon: commonStyles.fillSecondary,
                                            },
                                        }}
                                        label={t('saleTransactionsPage:state')}
                                        name="stage"
                                        options={statuses}
                                    />
                                </Box>
                                <Box mt={3} width="100%">
                                    <OutlinedDatePickerField
                                        color="secondary"
                                        label={t('saleTransactionsPage:filter.createdAt')}
                                        name="createdAt"
                                        placeholder={t('saleTransactionsPage:filter.chooseDate')}
                                    />
                                </Box>
                                <Box mt={3} width="100%">
                                    <OutlinedDatePickerField
                                        color="secondary"
                                        label={t('saleTransactionsPage:filter.targetHandoverDate')}
                                        name="targetHandoverDate"
                                        placeholder={t('saleTransactionsPage:filter.chooseDate')}
                                    />
                                </Box>
                                <Box mt={3} width="100%">
                                    <OutlinedSelectField
                                        color="secondary"
                                        inputProps={{
                                            classes: {
                                                icon: commonStyles.fillSecondary,
                                            },
                                        }}
                                        label={t('saleTransactionsPage:filter.awardedDealer')}
                                        name="awardedDealer"
                                        options={awardedDealerOptions}
                                    />
                                </Box>
                            </Box>
                            <Box className={styles.appltFilters}>
                                <Box alignSelf="center" margin="auto" width="100%">
                                    <Button
                                        className={styles.applyButton}
                                        color="secondary"
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        {t('saleTransactionsPage:filter.applyFilters')}
                                    </Button>
                                </Box>
                            </Box>
                        </Form>
                    </>
                )}
            </Formik>
        </Dialog>
    );
};

export default FilterDialog;
