import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../../../useCommonStyles';

const ResetPasswordTitle = () => {
    const commonStyles = useCommonStyles();
    const { t } = useTranslation(['resetPasswordPage']);

    return (
        <Grid xs={12} item>
            <Box mb={5}>
                <Typography align="center" className={commonStyles.fontWeightBold} color="secondary" variant="h5">
                    {t('resetPasswordPage:title')}
                </Typography>
            </Box>
        </Grid>
    );
};

export default ResetPasswordTitle;
