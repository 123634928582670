import React from 'react';
import { Redirect } from 'react-router-dom';
import { useCurrentUser } from '../../Session';
import { UserType } from '../../api';
import BasicLayout from '../../layouts/BasicLayout';
import { AllBiddingSessions } from '../../pages/DealerBiddings';

const AllBiddingSessionsRoute = () => {
    const currentUser = useCurrentUser();

    if (currentUser.type !== UserType.Dealer) {
        return <Redirect to="/403" />;
    }

    return (
        <BasicLayout>
            <AllBiddingSessions />
        </BasicLayout>
    );
};

export default AllBiddingSessionsRoute;
