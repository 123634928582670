import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../Session';
import { PurchaseTransactionStage, useGetPurchaseTransactionsQuery, UserType, DownloadFileType } from '../../api';
import ListPagination from '../../components/ListPagination';
import { useLoading } from '../../components/LoadingProvider';
import NoRecordsFound from '../../components/NoRecordsFound';
import {
    PurchaseTransactionsHeader,
    PurchaseTransactionsBody,
    PurchaseTransactionsFooter,
    FilterDialog,
    DownloadListFn,
} from './components';
import FilterMenu from './components/FilterMenu';

type ListContext = {
    search: string;
    page: number;
};

export type PurchaseTransactionsListProps = {
    downloadPurchaseTransactionsFile: DownloadListFn;
};

const pageSize = 9;

const PurchaseTransactionsList = ({ downloadPurchaseTransactionsFile }: PurchaseTransactionsListProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage']);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [stage, setStage] = useState<'all' | PurchaseTransactionStage>('all');
    const [createdAt, setCreatedAt] = useState<Date | null>(null);
    const [targetHandoverDate, setTargetHandoverDate] = useState<Date | null>(null);
    const currentUser = useCurrentUser();
    const { attach } = useLoading();

    const [{ page, search }, setListContext] = useState<ListContext>({ search: '', page: 0 });
    const [showFilterDialog, setShowFilterDialog] = useState<boolean>(false);
    const [showFilterMenu, setShowFilterMenu] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    const { data } = useGetPurchaseTransactionsQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            page: { limit: pageSize, offset: page * pageSize },
            filter: {
                search,
                stages: stage === 'all' ? null : [stage],
                createdAt,
                handoverAt: targetHandoverDate,
            },
            sort: {
                stagePriority: currentUser.type === UserType.Approver ? PurchaseTransactionStage.PendingApproval : null,
            },
        },
    });

    const setValues = useCallback(
        (salesStage, salesCreatedDate, targetHandoverDate) => {
            setStage(salesStage);
            setCreatedAt(salesCreatedDate);
            setTargetHandoverDate(targetHandoverDate);
        },
        [setStage, setCreatedAt]
    );

    const setActivePage = useCallback(
        (newPage: number) => {
            setListContext(state => ({ ...state, page: newPage }));
        },
        [setListContext]
    );

    const searchOnChange = useCallback(
        (searchValue: string) => {
            setListContext(state => ({ ...state, search: searchValue }));
        },
        [setListContext]
    );

    const count = data?.purchaseTransactions?.count || 0;
    const purchaseTransactions = data?.purchaseTransactions?.items || [];

    const downloadFile = useCallback(
        (type: DownloadFileType) => {
            const execute = async () => {
                await downloadPurchaseTransactionsFile({
                    downloadFileType: type,
                    filter: {
                        search,
                        stages: stage === 'all' ? null : [stage],
                        createdAt,
                        handoverAt: targetHandoverDate,
                    },
                    sort: {
                        stagePriority:
                            currentUser.type === UserType.Approver ? PurchaseTransactionStage.PendingApproval : null,
                    },
                });
            };

            attach(execute());
        },
        [attach, createdAt, currentUser.type, downloadPurchaseTransactionsFile, search, stage, targetHandoverDate]
    );

    const header = (
        <PurchaseTransactionsHeader
            downloadExcel={() => downloadFile(DownloadFileType.Excel)}
            downloadPdf={() => downloadFile(DownloadFileType.Pdf)}
            search={search}
            searchOnChange={searchOnChange}
            setShowFilterDialog={setShowFilterDialog}
            setShowFilterMenu={setShowFilterMenu}
            title={t('purchaseTransactionsPage:title')}
        />
    );

    if (count === 0) {
        return (
            <>
                {header}
                <NoRecordsFound />
                {isSmall && <PurchaseTransactionsFooter />}
            </>
        );
    }

    return (
        <>
            <FilterDialog
                createdAt={createdAt}
                open={showFilterDialog}
                setOpen={setShowFilterDialog}
                setValues={setValues}
                stage={stage}
                targetHandoverDate={targetHandoverDate}
            />
            <FilterMenu
                createdAt={createdAt}
                open={showFilterMenu}
                setOpen={setShowFilterMenu}
                setValues={setValues}
                stage={stage}
                targetHandoverDate={targetHandoverDate}
            />
            {header}
            <PurchaseTransactionsBody purchaseTransactions={purchaseTransactions} />
            <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
            {isSmall && <PurchaseTransactionsFooter />}
        </>
    );
};

export default PurchaseTransactionsList;
