import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useBiddingDuration = () => {
    const { t } = useTranslation(['saleTransactionsPage']);

    return useCallback(
        (date?: Date | string) => {
            const period = dayjs(date);
            const now = dayjs();
            if (!period.isAfter(now)) {
                return '';
            }

            const duration = dayjs.duration(period.diff(now));
            const years = parseInt(duration.format('Y'), 10);
            const months = parseInt(duration.format('M'), 10);
            const days = parseInt(duration.format('D'), 10);
            const hours = parseInt(duration.format('H'), 10);
            const minutes = parseInt(duration.format('m'), 10);
            const seconds = parseInt(duration.format('s'), 10);

            const hasYears = years > 0;
            const hasMonths = hasYears || months > 0;
            const hasDays = hasMonths || days > 0;
            const hasHours = hasDays || hours > 0;
            const hasMinutes = hasHours || minutes > 0;
            const hasSeconds = hasMinutes || seconds > 0;

            return [
                hasYears && t('saleTransactionsPage:dealerBiddings.remainYears', { count: years }),
                hasMonths && t('saleTransactionsPage:dealerBiddings.remainMonths', { count: months }),
                hasDays && t('saleTransactionsPage:dealerBiddings.remainDays', { count: days }),
                hasHours && t('saleTransactionsPage:dealerBiddings.remainHours', { count: hours }),
                hasMinutes && t('saleTransactionsPage:dealerBiddings.remainMinutes', { count: minutes }),
                hasSeconds && t('saleTransactionsPage:dealerBiddings.remainSeconds', { count: seconds }),
            ]
                .filter(Boolean)
                .join(' ');
        },
        [t]
    );
};

export default useBiddingDuration;
