import { useCallback } from 'react';
import join from 'url-join';
import { useRuntimeConfig } from '../runtimeConfig';

export const usePublicFn = () => {
    const { publicPath } = useRuntimeConfig();

    return useCallback((assetPath: string) => join(publicPath, assetPath), [publicPath]);
};

const usePublic = (assetPath: string) => usePublicFn()(assetPath);

export default usePublic;
