import {
    FormControl,
    FormControlLabel,
    Switch as MaterialSwitch,
    SwitchProps as MaterialSwitchProps,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useField, useFormikContext } from 'formik';

export interface SwitchProps extends Omit<MaterialSwitchProps, 'value' | 'onChange'> {
    name: string;
    label: string;
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControlLabel: {
            marginLeft: 0,
        },
        labelPlacementStart: {
            '& .MuiFormControlLabel-label': {
                marginRight: 'auto',
            },
        },
    })
);

const Switch = ({ name, label, labelPlacement, ...props }: SwitchProps) => {
    const styles = useStyles();
    const [field] = useField<boolean>({ name });
    const { setFieldValue } = useFormikContext();

    return (
        <FormControl fullWidth>
            <FormControlLabel
                className={styles.formControlLabel}
                classes={{
                    labelPlacementStart: styles.labelPlacementStart,
                }}
                control={
                    <MaterialSwitch
                        // spread props
                        {...props}
                        checked={field.value}
                        onChange={element => setFieldValue(name, element.target.checked)}
                    />
                }
                label={label}
                labelPlacement={labelPlacement}
            />
        </FormControl>
    );
};

export default Switch;
