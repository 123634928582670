import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PurchaseTransactionStage } from '../api';

const usePurchaseTransactionStatus = (transactionStatus: PurchaseTransactionStage) => {
    const { t } = useTranslation(['purchaseTransactionsPage']);

    return useMemo(() => {
        switch (transactionStatus) {
            case PurchaseTransactionStage.InitialValuation:
                return t('purchaseTransactionsPage:filter.initialValuation');

            case PurchaseTransactionStage.PendingAcceptance:
                return t('purchaseTransactionsPage:filter.pendingAcceptance');

            case PurchaseTransactionStage.FinalValuation:
                return t('purchaseTransactionsPage:filter.finalValuation');

            case PurchaseTransactionStage.PendingApproval:
                return t('purchaseTransactionsPage:filter.pendingApproval');

            case PurchaseTransactionStage.Handover:
                return t('purchaseTransactionsPage:filter.handover');

            case PurchaseTransactionStage.Completed:
                return t('purchaseTransactionsPage:filter.completed');

            case PurchaseTransactionStage.Cancelled:
                return t('purchaseTransactionsPage:filter.cancelled');

            default:
                return '';
        }
    }, [t, transactionStatus]);
};

export default usePurchaseTransactionStatus;
