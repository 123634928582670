import { Box, Card, CardContent, FormControl, Grid, Link, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { isEmpty, split, trim } from 'lodash/fp';
import { Trans, useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';
import OutlinedInputField from '../../../components/fields/OutlinedInputField';
import useBasicStyle from '../../../layouts/BasicLayout/useBasicStyles';
import { useHandleError } from '../../../utilities/handleErrors';
import { useStyles } from './CreateSaleTransactionBody';
import CreateSaleTransactionHeader from './CreateSaleTransactionHeader';
import { defaultValue, Page, useCreateSalesTransactionState } from './CreateSalesTransactionStateProvider';
import { CreateSaleTransactionFormValues } from '.';

export type VehiclePrefillFormValues = {
    raw: string;
};

const ltaLink = 'https://vrl.lta.gov.sg/lta/vrl/action/pubfunc?ID=EnquireRebateBeforeDeReg';

const parseNumber = (input: string, withDecimal = false) => {
    const purified = input.replace(/[^0-9.-]+/g, '');
    const parsed = withDecimal ? parseFloat(purified) : parseInt(purified, 10);

    return Number.isNaN(parsed) ? null : parsed;
};

const parseDate = (input: string) => {
    const value = dayjs(input);

    return value.isValid() ? value.toDate() : null;
};

const contentToFieldName: Record<string, keyof CreateSaleTransactionFormValues['vehicle']> = {
    'Vehicle No.': 'number',
    'Vehicle Make': 'make',
    'Vehicle Model': 'model',
    'Primary Colour': 'primaryColour',
    'Secondary Colour': 'secondaryColour',
    'Engine No.': 'engineNumber',
    'Chassis No.': 'chassisNumber',
    'COE Category': 'coeCategory',
    'OPC Cash Rebate Eligibility': 'opcCashRebateEligibility',
    'LTA Message': 'ltaMessage',

    // boolean
    'Vehicle to be Exported': 'beExported',
    'PARF Eligibility': 'parfEligibility',

    // date
    'Intended Deregistration Date': 'intendedDeregistrationDate',
    'PARF Eligibility Expiry Date': 'parfEligibilityDate',
    'COE Expiry Date': 'coeExpiryDate',
    'OPC Cash Rebate Eligibility Expiry Date': 'opcCashRebateEligibilityExpiryDate',
    'Original Registration Date': 'originalRegistrationDate',
    'First Registration Date': 'firstRegistrationDate',

    // int
    'Manufacturing Year': 'manufacturingYear',
    'Transfer Count': 'transferCount',
    'COE Period(Years)': 'coePeriodYear',

    // sanitizeHtml
    'Maximum Power Output': 'maximumPowerOutput',

    // float
    'Open Market Value': 'openMarketValue',
    'Actual ARF Paid': 'actualARFPaid',
    'PARF Rebate Amount': 'parfRebateAmount',
    'QP Paid': 'qpPaid',
    'COE Rebate Amount': 'coeRebateAmount',
    'OPC Cash Rebate Amount': 'opcCashRebateAmount',
    'PQP Paid': 'pqpPaid',
    'Total Rebate Amount': 'totalRebateAmount',
};

const parsePrefilledFields = (raw: string | null | undefined, currentValue: CreateSaleTransactionFormValues) => {
    if (isEmpty(raw)) {
        return {
            ...currentValue,
            vehicle: defaultValue.vehicle,
        };
    }

    const lines = split(/\r?\n/, raw).filter(Boolean);

    const result: CreateSaleTransactionFormValues = currentValue;
    let fieldName: keyof CreateSaleTransactionFormValues['vehicle'] | undefined;

    for (const line of lines) {
        if (fieldName) {
            switch (fieldName) {
                case 'number':
                case 'make':
                case 'model':
                case 'primaryColour':
                case 'secondaryColour':
                case 'engineNumber':
                case 'chassisNumber':
                case 'coeCategory':
                case 'opcCashRebateEligibility':
                case 'ltaMessage':
                    result.vehicle[fieldName] = trim(line);
                    break;

                case 'beExported':
                case 'parfEligibility':
                    result.vehicle[fieldName] = trim(line).toLowerCase().includes('yes');
                    break;

                case 'intendedDeregistrationDate':
                case 'originalRegistrationDate':
                case 'firstRegistrationDate':
                case 'parfEligibilityDate':
                case 'coeExpiryDate':
                case 'opcCashRebateEligibilityExpiryDate':
                    result.vehicle[fieldName] = parseDate(trim(line));
                    break;

                case 'manufacturingYear':
                case 'transferCount':
                case 'coePeriodYear':
                    result.vehicle[fieldName] = parseNumber(trim(line));
                    break;

                case 'maximumPowerOutput':
                    result.vehicle[fieldName] = sanitizeHtml(trim(line)).replace(/[\t\n]/g, '');
                    break;

                case 'openMarketValue':
                case 'actualARFPaid':
                case 'parfRebateAmount':
                case 'qpPaid':
                case 'coeRebateAmount':
                case 'opcCashRebateAmount':
                case 'pqpPaid':
                case 'totalRebateAmount':
                    result.vehicle[fieldName] = parseNumber(trim(line), true);
                    break;

                default:
                    break;
            }

            fieldName = undefined;

            continue;
        }

        const parsedFieldName = contentToFieldName[trim(line)];
        if (parsedFieldName) {
            fieldName = parsedFieldName;
        }
    }

    return result;
};

const VehiclePrefillForm = () => {
    const styles = useStyles();
    const theme = useTheme();
    const basicStyles = useBasicStyle();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    useTranslation(['saleTransactionsPage']);

    const {
        state: { prefillRaw, formValue },
        actions,
    } = useCreateSalesTransactionState();

    const onSubmit = useHandleError(async ({ raw }: VehiclePrefillFormValues) => {
        actions.setFormValue(parsePrefilledFields(raw, formValue));
        actions.setPrefillRaw(raw);
        actions.setCurrentPage(Page.VehicleForm);
    }, []);

    return (
        <Formik initialValues={{ raw: prefillRaw }} onSubmit={onSubmit}>
            {() => (
                <Form>
                    <CreateSaleTransactionHeader currentPage={Page.VehiclePrefill} />
                    <Box className={basicStyles.mainDesktop}>
                        <Box className={styles.root} mb={isSmall ? 10 : 0}>
                            <Grid container>
                                <Grid xs={12} item>
                                    <Card>
                                        <CardContent>
                                            <Box mb={2}>
                                                <Typography>
                                                    <Trans i18nKey="saleTransactionsPage:prefill.note">
                                                        <Link
                                                            color="secondary"
                                                            href={ltaLink}
                                                            target="_blank"
                                                            underline="always"
                                                        >
                                                            link
                                                        </Link>
                                                    </Trans>
                                                </Typography>
                                            </Box>
                                            <Grid spacing={2} container>
                                                <Grid lg={4} md={6} xs={12} item>
                                                    <FormControl fullWidth>
                                                        <OutlinedInputField name="raw" rows={10} multiline />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default VehiclePrefillForm;
